import platformApiUtils from '@ivendi/platform-api-utils';
import * as actionTypes from '../actionTypes';
import { cfcPrefillParser } from '../../core/apiDataParsers/cfcPreFillDataParser';
import * as quoteUtils from '../../utils/quoteUtils';
import _ from 'lodash';
import { filterQuotes, mapFundersToQuotes } from '../../core/helpers';
import { parseQuotes, addQuoteErrors } from '../../core/apiDataParsers/quotesParser';
import {
  trackCfcEligibilityCheckSubmitted,
  trackCfcEligibilityCheckCompleted,
  trackCfcEligibilityCheckFailed,
  formatQuoteVehicleToCfcTracking,
  getMaxEligibilityMatchRate,
  trackFinanceQuotesFailed,
  formatFinanceQuotesFailed
} from '../../tracking/avoTracking';
import appStore from '../../mobx-stores/appStore';
import { replace } from '../../routerHistory';
import { objectToSearchParams } from 'utils/objectToSearchParams';

export function startQuoting(
  customerId,
  customerEmail,
  vehicle,
  quoteRequest,
  restrictToProductType,
  restrictToFunderCode,
  customerType,
  restrictQuotesByProduct
) {
  return (dispatch, getState) => {
    const state = getState();

    let productSettings = _.map(state.products.items, function(product) {
      return {
        Name: product.Name,
        AprOnly: product.AprOnly,
        ProductUid: product.ProductUid,
        CommissionType: product.Settings.CommissionType,
        Rate: product.Settings.Rate,
        Visible: product.Settings.Visible,
        FunderCode: product.FunderCode,
        FunderProductCode: product.FunderProductCode
      };
    });

    dispatch({
      type: actionTypes.START_QUOTING,
      customerId,
      customerEmail,
      vehicle,
      quoteRequest,
      restrictToProductType,
      restrictToFunderCode,
      restrictQuotesByProduct,
      productSettings,
      customerType
    });
  };
}

export function startRequoting(
  customerId,
  customerEmail,
  vehicle,
  quoteRequest,
  customerType,
  currentProductUid,
  newAPR,
  preApprovalData
) {
  return (dispatch, getState) => {
    const state = getState();

    const productSettings = state.products.items
      .filter((product) => product.ProductUid === currentProductUid)
      .map((product) => ({
        Name: product.Name,
        AprOnly: product.AprOnly,
        ProductUid: product.ProductUid,
        // Hardcoded to 'APR' to ensure returned quotes match Rate we supply.
        CommissionType: 'APR',
        Rate: newAPR,
        Visible: product.Settings.Visible,
        FunderCode: product.FunderCode,
        FunderProductCode: product.FunderProductCode
      }));

    // Set Product + New APR
    // Set the current view to the list of quotes
    dispatch({
      type: actionTypes.START_REQUOTING,
      customerId,
      customerEmail,
      vehicle,
      quoteRequest,
      productSettings,
      customerType,
      isRequote: state.quotes.isRequote,
      PreApprovalData: preApprovalData
    });

    // Fetch the quotes with the new APR
    dispatch(changeCosts(quoteRequest, preApprovalData));
  };
}

export function changeView(view) {
  return { type: actionTypes.CHANGE_QUOTE_VIEW, view };
}

export function changeTerm(term) {
  return { type: actionTypes.CHANGE_MONTHLY_PAYMENT_TERM, term };
}

export function backToPreviousView() {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.CHANGE_QUOTE_VIEW, view: getState().quotes.previousView });
  };
}

export function changeCosts(quoteDetails, preApprovalData = {}) {
  return (dispatch, getState) => {
    const { ...quoteRequest } = quoteDetails;
    const CustomerType = quoteRequest.CustomerType;
    const state = getState();
    let quoteeUid = state.dealership.WebQuotewareId || state.dealership.QuotewareId;
    let loanType = quoteDetails.LoanType;
    let hasCheckedEligibility = state.quotes.hasCheckedEligibility;
    let eligibilityCheckDetails = state.quotes.eligibilityCheckDetails;
    let vehicle = state.quotes.vehicle;
    let restrictToProductType = state.quotes.restrictToProductType;
    let restrictToFunderCode = state.quotes.restrictToFunderCode;
    let restrictQuotesByProduct = state.quotes.restrictQuotesByProduct;
    let dealershipId = state.dealership.Id;
    let productSettings = state.quotes.productSettings
      .filter((productSetting) => appStore.uiState.canUseLender(productSetting.FunderCode))
      .filter((product) => {
        if (loanType === 'vapLoan') {
          return product.FunderProductCode === 'BL VAP' && product.FunderCode === restrictToFunderCode;
        } else if (loanType === 'negativeEquityLoan') {
          return product.FunderProductCode === 'BL NEG EQ' && product.FunderCode === restrictToFunderCode;
        } else if (loanType === 'vehicleLoan') {
          if (quoteDetails.FinanceType === 'HP') {
            return product.FunderProductCode === 'HP' && product.FunderCode === restrictToFunderCode;
          } else if (quoteDetails.FinanceType === 'PCP') {
            return product.FunderProductCode === 'PCP' && product.FunderCode === restrictToFunderCode;
          }
        } else {
          return product;
        }
      });
    dispatch({ type: actionTypes.CHANGE_COSTS, costs: quoteRequest, customerType: CustomerType });
    dispatch({ type: actionTypes.FETCH_QUOTES });

    if (hasCheckedEligibility) {
      quoteUtils
        .fetchQuotes(quoteeUid, vehicle, quoteRequest, productSettings, loanType)
        .then((response) => parseQuotes(response))
        .then((response) =>
          filterQuotes(response, restrictToProductType, restrictToFunderCode, restrictQuotesByProduct)
        )
        .then((response) => addQuoteErrors(response, CustomerType))
        .then((quotes) => mapFundersToQuotes(quotes, state.funders))
        .then((quotes) => {
          dispatch({
            type: actionTypes.FETCH_QUOTES_SUCCESS,
            quotes,
            CustomerType,
            hasAdjustedBalloon: quoteUtils.isBalloonAdjusted(quoteRequest, quotes),
            isRequote: state.quotes.isRequote
          });
          return quotes;
        })
        .catch((error) => dispatch({ type: actionTypes.FETCH_QUOTES_ERROR, error }))
        .then((quotes) => {
          dispatch({ type: actionTypes.CHECK_ELIGIBILITY });
          return quoteUtils.checkEligibility(dealershipId, eligibilityCheckDetails, quotes);
        })
        .then((response) => dispatch({ type: actionTypes.CHECK_ELIGIBILITY_SUCCESS, response }))
        .then(() => dispatch({ type: actionTypes.CHANGE_COSTS_SUCCESS }))
        .catch((error) => dispatch({ type: actionTypes.CHECK_ELIGIBILITY_ERROR, error }));
    } else {
      quoteUtils
        .fetchQuotes(quoteeUid, vehicle, quoteRequest, productSettings, loanType)
        .then((response) => parseQuotes(response))
        .then((response) =>
          filterQuotes(response, restrictToProductType, restrictToFunderCode, restrictQuotesByProduct)
        )
        .then((response) => addQuoteErrors(response, CustomerType))
        .then((quotes) => mapFundersToQuotes(quotes, state.funders))
        .then((quotes) => {
          const hasViableQuotes = quotes?.length > 0 && !quotes.every((quote) => 'Errors' in quote);
          if (hasViableQuotes) {
            dispatch({
              type: actionTypes.FETCH_QUOTES_SUCCESS,
              quotes,
              CustomerType,
              hasAdjustedBalloon: quoteUtils.isBalloonAdjusted(quoteRequest, quotes),
              isRequote: state.quotes?.isRequote
            });
          } else {
            const trackingData = {
              financeQuoteBalloonPayment: quoteDetails?.Balloon ?? 0,
              financeQuoteJourney: state.quotes?.isRequote ? 'intercepted' : 'quick_quote',
              financeQuoteCashDeposit: quoteDetails.CashDeposit ?? 0,
              financeQuoteErrorMessage: quotes[0]?.Errors[0]?.Message
            };

            trackFinanceQuotesFailed(
              formatFinanceQuotesFailed({ ...quoteDetails, ...vehicle, dealershipId }, trackingData)
            );
          }
        })
        .catch((error) => {
          return dispatch({ type: actionTypes.FETCH_QUOTES_ERROR, error });
        })
        .then(() => dispatch({ type: actionTypes.CHANGE_COSTS_SUCCESS }));
    }
  };
}

export function changeCommissions(settings) {
  return (dispatch, getState) => {
    const quoteRequest = getState().quotes.quoteRequest;
    quoteRequest.CustomerType = getState().quotes.customerType;
    dispatch({ type: actionTypes.CHANGE_QUOTE_COMMISSIONS, settings });
    dispatch(changeCosts(quoteRequest));
  };
}

export function addQuoteForCompare(quoteId) {
  return { type: actionTypes.COMPARE_QUOTE_ADD, quoteId };
}

export function removeQuoteForCompare(quoteId) {
  return { type: actionTypes.COMPARE_QUOTE_REMOVE, quoteId };
}

export function preFillEligibilityFormDetails() {
  return (dispatch, getState) => {
    let state = getState();
    let dealershipId = state.dealership.Id;
    let email = state.quotes.customerEmail;

    if (
      !email &&
      state.application &&
      state.application.PersonalDetails &&
      state.application.PersonalDetails.Email &&
      state.quotes.customerId === state.application.CustomerId
    ) {
      email = state.application.PersonalDetails.Email;
    }

    if (email) {
      dispatch({
        types: [
          actionTypes.GET_SHOWROOM_CFC_PREFILL,
          actionTypes.GET_SHOWROOM_CFC_PREFILL_SUCCESS,
          actionTypes.GET_SHOWROOM_CFC_PREFILL_ERROR
        ],
        callAPI: () =>
          platformApiUtils.get(`v1/cfcprospect/showroom-check/${dealershipId}?email=${encodeURIComponent(email)}`),
        payload: { email },
        parseResponse: (response) => cfcPrefillParser(response)
      });
    }
  };
}

export function updateEligibilityFormData(section, data) {
  return { type: actionTypes.UPDATE_ELIGIBILITY_FORM_DATA, section, data };
}

export function checkEligibility() {
  return (dispatch, getState) => {
    let state = getState();
    let dealershipId = state.dealership.Id;

    dispatch({ type: actionTypes.CHECK_ELIGIBILITY });
    trackCfcEligibilityCheckSubmitted(formatQuoteVehicleToCfcTracking(state.quotes.vehicle));

    quoteUtils
      .checkEligibility(dealershipId, state.quotes.eligibilityCheckDetails, state.quotes.returnedQuotes)
      .then((response) => {
        let consumerId = response.DealershipConsumerId;
        let vehicleId = response.VehicleId;

        if (response.FoundAddress && response.FoundProspectAtAddress) {
          dispatch({ type: actionTypes.CHECK_ELIGIBILITY_SUCCESS, response });
          trackCfcEligibilityCheckCompleted(
            formatQuoteVehicleToCfcTracking(state.quotes.vehicle),
            getMaxEligibilityMatchRate(response.QuoteResults).toString()
          );

          if (state.quotes.customerId === consumerId) {
            //todo something
            dispatch({ type: actionTypes.CHANGE_QUOTE_VIEW, view: 'LIST_VIEW' });
          } else {
            replace({
              pathname: `/d/${dealershipId}/consumers/${consumerId}/vehicle/${vehicleId}/quote`,
              search: `?${objectToSearchParams({ continue: true })}`
            });
          }
        } else {
          dispatch({ type: actionTypes.CHECK_ELIGIBILITY_ERROR, response });
          trackCfcEligibilityCheckFailed(
            formatQuoteVehicleToCfcTracking(state.quotes.vehicle),
            'Could not find prospect at address'
          );
        }
      })
      .catch((error) => {
        trackCfcEligibilityCheckFailed(formatQuoteVehicleToCfcTracking(state.quotes.vehicle), error.message);
        return dispatch({ type: actionTypes.CHECK_ELIGIBILITY_ERROR, error });
      });
  };
}

export function changeQuotingVehicle(vehicle) {
  return {
    type: actionTypes.CHANGE_QUOTING_VEHICLE,
    vehicle
  };
}
export function cleanupProductInfoModal() {
  return {
    type: actionTypes.CLEANUP_PRODUCT_INFO_MODAL
  };
}

export function onSortQuotes(field) {
  return {
    type: actionTypes.SORT_QUOTE_MODULE,
    field
  };
}

export function endQuoting() {
  return {
    type: actionTypes.END_QUOTING
  };
}
