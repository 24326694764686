import { useTranslation } from 'react-i18next';
import { splitCamelCaseToString } from '~/core/helpers';
import { getLenderUpdatesColor } from '~/core/lenderUpdatesColor';
import appStore from '~/mobx-stores/appStore';
import './applicationStatus.scss';

export const getStatusContentAndStyle = (
  t: any,
  { status, matchRate }: GetStatusContentAndStyleArgs
): { text: string; style: string | undefined } => {
  if (status) {
    return { text: t(`ApplicationStatus.${status}`), style: getLenderUpdatesColor(status) };
  }
  if (matchRate) {
    if (matchRate === -2) {
      return { text: t('ApplicationStatus.eligibility_score_expired'), style: undefined };
    } else if (matchRate < 0) {
      return { text: t('ApplicationStatus.unknown_eligibility'), style: undefined };
    } else if (matchRate > 50) {
      return { text: t('ApplicationStatus.high_eligibility'), style: 'high' };
    } else if (matchRate === 50) {
      return { text: t('ApplicationStatus.medium_eligibility'), style: 'medium' };
    } else if (matchRate < 50) {
      return { text: t('ApplicationStatus.low_eligibility'), style: 'low' };
    }
  }

  return { text: t('ApplicationStatus.unknown_eligibility'), style: undefined };
};

const ApplicationStatus = ({
  additionalClasses,
  status,
  matchRate,
  personalScorePercentage,
  handleClick,
  forceViewDetails
}: ApplicationStatusProps) => {
  const { t } = useTranslation('QuoteCard');

  const buttonTextAndStyle = getStatusContentAndStyle(t, { status, matchRate });

  const canUseCfcIvendiv2 = appStore.uiState.canUseCfcIvendiv2;

  const includesModal =
    matchRate !== -1 && (forceViewDetails || (canUseCfcIvendiv2 && personalScorePercentage !== null));

  return (
    <div
      className={`${additionalClasses} applicationStatus applicationStatus--${buttonTextAndStyle.style}`}
      data-th="application-eligibility-status"
    >
      {buttonTextAndStyle.text}

      {handleClick && includesModal && (
        <button className="applicationStatus__viewDetails" onClick={handleClick}>
          {t('ApplicationStatus.view_details')}
        </button>
      )}
    </div>
  );
};

type GetStatusContentAndStyleArgs = Pick<ApplicationStatusProps, 'status' | 'matchRate'>;

interface ApplicationStatusProps {
  additionalClasses?: string;
  status?: StatusType;
  handleClick?: () => void;
  matchRate?: number;
  personalScorePercentage?: number;
  forceViewDetails?: boolean;
}

export type StatusType =
  | 'Submitting'
  | 'Accepted'
  | 'ConditionalAccept'
  | 'Rejected'
  | 'NoDecisionYet'
  | 'Referred'
  | 'PaidOut'
  | 'Pending'
  | 'ProblemPayout'
  | 'Submitted'
  | 'NotTakenUp'
  | 'WaitingForFurtherInformation'
  | 'SentForPayout'
  | 'NotProceeding'
  | 'Other'
  | 'Error'
  | 'ProposalAdjustedAccepted'
  | 'ContactLender'
  | 'RateAdjustedAccepted'
  | 'NoChange'
  | 'Timeout'
  | 'Unknown'
  | 'NotSubmitted'
  | 'Medium'
  | 'Intercepted';

export default ApplicationStatus;
