import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import classes from './stockVrmPlate.module.scss';

const StockVrmPlate = ({ vrm, fuel = 'unknown', className }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={cn(classes.plateLeftSide, fuel.toLowerCase() === 'electric' && classes.isElectric)}></div>
      <div className={classes.plate}>{vrm || ' '}</div>
    </div>
  );
};

StockVrmPlate.propTypes = {
  vrm: PropTypes.string,
  fuel: PropTypes.string
};
export default StockVrmPlate;
