import { combineReducers } from 'redux';
import addVehicleReducer from './addVehicle/addVehicleReducer';
import applicationReducer from './application/applicationReducer';
import componentsReducer from './component/componentReducer';
import customerUiReducer from './customer/customerUiReducer';
import customerEntityReducer from './customerEntity/customerEntityReducer';
import dashboardReducer from './dashboard/dashboardReducer';
import dealershipReducer from './dealership/dealershipReducer';
import financeApplicationsReducer from './financeApplications/financeApplicationsReducer';
import fundersReducer from './funder/fundersReducer';
import globalDefaultSettingsReducer from './globalSettings/globalDefaultSettingsReducer';
import latestDealsReducer from './latestDeals/latestDealsReducer';
import lenderUpdatesReducer from './lenderUpdates/lenderUpdatesReducer';
import modalReducer from './modal/modalReducer';
import optionsReducer from './options/optionsReducer';
import platformUpdatesReducer from './platformUpdates/platformUpdatesReducer';
import productsReducer from './product/productsReducer';
import quickQuoteReducer from './quickQuote/quickQuoteReducer';
import quotesReducer from './quote/quotesReducer';
import sendDealReducer from './sendDeal/sendDealReducer';
import sessionReducer from './session/sessionReducer';
import userEntityReducer from './userEntity/userEntityReducer';

const rootReducer = combineReducers({
  application: applicationReducer,
  components: componentsReducer,
  dashboard: dashboardReducer,
  dealership: dealershipReducer,
  financeApplications: financeApplicationsReducer,
  funders: fundersReducer,
  globalDefaultSettings: globalDefaultSettingsReducer,
  latestDeals: latestDealsReducer,
  lenderUpdates: lenderUpdatesReducer,
  modals: modalReducer,
  options: optionsReducer,
  platformUpdates: platformUpdatesReducer,
  products: productsReducer,
  quickQuote: quickQuoteReducer,
  quotes: quotesReducer,
  sendDeal: sendDealReducer,
  session: sessionReducer,
  entities: combineReducers({
    customers: customerEntityReducer,
    users: userEntityReducer
  }),
  ui: combineReducers({
    addVehicle: addVehicleReducer,
    customer: customerUiReducer
  })
});

export default rootReducer;
