import removeLeadingSlash from './removeLeadingSlash';

// Only works for non-DE vehicles
const generateImageUrlFromVrmVinRef = ({ vrm, vin, dealerReference, params, imageNum }) => {
  const path = (vrm || vin || dealerReference || '')
    .replace(/\s/g, '')
    .replace(/-/g, '')
    .toUpperCase();
  return `${process.env.REACT_APP_STOCK_IMAGE_CLOUDFRONT}/${path}/${imageNum}.jpg?${params}`;
};

// Only works for DE vehicles with `imgPth`
const generateImageUrlFromImgPth = ({ imgPth, params, imageNum }) => {
  const path = removeLeadingSlash(imgPth);
  const region = process.env.REACT_APP_REGION;

  return `${process.env.REACT_APP_STOCK_IMAGE_CLOUDFRONT}/${region}/${path}/${imageNum}.jpg?${params}`;
};

const generateFeedVehicleImageUrl = ({
  imgPth,
  vrm,
  vin,
  dealerReference,
  imageNum = 0,
  params = 'height=150&mode=max'
}) => {
  const region = process.env.REACT_APP_REGION.toLowerCase();

  const imageSource =
    region === 'uk'
      ? // Feed vehicles without AlgoliaVehicle.imgPth attribute (for non-DE vehicles)
        generateImageUrlFromVrmVinRef({ vrm, vin, dealerReference, params, imageNum })
      : // Feed vehicles with AlgoliaVehicle.imgPth (for DE vehicles)
        generateImageUrlFromImgPth({ imgPth, params, imageNum });
  return imageSource;
};

export default generateFeedVehicleImageUrl;
