import { action, observable } from 'mobx';
import { lookupPostcode, lookupAddressId } from './api/postcodeLookupApi';
import Request from '../../../mobx-models/Request';

class PostcodeLookupModel {
  lookupPostcodeRequest = new Request();
  lookupAddressIdRequest = new Request();
  @observable postcode = '';
  @observable countryCode = '';
  @observable addresses = [];
  address = null;
  onSuccess = null;
  dealershipId = null;
  quoteId = null;

  @action
  setPostcode = (newPostCode) => {
    this.postcode = newPostCode && newPostCode.trim();
    return this;
  };

  @action
  setCountryCode = (countryCode) => {
    this.countryCode = countryCode.toUpperCase();
    return this;
  };

  @action
  setOnSuccess = (callback) => {
    this.onSuccess = callback;
    return this;
  };

  @action
  lookup = (dealershipId, quoteId) => {
    this.addresses = [];
    this.address = null;

    if (!this.postcode) {
      return;
    }

    if (!this.countryCode) {
      return;
    }

    this.lookupPostcodeRequest
      .onSuccess(this.handleLookupSuccess)
      .call(lookupPostcode, this.countryCode, this.postcode, dealershipId, quoteId);
  };

  @action
  handleLookupSuccess = (addresses) => {
    this.addresses = addresses;
  };

  @action
  lookupAddress = (addressId) => {
    this.address = null;

    this.lookupAddressIdRequest
      .setDelay(500)
      .onSuccess(this.handleLookupAddressSuccess)
      .onError(this.handleLookupAddressFail)
      .call(lookupAddressId, addressId);
  };

  @action
  handleLookupAddressSuccess = (address) => {
    this.addresses = [];
    this.address = address;

    if (this.onSuccess) {
      this.onSuccess(address);
    }
  };

  @action
  handleLookupAddressFail = () => {
    this.addresses = [];
    this.address = null;
  };
}

export default PostcodeLookupModel;
