import * as debug from '~/debug';

export const isBike = (klass) => {
  return klass && transformVehicleClass(klass) === 'motorbike';
};
export const isVan = (klass) => {
  return klass && transformVehicleClass(klass) === 'lcv';
};
export const isMotorhome = (klass) => {
  return klass && transformVehicleClass(klass) === 'motorhome';
};

// vrm lookup, edit vehicle endpoints return different vehicle classes for the same vehicle.
export const transformVehicleClass = (_klass) => {
  if (!_klass) return 'unknown';

  const klass = _klass.toLowerCase();
  if (klass === 'unknown') return 'unknown';

  switch (klass) {
    case 'bike':
    case 'motorbike':
      return 'motorbike';
    case 'lcv':
    case 'van':
      return 'van';
    case 'car':
      return 'car';
    default:
      debug.error(`Bad vehicle class received ${klass}`);
      return 'unknown';
  }
};

const transformTaxonomyData = (vehicleTaxonomyData) => {
  const klass = transformVehicleClass(vehicleTaxonomyData.Class);
  const isMotorcycle = isBike(klass);

  return {
    class: klass,
    make: vehicleTaxonomyData.Make || '',
    model: vehicleTaxonomyData.Model || '',
    derivative: vehicleTaxonomyData.Derivative || '',
    bodyStyle: vehicleTaxonomyData.BodyStyle || '',
    doors: vehicleTaxonomyData.Doors ? String(vehicleTaxonomyData.Doors) : '',
    seats: isMotorcycle ? 1 : vehicleTaxonomyData.NoofSeats || '',
    transmission: isMotorcycle ? 'Manual' : vehicleTaxonomyData.Transmission || '',
    fuelType: isMotorcycle ? 'Petrol' : vehicleTaxonomyData.FuelType || '',
    cc: vehicleTaxonomyData.CC || '',
    bhp: vehicleTaxonomyData.EnginePowerBHP || ''
  };
};

export default transformTaxonomyData;
