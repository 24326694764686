import i18n from '~/i18n';

export type FacilityType = 'HP' | 'PCP' | 'LP' | 'CS' | 'SC' | 'FS' | 'PCH' | 'CH' | 'FL' | 'OL' | 'CP' | 'BP';

export enum FacilityTypeIdEnum {
  HP = 1, // HP without balloon
  PCP = 2, // Personal Contract Purchase
  LP = 3, // Lease Purchase - HP with balloon
  CS = 4, // Conditional Sale
  SC = 5, // Supplier Creditor
  FS = 6, // Fixed Sum
  PCH = 7, // Personal Contact Hire
  CH = 8, // Contract Hire
  FL = 9, // Finance Lease
  OL = 10, // Operating Lease
  CP = 11, // Contract Purchase
  BP = 12 // Balanced payments
}
export type CustomerType = 'consumer' | 'corporate';
export enum CustomerTypeEnum {
  consumer = 1,
  corporate = 2
}
export enum SupportServiceContentTypesEnum {
  website = 1,
  phone,
  email
}
export const SupportServiceContentTypes = {
  1: 'website',
  2: 'phone',
  3: 'email'
};

export const getFacilityTypeId = (financeType: FacilityType) => FacilityTypeIdEnum[financeType];

export interface ConsumerDutyDetails {
  funderCode: string;
  facilityTypeId: number;
  funderProductUID: string; // guid
  customerTypeId: number;
  language: string; // "en-GB"
  dealershipId: string;
}

export const mapToIsoLanguage = (language: string) => {
  const ISOLanguage = {
    en: 'en-GB',
    fr: 'fr-FR',
    de: 'de-DE',
    it: 'it-IT',
    es: 'es-ES'
  };
  if (ISOLanguage[language as keyof typeof ISOLanguage]) {
    return ISOLanguage[language as keyof typeof ISOLanguage];
  }

  return language;
};
export const getConsumerDutyDetails = (
  chosenQuote: any,
  customerType: CustomerType,
  dealershipId: string
): ConsumerDutyDetails => {
  const consumerDutyDetails = {
    funderCode: chosenQuote.FunderCode,
    facilityTypeId: getFacilityTypeId(chosenQuote?.FinanceType),
    // combined quotes have nested VehicleLoan and PersonalLoan sections
    funderProductUID: chosenQuote.FunderProductUID,
    customerTypeId: CustomerTypeEnum[customerType],
    language: mapToIsoLanguage(i18n.language), // The language we use on DP doesn't match the format needed by the content service
    dealershipId
  };

  return consumerDutyDetails;
};

export interface FunderContentFilters {
  funderCode: string;
  language: string;
}

export const getFundersContentFilters = (funderCode: string | undefined | null): FunderContentFilters | undefined => {
  if (funderCode) {
    return {
      funderCode,
      language: mapToIsoLanguage(i18n.language)
    };
  }
};
