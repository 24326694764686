import * as snowplow from '@ivendi/snowplow-js';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Breadcrumbs from '../Common/Breadcrumbs';
import Button from '../Common/Button/Button';
import Page from '../Common/Page';
import PageNotFound from '../Common/PageNotFound';
import Panel from '../Common/Panel';
import PanelContent from '../Common/PanelContent';
import PanelHeader from '../Common/PanelHeader';
import TrackPageView from '../Common/Tracking/TrackPageView';
import VehicleImage from '../Common/VehicleImage';
import ConsumerVehicleInfo from '../Customer/components/ConsumerVehicleInfo';
import PushDealConfirm from './PushDealConfirm';
import PushDealPricing from './PushDealPricing';
import PushDealProgress from './PushDealProgress';
import PushDealSelectCustomer from './PushDealSelectCustomer';
import PushDealSelectQuotes from './PushDealSelectQuotes';
import PushDealSelectVehicle from './PushDealSelectVehicle';
import { VehicleValidForQuoting } from '../../validators/VehicleValidForQuoting';
import { compose } from 'redux';
import { withParams, withQuery } from 'hocs/router';
import './pushDealContainer.scss';

class PushDealContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTitle: 'selectVehicle',
      entry: ''
    };
  }

  get hasVehicle() {
    return (
      this.props.appStore.pushDealStore.vehicle.CapId ||
      this.props.appStore.pushDealStore.vehicle.VehicleId ||
      VehicleValidForQuoting.safeParse(this.props.appStore.pushDealStore.vehicle).success
    );
  }

  componentDidMount() {
    if (this.props.query && this.props.query.consumerId !== undefined) {
      this.props.appStore.customerStore.fetchCustomerData(this.props.query.consumerId);
    }

    let productSettings = this.props.productSettings.filter((productSetting) =>
      this.props.appStore.uiState.canUseLender(productSetting.FunderCode)
    );

    this.props.appStore.pushDealStore.resetCustomer();
    this.props.appStore.pushDealStore.quoteRequest.updateTotalPrice();
    const entry = this.props.entry || this.props.query.entry;

    if (entry === 'with-finance' && this.hasVehicle) {
      this.setState({
        activeTitle: 'pricing',
        entry: 'with-vehicle'
      });

      this.props.appStore.pushDealStore.setDefaultSettings(
        productSettings,
        this.props.dealership.WebQuotewareId,
        this.props.dealership.Id,
        this.props.appStore.vapStore.getVisibleDefaultProductsClassFiltered(
          this.props.appStore.pushDealStore.vehicle.Class
        )
      );
    } else if (entry === 'with-vehicle' && this.hasVehicle) {
      this.setState({
        activeTitle: 'pricing',
        entry: 'with-vehicle'
      });

      this.props.appStore.pushDealStore.setQuoteDefaultSettings(
        this.props.preferredDeposit,
        this.props.preferredTerm,
        this.props.preferredAnnualMileage,
        productSettings,
        this.props.dealership.WebQuotewareId,
        this.props.dealership.Id,
        this.props.appStore.vapStore.getVisibleDefaultProductsClassFiltered(
          this.props.appStore.pushDealStore.vehicle.Class
        )
      );
    } else {
      this.setState({
        entry: ''
      });

      this.props.appStore.pushDealStore.setQuoteDefaultSettings(
        this.props.preferredDeposit,
        this.props.preferredTerm,
        this.props.preferredAnnualMileage,
        productSettings,
        this.props.dealership.WebQuotewareId,
        this.props.dealership.Id,
        this.props.appStore.vapStore.getVisibleDefaultProductsClassFiltered(
          this.props.appStore.pushDealStore.vehicle.Class
        )
      );
    }

    if (this.props.query.consumerId && this.props.query.consumerId !== undefined) {
      this.props.appStore.customerStore.fetchCustomerData(this.props.query.consumerId).then(() => {
        this.props.appStore.pushDealStore.setCustomerFromRecord(this.props.appStore.customerStore.customer);
      });
    }

    this.props.appStore.quotingStore.getNewCarOffers(this.props.dealership.WebQuotewareId);
  }

  componentWillUnmount() {
    this.props.appStore.pushDealStore.resetPushDealStore(
      this.props.preferredDeposit,
      this.props.preferredTerm,
      this.props.preferredAnnualMileage
    );
  }

  changeTitle = (title) => {
    this.setState({
      activeTitle: title
    });
    window.ga && window.ga('send', 'event', 'PushDeal', 'changeTitle', `PushDealPageChangeTo${title}`);
    snowplow.trackSelfDescribingEvent(
      'pushDeal',
      'pushDeal__pageChange',
      JSON.stringify({
        pageTitle: title
      })
    );
  };

  changeVehicle = (title) => {
    this.changeTitle(title);
    this.props.appStore.pushDealStore.resetPushDealStore(
      this.props.preferredDeposit,
      this.props.preferredTerm,
      this.props.preferredAnnualMileage
    );
    window.ga && window.ga('send', 'event', 'PushDeal', 'pageView', `PushDealChangeVehicle`);
  };

  previousSection = (title) => {
    if (title === 'Select Vehicle' && this.state.entry === 'with-vehicle') {
      this.returnToPreviousPage();
    } else {
      this.changeTitle(title);
    }
  };

  returnToPreviousPage = () => {
    window.history.back();
  };

  renderContent = () => {
    const vehicle = this.props.appStore.pushDealStore.vehicle;

    if (this.state.activeTitle === 'selectVehicle') {
      return (
        <PushDealSelectVehicle
          continue={() => this.changeTitle('pricing')}
          preferredDeposit={this.props.preferredDeposit}
          preferredTerm={this.props.preferredTerm}
          preferredAnnualMileage={this.props.preferredAnnualMileage}
          productSettings={this.props.productSettings}
          webQuotewareId={this.props.dealership.WebQuotewareId}
          dealershipId={this.props.dealership.Id}
        />
      );
    }

    if (this.state.activeTitle === 'pricing') {
      return (
        <PushDealPricing
          nextSection={() => this.changeTitle('selectCustomer')}
          previousSection={() => this.previousSection('selectVehicle')}
          preferredDeposit={this.props.preferredDeposit}
          preferredTerm={this.props.preferredTerm}
          preferredAnnualMileage={this.props.preferredAnnualMileage}
          productSettings={this.props.productSettings}
          webQuotewareId={this.props.dealership.WebQuotewareId}
          dealershipId={this.props.dealership.Id}
          consumerId={this.props.query && this.props.query.consumerId}
          customerType={this.props.appStore.customerStore.customer?.CustomerType}
        />
      );
    }

    if (this.state.activeTitle === 'selectCustomer') {
      return (
        <PushDealSelectCustomer
          customerId={this.props.query.consumerId}
          changeTitle={this.changeTitle}
          consumerId={this.props.query && this.props.query.consumerId}
          email={this.props.email}
          nextSection={() => this.changeTitle('selectQuotes')}
        />
      );
    }

    if (this.state.activeTitle === 'selectQuotes') {
      return <PushDealSelectQuotes customerId={this.props.query.consumerId} changeTitle={this.changeTitle} />;
    }

    if (this.state.activeTitle === 'confirmAndSend') {
      return <PushDealConfirm changeTitle={this.changeTitle} vehicleClass={vehicle.Class} vrm={vehicle.Vrm} />;
    }
  };

  render() {
    if (!this.props.appStore.uiState.hasOrders) {
      return <PageNotFound />;
    }

    const titles = ['selectVehicle', 'pricing', 'selectCustomer', 'selectQuotes', 'confirmAndSend'];
    const { vehicle } = this.props.appStore.pushDealStore;
    const consumerId = this.props.query && this.props.query.consumerId;
    return (
      <Page>
        <TrackPageView dealership={this.props.params.dealershipId} />
        <div className="pushDealContainer__outer">
          {!this.props.hideBreadcrumbs && (
            <>
              {consumerId !== undefined && this.props.appStore.customerStore.customer ? (
                <Breadcrumbs
                  items={[
                    {
                      name: this.props.t('PushDealContainer.home'),
                      path: `/d/${this.props.params.dealershipId}`
                    },
                    {
                      name: this.props.t('PushDealContainer.customer_list'),
                      path: `/d/${this.props.params.dealershipId}/consumers`
                    },
                    {
                      name: this.props.t('PushDealContainer.consumer'),
                      path: `/d/${this.props.params.dealershipId}/consumers/${consumerId}`
                    },
                    {
                      name: this.props.t('PushDealContainer.send_deal'),
                      path: `/d/${this.props.params.dealershipId}/send-deal`
                    }
                  ]}
                  consumer={this.props.appStore.customerStore && this.props.appStore.customerStore.customer}
                />
              ) : (
                <Breadcrumbs
                  items={[
                    {
                      name: this.props.t('PushDealContainer.home'),
                      path: `/d/${this.props.params.dealershipId}`
                    },
                    {
                      name: this.props.t('PushDealContainer.send_deal'),
                      path: `/d/${this.props.params.dealershipId}/send-deal`
                    }
                  ]}
                />
              )}
            </>
          )}

          <Panel>
            <PanelHeader>{this.props.t('PushDealContainer.send_deal')}</PanelHeader>
            {(this.hasVehicle || vehicle.Condition === 'new') && (
              <div className="pushDealContainer__vehicleInfoContainer">
                <div className="pushDealContainer__vehicleInfo">
                  <div className="pushDealContainer__vehicleImageContainer">
                    <VehicleImage
                      imageSource={
                        vehicle.Condition.toLowerCase() === 'used'
                          ? this.props.appStore.pushDealStore.vehicleImage
                          : null
                      }
                      vehicleClass={vehicle.Class}
                      vrm={vehicle.Vrm}
                      imageWidth="130px"
                      iconMargin="25px auto"
                      imageHeight="100px"
                      onError={this.props.appStore.pushDealStore.resetVehicleImage}
                    />
                  </div>

                  <ConsumerVehicleInfo vehicle={this.props.appStore.pushDealStore.vehicle} />
                </div>

                <div className="pushDealContainer__vehicleButton">
                  {!this.props.query.consumerId && (
                    <Button buttonStyle="secondary" size="small" onClick={() => this.changeVehicle('selectVehicle')}>
                      {this.props.t('PushDealContainer.change_vehicle')}
                    </Button>
                  )}
                </div>
              </div>
            )}

            <PushDealProgress
              titles={titles}
              position={titles.indexOf(this.state.activeTitle) + 1}
              activeTitle={this.state.activeTitle}
              changeTitle={this.changeTitle}
            />

            <PanelContent noPadding={true}>{this.renderContent()}</PanelContent>
          </Panel>
        </div>
      </Page>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    dealership: state.dealership,
    preferredDeposit: state.globalDefaultSettings.DepositValue,
    preferredTerm: state.globalDefaultSettings.PreferredTerm,
    preferredAnnualMileage: state.globalDefaultSettings.DefaultAnnualMileage,
    productSettings: state.products.items,
    globalSettings: state.globalDefaultSettings
  };
}

export default compose(
  withQuery,
  withParams,
  withTranslation('PushDeal'),
  connect(mapStateToProps),
  inject('appStore'),
  observer
)(PushDealContainer);
