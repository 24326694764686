import { TFunction } from 'i18next';
import { ConsumerVehicleType } from '~/components/Common/ConsumerVehicleInfoPanel';
import appStore from '~/mobx-stores/appStore';

export function getVehicleIdentifier(vehicle: ConsumerVehicleType, quote: any, t: TFunction) {
  const { isDe, showVRM } = appStore?.uiState || {};

  let Vrm = null;
  let Vin = null;

  if (isDe) {
    Vin = vehicle?.Vin || null;
  } else if (showVRM) {
    Vrm =
      vehicle?.Vrm ||
      quote?.VehicleVrm ||
      (vehicle?.Condition?.toLowerCase() === 'new' && t('ConsumerVehicleInfo.new')) ||
      null;
  }

  return { Vrm, Vin };
}
