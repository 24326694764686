import { StylesConfig } from 'react-select';
import { ApiResponse } from '../types';

export const getChartOptions = (showLegend: boolean, data: any) => ({
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: data && showLegend ? true : false,
      position: 'top' as const,
      align: 'start' as const,
      onClick: () => {}
    }
  },
  interaction: {
    intersect: false
  }
});

export const legendPadding = {
  id: 'increase-legend-spacing',
  beforeInit(chart: any) {
    const originalFit = chart.legend.fit;
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      this.height += 20;
    };
  }
};

export const customSelectStyles: StylesConfig<any, false> = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #ccc',
    boxShadow: state.isFocused ? '0 2px 5px rgba(0, 0, 0, 0.1)' : 'none',
    '&:hover': {
      borderColor: '#ccc'
    }
  }),
  option: (provided, { isFocused, isSelected }) => ({
    ...provided,
    backgroundColor: isSelected ? '#3182CE' : isFocused ? '#f7f7f7' : 'none',
    color: isSelected ? '#fff' : '#4a4a4a',
    padding: '10px'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    transform: 'rotate(0deg)',
    transition: 'transform 0.3s ease'
  }),
  menu: (provided) => ({
    ...provided,
    border: '1px solid #ccc',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
  })
};

export const defaultLineChartData: ApiResponse = {
  lastModified: 'lastModified',
  points: ['points'],
  datasets: [
    {
      avg: [0],
      label: 'label',
      max: [0],
      min: [0],
      sum: [0],
      users: [0]
    }
  ]
};
