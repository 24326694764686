import PropTypes from 'prop-types';
import VRMPlate from '~Common/VrmPlate';
import cn from 'classnames';
import appStore from '~/mobx-stores/appStore';
import { useTranslation } from 'react-i18next';
import { ConsumerVehicleQuoteType, ConsumerVehicleType } from '~/components/Common/ConsumerVehicleInfoPanel';
import { getVehicleIdentifier } from '../utils';
import './consumerVehicleInfo.scss';

type AppStoreType = typeof appStore;
type ConsumerVehicleInfoProps = {
  vehicle: ConsumerVehicleType;
  quote?: ConsumerVehicleQuoteType;
  variant?: ConsumerVehicleInfoVariantType;
  appStore?: AppStoreType;
};

export type ConsumerVehicleInfoVariantType = 'column' | 'row' | 'alt';

const ConsumerVehicleInfo = ({ vehicle, quote, variant = 'row' }: ConsumerVehicleInfoProps) => {
  const { t } = useTranslation('Customer');
  const outer = cn('consumerVehicleInfo', {
    [`consumerVehicleInfo--${variant || 'row'}`]: true
  });

  const { Vrm, Vin } = getVehicleIdentifier(vehicle, quote, t);

  return (
    <div className={outer} data-th="vehicleInfo">
      {Vrm && (
        <div className="consumerVehicleInfo__vehicleIdentifier">
          <VRMPlate vrm={Vrm} />
        </div>
      )}
      {Vin && (
        <div className="consumerVehicleInfo__vehicleIdentifier">
          <div className="consumerVehicleInfo__vin">{Vin}</div>
        </div>
      )}
      <div className={`consumerVehicleInfo__makeModelDerivative makeModelDerivative--${variant || 'column'}`}>
        <span className="consumerVehicleInfo__makeModel">
          {vehicle?.Make} {vehicle?.Model}
        </span>
        <span className="consumerVehicleInfo__derivative">{vehicle?.Derivative}</span>
      </div>
    </div>
  );
};

ConsumerVehicleInfo.propTypes = {
  vehicle: PropTypes.shape({
    Vrm: PropTypes.string,
    Make: PropTypes.string.isRequired,
    Model: PropTypes.string.isRequired,
    Derivative: PropTypes.string.isRequired,
    Condition: PropTypes.oneOf(['New', 'Used', 'new', 'used'])
  }).isRequired
};

export { ConsumerVehicleInfo as ConsumerVehicleInfoUnwrapped };
export default ConsumerVehicleInfo;

