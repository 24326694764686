import React from 'react';
import PropTypes from 'prop-types';
import './buttonWithIcon.scss';
import Icon from '../Icon/Icon';
import classnames from 'classnames';

const ButtonWithIcon = ({ children, iconName, buttonStyle, onClick }) => {
  let iconClasses = classnames('buttonWithIcon', buttonStyle && `buttonWithIcon--${buttonStyle}`);

  return (
    <span className={iconClasses} onClick={onClick} data-th="ButtonWithIcon">
      <div className="buttonWithIcon__icon">
        <Icon name={iconName} />
      </div>
      <div>{children}</div>
    </span>
  );
};
ButtonWithIcon.defaultProps = {
  buttonStyle: 'primary'
};
ButtonWithIcon.propTypes = {
  children: PropTypes.node,
  iconName: PropTypes.string,
  buttonStyle: PropTypes.string,
  onClick: PropTypes.func
};

export default ButtonWithIcon;
