import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import Icon from '../Common/Icon/Icon';
import withFormState from './withFormState';
import './mobxButtonFieldGroup.scss';

@observer
@withFormState
class MobxButtonFieldGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldShowError: false
    };
  }

  handleBlur = () => {
    this.setState({ shouldShowError: true });
  };

  render() {
    let { error, errorMessage } = this.props;

    let showError = this.props.isFormSubmitted || this.state.shouldShowError || this.props.showError;

    let isValid = showError && !error;
    let isInvalid = showError && !!error;

    let iconClasses = classNames(
      'mobxButtonFieldGroup__icon',
      isValid && 'mobxButtonFieldGroup__tick',
      isInvalid && 'mobxButtonFieldGroup__cross'
    );

    const children = React.Children.toArray(this.props.children);

    return (
      <div>
        <div className="mobxButtonFieldGroup">
          <div className="mobxButtonFieldGroup__label">{React.cloneElement(children[0])}</div>
          <div className="mobxButtonFieldGroup__formElements">
            <div className="mobxButtonFieldGroup__input">
              {React.cloneElement(children[1], {
                isValid,
                isInvalid,
                showError,
                onBlur: this.handleBlur
              })}
            </div>
            <div className="mobxButtonFieldGroup__divider">or</div>
            <div className="mobxButtonFieldGroup__button">{React.cloneElement(children[2])}</div>
          </div>
          <div className={iconClasses}>
            {isValid && <Icon name="validation-tick" />}
            {isInvalid && <Icon name="validation-cross" />}
          </div>
          <div className="mobxButtonFieldGroup__errorMessage">
            {showError && this.props.error && <span>{errorMessage || error.message}</span>}
          </div>
        </div>
        {children[3] && <div className="mobxButtonFieldGroup__message">{children[3]}</div>}
      </div>
    );
  }
}

MobxButtonFieldGroup.propTypes = {
  children: PropTypes.any,
  error: PropTypes.object,
  shouldShowError: PropTypes.bool,
  isFormSubmitted: PropTypes.bool,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string
};

export default MobxButtonFieldGroup;
