import { types } from '../schema';

export default {
  Model: {
    Page: types.Number,
    PageSize: types.Number,
    Results: [
      {
        Id: types.String,
        Title: types.String,
        Content: types.String,
        CreatedDate: types.String,
        CreatedTimestamp: types.Number
      }
    ],
    SortDirection: types.String,
    SortField: types.String,
    Total: types.Number
  }
};
