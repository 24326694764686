import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import QuoteCosts from './QuoteCosts';
import QuoteResults from './QuoteResults';
import ReproposeToSameLenderModal from '../../Application/modals/ReproposeToSameLenderModal';
import CheckEligibility from './CheckEligibility';
import QuotingNotAvailable from './QuotingNotAvailable';
import CompareView from './CompareView';
import Modal from '../../Common/Modal/Modal';
import EditQuoteCommissionsForm from '../forms/EditQuoteCommissionsForm';
import ConsumerVehicleInfoPanel from '~/components/Common/ConsumerVehicleInfoPanel';
import ConsumerDutyContainer from '../../ConsumerDuty/containers/ConsumerDutyContainer';
import { inject, observer } from 'mobx-react';
import CombinedQuotesModal from '../modals/CombinedQuotesModal';
import CombinedQuoteMessage from '../../Common/CombinedQuoteMessage';
import LoansBreakdownView from './LoansBreakdownView';
import { compose } from 'redux';
import { withParams } from 'hocs/router';
import { trackFinanceQuoteApplySelected } from '~/tracking/avoTracking';

class QuoteModule extends React.Component {
  constructor() {
    super();
    this.state = {
      productForEditCommissions: null,
      isEditQuoteModalOpen: false,
      isReproposeModalOpen: false,
      isCombinedQuoteModalOpen: false,
      combinedQuoteToShow: {}
    };
  }

  componentDidMount() {
    this.props.updateQueryString();
    this.props.appStore.quotingStore.setHasViewedOptions(false);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.currentView !== this.props.currentView) {
      window.scrollTo(0, 0);
      this.props.updateQueryString();
    }
  }

  componentWillUnmount() {
    this.props.appStore.quotingStore.setHasViewedOptions(false);
  }

  fireFinanceQuoteApplySelectedEvent = (chosenQuote) => {
    const { returnedQuotes, vehicle, sortField, sortDirection, params } = this.props;
    const visibleQuotes = returnedQuotes.filter((x) => !x.Errors);
    const quotePosition = visibleQuotes.findIndex((x) => x.QuoteId === chosenQuote.QuoteId);

    trackFinanceQuoteApplySelected({
      dealershipId: params.dealershipId,
      financeFunderCode: chosenQuote.FunderCode,
      financeQuoteId: chosenQuote.QuoteId,
      financeQuoteMileage: chosenQuote.VehicleMileage,
      financeQuoteMonthlyPayment: chosenQuote.FollowingPayments,
      financeQuotePosition: quotePosition,
      financeQuoteProductId: chosenQuote.ProductId,
      financeQuoteProductType: chosenQuote.FinanceType,
      financeQuotesCount: visibleQuotes.length,
      financeQuotesSortDirection: sortDirection,
      financeQuotesSortField: sortField,
      financeQuoteTerm: chosenQuote.Term,
      financeQuoteTotalDeposit: chosenQuote.TotalDeposit,
      vehicleClass: vehicle.Class,
      vehicleCondition: vehicle.Condition,
      vehicleDerivative: vehicle.Derivative,
      vehicleMake: vehicle.Make,
      vehicleMileage: vehicle.Mileage,
      vehiclePrice: vehicle.Price,
      vehicleModel: vehicle.Model,
      vehicleVrm: vehicle.Vrm
    });
  };

  handleProceedWithQuote = (chosenQuote) => {
    this.setState({
      chosenQuote,
      isReproposeModalOpen: this.props.warnIfLenderIs === chosenQuote.FunderCode
    });

    if (this.props.warnIfLenderIs !== chosenQuote.FunderCode) {
      this.props.changeToConsumerDutyView();
    }

    this.fireFinanceQuoteApplySelectedEvent(chosenQuote);

    window.ga &&
      window.ga(
        'send',
        'event',
        this.props.isQuickQuote ? 'QuickQuoteResults' : 'QuoteResults',
        'ButtonPress',
        'Proceed'
      );
  };
  handleReproposeToSameLenderModalAgree = () => {
    this.closeModal();
    this.props.changeToConsumerDutyView();
  };
  handleSubmitFairProcessingModal = () => {
    this.proceedWithQuote(this.state.chosenQuote);
  };
  handleCloseFairProcessingModal = () => {
    this.setState({
      chosenQuote: null
    });
  };

  proceedWithQuote(quote) {
    this.props.onProceed(quote, this.props.quotes.quoteRequest);
  }

  handleProductNameClick = (productId) => {
    let productForEditCommissions = _.find(this.props.quotes.productSettings, {
      ProductUid: productId
    });

    window.ga && window.ga('send', 'event', 'EditSchemesModal', 'ButtonPress', 'View');
    this.setState({
      productForEditCommissions,
      isEditQuoteModalOpen: true
    });
  };
  handleProductCommissionsChange = (settings) => {
    this.props.changeComissions(settings);
    this.closeModal();
  };
  handleSave = (chosenQuote) => {
    this.setState(
      {
        chosenQuote
      },
      () => {
        this.props.onSave(chosenQuote);
      }
    );
    window.ga &&
      window.ga('send', 'event', this.props.isQuickQuote ? 'QuickQuoteResults' : 'QuoteResults', 'ButtonPress', 'Save');
  };
  closeModal = () => {
    this.setState({
      isEditQuoteModalOpen: false,
      isReproposeModalOpen: false,
      isCombinedQuoteModalOpen: false
    });
  };
  showCombinedQuoteClick = (selectedQuote) => {
    this.setState({
      isCombinedQuoteModalOpen: true,
      combinedQuoteToShow: selectedQuote
    });
  };
  showFullBreakdown = (selectedQuote) => {
    this.closeModal();

    if (selectedQuote) {
      this.setState({
        combinedQuoteToShow: selectedQuote
      });
    }

    this.props.setBreakDownView();
  };

  render() {
    if (!this.props.vehicle) {
      return null;
    }

    if (!this.props.appStore.uiState.canGenerateQuotes) {
      return <QuotingNotAvailable />;
    }

    let numLoans =
      this.state.combinedQuoteToShow.PersonalLoan && this.state.combinedQuoteToShow.NegativeEquityLoan ? 3 : 2;

    return (
      <div data-th="QuoteModule">
        <ConsumerVehicleInfoPanel vehicle={this.props.vehicle} quote={this.props.quote} />
        {this.props.currentView === 'COSTS_VIEW' && (
          <QuoteCosts
            defaultValues={this.props.defaultQuoteDetails}
            onSubmit={this.props.handleCostsChange}
            isLoading={this.props.quotes.isFetchingQuotes}
            vehicleClass={this.props.vehicle.Class}
            vehicleCondition={this.props.vehicle.Condition}
            onCancel={this.props.previousView ? this.props.handleBackToPreviousView : this.props.onCancel}
            checkingEligibilityState={this.props.checkingEligibilityState}
            hasCheckedEligibility={this.props.hasCheckedEligibility}
            isQuickQuote={this.props.isQuickQuote}
            dealershipId={this.props.params.dealershipId}
            customerId={this.props.customerId}
            customerType={this.props.customerType}
            vehicle={this.props.vehicle}
            quote={this.props.quote}
            tag={this.props.tag}
          />
        )}

        {this.props.currentView === 'CONSUMER_DUTY' && (
          <ConsumerDutyContainer
            dealershipId={this.props.params.dealershipId}
            chosenQuote={this.state.chosenQuote}
            customerType={this.props.customerType}
            onCancel={this.props.handleReturnToListViewClick}
            onContinue={this.handleSubmitFairProcessingModal}
            isRequote={!!this.props.preApprovalData}
          />
        )}

        {this.props.currentView === 'LIST_VIEW' && (
          <QuoteResults
            quotes={this.props.returnedQuotes}
            hasAdjustedBalloon={this.props.hasAdjustedBalloon}
            quotesForCompare={this.props.quotes.quotesForCompare}
            isLoading={this.props.quotes.isFetchingQuotes}
            onCompare={this.props.changeToMonthlyPaymentsView}
            onProceed={this.handleProceedWithQuote}
            onQuoteSelect={this.props.handleQuoteSelect}
            onChangeCosts={this.props.handleChangeCostsClick}
            onProductNameClick={this.handleProductNameClick}
            onCheckEligibilityClick={this.props.handleCheckEligibilityClick}
            onCancel={this.props.onCancel}
            enableShowroomCfc={this.props.enableShowroomCfc}
            hasCheckedEligibility={this.props.hasCheckedEligibility}
            craScorePercentage={this.props.craScorePercentage}
            personalScorePercentage={this.props.personalScorePercentage}
            proceedingState={this.props.proceedingState}
            chosenQuoteId={this.state.chosenQuote && this.state.chosenQuote.QuoteId}
            onSave={this.handleSave}
            onEligibilityMatchClick={this.props.onEligibilityMatchClick}
            onEligibilitySummaryClick={this.props.onEligibilitySummaryClick}
            isQuickQuote={this.props.isQuickQuote}
            isRequote={!!this.props.preApprovalData}
            isCustomerQuote={this.props.isCustomerQuote}
            enableSendDeal={this.props.enableSendDeal}
            onSortQuotes={this.props.onSortQuotes}
            sortField={this.props.sortField}
            sortDirection={this.props.sortDirection}
            onSendDeal={this.props.onSendDeal}
            vehicleClass={this.props.vehicle.Class}
            vehicleCondition={this.props.vehicle.Condition}
            customerType={this.props.customerType}
            showCombinedQuoteClick={this.showCombinedQuoteClick}
            proceedToBreakDown={this.showFullBreakdown}
            canCreateApplications={this.props.appStore.uiState.canCreateApplications}
          />
        )}

        {(this.props.currentView === 'COMPARE_VIEW' || this.props.currentView === 'MONTHLY_PAYMENTS_VIEW') && (
          <CompareView
            title={
              this.props.currentView === 'MONTHLY_PAYMENTS_VIEW'
                ? this.props.t('QuoteModule.price_position')
                : this.props.t('QuoteModule.product_compare')
            }
            onReturnToListClick={this.props.handleReturnToListViewClick}
            onChangeCostsClick={this.props.handleChangeCostsClick}
            changeToMonthlyPaymentsView={this.props.changeToMonthlyPaymentsView}
            changeToCompareView={this.props.changeToCompareView}
            currentTabIndex={this.props.currentView === 'MONTHLY_PAYMENTS_VIEW' ? 0 : 1}
            fullQuoteDetails={this.props.fullQuoteDetails}
            monthlyPayments={this.props.monthlyPayments}
            isFetchingMonthlyPayments={this.props.isFetchingMonthlyPayments}
            hasFetchMonthlyPaymentsError={this.props.hasFetchMonthlyPaymentsError}
            quotes={this.props.quotes}
            vehicle={this.props.vehicle}
            handleProceedWithQuote={this.handleProceedWithQuote}
            proceedingState={this.props.proceedingState}
            applyingState={this.props.applyingState}
            savingState={this.props.savingState}
            handleRemoveQuoteFromCompare={this.props.handleRemoveQuoteFromCompare}
            customerEmail={this.props.customerEmail}
            onPrint={this.props.onPrint}
            assignees={this.props.assignees}
            chosenQuoteId={this.state.chosenQuote && this.state.chosenQuote.QuoteId}
            changeMonthlyPaymentTerm={this.props.changeMonthlyPaymentTerm}
            selectTerm={this.props.selectTerm}
            selectMonthlyPayment={this.props.selectMonthlyPayment}
            closeModal={this.props.closeChangeMonthlyPaymentsTermModal}
            isFetchingQuotes={this.props.quotes.isFetchingQuotes}
            onSaveQuote={this.handleSave}
            updateMonthlyPayments={this.props.updateMonthlyPayments}
            proceedToBreakDown={this.showFullBreakdown}
          />
        )}

        {this.props.currentView === 'CHECK_ELIGIBILITY_VIEW' && (
          <CheckEligibility
            vehicle={this.props.vehicle}
            eligibilityCheckDetails={this.props.eligibilityCheckDetails}
            isLoadingPreFill={this.props.isLoadingCfcPreFill}
            onSectionSubmit={this.props.handleEligibilityFormSectionSubmit}
            onSubmit={this.props.handleEligibilityFormSubmit}
            customerEmail={this.props.customerEmail}
            checkingEligibilityState={this.props.checkingEligibilityState}
            hasAddressError={this.props.quotes.hasAddressError}
            dealershipId={this.props.dealershipId}
          />
        )}

        {this.props.currentView === 'LOANS_BREAKDOWN_VIEW' && (
          <LoansBreakdownView
            quote={this.state.combinedQuoteToShow}
            proceedToApplication={this.handleProceedWithQuote}
            onCancel={this.props.handleReturnToListViewClick}
            loadingState={this.props.proceedingState}
            applicationCreationLoading={this.props.applicationCreationLoading}
          />
        )}

        <Modal isOpen={this.state.isReproposeModalOpen} onClose={this.closeModal}>
          <ReproposeToSameLenderModal
            onAgree={this.handleReproposeToSameLenderModalAgree}
            canEdit={this.props.isEditableOnReproposeJourney}
            onEdit={this.props.onEditExistingApplication}
            close={this.closeModal}
          />
        </Modal>

        <Modal isOpen={this.state.isEditQuoteModalOpen} onClose={this.closeModal}>
          <EditQuoteCommissionsForm
            defaultValues={this.state.productForEditCommissions}
            onSubmit={this.handleProductCommissionsChange}
            onCancel={this.closeModal}
            hasCheckedEligibility={this.props.hasCheckedEligibility}
          />
        </Modal>

        <Modal
          isOpen={this.state.isCombinedQuoteModalOpen}
          onClose={this.closeModal}
          title={<CombinedQuoteMessage numberOfIcons={numLoans} />}
        >
          <CombinedQuotesModal quote={this.state.combinedQuoteToShow} onProceed={this.showFullBreakdown} />
        </Modal>
      </div>
    );
  }
}

QuoteModule.propTypes = {
  quotes: PropTypes.object.isRequired,
  hasAdjustedBalloon: PropTypes.bool,
  returnedQuotes: PropTypes.array,
  vehicle: PropTypes.object.isRequired,
  initialCosts: PropTypes.object,
  fullQuoteDetails: PropTypes.object,
  defaultQuoteDetails: PropTypes.object,
  onProceed: PropTypes.func.isRequired,
  proceedingState: PropTypes.string,
  savingState: PropTypes.string,
  applyingState: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  restrictToProductType: PropTypes.string,
  restrictToFunderCode: PropTypes.string,
  warnIfLenderIs: PropTypes.string,
  onEditExistingApplication: PropTypes.func,
  isEditableOnReproposeJourney: PropTypes.bool,
  customerEmail: PropTypes.string,
  eligibilityCheckDetails: PropTypes.object.isRequired,
  isLoadingCfcPreFill: PropTypes.bool,
  checkingEligibilityState: PropTypes.string,
  hasCheckedEligibility: PropTypes.bool,
  currentView: PropTypes.string,
  previousView: PropTypes.string,
  enableShowroomCfc: PropTypes.bool,
  startQuoting: PropTypes.func.isRequired,
  continueQuoting: PropTypes.func.isRequired,
  updateQueryString: PropTypes.func.isRequired,
  changeComissions: PropTypes.func.isRequired,
  handleCostsChange: PropTypes.func.isRequired,
  handleCompareQuotesClick: PropTypes.func,
  handleQuoteSelect: PropTypes.func.isRequired,
  handleChangeCostsClick: PropTypes.func.isRequired,
  handleCheckEligibilityClick: PropTypes.func.isRequired,
  handleReturnToListViewClick: PropTypes.func.isRequired,
  handleRemoveQuoteFromCompare: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  handleEligibilityFormSectionSubmit: PropTypes.func.isRequired,
  handleEligibilityFormSubmit: PropTypes.func.isRequired,
  craScorePercentage: PropTypes.number,
  personalScorePercentage: PropTypes.number,
  handleBackToPreviousView: PropTypes.func,
  isContinuingFromQuickQuote: PropTypes.bool,
  assignees: PropTypes.array,
  onSave: PropTypes.func,
  onEligibilityMatchClick: PropTypes.func,
  onEligibilitySummaryClick: PropTypes.func,
  isQuickQuote: PropTypes.bool,
  isCustomerQuote: PropTypes.bool,
  onSortQuotes: PropTypes.func,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  enableSendDeal: PropTypes.bool,
  onSendDeal: PropTypes.func,
  monthlyPayments: PropTypes.array,
  isFetchingMonthlyPayments: PropTypes.bool,
  hasFetchMonthlyPaymentsError: PropTypes.bool,
  changeToMonthlyPaymentsView: PropTypes.func,
  changeToCompareView: PropTypes.func,
  changeToConsumerDutyView: PropTypes.func,
  changeMonthlyPaymentTerm: PropTypes.func,
  selectTerm: PropTypes.func,
  closeChangeMonthlyPaymentsTermModal: PropTypes.func,
  selectMonthlyPayment: PropTypes.func,
  updateMonthlyPayments: PropTypes.func,
  dealershipId: PropTypes.string,
  appStore: PropTypes.object,
  customerId: PropTypes.string,
  customerType: PropTypes.string,
  params: PropTypes.object,
  location: PropTypes.object,
  restrictQuotesByProduct: PropTypes.string,
  setBreakDownView: PropTypes.func,
  applicationCreationLoading: PropTypes.bool,
  preApprovalData: PropTypes.object
};
export default compose(withParams, withTranslation('Quoting'), inject(['appStore']), observer)(QuoteModule);
