import platformApiUtils from '@ivendi/platform-api-utils';

export function lookupPostcode(countryCode, postcode, dealershipId, quoteId) {
  return platformApiUtils
    .post(
      `v2/addresses/intl-address/${countryCode}`,
      {
        Postcode: postcode
      },
      {
        dealershipId,
        quoteId
      }
    )
    .then((data) => {
      if (!data || !data.length) {
        throw new Error('Address not found.');
      }
      return data;
    });
}

export function lookupAddressId(addressId) {
  return platformApiUtils.post(`v2/addresses/intl-address-resolve/${addressId}`).then((address) => {
    return {
      BuildingName: address.BuildingName,
      BuildingNumber: address.BuildingNumber,
      PostTown: address.City,
      Company: address.Company,
      CountryName: address.CountryName,
      District: address.District,
      Postcode: address.PostalCode,
      ProvinceName: address.ProvinceName,
      Street: address.Street,
      SubBuilding: address.SubBuilding
    };
  });
}
