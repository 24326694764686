import { useMemo } from 'react';
import moment from 'moment';

const usePastDays = (day: number) => {
  const pastDays = useMemo(() => {
    return moment()
      .subtract(day, 'days')
      .toISOString();
  }, [day]);

  return pastDays;
};

export default usePastDays;
