import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

const FIA = produce(defaultRules, (draft: LenderConsumerConfig) => {
  /*
   * AffordabilityDetails defaulted to true:
   * GrossAnnual, IsReplacementLoan, DetailsOfReplacementLoan
   */
  draft.applicationFields.AffordabilityDetails!.ForeseeFutureDownturnInFinances = false;
  draft.applicationFields.AffordabilityDetails!.DownturnReason = false;
  draft.applicationStatuses.Pending!.isEditable = false;
  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances;
});
export default FIA;
