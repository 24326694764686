import React from 'react';
import { observer } from 'mobx-react';
import Request from '../../../mobx-models/Request';
import PropTypes from 'prop-types';
import LoadingSpinner from './LoadingSpinner';
import './loadingSelect.scss';

const LoadingSelect = ({ request, onChange, children, dataThook }) => {
  if (request.isLoading) {
    return (
      <div data-thook={`${dataThook}.Spinner`} className="loadingSelect loadingSelect--loading">
        <div className="loadingSelect__loadingSpinner">
          <LoadingSpinner size="small" />
        </div>
      </div>
    );
  }

  return (
    <select data-thook={`${dataThook}.Select`} className="loadingSelect" onChange={onChange}>
      {children}
    </select>
  );
};

LoadingSelect.propTypes = {
  request: PropTypes.instanceOf(Request),
  onChange: PropTypes.func,
  children: PropTypes.node
};

export default observer(LoadingSelect);
