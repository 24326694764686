import platformApiUtils from '@ivendi/platform-api-utils';
import { z } from 'zod';

export const StockSettingsSchema = z.object({
  id: z.string().optional(),
  mobileDeAccountNumber: z.string()
});
export const UpdateStockSettingsSchema = StockSettingsSchema.partial();

export type StockSettings = z.infer<typeof StockSettingsSchema>;
export type UpdateStockSettings = z.infer<typeof UpdateStockSettingsSchema>;

export const getStockSettings = async (dealershipId: string) => {
  const data = await platformApiUtils.get(`dealershipservice/api/v1/stocksettings/${dealershipId}`);
  return StockSettingsSchema.parse(data);
};

export const updateStockSettings = async ({
  dealershipId,
  stockSettings
}: {
  dealershipId: string;
  stockSettings: UpdateStockSettings;
}) => {
  const body = await UpdateStockSettingsSchema.parse(stockSettings);
  await platformApiUtils.put(
    `dealershipservice/api/v1/stocksettings/${dealershipId}/add-stock-settings-from-dealer-platform`,
    body
  );
};
