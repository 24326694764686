import React from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { default as QuoteCostsForm_JS } from './QuoteCostsForm';
import { default as QuoteCostsForm_TS } from './QuoteCostsForm_TS';

// @ts-ignore
const Index = ({ children: any, ...props }) => {
  const isFeatureOn = useFeatureIsOn('rtl-2110-quote-costs-form-ts');

  return isFeatureOn ? (
    // @ts-ignore
    <QuoteCostsForm_TS {...props}>{props.children}</QuoteCostsForm_TS>
  ) : (
    <QuoteCostsForm_JS {...props}>{props.children}</QuoteCostsForm_JS>
  );
};

export default Index;
