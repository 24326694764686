import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import PanelToolBar from '../../Common/PanelToolBar';
import Button from '../../Common/Button/Button';
import MediaQuery from 'react-responsive';
import QuotesList from './QuotesList';
import NoQuotes from './NoQuotes';
import ToggleSwitch from '../../Common/ToggleSwitch';
import EligibilitySummaryButton from '../../Common/EligibilitySummaryButton';
import QuoteToolBarSplitButton from '../../QuotesList/components/QuoteToolBarSplitButton';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';
import './quoteResults.scss';

@inject('appStore')
@observer
class QuoteResults extends React.Component {
  constructor() {
    super();
    this.state = {
      showAllQuotes: false,
      showFullQuote: true
    };
  }

  componentDidMount() {
    this.setState({
      showAllQuotes: this.props.appStore.quotingStore.hasViewedOptions
    });
    const quote = this.props.quotes.find((quote) => !!quote.QuoteeUid);
    if (quote) this.props.appStore.quotingStore.getNewCarOffers(quote.QuoteeUid);
  }

  handleViewMoreOptionsClick = () => {
    this.setState(
      (prevState) => ({
        showAllQuotes: !prevState.showAllQuotes
      }),
      () => {
        this.props.appStore.quotingStore.setHasViewedOptions(this.state.showAllQuotes);
      }
    );
  };

  handleToggleSchemesClick = () => {
    this.setState(
      (prevState) => ({
        showSchemes: !prevState.showSchemes
      }),
      () => {
        window.ga &&
          window.ga(
            'send',
            'event',
            'QuotesListView',
            `ShowQuoteSchemes${this.state.showSchemes ? 'TurnedOn' : 'TurnedOff'}`
          );
      }
    );
  };

  handleToggleFullQuoteClick = () => {
    this.setState(
      (prevState) => ({
        showFullQuote: !prevState.showFullQuote
      }),
      () => {
        window.ga &&
          window.ga(
            'send',
            'event',
            this.props.appStore.repExampleStore.repExampleSelected
              ? 'RepExSelectRepresentativeQuote'
              : 'QuotesListView',
            this.props.appStore.repExampleStore.repExampleSelected
              ? 'ShowFullQuote'
              : `ShowFullQuotes${this.state.showFullQuote ? 'TurnedOn' : 'TurnedOff'}`
          );
      }
    );
  };

  handleSendDeal = () => {
    this.props.onSendDeal(
      _.filter(this.props.quotes, (quote) => quote.isChecked),
      this.props.customerType
    );
    window.ga &&
      window.ga(
        'send',
        'event',
        this.props.isQuickQuote ? 'QuickQuoteResults' : 'QuoteResults',
        'ButtonPress',
        'SendDeal'
      );
  };

  render() {
    const { isRequote: isRequotingPage = false } = this.props;

    let quotes = this.state.showAllQuotes
      ? this.props.quotes
      : _.filter(this.props.quotes, (product) => {
          let shouldShowRegardless = false;

          if (product.Errors) {
            product.Errors.forEach((error) => {
              if (error.Number === 544 && this.props.vehicleClass === 'lcv') {
                shouldShowRegardless = true;
                error.Message = error.Message.includes('£')
                  ? error.Message
                  : error.Message.replace(
                      this.props.t('QuoteResults.adding_the'),
                      this.props.t('QuoteResults.adding_the_£')
                    );
              } else if (error.Number === 0) {
                shouldShowRegardless = true;
              }
            });
          }

          // TECH DEBT: This is a temporary fix to show BDK products in the quote results
          return (product.isVisible || product.FunderCode === 'BDK') && (!product.Errors || shouldShowRegardless);
        });

    let numSelectedQuotesForCompare = this.props.quotesForCompare.length;

    let numSelectedQuotes = _.filter(this.props.quotes, (quote) => quote.isChecked);

    let hasCheckedEligibility = this.props.hasCheckedEligibility;
    return (
      <Panel>
        {isRequotingPage && <PanelHeader noBorder>{this.props.t('QuoteResults.new_rate_quote')}</PanelHeader>}
        {!isRequotingPage && (
          <>
            <PanelHeader>{this.props.t('QuoteResults.quotes')}</PanelHeader>
            <PanelToolBar>
              <div className="quoteResults__toolbarInner">
                {!this.props.appStore.uiState.isBdk && (
                  <div className="quoteResults__showSchemes">
                    <div className="quoteResults__showSchemesInner">
                      <span className="quoteResults__showSchemesText">{this.props.t('QuoteResults.show_schemes')}</span>
                      <ToggleSwitch
                        id="compareToggle"
                        isActive={this.state.showSchemes}
                        handleUpdate={this.handleToggleSchemesClick}
                      />
                    </div>
                    <div className="quoteResults__showSchemesInner">
                      <span className="quoteResults__showSchemesText">
                        {this.props.t('QuoteResults.show_full_quotes')}
                      </span>
                      <ToggleSwitch
                        id="compareToggle"
                        isActive={this.state.showFullQuote}
                        handleUpdate={this.handleToggleFullQuoteClick}
                      />
                    </div>
                  </div>
                )}

                <MediaQuery maxWidth="1125px">
                  <div className="quoteResults__buttonContainer">
                    <QuoteToolBarSplitButton
                      onChangeDetails={this.props.onChangeCosts}
                      onSendOffer={this.handleSendDeal}
                      onCheckEligibility={this.props.onCheckEligibilityClick}
                      onHideMoreOptions={this.handleViewMoreOptionsClick}
                      onPricePosition={this.props.onCompare}
                      enableSendDeal={this.props.enableSendDeal}
                      numSelectedQuotesForCompare={numSelectedQuotesForCompare}
                      enableCheckEligibility={
                        this.props.appStore.uiState.canUseShowroomCfc &&
                        !hasCheckedEligibility &&
                        this.props.customerType &&
                        this.props.customerType.toLowerCase() !== 'corporate'
                      }
                    />
                  </div>
                </MediaQuery>

                <MediaQuery minWidth="1126px">
                  <div className="quoteResults__buttonContainer">
                    <div className="quoteResults__button">
                      <Button
                        buttonStyle="secondary"
                        type="button"
                        onClick={this.props.onChangeCosts}
                        id="Change Details"
                        dataThook="EditQuotesButton"
                      >
                        {this.props.t('QuoteResults.edit_quotes')}
                      </Button>
                    </div>
                    {this.props.appStore.uiState.canUseShowroomCfc &&
                      !hasCheckedEligibility &&
                      this.props.customerType &&
                      this.props.customerType.toLowerCase() !== 'corporate' && (
                        <div className="quoteResults__button">
                          <Button
                            buttonStyle="secondary"
                            type="button"
                            stretch={true}
                            onClick={this.props.onCheckEligibilityClick}
                            id="Check Eligibility"
                            dataThook="CheckEligibilityButton"
                          >
                            {this.props.t('QuoteResults.check_eligibility')}
                          </Button>
                        </div>
                      )}
                    <div className="quoteResults__button">
                      <Button
                        buttonStyle="secondary"
                        type="button"
                        onClick={this.handleViewMoreOptionsClick}
                        id={
                          this.state.showAllQuotes
                            ? this.props.t('QuoteResults.less_quotes')
                            : this.props.t('QuoteResults.more_quotes')
                        }
                        dataThook="MoreQuotesButton"
                      >
                        {this.state.showAllQuotes
                          ? this.props.t('QuoteResults.less_quotes')
                          : this.props.t('QuoteResults.more_quotes')}
                      </Button>
                    </div>
                    {!this.props.appStore.uiState.isBdk && (
                      <div className="quoteResults__button">
                        <div className="quoteResults__pricePositionButton">
                          <Button
                            buttonStyle="select"
                            type="button"
                            disabled={numSelectedQuotesForCompare < 1}
                            onClick={this.props.onCompare}
                            id="Price Position"
                            dataThook="PricePositionButton"
                          >
                            {this.props.t('QuoteResults.price_position')}
                          </Button>
                        </div>
                      </div>
                    )}
                    {this.props.enableSendDeal ? (
                      <div className="quoteResults__button">
                        <Button
                          buttonStyle="select"
                          type="button"
                          disabled={numSelectedQuotes < 1}
                          onClick={this.handleSendDeal}
                          id="Send Deal"
                          dataThook="SendDealButton"
                        >
                          {this.props.t('QuoteResults.send_deal')}
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </MediaQuery>
              </div>
            </PanelToolBar>
          </>
        )}
        {this.props.hasCheckedEligibility &&
          this.props.personalScorePercentage !== null &&
          this.props.craScorePercentage !== null && (
            <EligibilitySummaryButton
              onClick={() =>
                this.props.onEligibilitySummaryClick(this.props.personalScorePercentage, this.props.craScorePercentage)
              }
            />
          )}
        <PanelContent noPadding={true}>
          {this.props.isLoading ? (
            <div className="quoteResults__loading">
              <LoadingSpinner size="large" />
            </div>
          ) : quotes.length ? (
            <div>
              <QuotesList
                quotes={quotes}
                hasAdjustedBalloon={this.props.hasAdjustedBalloon}
                hasCheckedEligibility={hasCheckedEligibility}
                onProceed={this.props.onProceed}
                schemesVisible={this.state.showSchemes}
                onQuoteSelect={this.props.onQuoteSelect}
                onProductNameClick={this.props.onProductNameClick}
                enableShowroomCfc={this.props.enableShowroomCfc}
                chosenQuoteId={this.props.chosenQuoteId}
                onSave={this.props.onSave}
                onEligibilityMatchClick={this.props.onEligibilityMatchClick}
                isQuickQuote={this.props.isQuickQuote}
                isCustomerQuote={this.props.isCustomerQuote}
                onSortQuotes={this.props.onSortQuotes}
                sortField={this.props.sortField}
                sortDirection={this.props.sortDirection}
                proceedingState={this.props.proceedingState}
                vehicleClass={this.props.vehicleClass}
                customerType={this.props.customerType}
                showCombinedQuoteClick={this.props.showCombinedQuoteClick}
                proceedToBreakDown={this.props.proceedToBreakDown}
                showFullQuote={this.state.showFullQuote}
                newCarOffers={toJS(this.props.appStore.quotingStore.newCarOffers)}
                vehicleCondition={this.props.vehicleCondition}
              />
            </div>
          ) : (
            <NoQuotes />
          )}
          <div className="quoteResults__buttonFooter">
            <div className="quoteResults__cancelButton" key="cancel">
              <Button buttonStyle="cancel" type="button" onClick={this.props.onCancel}>
                {this.props.t('QuoteResults.cancel')}
              </Button>
            </div>
          </div>
        </PanelContent>
      </Panel>
    );
  }
}

QuoteResults.propTypes = {
  quotes: PropTypes.array.isRequired,
  hasAdjustedBalloon: PropTypes.bool,
  quotesForCompare: PropTypes.array,
  isLoading: PropTypes.bool,
  onChangeCosts: PropTypes.func.isRequired,
  onCompare: PropTypes.func,
  onProceed: PropTypes.func,
  proceedingState: PropTypes.string,
  onQuoteSelect: PropTypes.func,
  onProductNameClick: PropTypes.func,
  onCancel: PropTypes.func,
  enableShowroomCfc: PropTypes.bool,
  onCheckEligibilityClick: PropTypes.func,
  hasCheckedEligibility: PropTypes.bool,
  personalScorePercentage: PropTypes.number,
  craScorePercentage: PropTypes.number,
  chosenQuoteId: PropTypes.string,
  onSave: PropTypes.func,
  onEligibilityMatchClick: PropTypes.func,
  onEligibilitySummaryClick: PropTypes.func,
  isQuickQuote: PropTypes.bool,
  isRequote: PropTypes.bool,
  isCustomerQuote: PropTypes.bool,
  onSortQuotes: PropTypes.func,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  onSendDeal: PropTypes.func,
  enableSendDeal: PropTypes.bool,
  vehicleClass: PropTypes.string,
  appStore: PropTypes.object,
  customerType: PropTypes.string,
  showCombinedQuoteClick: PropTypes.func,
  proceedToBreakDown: PropTypes.func
};

export default withTranslation('Quoting')(inject('appStore')(observer(QuoteResults)));
