export const isOnlineSource = (application) => {
  const applicationSource = application.ClientApp || application.Referrer;

  return applicationSource && applicationSource !== 'ivendi-dealer-platform';
};

export const removeSpacesFromString = (string = '') => string.replace(/\s/g, '');

export const isLicensePlateRegisteredInUK = (licensePlate) => {
  // Regular Expression to Validate UK Number Plates found here: https://gist.github.com/danielrbradley/7567269

  const filter = /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/g;

  return filter.test(licensePlate);
};
