import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cn from 'classnames';
import MediaQuery from 'react-responsive';

import Button from '~Common/Button/Button';
import MoneyFormat from '~Common/MoneyFormat';
import AlertCard from '~Common/AlertCard/AlertCard';
import { isDefined } from '~/core/helpers';
import AlgoliaVehicle from '~/mobx-models/AlgoliaVehicle';
import * as quickQuoteActions from '~/redux/quickQuote/quickQuoteActions.js';
import { fetchImageCount } from '~/api/stockModule';
import * as debug from '~/debug';

import { StockListItemButtons, StockVrmPlate, UpdatingTag, ImageCount } from './components';
import { createImageSource } from '../StockListVehicleImage/StockListVehicleImage';
import { VehicleNumber } from '../../../../../../shared/components';

import { StockListItemDataAccuracy, StockListVehicleImage } from './../';

import stockStyles from '~SM/styles/stockStyles.module.scss';

import classes from './stockListItem.module.scss';
import { useNavigate } from 'hooks/useNavigate';
import { useParams } from 'react-router-dom';

const { tabletStart } = stockStyles;

const StockListItem = ({
  vehicle,
  appStore,
  changeQuickQuoteVehicle,
  handleNewDataPending,
  openModal,
  openDataIssueModal
}) => {
  const { canUsePushDeal, canGenerateQuotes, showVRM, showVehicleNumber, canViewDataAccuracy } = appStore.uiState;
  const { setStockListScrollPosition } = appStore.stockStore;

  const { t, i18n } = useTranslation('Stock');
  const navigate = useNavigate();
  const params = useParams();
  const lng = i18n.language;
  const {
    AccuracyScore,
    Class: Klass,
    DealerPlatformUpload,
    Derivative,
    Fuel,
    ImagesMetadata,
    Make,
    Mileage,
    Model,
    Price,
    RegistrationDate,
    SDerivative,
    SFuel,
    SMake,
    SModel,
    STransmission,
    Taxonomies,
    Transmission,
    Vrm,
    BodyStyle,
    Bhp,
    BadgeEngineSize,
    Doors,
    Colour,
    PwrKW,
    CC,
    Vin,
    DealerReference,
    Id,
    ValidationErrorCodes,
    ImgPth,
    Imgs
  } = vehicle;

  const handleUpdatingTagClick = () => {
    handleNewDataPending(vehicle);
  };
  const [imageCount, setImageCount] = React.useState(0);

  useEffect(() => {
    // BE hasn't processed the image count but it can be fetched
    if (Imgs === -1) {
      (async () => {
        const fetchedImgCount = await fetchImageCount(ImgPth);
        fetchedImgCount && setImageCount(fetchedImgCount);
      })();
    } else if (!!Imgs) {
      setImageCount(Imgs);
    }
  }, [Imgs, ImgPth]);

  const quoteVehicle = () => {
    let quoteVehicle = JSON.parse(JSON.stringify(vehicle));
    if (quoteVehicle.Klass === 'van') {
      quoteVehicle.Klass = 'lcv';
    }

    quoteVehicle.imageSource = createImageSource({
      vrm: Vrm,
      vin: Vin,
      dealerReference: DealerReference,
      dealerPlatformUpload: DealerPlatformUpload,
      imagesMetadata: toJS(ImagesMetadata),
      imgPth: ImgPth
    });

    changeQuickQuoteVehicle(quoteVehicle);
    navigate({
      pathname: `/d/${params.dealershipId}/quickquote/results`
    });
  };

  const editVehicleDetails = (refineType) => {
    setStockListScrollPosition(document.querySelector('.globalLayout__content-inner').scrollTop);
    switch (refineType) {
      case 'dpData':
        window.scrollTo(0, 0);
        navigate(`/d/${params.dealershipId}/stock/edit/${Id}/details`);
        break;
      case 'mediaData':
        if (DealerPlatformUpload) {
          throw new Error('Only vehicles uploaded by media companies can access this page');
        }
        window.scrollTo(0, 0);
        navigate(`/d/${params.dealershipId}/stock/${Id}/fix-issues`);
        break;

      default:
        debug.error(`Unknown refineType - ${refineType}`);
        throw new Error(`Unknown refineType'`);
    }
  };

  const pushDeal = () => {
    let pushDealVehicle = JSON.parse(JSON.stringify(vehicle));
    if (pushDealVehicle.Klass === 'van') {
      pushDealVehicle.Klass = 'lcv';
    }

    pushDealVehicle.imageSource = createImageSource({
      vrm: Vrm,
      vin: Vin,
      dealerReference: DealerReference,
      dealerPlatformUpload: DealerPlatformUpload,
      imagesMetadata: toJS(ImagesMetadata),
      imgPth: ImgPth
    });

    appStore.pushDealStore.setVehicleFromStock(pushDealVehicle, vehicle.Price);
    window.scrollTo(0, 0);
    navigate({
      pathname: `/d/${params.dealershipId}/send-deal`,
      query: {
        entry: 'with-vehicle'
      }
    });
  };

  // convert Accuracy score to star rating.
  const convertAccuracyScore = (stars, score) => {
    if (score === 0 || score === null || score === 4) {
      return 0;
    } else {
      return stars - score;
    }
  };

  let shouldRenderUpdating = false;
  const ids = JSON.parse(localStorage.getItem('updatedVehicleIds'));

  if (Taxonomies && Taxonomies.length > 0) {
    const dealerObj = Taxonomies.find((item) => item.origin === 'dealer');
    const stockObj = Taxonomies.find((item) => item.origin === 'stock');

    if (dealerObj) {
      shouldRenderUpdating = stockObj && stockObj.propagating === true && dealerObj.propagating;
    }
  }

  // eslint-disable-next-line array-callback-return
  ids.map((id) => {
    const idKey = id.vehicleId;

    if (idKey && idKey.indexOf(Id) !== -1) {
      shouldRenderUpdating = true;
    }
  });

  const vehicleInfo = [RegistrationDate.split('/')[2] || t('StockListItem.date_of_reg_missing')];
  // TODO: refactor
  if (BodyStyle) vehicleInfo.push(BodyStyle);
  if (Mileage) vehicleInfo.push(t('StockListItem.miles', { value: Mileage }));
  if (BadgeEngineSize) vehicleInfo.push(t('StockListItem.engineSize', { value: BadgeEngineSize.toFixed(1) }));
  if (CC) vehicleInfo.push(t('StockListItem.cc', { value: CC }));
  if (Bhp || PwrKW) {
    if (lng === 'en' && Bhp) vehicleInfo.push(t('StockListItem.bhp', { value: Bhp }));
    else if (PwrKW) vehicleInfo.push(t('StockListItem.kw', { value: PwrKW }));
  }
  if (DealerPlatformUpload && Transmission) vehicleInfo.push(Transmission);
  if (!DealerPlatformUpload && STransmission) vehicleInfo.push(STransmission);
  if (DealerPlatformUpload && Fuel) vehicleInfo.push(Fuel);
  if (!DealerPlatformUpload && SFuel) vehicleInfo.push(SFuel);
  if (Doors && Doors !== '0') vehicleInfo.push(t('StockListItem.doors', { value: Doors }));
  if (Colour) vehicleInfo.push(Colour);

  const trackingContext = { dealershipId: params.dealershipId, vrm: Vrm };

  const hasValidationErrors = Array.isArray(ValidationErrorCodes) && ValidationErrorCodes.length > 0;
  const vehicleErrors = hasValidationErrors
    ? ValidationErrorCodes.map((errorKey) => t(`StockListItem.${errorKey}`))
    : null;

  return (
    <div className={classes.item} data-th="StockListItem">
      <div className={classes.topFlex}>
        <div className={classes.leftSide}>
          <div className={classes.vrm}>
            {showVRM ? <StockVrmPlate fuel={Fuel || SFuel} vrm={Vrm || Vin || DealerReference} /> : null}
          </div>
          <div className={classes.vehicleImage}>
            <ImageCount count={imageCount} />
            <StockListVehicleImage
              vehicleClass={Klass}
              vrm={Vrm}
              vin={Vin}
              dealerReference={DealerReference}
              dealerPlatformUpload={DealerPlatformUpload}
              imagesMetadata={toJS(ImagesMetadata)}
              imgPth={ImgPth}
              imgParams="width=180&height=140&bgcolor=#000000&mode=max"
            />
          </div>
          {canViewDataAccuracy ? (
            <MediaQuery maxWidth={tabletStart}>
              <StockListItemDataAccuracy activeStars={convertAccuracyScore(4, AccuracyScore)} onClick={openModal} />
            </MediaQuery>
          ) : null}
          <div className={classes.sendDealWrapper}>
            <MediaQuery minWidth={tabletStart}>
              {canUsePushDeal && canGenerateQuotes && (
                <Button
                  dataThook={'sendDeal'}
                  buttonStyle="secondary"
                  className={classes.pushDealButton}
                  onClick={pushDeal}
                >
                  <div className={classes.iconButtonWrapper}>
                    <span className={cn('icon icon-send-deal', classes.sendDealIcon)} />

                    <span className={classes.sendDealText}>{t('StockListItem.send_deal')}</span>
                  </div>
                </Button>
              )}
            </MediaQuery>
          </div>
        </div>
        <div className={classes.rightSide}>
          <MediaQuery maxWidth={tabletStart}>
            <div className={classes.price}>
              <MoneyFormat value={Price} />
            </div>
          </MediaQuery>
          <div className={classes.makeModelWrapper}>
            <span className={classes.makeModel}>
              {DealerPlatformUpload ? `${Make || '-'} ${Model || '-'}` : `${SMake || '-'} ${SModel || '-'}`}
            </span>
            {showVehicleNumber ? <VehicleNumber value={DealerReference || Vin || '-'} /> : null}
            <span className={classes.derivative}>
              <MediaQuery maxWidth={tabletStart}>
                <span>|</span>
              </MediaQuery>
              {DealerPlatformUpload ? ` ${Derivative || '-'}` : ` ${SDerivative || '-'}`}
            </span>
          </div>
          {/* Warning before the pipe is a nbsp make sure not to remove*/}
          <div className={classes.vehicleFacets}>{vehicleInfo.join(' | ')}</div>
        </div>

        <MediaQuery minWidth={tabletStart}>
          <div className={classes.extraInfo}>
            <div className={classes.priceRight}>
              <MoneyFormat value={Price} />
            </div>
            {canViewDataAccuracy ? (
              <div>
                <StockListItemDataAccuracy
                  className={classes.dataAccuracyRight}
                  activeStars={convertAccuracyScore(4, AccuracyScore)}
                  onClick={openModal}
                />
              </div>
            ) : null}
            <StockListItemButtons
              id={Id}
              hasDataIssues={AccuracyScore >= 2}
              onQuoteClick={quoteVehicle}
              onEditClick={editVehicleDetails}
              trackingContext={trackingContext}
              dealerPlatformUpload={DealerPlatformUpload}
            />
          </div>
        </MediaQuery>
      </div>
      <MediaQuery maxWidth={tabletStart}>
        <div className={classes.mobileButtonWrapper}>
          {canUsePushDeal && canGenerateQuotes && (
            <Button
              dataThook={'pushDeal'}
              buttonStyle="secondary"
              className={classes.pushDealButton}
              onClick={pushDeal}
            >
              <div className={classes.iconButtonWrapper}>
                <span className={cn('icon icon-send-deal', classes.sendDealIcon)} />
                <span className={classes.sendDealText}>{t('StockListItem.send_deal')}</span>
              </div>
            </Button>
          )}
          <StockListItemButtons
            id={Id}
            hasDataIssues={AccuracyScore >= 2}
            onQuoteClick={quoteVehicle}
            onEditClick={editVehicleDetails}
            trackingContext={trackingContext}
            dealerPlatformUpload={DealerPlatformUpload}
          />
        </div>
      </MediaQuery>

      {vehicleErrors ? (
        <AlertCard
          className={cn(classes.alertCard)}
          iconName="alert"
          paragraph={t('StockListItem.this_current_vehicle_is_not_advertised')}
          linkText={t('StockListItem.more_information')}
          onClick={() => openDataIssueModal(vehicleErrors)}
        />
      ) : null}

      {shouldRenderUpdating && <UpdatingTag onClick={handleUpdatingTagClick} />}
    </div>
  );
};

StockListItem.propTypes = {
  vehicle: PropTypes.instanceOf(AlgoliaVehicle),
  changeQuickQuoteVehicle: PropTypes.func,
  openModal: PropTypes.func,
  handleNewDataPending: PropTypes.func,
  appStore: PropTypes.any,
  openDataIssueModal: PropTypes.func
};

function mapDispatchToProps(dispatch) {
  return {
    changeQuickQuoteVehicle: (vehicle) => {
      dispatch(
        quickQuoteActions.changeQuickQuoteVehicle({
          CapId: vehicle.CapId,
          Class: vehicle.SClass ?? vehicle.Class,
          Condition: vehicle.Condition,
          Derivative: vehicle.SDerivative ?? vehicle.Derivative,
          DerivativeId: vehicle.DerivativeId,
          Make: vehicle.SMake ?? vehicle.Make,
          MakeId: vehicle.MakeId,
          Mileage: isDefined(vehicle.Mileage) && vehicle.Mileage.toString(),
          Model: vehicle.SModel ?? vehicle.Model,
          ModelId: vehicle.ModelId,
          Price: vehicle.Price,
          RegistrationDate: vehicle.RegistrationDate,
          Vrm: vehicle.Vrm,
          VehicleId: vehicle.Id,
          CdnVehicleImageUrl: vehicle.imageSource
        })
      );
    }
  };
}

export default connect(null, mapDispatchToProps)(StockListItem);
