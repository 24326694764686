import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import Validator from '../../validators/Validator';
import Button from '../Common/Button/Button';
import Checkbox from '../Common/Form/Checkbox';
import CheckboxLabel from '../Common/Form/CheckboxLabel';
import CurrencyInput from '../Common/Form/CurrencyInput';
import FormFooter from '../Common/Form/FormFooter';
import PercentageInput from '../Common/Form/PercentageInput';
import RadioButton from '../Common/Form/RadioButton';
import SelectInput from '../Common/Form/SelectInput';
import Icon from '../Common/Icon/Icon';
import ToggleSwitch from '../Common/ToggleSwitch';
import { default as MobxFieldGroup, default as MobxFormFieldGroup } from '../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../MobxForm/MobxFormLabel';
import MobxForm from '../MobxForm/MobxForm';
import AddVapModal from '../Quoting/forms/AddVapModal';
import EditVapModal from '../Quoting/forms/EditVapModal';
import ValueAddedProductPushDeal from '../Quoting/forms/ValueAddedProductPushDeal';
import PushDealViewOffer from './PushDealViewOffer';
import classnames from 'classnames';
import TrackEvent from '../Common/Tracking/TrackEvent';
import MoneyFormat from '../Common/MoneyFormat';
import { isItBike } from '../../core/helpers';
import BDKLenderVaps from '~/components/Quoting/forms/LenderVaps';
import ImageButton from '~Common/ImageButton';
import CustomerCreditModal from '~/components/Quoting/modals/CustomerCreditModal';
import './pushDealPricing.scss';
import { hasExtendedTermLength } from '~/utils/quoteUtils';
import { shouldShowNegativeEquityWarning } from '../../components/Quoting/components/utils';

class PushDealPricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discounted: false,
      indexOfVapToEdit: null,
      addVapModalOpen: false,
      isEditVapModalOpen: false,
      showFinanceFields: false,
      hasLookedupSettlement: false,
      customerCreditModalOpen: false
    };
    this.validationRules = {
      VehiclePrice: 'required, currency',
      DiscountAmount: 'currency',
      BasicPrice: 'required, currency',
      VatPercent: 'number',
      VatAmount: 'currency',
      Accessories: 'currency',
      CashDeposit: 'currency',
      PartExchange: 'currency',
      OutstandingSettlement: 'currency',
      AnnualDistance: 'required',
      Term: 'required',
      ResidualValue: 'currency',
      ValueAddedProducts: [
        {
          ProductTypeCode: 'required',
          Id: 'required',
          Price: 'required, currency'
        }
      ]
    };
    this.setUpValidation();
  }

  UNSAFE_componentWillMount() {
    this.setState({
      showFinanceFields:
        this.props.appStore.pushDealStore.quoteRequest.OutstandingSettlement ||
        this.props.appStore.pushDealStore.quoteRequest.PartExchange
          ? true
          : false,
      hasLookedupSettlement:
        this.props.appStore.pushDealStore.quoteRequest.SettlementSource &&
        this.props.appStore.pushDealStore.quoteRequest.SettlementSource.includes('MNF')
    });
  }

  componentDidMount() {
    let ActualCashDeposit =
      this.props.appStore.pushDealStore.quoteRequest.ActualCashDeposit &&
      parseFloat(this.props.appStore.pushDealStore.quoteRequest.ActualCashDeposit)
        ? parseFloat(this.props.appStore.pushDealStore.quoteRequest.ActualCashDeposit)
        : 0;
    this.setState({
      ActualCashDeposit
    });
  }

  setUpValidation() {
    this.validator = new Validator();
    this.validator.setRules(this.validationRules);
    this.validationReactionDisposer = reaction(
      () => ({ ...this.props.appStore.pushDealStore.quoteRequest }),
      this.validator.validate,
      {
        fireImmediately: true
      }
    );
  }

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  setBasicPrice = (BasicPrice) => this.props.appStore.pushDealStore.quoteRequest.set('BasicPrice', BasicPrice);
  setVehiclePrice = (VehiclePrice) => this.props.appStore.pushDealStore.quoteRequest.set('VehiclePrice', VehiclePrice);
  setVehicleClass = (VehicleClass) => this.props.appStore.pushDealStore.quoteRequest.set('VehicleClass', VehicleClass);
  setVatPercent = (fieldName, VatPercent) =>
    this.props.appStore.pushDealStore.quoteRequest.set('VatPercent', VatPercent);
  setVatAmount = (VatAmount) => this.props.appStore.pushDealStore.quoteRequest.set('VatAmount', VatAmount);
  setVatAddedToDeposit = (VatAddedToDeposit) => {
    this.props.appStore.pushDealStore.quoteRequest.set('VatAddedToDeposit', VatAddedToDeposit);
  };
  setCashDeposit = (CashDeposit) => this.props.appStore.pushDealStore.quoteRequest.set('CashDeposit', CashDeposit);
  setResidualValue = (ResidualValue) =>
    this.props.appStore.pushDealStore.quoteRequest.set('ResidualValue', ResidualValue);
  setPartExchange = (PartExchange) => this.props.appStore.pushDealStore.quoteRequest.set('PartExchange', PartExchange);
  setOutstandingSettlement = (OutstandingSettlement, SettlementSource) => {
    this.props.appStore.pushDealStore.quoteRequest.set('OutstandingSettlement', OutstandingSettlement);
    this.props.appStore.pushDealStore.quoteRequest.set('SettlementSource', SettlementSource);
  };
  handleToggleOfferStatus = (checked) => {
    this.props.appStore.pushDealStore.toggleOfferStatus(checked);
    window.ga && window.ga('send', 'event', 'PushDeal_Pricing', 'toggleEvent', `ToggleOfferStatus_to_${checked}`);
  };
  openAddVapModal = () => {
    this.setState({
      addVapModalOpen: true
    });
    window.ga && window.ga('send', 'event', 'PushDeal', 'openAddVapModal');
  };
  editVap = (indexOfVapToEdit) => {
    this.setState({
      indexOfVapToEdit: indexOfVapToEdit,
      isEditVapModalOpen: true
    });
  };
  setVapPrice = (index, newPrice) => {
    this.props.appStore.pushDealStore.quoteRequest.changeVapPrice(index, newPrice);
  };
  removeVap = (index) => {
    this.props.appStore.pushDealStore.quoteRequest.removeVap(index);
  };
  setVatQualifying = (e) => {
    const value = e.target.value;
    const isVatQualifying = value === 'VatQualifying';
    this.props.appStore.pushDealStore.quoteRequest.set('VatQualifying', isVatQualifying);
  };
  setSettlementFigure = (figure, agreementId) => {
    if (this.props.appStore.quotingStore.statusCode === 200 || this.props.appStore.quotingStore.statusCode === 201) {
      this.setOutstandingSettlement(figure, `MNF:${agreementId}`);
      this.setState({
        hasLookedupSettlement: true
      });
    }
  };
  setDiscountAmount = (DiscountAmount) =>
    this.props.appStore.pushDealStore.quoteRequest.set('DiscountAmount', DiscountAmount);
  openCustomerCreditModal = (e) => {
    e.preventDefault();
    this.setState({
      customerCreditModalOpen: true
    });
  };

  closeCustomerCreditModal = () =>
    this.setState({
      customerCreditModalOpen: false
    });
  setLenderVaps = (lenderVaps) => {
    this.props.appStore.pushDealStore.quoteRequest.set('LenderVaps', lenderVaps);
  };

  removeSettlementFigure = () => {
    this.setOutstandingSettlement('');
    this.setState({
      hasLookedupSettlement: false
    });
    this.props.appStore.quotingStore.settlementRequest.reset();
    this.props.appStore.quotingStore.resetSettlement();
  };
  handleSubmitAddVapModal = (productId, price, productTypeCode, shouldRemoveExistingDuplicate) => {
    const product = this.props.appStore.vapStore.visibleProducts.find((v) => v.Id.toString() === productId);

    if (shouldRemoveExistingDuplicate) {
      let toRemove = _.find(
        this.props.appStore.pushDealStore.quoteRequest.ValueAddedProducts,
        (vap) => vap.ProductTypeCode === productTypeCode
      );

      this.props.appStore.pushDealStore.quoteRequest.removeVap(
        _.indexOf(this.props.appStore.pushDealStore.quoteRequest.ValueAddedProducts, toRemove)
      );
    }

    this.props.appStore.pushDealStore.quoteRequest.addVap({ ...product, Price: price });

    this.closeAddVapModal();
  };
  handleSubmitEditVapModal = (productId, price, productTypeCode, shouldRemoveExistingDuplicate) => {
    const product = this.props.appStore.vapStore.visibleProducts.find((v) => v.Id.toString() === productId);

    if (shouldRemoveExistingDuplicate) {
      let toRemove = _.find(
        this.props.appStore.pushDealStore.ValueAddedProducts,
        (vap) => vap.ProductTypeCode === productTypeCode
      );

      this.props.appStore.pushDealStore.quoteRequest.removeVap(
        _.indexOf(this.props.appStore.pushDealStore.ValueAddedProducts, toRemove)
      );
    }

    this.props.appStore.pushDealStore.quoteRequest.changeVap(this.state.indexOfVapToEdit, {
      ...product,
      Price: price
    });

    this.closeAddVapModal();
  };
  closeAddVapModal = () => {
    this.setState({
      addVapModalOpen: false,
      isEditVapModalOpen: false,
      indexOfVapToEdit: null
    });
  };
  setAnnualDistance = (fieldName, AnnualDistance) =>
    this.props.appStore.pushDealStore.quoteRequest.set('AnnualDistance', AnnualDistance);
  setTerm = (fieldName, Term) => this.props.appStore.pushDealStore.quoteRequest.set('Term', Term);
  setCreditScore = (fieldName, CreditScore) => {
    this.props.appStore.pushDealStore.quoteRequest.set('CustomerCreditScore', CreditScore);
  };
  cancel = () => {
    this.props.appStore.pushDealStore.resetPricingForm(
      this.props.preferredDeposit,
      this.props.preferredTerm,
      this.props.preferredAnnualMileage
    );
    this.props.previousSection();
  };
  onSubmit = () => {
    this.validator.validate(this.props.appStore.pushDealStore.quoteRequest);

    if (this.validator.errorCount === 0) {
      this.props.nextSection();
    }
  };

  render() {
    const { pushDealStore } = this.props.appStore;
    const { quoteRequest, OfferStatus } = pushDealStore;
    const { VehiclePrice, AnnualDistance, Term, DiscountAmount, TotalPrice, BalanceToChange } = quoteRequest;
    const vehicle = this.props.appStore.pushDealStore.vehicle;
    const isLcv = vehicle.Class.toLowerCase() === 'lcv';
    const isNew = vehicle.Condition.toLowerCase() === 'new';
    const isUsed = vehicle.Condition.toLowerCase() === 'used';
    const errors = this.validator.getErrors();
    const canUseVaps = !quoteRequest.Accessories > 0;
    const showNegativeEquityWarning = shouldShowNegativeEquityWarning(this.quoteRequest);

    const pushDealPricingDiscountClasses = classnames(
      OfferStatus ? 'pushDealPricing__DiscountContainer' : 'pushDealPricing__DiscountContainer--closed'
    );
    const pushDealvatQualifyingContainerClasses = classnames(
      quoteRequest.VatQualifying
        ? 'pushDealPricing__vatQualifyingContainer'
        : 'pushDealPricing__vatQualifyingContainer--closed'
    );
    return (
      <MobxForm focusOnFirstElement className="pushDealPricing__container" onSubmit={this.onSubmit}>
        <div className="pushDealPricing__offerSection">
          <div className="pushDealPricing__sectionInner">
            {isLcv && quoteRequest.VatQualifying ? (
              <MobxFieldGroup error={errors.BasicPrice} pushDealStyle={true}>
                <MobxFormLabel htmlFor="BasicPrice">{this.props.t('PushDealPricing.basic_price')}</MobxFormLabel>
                <CurrencyInput
                  value={quoteRequest.BasicPrice}
                  onChange={this.setBasicPrice}
                  id="BasicPrice"
                  dataThook="BasicPrice"
                />
              </MobxFieldGroup>
            ) : (
              <MobxFormFieldGroup error={errors.VehiclePrice} pushDealStyle={true}>
                <MobxFormLabel>{this.props.t('PushDealPricing.vehicle_price')}</MobxFormLabel>
                <CurrencyInput
                  value={quoteRequest.VehiclePrice}
                  onChange={this.setVehiclePrice}
                  id="VehiclePrice"
                  dataThook="vehiclePrice"
                />
              </MobxFormFieldGroup>
            )}
            <div className="pushDealPricing__toggleField">
              <div className="pushDealPricing__toggleFieldInner">
                <span>{this.props.t('PushDealPricing.would_you_like_to_discount_this_offer')}</span>
              </div>
              <div className="pushDealPricing__toggleOuter">
                <ToggleSwitch
                  id={'offerStatus'}
                  isActive={OfferStatus}
                  handleUpdate={this.handleToggleOfferStatus}
                  trackingPage="pushDealPricing"
                />
              </div>
            </div>

            <div className={pushDealPricingDiscountClasses}>
              <MobxFormFieldGroup pushDealStyle={true}>
                <MobxFormLabel>{this.props.t('PushDealPricing.discount_amount')}</MobxFormLabel>

                <CurrencyInput
                  value={DiscountAmount}
                  onChange={this.setDiscountAmount}
                  id="DiscountAmount"
                  dataThook="discountAmount"
                />
              </MobxFormFieldGroup>
              <MobxFormFieldGroup pushDealStyle={true}>
                <MobxFormLabel>{this.props.t('PushDealPricing.what_your_customer_will_see')}</MobxFormLabel>
              </MobxFormFieldGroup>
              <div className="pushDealPricing__viewOffer">
                <PushDealViewOffer
                  fullPrice={VehiclePrice}
                  offerPrice={pushDealStore.discountedPrice}
                  saving={DiscountAmount}
                />
              </div>
            </div>

            {isUsed && (
              <div>
                {this.props.appStore.uiState.canQuoteVAT && (
                  <div className="pushDealPricing__vatOptionsContainer">
                    <div className="pushDealPricing__vatOptions">
                      <RadioButton
                        id="vatMarginScheme"
                        name="VatQualifying"
                        onChange={this.setVatQualifying}
                        value="VatMarginScheme"
                        disabled={pushDealStore.vehicle.Condition === 'new'}
                        checked={!quoteRequest.VatQualifying}
                      >
                        {this.props.t('PushDealPricing.vat_margin_scheme')}
                      </RadioButton>
                    </div>
                    <div className="pushDealPricing__vatOptions">
                      <RadioButton
                        id="vatQualifying"
                        name="VatQualifying"
                        onChange={this.setVatQualifying}
                        value="VatQualifying"
                        disabled={isNew}
                        checked={quoteRequest.VatQualifying}
                      >
                        {this.props.t('PushDealPricing.vat_qualifying')}
                      </RadioButton>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className={pushDealvatQualifyingContainerClasses}>
              {isLcv ? (
                <MobxFieldGroup error={errors.VehiclePrice} pushDealStyle={true}>
                  <MobxFormLabel htmlFor="VehiclePrice">{this.props.t('PushDealPricing.vehicle_price')}</MobxFormLabel>
                  <CurrencyInput
                    value={quoteRequest.VehiclePrice}
                    onChange={this.setVehiclePrice}
                    id="VehiclePrice"
                    dataThook="vehiclePrice"
                  />
                </MobxFieldGroup>
              ) : (
                <MobxFieldGroup error={errors.BasicPrice} pushDealStyle={true}>
                  <MobxFormLabel htmlFor="BasicPrice">{this.props.t('PushDealPricing.basic_price')}</MobxFormLabel>
                  <CurrencyInput
                    value={quoteRequest.BasicPrice}
                    onChange={this.setBasicPrice}
                    id="BasicPrice"
                    dataThook="basicPrice"
                  />
                </MobxFieldGroup>
              )}

              <MobxFieldGroup error={errors.VatPercent} pushDealStyle={true}>
                <MobxFormLabel htmlFor="VatPercent">{this.props.t('PushDealPricing.vat')}</MobxFormLabel>
                <PercentageInput
                  pushDealStyle={true}
                  value={quoteRequest.VatPercent}
                  onChange={this.setVatPercent}
                  id="VatPercent"
                  dataThook="vatPercent"
                />
              </MobxFieldGroup>

              <MobxFieldGroup error={errors.VatAmount} pushDealStyle={true}>
                <MobxFormLabel htmlFor="VatAmount">{this.props.t('PushDealPricing.vat_amount')}</MobxFormLabel>
                <CurrencyInput
                  value={quoteRequest.VatAmount}
                  onChange={this.setVatAmount}
                  id="VatAmount"
                  dataThook="vatAmount"
                />
              </MobxFieldGroup>

              {isLcv && (
                <div className="pushDealPricing___vatCheckbox">
                  <CheckboxLabel>
                    <Checkbox
                      value={quoteRequest.VatAddedToDeposit}
                      onChange={this.setVatAddedToDeposit}
                      id="VatAddedToDeposit"
                    />
                    {this.props.t('PushDealPricing.include_vat_with_deposit')}
                  </CheckboxLabel>
                </div>
              )}
            </div>
          </div>
        </div>
        {this.props.appStore.vapStore.visibleProducts.length > 0 &&
          !this.props.appStore.vapStore.fetchVapsRequest.hasError && (
            <div className="pushDealPricing__offerSection">
              <div className="pushDealPricing__sectionInner">
                {!pushDealStore.withoutVapsCreation &&
                  canUseVaps &&
                  this.props.appStore.vapStore.visibleProducts.length > 0 && (
                    <div className="pushDealPricing__valueAddedProducts">
                      <div className="pushDealPricing__addVapContainer">
                        <div>{this.props.t('PushDealPricing.value_added_products')}</div>
                        <div className="pushDealPricing__addVapButton">
                          <TrackEvent featureName="pushDeal" interactionName="pushDealAddAnotherVap">
                            <Button
                              type="button"
                              buttonStyle="secondary"
                              onClick={this.openAddVapModal}
                              disabled={pushDealStore.withoutVapsCreation}
                              dataThook="addVapButton"
                            >
                              {quoteRequest.ValueAddedProducts.length > 0
                                ? this.props.t('PushDealPricing.add_another_vap')
                                : this.props.t('PushDealPricing.add_a_vap')}
                            </Button>
                          </TrackEvent>
                        </div>
                      </div>

                      {quoteRequest.ValueAddedProducts &&
                        quoteRequest.ValueAddedProducts.length > 0 &&
                        quoteRequest.ValueAddedProducts.map((vap, index) => (
                          <div data-name={vap.Name} key={`valueAddedProduct-${index}`}>
                            <ValueAddedProductPushDeal
                              index={index}
                              label={vap.ProductTypeText}
                              subLabel={vap.Name}
                              value={vap.Price}
                              onRemove={this.removeVap}
                              onChange={this.setVapPrice}
                              onEdit={this.editVap}
                              error={errors.ValueAddedProducts && errors.ValueAddedProducts[index]}
                            />
                          </div>
                        ))}
                      <AddVapModal
                        isOpen={this.state.addVapModalOpen}
                        onClose={this.closeAddVapModal}
                        onSubmit={this.handleSubmitAddVapModal}
                        currentVaps={quoteRequest.ValueAddedProducts}
                        vehicleClass={vehicle.Class}
                      />
                      <EditVapModal
                        isOpen={this.state.isEditVapModalOpen}
                        onClose={this.closeAddVapModal}
                        onSubmit={this.handleSubmitEditVapModal}
                        vapToEdit={
                          this.props.appStore.pushDealStore.quoteRequest.ValueAddedProducts[this.state.indexOfVapToEdit]
                        }
                        currentVaps={this.props.appStore.pushDealStore.quoteRequest.ValueAddedProducts}
                        vehicleClass={vehicle.Class}
                      />
                      <div className="pushDealPricing__Divide" />
                      <div className="pushDealPricing__totalSection">
                        <div>{this.props.t('PushDealPricing.total_value_added_products')}</div>
                        <div data-thook="totalVapPriceText">
                          {<MoneyFormat value={quoteRequest.valueAddedProductsTotal} />}
                        </div>
                      </div>
                    </div>
                  )}
                {this.props.appStore.vapStore.fetchVapsRequest.hasError && (
                  <div className="pushDealPricing__valueAddedProducts">
                    <div className="pushDealPricing__addVapContainer">
                      <div className="pushDealPricing__noVaps">
                        {this.props.t('PushDealPricing.sorry_there_was_a_problem_loading_your_va_ps')}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

        {this.props.appStore.uiState.isBdk && (
          <div className="pushDealPricing__offerSection">
            <div className="pushDealPricing__sectionInner">
              <h4 className="pushDealPricing__sectionTitle pushDealPricing__sectionTitle--center">
                BDK {this.props.t('PushDealPricing.value_added_products')}
              </h4>
              <BDKLenderVaps
                LenderVaps={this.props.appStore.pushDealStore.quoteRequest.LenderVaps}
                onChange={this.setLenderVaps}
              />
            </div>
          </div>
        )}

        <div className="pushDealPricing__balanceSection">
          <div className="pushDealPricing__totalSection">
            <div>{this.props.t('PushDealPricing.total_price')}</div>
            <div data-thook="totalPriceText">{<MoneyFormat value={TotalPrice} />}</div>
          </div>
        </div>

        <div className="pushDealPricing__depositSection">
          <div className="pushDealPricing__sectionInner">
            <MobxFieldGroup error={errors.CashDeposit} pushDealStyle={true}>
              <MobxFormLabel htmlFor="CashDeposit">
                {isLcv && quoteRequest.VatAddedToDeposit
                  ? this.props.t('PushDealPricing.additional_deposit')
                  : this.props.t('PushDealPricing.cash_deposit')}
              </MobxFormLabel>
              <CurrencyInput
                value={quoteRequest.CashDeposit}
                onChange={this.setCashDeposit}
                id="CashDeposit"
                dataThook="cashDeposit"
              />
            </MobxFieldGroup>
            {!this.props.appStore.uiState.isBdk && (
              <div>
                <MobxFieldGroup error={errors.PartExchange} pushDealStyle={true}>
                  <MobxFormLabel htmlFor="PartExchange">
                    {this.props.t('PushDealPricing.part_exchange_value')}
                  </MobxFormLabel>
                  <CurrencyInput
                    value={quoteRequest.PartExchange}
                    onChange={this.setPartExchange}
                    id="PartExchange"
                    dataThook="partExchange"
                  />
                </MobxFieldGroup>
                <MobxFieldGroup error={errors.OutstandingSettlement} pushDealStyle={true}>
                  <MobxFormLabel htmlFor="OutstandingSettlement">
                    {this.props.t('PushDealPricing.settlement_figure')}
                  </MobxFormLabel>
                  <CurrencyInput
                    value={quoteRequest.OutstandingSettlement}
                    onChange={this.setOutstandingSettlement}
                    id="SettlementFigure"
                    dataThook="settlementFigure"
                  />
                </MobxFieldGroup>
              </div>
            )}

            {showNegativeEquityWarning && (
              <div className="pushDealPricing__negativeEquityMessage">
                <Icon name="information" />
                {this.props.t('PushDealPricing.this_deal_contains_negative_equity')}
              </div>
            )}
            <div className="pushDealPricing__Divide" />
            <div className="pushDealPricing__totalSection">
              <div>{this.props.t('PushDealPricing.total_deposit')}</div>
              <div data-thook="totalDepositText"> {<MoneyFormat value={quoteRequest.getNetDeposit()} />}</div>
            </div>
          </div>
        </div>

        <div className="pushDealPricing__financeSection">
          <div className="pushDealPricing__sectionInner">
            <MobxFormFieldGroup error={errors.AnnualDistance} pushDealStyle={true}>
              <MobxFormLabel>{this.props.t('PushDealPricing.annual_mileage')}</MobxFormLabel>
              <SelectInput
                options={isItBike(vehicle.Class) ? 'MotorBikeMileageOptions' : 'MileageOptions'}
                value={AnnualDistance}
                onChange={this.setAnnualDistance}
                dataThook="annualMileage"
              />
            </MobxFormFieldGroup>
            <MobxFormFieldGroup error={errors.Term} pushDealStyle={true}>
              <MobxFormLabel>{this.props.t('PushDealPricing.term_months')}</MobxFormLabel>
              <SelectInput
                options={hasExtendedTermLength(vehicle.Class) ? 'TermsExtended' : 'Terms'}
                value={Term}
                onChange={this.setTerm}
                dataThook="term"
              />
            </MobxFormFieldGroup>
            {this.props.appStore.uiState.canUseRbp && this.props.customerType?.toLowerCase() !== 'corporate' && (
              <MobxFieldGroup pushDealStyle={true} error={errors.CustomerCreditScore}>
                <MobxFormLabel htmlFor="CreditScore">
                  {this.props.t('PushDealPricing.customer_credit_rating')}
                </MobxFormLabel>
                <SelectInput
                  value={quoteRequest.CustomerCreditScore}
                  onChange={this.setCreditScore}
                  options="CreditScoreOptions"
                  id="CustomerCreditScore"
                />
                <div className="pushDealPricing__customerCreditTooltip">
                  <ImageButton image="information" width="25px" height="25px" onClick={this.openCustomerCreditModal} />
                  <button onClick={this.openCustomerCreditModal}>
                    {this.props.t('PushDealPricing.customer_credit_rating_explained')}
                  </button>
                </div>
              </MobxFieldGroup>
            )}
            {this.props.appStore.uiState.isBdk && (
              <MobxFieldGroup error={errors.ResidualValue} pushDealStyle={true}>
                <MobxFormLabel htmlFor="ResidualValue">{this.props.t('PushDealPricing.residual_value')}</MobxFormLabel>
                <CurrencyInput value={quoteRequest.ResidualValue} onChange={this.setResidualValue} id="ResidualValue" />
              </MobxFieldGroup>
            )}
          </div>
        </div>
        <div className="pushDealPricing__balanceSection">
          <div className="pushDealPricing__totalSection">
            <div>{this.props.t('PushDealPricing.balance_to_change')}</div>
            <div data-thook="balanceToChangeText"> {<MoneyFormat value={BalanceToChange || 0} />}</div>
          </div>
        </div>

        <FormFooter
          onCancel={this.cancel}
          submitLabel={this.props.t('PushDealPricing.next')}
          errorMessage=""
          cancelLabel={this.props.t('PushDealPricing.back')}
          trackingPage="PushDealPricing"
        />
        <CustomerCreditModal isOpen={this.state.customerCreditModalOpen} onClose={this.closeCustomerCreditModal} />
      </MobxForm>
    );
  }
}

export default withTranslation('PushDeal')(inject('appStore')(observer(PushDealPricing)));
