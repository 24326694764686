import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Common/Icon/Icon';
import './largeMenuButton.scss';
import { useNavigate } from 'hooks/useNavigate';

const LargeMenuButton = ({ icon, text, link, handleOpenModal, dataThook, buttonText }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (handleOpenModal) {
      handleOpenModal();
    } else {
      navigate(link);
    }
  };

  return (
    <button data-thook={dataThook} className="largeMenuButton" onClick={handleClick} key="outer" type="button">
      <div className="largeMenuButton__inner">
        <div className="largeMenuButton__textOuter">
          <div className="largeMenuButton__text">{text}</div>
        </div>
        <div className="largeMenuButton__icon">
          <Icon name={icon} />
        </div>
        <div className="largeMenuButton__button">{buttonText}</div>
      </div>
    </button>
  );
};

LargeMenuButton.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  float: PropTypes.string,
  topIconOffset: PropTypes.number,
  leftIconOffset: PropTypes.number,
  disabled: PropTypes.bool,
  buttonText: PropTypes.string,
  handleOpenModal: PropTypes.func,
  dataThook: PropTypes.string
};

LargeMenuButton.defaultProps = {
  buttonText: 'Edit'
};

export default LargeMenuButton;
