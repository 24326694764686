import React from 'react';
import RepExampleSettings from '../components/RepExampleSettings';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

const RepExampleSettingsContainer = ({
  preferredTerm,
  preferredDeposit,
  preferredAnnualMileage,

  dealership,
  productSettings
}) => {
  const params = useParams();

  return (
    <RepExampleSettings
      preferredTerm={preferredTerm}
      preferredDeposit={preferredDeposit}
      preferredAnnualMileage={preferredAnnualMileage}
      params={params}
      dealership={dealership}
      productSettings={productSettings}
    />
  );
};

function mapStateToProps(state, ownProps) {
  return {
    dealership: state.dealership,
    preferredTerm: state.globalDefaultSettings.PreferredTerm,
    preferredDeposit: state.globalDefaultSettings.DepositValue,
    preferredAnnualMileage: state.globalDefaultSettings.DefaultAnnualMileage,
    productSettings: state.products.items
  };
}

export default connect(mapStateToProps)(RepExampleSettingsContainer);
