import { Dispatch, useEffect, useState } from 'react';
import Select from 'react-select';
import ToggleSwitch from '~/components/Common/ToggleSwitch';
import s from './toggleSelect.module.scss';

interface ToggleSelectProps<T extends string | number | symbol> {
  options: ToggleOption<T>[];
  values: T[];
  onChange: Dispatch<T[]>;
  singleSelection?: boolean; // Whether only one can be selected at a time
}

interface ToggleOption<T> {
  id: T;
  label: string;
  isDisabled?: boolean;
}

const ToggleSelect = <T extends string | number | symbol>({
  options,
  values,
  onChange,
  singleSelection = false
}: ToggleSelectProps<T>) => {
  const [toggleStates, setToggleStates] = useState<Record<T, boolean>>({} as Record<T, boolean>);

  // Sync toggle states based on selected values
  useEffect(() => {
    const newState: Record<T, boolean> = {} as Record<T, boolean>;
    options.forEach((option) => {
      newState[option.id] = values.includes(option.id);
    });
    setToggleStates(newState);
  }, [values, options]);

  const handleToggleChange = (id: T) => {
    let newSelectedValues = [...values];

    if (singleSelection) {
      return [id]; // In single selection mode, only allow one active option
    }

    if (newSelectedValues.includes(id)) {
      // If already selected, remove it
      newSelectedValues = newSelectedValues.filter((val) => val !== id);
    } else {
      // If not selected, add it
      newSelectedValues.push(id);
    }
    onChange(newSelectedValues);
  };

  const SwitchOption = ({ data }: { data: ToggleOption<T> & { isActive: boolean } }) => {
    const { id, isActive, label, isDisabled } = data;
    return (
      <span className={s['optionContainer']}>
        {label}
        <ToggleSwitch
          handleUpdate={() => handleToggleChange(id)}
          isActive={isActive}
          isDisabled={isDisabled}
          showToggleText={false}
        />
      </span>
    );
  };

  // Add `isActive` to options so they know their state
  const displayOptions = options.map((option) => ({
    ...option,
    isActive: toggleStates[option.id]
  }));

  const selectedOption = displayOptions.find((option) => values.includes(option.id)) || displayOptions[0];

  return (
    <Select
      placeholder="Select option"
      className={s['selectContainer']}
      components={{ Option: SwitchOption }}
      filterOption={null}
      isSearchable={false}
      isMulti={false}
      maxMenuHeight={380}
      value={selectedOption}
      options={displayOptions}
    />
  );
};

export default ToggleSelect;
