import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import ImageWithFallback from './components/ImageWithFallback';
import {
  generateFeedVehicleImageUrl,
  generateImageUrlFromFullPath
} from '../../../../../../../../shared/helpers/vehicleImages';
import { CameraIcon, VideoCameraIcon } from '../../../../../../../../shared/icons';

import car from '~/styles/icons/car-placeholder-v2.svg';
import bike from '~/styles/icons/bike-placeholder-v2.svg';
import lcv from '~/styles/icons/lcv-placeholder-v2.svg';
import motorhome from '~/styles/icons/motorhome-placeholder-v2.svg';

import s from './vehicleImage.module.scss';
import { VehicleClass } from '~/types/vehicle';

interface Props {
  count?: number;
  imagesMetadata?: {
    fileName: string;
    fullPath: string;
  }[];
  vehicleClass?: VehicleClass;
  videosMetadata?: {
    url: string;
    source: string;
  }[];
  dealerReference?: string;
  imgPth?: string;
  vin?: string;
  vrm?: string;
}
const VehicleImage = ({
  videosMetadata = [],
  imagesMetadata = [],
  vehicleClass,
  dealerReference,
  imgPth,
  vin,
  vrm,
  count = 0
}: Props) => {
  const { t } = useTranslation('Stock');

  const placeHolder = (vehicleClass?: string) => {
    const vehicleClassLowerCase = vehicleClass?.toLowerCase();
    switch (vehicleClassLowerCase) {
      case VehicleClass.car:
        return car;
      case VehicleClass.bike:
      case VehicleClass.motorbike:
        return bike;
      case VehicleClass.lcv:
        return lcv;
      case VehicleClass.motorhome:
      case VehicleClass.touringCaravan:
        return motorhome;
      default:
        return car;
    }
  };

  const videoIcon = (
    <>
      <VideoCameraIcon /> {t('StockListItem.video')}
    </>
  );

  const displayVideoIdentifier = videosMetadata?.length ? videoIcon : null;

  // Using fallback image URLs and hoping for the best. i.e. 0.jpg 1.jpg 2.jpg....
  const usingFallbackImagePaths = count > 0 && Array.isArray(imagesMetadata) && imagesMetadata.length === 0;

  //  When we pass undefined to <ImageWithFallback /> it won't attempt to fetch reducing 404 errors in console
  //  We'll still see 404 as some images don't exist in test even tho we have a url for them
  const imgUrl0 = usingFallbackImagePaths
    ? generateFeedVehicleImageUrl({ dealerReference, imgPth, vin, vrm, imageNum: 0 })
    : generateImageUrlFromFullPath(imagesMetadata[0]?.fullPath);

  if (count >= 4) {
    let imgUrl1, imgUrl2, imgUrl3;
    if (usingFallbackImagePaths) {
      imgUrl1 = generateFeedVehicleImageUrl({ dealerReference, imgPth, vin, vrm, imageNum: 1 });
      imgUrl2 = generateFeedVehicleImageUrl({ dealerReference, imgPth, vin, vrm, imageNum: 2 });
      imgUrl3 = generateFeedVehicleImageUrl({ dealerReference, imgPth, vin, vrm, imageNum: 3 });
    } else {
      imgUrl1 = generateImageUrlFromFullPath(imagesMetadata[1]?.fullPath);
      imgUrl2 = generateImageUrlFromFullPath(imagesMetadata[2]?.fullPath);
      imgUrl3 = generateImageUrlFromFullPath(imagesMetadata[3]?.fullPath);
    }
    // }
    return (
      <>
        <div data-th="imageVideoIdentifier" className={cn(s['imageCount'])}>
          <CameraIcon />
          <span data-th="imageCount">{count}</span>
          {displayVideoIdentifier}
        </div>
        <div className={cn(s['mainImage'], s.aspectRatio)}>
          <ImageWithFallback placeholder={placeHolder(vehicleClass)} imgUrl={imgUrl0} />
        </div>
        <div className={cn(s['imageReel'])}>
          <div className={cn(s['miniImage'])}>
            <ImageWithFallback placeholder={placeHolder(vehicleClass)} imgUrl={imgUrl1} />
          </div>
          <div className={cn(s['miniImage'])}>
            <ImageWithFallback placeholder={placeHolder(vehicleClass)} imgUrl={imgUrl2} />
          </div>
          <div className={cn(s['miniImage'])}>
            <ImageWithFallback placeholder={placeHolder(vehicleClass)} imgUrl={imgUrl3} />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div data-th="imageVideoIdentifier" className={cn(s['imageCount'])}>
        <CameraIcon />
        <span data-th="imageCount">{count}</span>
        {displayVideoIdentifier}
      </div>
      <div className={cn(s['SingleMainImage'], s.aspectRatio)}>
        <ImageWithFallback placeholder={placeHolder(vehicleClass)} imgUrl={imgUrl0 ? imgUrl0 : undefined} />
      </div>
    </>
  );
};

export default VehicleImage;
