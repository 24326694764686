import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Button from '../../Common/Button/Button';
import Alert from '../../Common/Alert';
import { Link } from 'react-router-dom';
import './login.scss';
import { withQuery } from 'hocs/router';

class LoginPage extends React.Component {
  handleSubmit = (event) => {
    const username = this.refs.email.value; //REFS IN FORMS!
    const password = this.refs.password.value;
    event.preventDefault();
    this.props.signIn(username, password, this.props.query.returnpath);
  };

  render() {
    const { t } = this.props;

    return (
      <div className="login">
        <div className="login__inner">
          <form onSubmit={this.handleSubmit} className="login__form">
            <h1 className="login__title" key="title">
              {t('welcomeText')}
            </h1>

            <div className="login__loginErrors">
              {this.props.session.hasSsoLoginError && (
                <Alert>
                  Please login via the{' '}
                  <a className="login__alertLink" href="https://www.motonovofinance.com/login">
                    MotoNovo Dealer Hub
                  </a>
                </Alert>
              )}
              {!this.props.session.hasSsoLoginError && this.props.session.hasIncorrectLogin && (
                <Alert>{t('incorrectDetails')}</Alert>
              )}
              {!this.props.session.hasSsoLoginError &&
                !this.props.session.hasIncorrectLogin &&
                this.props.session.loadingState === 'error' && <Alert>{t('errorMessage')}</Alert>}
              {!this.props.session.hasSsoLoginError &&
                !this.props.session.hasIncorrectLogin &&
                this.props.session.errorMessage && <Alert>{this.props.session.errorMessage}</Alert>}
            </div>

            <div className="login__inputContainer" key="ic1">
              <input
                className="login__input"
                key="input2"
                type="email"
                placeholder={t('emailPlaceholder')}
                ref="email"
                id="email"
                maxLength={100}
              />
            </div>

            <div className="login__inputContainer" key="ic2">
              <input
                className="login__input"
                key="input2"
                type="password"
                placeholder={t('passwordPlaceholder')}
                ref="password"
                id="password"
                maxLength={100}
              />
            </div>

            <div className="login__actions">
              <Button
                type="submit"
                buttonStyle="primary"
                isLoading={this.props.session.loadingState === 'loading'}
                hasError={this.props.session.loadingState === 'error'}
                id="SignIn"
              >
                {t('signIn')}
              </Button>
            </div>

            <Link to="/auth/forgotpassword" className="login__forgotPasswordContainer" id="ForgotPassword">
              <h1 className="login__forgotPassword"> {t('forgotPassword')}</h1>
            </Link>
          </form>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  query: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired
};

export default withTranslation(['Login'])(withQuery(LoginPage));
