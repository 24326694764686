import * as actionTypes from '../actionTypes';

const defaultState = {
  Page: 1,
  PageSize: 5,
  Results: [],
  Total: 0,
  isLoading: false,
  SortDirection: 'desc'
};

export default function platformUpdatesReducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.GET_PLATFORM_UPDATES:
      return Object.assign({}, state, {
        isLoading: true
      });

    case actionTypes.GET_PLATFORM_UPDATES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        ...action.response.Model
      });

    case actionTypes.GET_PLATFORM_UPDATES_ERROR:
      return Object.assign({}, state, {
        isLoading: false
      });

    case actionTypes.CHANGE_PLATFORM_UPDATES_PAGE:
      return Object.assign({}, state, {
        Page: action.page
      });

    case actionTypes.RESET_PLATFORM_UPDATES_OPTIONS:
      return defaultState;

    default:
      return state;
  }
}
