import React from 'react';
import get from 'lodash/get';

import {
  generateFeedVehicleImageUrl,
  generateImageUrlFromFullPath
} from '../../../../../../shared/helpers/vehicleImages';
import VehicleImage from '~Common/VehicleImage';

import './stockListVehicleImage.scss';

export const createImageSource = ({
  vrm,
  vin,
  dealerReference,
  dealerPlatformUpload,
  imagesMetadata,
  imgPth,
  imgParams = 'width=200&height=140&scale=both&bgcolor=#000000'
}) => {
  // 1. The vehicle has not been created in the dealer platform i.e. a feed vehicle. UK
  if (!dealerPlatformUpload) {
    return generateFeedVehicleImageUrl({ imgPth, vrm, vin, dealerReference, params: imgParams }).split('?')[0];
  }

  // 2. The vehicle has been uploaded via the dealer platform and the "fullPath" property is present on the imageMetadata object.
  const fullPath = get(imagesMetadata, '[0].fullPath');
  if (fullPath) {
    return generateImageUrlFromFullPath(fullPath, imgParams).split('?')[0];
  }
};

const StockListVehicleImage = ({
  vehicleClass,
  vrm,
  vin,
  dealerReference,
  dealerPlatformUpload,
  imagesMetadata,
  imgPth,
  imgParams = 'width=200&height=140&scale=both&bgcolor=#000000'
}) => {
  // 1. The vehicle has not been created in the dealer platform i.e. a feed vehicle. UK
  if (!dealerPlatformUpload) {
    const imageSource = generateFeedVehicleImageUrl({ imgPth, vrm, vin, dealerReference, params: imgParams });
    return (
      <VehicleImage vehicleClass={vehicleClass} iconFontSize="100px" iconMargin="25px auto" imageSource={imageSource} />
    );
  }

  // 2. The vehicle has been uploaded via the dealer platform and the "fullPath" property is present on the imageMetadata object.
  const fullPath = get(imagesMetadata, '[0].fullPath');
  if (fullPath) {
    const imageSource = generateImageUrlFromFullPath(fullPath, imgParams);
    return (
      <>
        <VehicleImage
          vehicleClass={vehicleClass}
          iconFontSize="100px"
          iconMargin="25px auto"
          imageSource={imageSource}
        />
      </>
    );
  }

  // 3. The vehicle has been uploaded via the dealer platform but the "fullPath" property isn't present, display a placeholder.
  return (
    <div className="stockListVehicleImage__placeholder">
      <VehicleImage vehicleClass={vehicleClass} iconFontSize="100px" iconMargin="25px auto" />
    </div>
  );
};

export default StockListVehicleImage;
