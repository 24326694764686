import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { observer, inject } from 'mobx-react';
import _ from 'lodash';
import { countItemsInObject, titleCase } from '../../../core/helpers';
import ApplicationSummaryPanelHeader from './ApplicationSummaryPanelHeader';
import MiniPanel from '../../Common/MiniPanel';
import AccessoriesPanel from '../../Reporting/PartialSummarySections/AccessoriesPanel';
import VehiclePanel from '../../Reporting/PartialSummarySections/VehiclePanel';
import PartExchangePanel from '../../Reporting/PartialSummarySections/PartExchangePanel';
import PersonalDetailsPanel from '../../Reporting/PartialSummarySections/PersonalDetailsPanel';
import AddressHistoryPanel from '../../Reporting/PartialSummarySections/AddressHistoryPanel';
import EmploymentHistoryPanel from '../../Reporting/PartialSummarySections/EmploymentHistoryPanel';
import BankDetailsPanel from '../../Reporting/PartialSummarySections/BankDetailsPanel';
import AffordabilityPanel from '../../Reporting/PartialSummarySections/AffordabilityPanel';
import BusinessDetailsPanel from '../../Reporting/PartialSummarySections/BusinessDetailsPanel';
import BusinessAddressHistoryPanel from '../../Reporting/PartialSummarySections/BusinessAddressHistoryPanel';
import OrganisationContactsPanel from '../../Reporting/PartialSummarySections/OrganisationContactsPanel';
import DealerInfo from '../../Common/DealerInfo';
import ConsumerHubInfoPanel from './ConsumerHubInfoPanel';
import QuotePanelV2 from '../../Reporting/PartialSummarySections/QuotePanelV2';
import { hasCompanionQuotes } from '~/features';
import { findCompanionQuoteByType } from '~/components/QuoteCard/utils/getQuoteDisplaySchema';
import { LoanTypeJs, QuoteSummaryVariantTypeJs } from '~/components/QuoteCard/types';
import './applicationSummaryTables.scss';

class ApplicationSummaryTables extends React.Component {
  constructor(props) {
    super(props);
    const isBankDetailsHidden = !_.isEmpty(this.props.application.BankDetails.AccountName);
    this.state = {
      hideBankDetails: isBankDetailsHidden
    };
  }

  componentDidMount() {
    this.props.appStore.quotingStore.getNewCarOffers(this.props.dealership.WebQuotewareId);
  }

  showHideBankDetails = () => {
    this.setState({
      hideBankDetails: !this.state.hideBankDetails
    });
  };

  render() {
    const application = this.props.application;
    const applicationFields = this.props.applicationFields;
    const vehicle = application.Vehicle;
    const quote = application.Quote;
    const addresses = application.AddressHistory.Items || [];

    let vehicleLoan;
    let vapsLoan;
    let negativeEquityLoan;
    if (hasCompanionQuotes(quote)) {
      vehicleLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanTypeJs.vehicleLoan);
      vapsLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanTypeJs.vapsLoan);
      negativeEquityLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanTypeJs.negativeEquityLoan);
    }

    //If no address history, add a blank one so we still see the fields
    if (!addresses.length) {
      addresses.push({
        TimeAtAddress: {}
      });
    }

    const employments = application.EmploymentHistory.Items || [];

    //If no employment history, add a blank one so we still see the fields
    if (!employments.length) {
      employments.push({
        TimeAtEmployment: {}
      });
    }

    const contacts = (application.OrganisationContacts && application.OrganisationContacts.Items) || [];

    if (!contacts.length) {
      contacts.push({
        Items: [{}]
      });
    }

    let corporateSection = ['BusinessDetails', 'AddressHistory', 'OrganisationContacts', 'BankDetails'];
    let consumerSection = [
      'PersonalDetails',
      'AddressHistory',
      'EmploymentHistory',
      'BankDetails',
      'AffordabilityDetails'
    ];
    let initialSections = this.props.application.CustomerType === 'corporate' ? corporateSection : consumerSection;
    let sections = {};
    initialSections.forEach((section) => {
      let errors = this.props.application.validationErrors ? this.props.application.validationErrors[section] : null;
      let errorCount = countItemsInObject(errors);
      let errorMessage;

      if (errorCount === 1 && errors.totalMonths) {
        let totalMonths =
          this.props.application[section].totalMonths === undefined ? 0 : this.props.application[section].totalMonths;
        let monthsNeeded = 36 - totalMonths;
        errorMessage = this.props.t('ApplicationSummaryTables.months_needed_months_of_section_is_still_required', {
          monthsNeeded,
          section: _.startCase(section)
        });
      } else {
        errorMessage = this.props.t('ApplicationSummaryTables.field_required', {
          count: errorCount
        });
      }

      sections[section] = {
        errorCount,
        errorMessage
      };
    });
    const partExchanges = this.props.application.PartExchanges || [];
    const newCarOffer =
      this.props.appStore.quotingStore.newCarOffers &&
      Array.isArray(this.props.appStore.quotingStore.newCarOffers) &&
      this.props.appStore.quotingStore.newCarOffers.find((offer) => offer.ProductUid === quote.ProductId);
    return (
      <div className="applicationSummaryTables">
        <div className="applicationSummaryTables__inner">
          <DealerInfo dealership={this.props.dealership} />
          {this.props.showConsumerHubInfoPanel && <ConsumerHubInfoPanel application={application} />}
          <MiniPanel>
            <ApplicationSummaryPanelHeader
              title={this.props.t('ApplicationSummaryTables.vehicle')}
              showValidationTicks={this.props.showValidationTicks}
            />
            <VehiclePanel vehicle={vehicle} quote={quote} />
          </MiniPanel>
          {(quote.Insurance > 0 || quote.Warranty > 0 || quote.OtherAccessories > 0 || quote.NonVatableItems > 0) && (
            <MiniPanel>
              <ApplicationSummaryPanelHeader
                title={this.props.t('ApplicationSummaryTables.accessories')}
                showValidationTicks={this.props.showValidationTicks}
              />
              <AccessoriesPanel quote={quote} />
            </MiniPanel>
          )}
          {partExchanges.length > 0 && (
            <MiniPanel>
              <ApplicationSummaryPanelHeader
                title={this.props.t('ApplicationSummaryTables.part_exchange')}
                showValidationTicks={this.props.showValidationTicks}
              />
              <PartExchangePanel partExchanges={application.PartExchanges} />
            </MiniPanel>
          )}

          {/* Standard or Aggregate Quote START */}
          <MiniPanel>
            <ApplicationSummaryPanelHeader
              title={this.props.t('ApplicationSummaryTables.quote')}
              showValidationTicks={this.props.showValidationTicks}
            />
            <QuotePanelV2
              quote={quote}
              loanType={LoanTypeJs.standardLoan}
              variant={QuoteSummaryVariantTypeJs.FinanceApplicationSummary}
              newCarOfferText={newCarOffer && newCarOffer.OfferText}
              isPreApproved={application?.PreApprovalData?.NewAPR}
            />
          </MiniPanel>
          {/* Standard or Aggregate Quote END */}

          {/* Companion Quotes START */}
          {vehicleLoan && (vapsLoan || negativeEquityLoan) && (
            <MiniPanel>
              <h3 className="applicationSummaryTables__multiloansHeader">
                {this.props.t('ApplicationSummaryTables.multiple_finance_components')}
              </h3>
              <ApplicationSummaryPanelHeader
                title={this.props.t('ApplicationSummaryTables.vehicle_loan')}
                showValidationTicks={this.props.showValidationTicks}
              />
              <QuotePanelV2
                quote={vehicleLoan}
                loanType={LoanTypeJs.vehicleLoan}
                variant={QuoteSummaryVariantTypeJs.FinanceApplicationSummary}
                hasNegativeEquityLoan={negativeEquityLoan !== undefined}
                isPreApproved={application?.PreApprovalData?.NewAPR}
              />
            </MiniPanel>
          )}
          {negativeEquityLoan && (
            <MiniPanel>
              <ApplicationSummaryPanelHeader
                title={this.props.t('ApplicationSummaryTables.negative_equity_loan')}
                showValidationTicks={this.props.showValidationTicks}
              />
              <QuotePanelV2
                quote={negativeEquityLoan}
                loanType={LoanTypeJs.negativeEquityLoan}
                variant={QuoteSummaryVariantTypeJs.FinanceApplicationSummary}
                isPreApproved={application?.PreApprovalData?.NewAPR}
              />
            </MiniPanel>
          )}
          {vapsLoan && (
            <MiniPanel>
              <ApplicationSummaryPanelHeader
                title={this.props.t('ApplicationSummaryTables.value_added_products_loan')}
                showValidationTicks={this.props.showValidationTicks}
              />
              <QuotePanelV2
                quote={vapsLoan}
                loanType={LoanTypeJs.vapsLoan}
                variant={QuoteSummaryVariantTypeJs.FinanceApplicationSummary}
                isPreApproved={application?.PreApprovalData?.NewAPR}
              />
            </MiniPanel>
          )}
          {/* Companion Quotes END */}

          {application.CustomerType === 'consumer' && (
            <MiniPanel>
              <ApplicationSummaryPanelHeader
                title={this.props.t('ApplicationSummaryTables.personal_details')}
                errorCount={sections.PersonalDetails.errorCount}
                errorMessage={sections.PersonalDetails.errorMessage}
                showValidationTicks={this.props.showValidationTicks}
              />
              <PersonalDetailsPanel
                details={application.PersonalDetails}
                applicationFields={applicationFields.PersonalDetails}
              />
            </MiniPanel>
          )}
          {application.CustomerType === 'corporate' && (
            <MiniPanel>
              <ApplicationSummaryPanelHeader
                title={this.props.t('ApplicationSummaryTables.business_details')}
                errorCount={sections.BusinessDetails.errorCount}
                errorMessage={sections.BusinessDetails.errorMessage}
                showValidationTicks={this.props.showValidationTicks}
              />
              <BusinessDetailsPanel details={application.BusinessDetails} />
            </MiniPanel>
          )}
          {application.CustomerType === 'consumer' && (
            <MiniPanel>
              <ApplicationSummaryPanelHeader
                title={this.props.t('ApplicationSummaryTables.address_history')}
                errorCount={sections.AddressHistory.errorCount}
                errorMessage={sections.AddressHistory.errorMessage}
                showValidationTicks={this.props.showValidationTicks}
              />
              <AddressHistoryPanel addresses={addresses} />
            </MiniPanel>
          )}
          {application.CustomerType === 'corporate' && (
            <MiniPanel>
              <ApplicationSummaryPanelHeader
                title={this.props.t('ApplicationSummaryTables.business_address_history')}
                errorCount={sections.AddressHistory.errorCount}
                errorMessage={sections.AddressHistory.errorMessage}
                showValidationTicks={this.props.showValidationTicks}
              />
              <BusinessAddressHistoryPanel addresses={addresses} />
            </MiniPanel>
          )}
          {application.CustomerType === 'consumer' && (
            <MiniPanel>
              <ApplicationSummaryPanelHeader
                title={this.props.t('ApplicationSummaryTables.employment_history')}
                errorCount={sections.EmploymentHistory.errorCount}
                errorMessage={sections.EmploymentHistory.errorMessage}
                showValidationTicks={this.props.showValidationTicks}
              />
              <EmploymentHistoryPanel employment={employments} />
            </MiniPanel>
          )}
          {application.CustomerType === 'corporate' && (
            <div>
              {contacts.map((contact, index) => (
                <MiniPanel key={`contact${index}`}>
                  <ApplicationSummaryPanelHeader
                    title={`Organisation Contact ${contact.Title} ${contact.FirstName &&
                      titleCase(contact.FirstName)} ${contact.MiddleNames &&
                      titleCase(contact.MiddleNames)} ${contact.LastName && titleCase(contact.LastName)}`}
                    errorCount={sections.OrganisationContacts.errorCount}
                    errorMessage={sections.OrganisationContacts.errorMessage}
                    showValidationTicks={this.props.showValidationTicks}
                    key={`contactHeader${index}`}
                  />
                  <OrganisationContactsPanel contact={contact} />
                </MiniPanel>
              ))}
            </div>
          )}
          <MiniPanel>
            <ApplicationSummaryPanelHeader
              title={this.props.t('ApplicationSummaryTables.bank_details')}
              errorCount={sections.BankDetails.errorCount}
              errorMessage={sections.BankDetails.errorMessage}
              showValidationTicks={this.props.showValidationTicks}
              showHideDetails={this.showHideBankDetails}
              hideDetails={this.state.hideBankDetails}
            />
            <BankDetailsPanel hideBankDetails={this.state.hideBankDetails} details={application.BankDetails} />
          </MiniPanel>
          {application.CustomerType === 'consumer' && (
            <MiniPanel>
              <ApplicationSummaryPanelHeader
                title={this.props.t('ApplicationSummaryTables.affordability')}
                errorCount={sections.AffordabilityDetails.errorCount}
                errorMessage={sections.AffordabilityDetails.errorMessage}
                showValidationTicks={this.props.showValidationTicks}
              />
              <AffordabilityPanel
                details={application.AffordabilityDetails}
                applicationFields={this.props.applicationFields.AffordabilityDetails}
                funderCode={application.Quote.FunderCode}
              />
            </MiniPanel>
          )}
        </div>
      </div>
    );
  }
}

ApplicationSummaryTables.propTypes = {
  application: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  showValidationTicks: PropTypes.bool,
  showConsumerHubInfoPanel: PropTypes.bool,
  applicationFields: PropTypes.object.isRequired,
  dealership: PropTypes.object
};

function mapStateToProps(state) {
  return {
    dealership: state.dealership
  };
}

export default withTranslation('Application')(
  connect(mapStateToProps)(inject('appStore')(observer(ApplicationSummaryTables)))
);
