import s from './chartContainer.module.scss';

type ChartContainerProps = {
  children: React.ReactNode;
};

const ChartContainer = ({ children }: ChartContainerProps) => {
  return <div className={s['container']}>{children}</div>;
};

export default ChartContainer;

