import { withTranslation } from 'react-i18next';
import { clone, kebabCase } from 'lodash';
import { action, extendObservable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import Validator from '../../../validators/Validator';
import Alert from '../../Common/Alert';
import Button from '../../Common/Button/Button';
import ButtonWithIcon from '../../Common/Button/ButtonWithIcon';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import Modal from '../../Common/Modal/Modal';
import MobxFormFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import PermissionsGroup from '../../Permissions/PermissionsGroup';
import PermissionsGroupItem from '../../Permissions/PermissionsGroupItem';
import RemoveRecordModal from './RemoveRecordModal';
import './settingsAccountEditDetails.scss';
import RadioButton from '~Common/Form/RadioButton';
import { canSeeReportingInsights } from '~/features';

const settingGroups = ['consumers', 'applications', 'quoting', 'webshops', 'insights'];

class SettingsAccountEditDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      openSettingGroups: new Set()
    };
    extendObservable(this, {
      formData: {
        firstName: props.user.firstName || '',
        surname: props.user.surname || '',
        mobile: props.user.mobile || '',
        email: props.user.email || '',
        claims: {
          deleteCustomers:
            props.user.claims.includes('dealershipadmin') || props.user.claims.includes('delete-customers'),
          canassign: props.user.claims.includes('dealershipadmin') || props.user.claims.includes('canassign'),
          closeDeal: props.user.claims.includes('dealershipadmin') || props.user.claims.includes('close-deal'),
          viewApplications:
            props.user.claims.includes('dealershipadmin') || props.user.claims.includes('view-applications'),
          createApplications:
            props.user.claims.includes('dealershipadmin') ||
            (props.user.claims.includes('view-applications') && props.user.claims.includes('create-applications')),
          generateQuotes:
            props.user.claims.includes('dealershipadmin') || props.user.claims.includes('generate-quotes'),
          editSchemes:
            props.user.claims.includes('dealershipadmin') ||
            (props.user.claims.includes('generate-quotes') && props.user.claims.includes('edit-schemes')),
          editVaps:
            props.user.claims.includes('dealershipadmin') ||
            (props.user.claims.includes('generate-quotes') && props.user.claims.includes('edit-vaps')),
          webshops: props.user.claims.includes('dealershipadmin') || props.user.claims.includes('webshops'),
          dealershipadmin: props.user.claims.includes('dealershipadmin'),
          reportingFinance:
            props.user.claims.includes('dealershipadmin') || props.user.claims.includes('reporting-finance'),
          reportingStock: props.user.claims.includes('dealershipadmin') || props.user.claims.includes('reporting-stock')
        }
      }
    });
    const validationRules = {
      firstName: 'required',
      surname: 'required',
      mobile: 'mobile',
      email: 'required, email'
    };
    this.setUpValidation(validationRules);
  }

  componentDidMount() {
    this.handleExpandAll();
  }

  isOwnUser = () => {
    return (
      !this.props.isNewUser &&
      this.props.user &&
      (this.props.user.id ? this.props.user.id : '') === this.props.currentUser
    );
  };

  canManageUsers = this.props.appStore.uiState.canManageUsers;

  setUpValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.formData }), this.validator.validate, {
      fireImmediately: true
    });
  }

  handleRemove = (e) => {
    const userId = this.props.user.id;
    this.props.handleRemove(userId);
    e.preventDefault();
  };
  closeModal = () => {
    this.setState({
      modalOpen: false
    });
  };
  openModal = () => {
    this.setState({
      modalOpen: true
    });
  };
  handleSubmit = () => {
    if (!this.validator.errorCount) {
      const data = clone(this.formData);
      if (data.claims.dealershipadmin) {
        data.claims = ['dealershipadmin', 'webshops'];
      } else {
        data.claims = Object.keys(data.claims)
          .filter((key) => data.claims[key] !== false)
          .map((claim) => kebabCase(claim));
      }
      this.props.onSubmit(this.props.user.id, data);
    }
  };
  handleDependentClaims = (claimStatus, claimId) => {
    switch (claimId) {
      case 'viewApplications':
        claimStatus === false && this.setClaim(claimStatus, 'createApplications');
        break;

      case 'generateQuotes':
        if (claimStatus === false) {
          this.setClaim(claimStatus, 'editSchemes');
          this.setClaim(claimStatus, 'editVaps');
        }

        break;

      default:
        break;
    }

    this.setClaim(claimStatus, claimId);
  };
  handleToggleItem = (claimStatus, claimId) => {
    this.handleDependentClaims(claimStatus, claimId);
  };
  handleToggleSettingGroup = (groupId) => {
    const newSettingGroups = clone(this.state.openSettingGroups);
    window.ga &&
      window.ga(
        'send',
        'event',
        'SettingsAccountEditDetails',
        `ToggleCollapseSettingsGroup`,
        `${groupId}PermsGroup${!newSettingGroups.has(groupId) ? 'Expanded' : 'Collapsed'}`
      );

    if (newSettingGroups.has(groupId)) {
      newSettingGroups.delete(groupId);
    } else {
      newSettingGroups.add(groupId);
    }

    this.setState({
      openSettingGroups: newSettingGroups
    });
  };
  handleExpandAll = () => {
    const allGroupsExpanded = this.state.openSettingGroups.size === 4; // set number to same as number of PermissionGroups that exist

    const newSettingGroups = clone(this.state.openSettingGroups);

    if (allGroupsExpanded) {
      newSettingGroups.clear();
    } else {
      settingGroups.forEach((groupId) => newSettingGroups.add(groupId));
    }

    window.ga &&
      window.ga(
        'send',
        'event',
        'SettingsAccountEditDetails',
        `ToggleCollapseSettingsGroup`,
        `allPermsGroup${!allGroupsExpanded ? 'Expanded' : 'Collapsed'}`
      );
    this.setState({
      openSettingGroups: newSettingGroups
    });
  };

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  @action
  setFirstname = (firstName) => (this.formData.firstName = firstName);
  @action
  setSurname = (surname) => (this.formData.surname = surname);
  @action
  setMobile = (mobile) => (this.formData.mobile = mobile);
  @action
  setEmail = (email) => (this.formData.email = email);
  @action
  setClaim = (claimStatus, claimId) => {
    this.formData.claims[claimId] = claimStatus;
  };
  @action
  setUserGroup = (e) => {
    this.formData.claims.dealershipadmin = !this.formData.claims.dealershipadmin;
    for (const claim in this.formData.claims) {
      if (claim !== 'dealershipadmin') {
        this.formData.claims[claim] = false;
      }
    }
  };

  render() {
    const errors = this.validator.getErrors();
    const allGroupsExpanded = this.state.openSettingGroups.size === 4; // set number to same as number of PermissionGroups that exist
    return (
      <MobxForm onSubmit={this.handleSubmit} focusOnFirstElement>
        <div className="settingsAccountEditDetails">
          <div className="settingsAccountEditDetails__inner">
            {this.props.hasDuplicateEmailError && (
              <div className="settingsAccountEditDetails__duplicateEmailError">
                <Alert>{this.props.t('SettingsAccountEditDetails.this_email_address_is_already_in_use')}</Alert>
              </div>
            )}
            <div className="settingsAccountEditDetails__section">
              <MobxFormFieldGroup error={errors.firstName}>
                <MobxFormLabel htmlFor="firstName">
                  {this.props.t('SettingsAccountEditDetails.first_name')}
                </MobxFormLabel>
                <TextInputWrappingInput id="firstName" value={this.formData.firstName} onChange={this.setFirstname} />
              </MobxFormFieldGroup>
              <MobxFormFieldGroup error={errors.surname}>
                <MobxFormLabel htmlFor="surname">{this.props.t('SettingsAccountEditDetails.last_name')}</MobxFormLabel>
                <TextInputWrappingInput id="surname" value={this.formData.surname} onChange={this.setSurname} />
              </MobxFormFieldGroup>
            </div>
            <div className="settingsAccountEditDetails__section">
              <MobxFormFieldGroup error={errors.mobile}>
                <MobxFormLabel htmlFor="mobile">
                  {this.props.t('SettingsAccountEditDetails.mobile_phone')}
                </MobxFormLabel>
                <TextInputWrappingInput id="mobile" type="tel" value={this.formData.mobile} onChange={this.setMobile} />
              </MobxFormFieldGroup>
              <MobxFormFieldGroup error={errors.email}>
                <MobxFormLabel htmlFor="email">{this.props.t('SettingsAccountEditDetails.email')}</MobxFormLabel>
                <TextInputWrappingInput
                  disabled={!this.props.isNewUser}
                  id="email"
                  type="email"
                  value={this.formData.email}
                  onChange={this.setEmail}
                />
              </MobxFormFieldGroup>
            </div>
            <div className="settingsAccountEditDetails__section">
              <div className="settingsAccountEditDetails__userGroup">
                <strong className="settingsAccountEditDetails__userGroupLabel">
                  {this.props.t('SettingsAccountEditDetails.user_group')}
                </strong>
                {this.canManageUsers && !this.isOwnUser() ? (
                  <div className="settingsAccountEditDetails__userGroup--radios">
                    <RadioButton
                      className="settingsAccountEditDetails__userGroup--radio"
                      onChange={this.setUserGroup}
                      value={true}
                      name="userGroup"
                      id="isAdmin"
                      checked={this.formData.claims.dealershipadmin}
                    >
                      <strong className="settingsAccountEditDetails__userGroupLabel">
                        {this.props.t('SettingsAccountEditDetails.admin')}
                      </strong>
                    </RadioButton>
                    <RadioButton
                      className="settingsAccountEditDetails__userGroup--radio"
                      onChange={this.setUserGroup}
                      value={false}
                      name="userGroup"
                      id="isStandard"
                      checked={!this.formData.claims.dealershipadmin}
                    >
                      <strong className="settingsAccountEditDetails__userGroupLabel">
                        {this.props.t('SettingsAccountEditDetails.standard')}
                      </strong>
                    </RadioButton>
                  </div>
                ) : (
                  <div>
                    {this.formData.claims.dealershipadmin ? (
                      <div className="settingsAccountEditDetails__userGroupData">
                        {this.props.t('SettingsAccountEditDetails.admin')}
                      </div>
                    ) : (
                      <div className="settingsAccountEditDetails__userGroupData">
                        {this.props.t('SettingsAccountEditDetails.standard')}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {this.isOwnUser() && (
              <div className="settingsAccountEditDetails__section">
                <div className="settingsAccountEditDetails__passwordContainer__section">
                  <div className="settingsAccountEditDetails__passwordContainer" key="passwordContainer">
                    <Button to={`/d/${this.props.dealershipId}/settings/changepassword`} buttonStyle="secondary">
                      {this.props.t('SettingsAccountEditDetails.change_password')}
                    </Button>
                  </div>
                </div>
              </div>
            )}

            <div className="settingsAccountEditPermissions__section settingsAccountEditPermissions__section__permissions">
              <div className="settingsAccountEditPermissions__header">
                {this.props.t('SettingsAccountEditDetails.user_permission_controls')}
              </div>
              <div className="settingsAccountEditPermissions__desc">
                {!this.isOwnUser()
                  ? this.props.t(
                      'SettingsAccountEditDetails.as_an_admin_you_can_choose_to_turn_on_or_off_these_permissions_at_any_time'
                    )
                  : this.props.t(
                      'SettingsAccountEditDetails.this_is_your_own_user_and_you_are_unable_to_edit_your_own_permissions'
                    )}
              </div>
              <div className="settingsAccountEditPermissions__btnExpand">
                <div>
                  <ButtonWithIcon
                    onClick={this.handleExpandAll}
                    iconName={!allGroupsExpanded ? 'plus' : 'minus'}
                    buttonStyle="secondary"
                  >
                    {!allGroupsExpanded
                      ? this.props.t('SettingsAccountEditDetails.expand_all_groups')
                      : this.props.t('SettingsAccountEditDetails.collapse_all_groups')}
                  </ButtonWithIcon>
                </div>
              </div>
              <PermissionsGroup
                groupId="consumers"
                label={this.props.t('SettingsAccountEditDetails.manage_customers')}
                handleToggleItem={this.handleToggleItem}
                handleToggleOpen={this.handleToggleSettingGroup}
                openSettingGroups={this.state.openSettingGroups}
                disabled={this.isOwnUser() || this.formData.claims.dealershipadmin}
                toggleAdmin={this.formData.claims.dealershipadmin}
                trackingPage={this.props.trackingPage}
              >
                <PermissionsGroupItem
                  id="deleteCustomers"
                  label={this.props.t('SettingsAccountEditDetails.delete_customer')}
                  icon="bin"
                  isActive={this.formData.claims.deleteCustomers}
                />
                <PermissionsGroupItem
                  id="canassign"
                  label={this.props.t('SettingsAccountEditDetails.assign_leads')}
                  icon="assign"
                  isActive={this.formData.claims.canassign}
                />
                <PermissionsGroupItem
                  id="closeDeal"
                  label={this.props.t('SettingsAccountEditDetails.close_deal')}
                  icon="cross"
                  isActive={this.formData.claims.closeDeal}
                />
              </PermissionsGroup>

              {this.props.appStore.uiState.canUseFinancing && (
                <PermissionsGroup
                  groupId="applications"
                  label={this.props.t('SettingsAccountEditDetails.applications')}
                  handleToggleItem={this.handleToggleItem}
                  handleToggleOpen={this.handleToggleSettingGroup}
                  openSettingGroups={this.state.openSettingGroups}
                  disabled={this.isOwnUser() || this.formData.claims.dealershipadmin}
                  toggleAdmin={this.props.appStore.uiState.canUseFinancing && this.formData.claims.dealershipadmin}
                  trackingPage={this.props.trackingPage}
                >
                  <PermissionsGroupItem
                    id="viewApplications"
                    label={this.props.t('SettingsAccountEditDetails.view_applications')}
                    icon="application"
                    isActive={this.formData.claims.viewApplications}
                  />
                  <PermissionsGroupItem
                    id="createApplications"
                    label={this.props.t('SettingsAccountEditDetails.create_applications')}
                    icon="approved"
                    handleToggle={this.handleToggleItem}
                    disabled={!this.formData.claims.viewApplications}
                    isActive={this.formData.claims.createApplications}
                  />
                </PermissionsGroup>
              )}
              {this.props.appStore.uiState.canQuote && (
                <PermissionsGroup
                  groupId="quoting"
                  label={this.props.t('SettingsAccountEditDetails.quoting')}
                  handleToggleItem={this.handleToggleItem}
                  handleToggleOpen={this.handleToggleSettingGroup}
                  openSettingGroups={this.state.openSettingGroups}
                  disabled={this.isOwnUser() || this.formData.claims.dealershipadmin}
                  toggleAdmin={this.props.appStore.uiState.canQuote && this.formData.claims.dealershipadmin}
                  trackingPage={this.props.trackingPage}
                >
                  <PermissionsGroupItem
                    id="generateQuotes"
                    icon="tick-speech"
                    label={this.props.t('SettingsAccountEditDetails.generate_quotes')}
                    isActive={this.formData.claims.generateQuotes}
                  />
                  {!this.props.appStore.uiState.isBdk && (
                    <PermissionsGroupItem
                      id="editSchemes"
                      icon="edit-schemes"
                      label={this.props.t('SettingsAccountEditDetails.edit_schemes')}
                      disabled={!this.formData.claims.generateQuotes}
                      isActive={this.formData.claims.editSchemes}
                    />
                  )}
                  <PermissionsGroupItem
                    id="editVaps"
                    icon="vaps"
                    label={this.props.t('SettingsAccountEditDetails.edit_and_remove_vaps')}
                    disabled={!this.formData.claims.generateQuotes}
                    isActive={this.formData.claims.editVaps}
                  />
                </PermissionsGroup>
              )}
              {this.props.appStore.uiState.hasWebshopEnabled && (
                <PermissionsGroup
                  groupId="webshops"
                  label={'Webshop'}
                  handleToggleItem={this.handleToggleItem}
                  handleToggleOpen={this.handleToggleSettingGroup}
                  openSettingGroups={this.state.openSettingGroups}
                  disabled={this.isOwnUser() || this.formData.claims.dealershipadmin}
                  toggleAdmin={this.props.appStore.uiState.hasWebshopEnabled && this.formData.claims.dealershipadmin}
                  trackingPage={this.props.trackingPage}
                >
                  <PermissionsGroupItem
                    id="webshops"
                    icon="webshop"
                    label={'Webshop'}
                    isActive={this.formData.claims.webshops}
                  />
                </PermissionsGroup>
              )}
              {canSeeReportingInsights() && (
                <PermissionsGroup
                  groupId="insights"
                  label={this.props.t('SettingsAccountEditDetails.insights')}
                  handleToggleItem={this.handleToggleItem}
                  handleToggleOpen={this.handleToggleSettingGroup}
                  openSettingGroups={this.state.openSettingGroups}
                  disabled={this.isOwnUser() || this.formData.claims.dealershipadmin}
                  toggleAdmin={this.formData.claims.dealershipadmin}
                  trackingPage={this.props.trackingPage}
                >
                  <PermissionsGroupItem
                    id="reportingFinance"
                    icon="report"
                    label={this.props.t('SettingsAccountEditDetails.finance_reports')}
                    isActive={this.formData.claims.reportingFinance}
                  />
                  <PermissionsGroupItem
                    id="reportingStock"
                    icon="report"
                    label={this.props.t('SettingsAccountEditDetails.stock_reports')}
                    isActive={this.formData.claims.reportingStock}
                  />
                </PermissionsGroup>
              )}
            </div>
          </div>
        </div>
        <div className="settingsAccountEditDetails__buttonContainer">
          <div className="settingsAccountEditDetails__saveButton">
            <Button
              isLoading={this.props.isSubmitting}
              hasError={this.props.hasSubmittingError}
              type="submit"
              stretch={true}
              buttonStyle="primary"
            >
              {this.props.t('SettingsAccountEditDetails.save')}
            </Button>
          </div>
          {!this.isOwnUser() && !this.props.isNewUser && this.props.appStore.uiState.isDealershipAdmin && (
            <div className="settingsAccountEditDetails__removeButton">
              <Button type="button" buttonStyle="cancel" onClick={this.openModal}>
                {this.props.t('SettingsAccountEditDetails.remove_user')}
              </Button>
            </div>
          )}
          <div className="settingsAccountEditDetails__cancel">
            <Button to={this.props.goBackLink} buttonStyle="cancel">
              {this.props.t('SettingsAccountEditDetails.go_back')}
            </Button>
          </div>
        </div>
        <Modal isOpen={this.state.modalOpen} onClose={this.closeModal}>
          <RemoveRecordModal
            ref="removeModal"
            handleRemove={this.handleRemove}
            isRemoving={this.props.isRemoving}
            hasRemovingError={this.props.hasRemovingError}
            onClose={this.closeModal}
          />
        </Modal>
      </MobxForm>
    );
  }
}

SettingsAccountEditDetails.defaultProps = {
  isNewUser: false,
  hasAddUserError: false,
  hasDuplicateEmailError: false
};

SettingsAccountEditDetails.propTypes = {
  isRemoving: PropTypes.bool,
  hasRemovingError: PropTypes.bool,
  user: PropTypes.object,
  currentUser: PropTypes.string,
  options: PropTypes.object,
  dealershipId: PropTypes.string,
  goBackLink: PropTypes.string,
  handleRemove: PropTypes.func,
  onSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
  hasSubmittingError: PropTypes.bool,
  isNewUser: PropTypes.bool,
  trackingPage: PropTypes.string.isRequired,
  hasDuplicateEmailError: PropTypes.bool
};

export default withTranslation('ManageUsers')(inject('appStore')(observer(SettingsAccountEditDetails)));
