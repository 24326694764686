import React from 'react';
import PropTypes from 'prop-types';
import SearchBar from '../Search/SearchBar';
import SelectInput from '../Common/Form/SelectInput';
import MobxFormFieldGroup from '../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../MobxForm/MobxFormLabel';
import MobxForm from '../MobxForm/MobxForm';
import VehicleFormValidator from '../../validators/VehicleFormValidator';
import DeVehicleFormValidator from '../../validators/DeVehicleFormValidator';
import { reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import VrmLookup from '../VrmLookup/VrmLookup';
import DateInput from '../Common/Form/DateInput';
import TextInput from '../Common/Form/TextInput';
import VisCard from './VisCard';
import { visMotorhomeRequest, visRequest } from '../../api/visVehicles';
import Button from '../Common/Button/Button';
import moment from 'moment';
import Pagination from '../Common/Pagination';
import { withTranslation } from 'react-i18next';
import './visQuotesPage.scss';
import { VehicleClassJs, isMotorhomeOrTouringCaravan } from '~/types/vehicle';

const getValidator = (country) => {
  switch (country) {
    case 'de':
      return new DeVehicleFormValidator();
    default:
      return new VehicleFormValidator();
  }
};

class VisQuotePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        GlassId: '',
        Condition: 'used',
        Vrm: '',
        RegistrationDate: '',
        MakeId: '',
        Make: '',
        ModelId: '',
        Model: '',
        DerivativeId: '',
        Derivative: '',
        Mileage: '',
        Vin: '',
        ...this.props.initialData
      },
      searchResults: [],
      searchStatus: '',
      vrmError: {},
      isManualEntry: false,
      currentPage: 1,
      numberOfItems: 0
    };

    this.setUpValidation();
  }

  setUpValidation() {
    this.validator = getValidator(this.props.appStore.uiState.countryCode);

    this.validationReactionDisposer = reaction(() => ({ ...this.state.formData }), this.validator.validate, {
      fireImmediately: true
    });
  }

  componentDidMount() {
    if (this.props.searchTerms) {
      this.handleSearch('searchBar', this.props.searchTerms);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.searchTerms !== this.state.searchTerms) {
      this.handleSearch('search', nextProps.searchTerms);
    }
  }

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  handleSearch = (id, value, pageNumber) => {
    this.setState({ searchTerms: value, searchStatus: 'loading' });

    if (value && value !== '') {
      let pageToSearch = pageNumber ? pageNumber - 1 : 0;
      const visService = isMotorhomeOrTouringCaravan(this.state.formData.Class) ? visMotorhomeRequest : visRequest;

      visService({
        query: value,
        currentPage: pageToSearch,
        pageSize: 5,
        class: this.state.formData.Class,
        country: this.props.appStore.uiState.countryCode,
        klass: this.state.formData.Class
      })
        .then((response) => {
          this.setState({
            searchResults: response.response,
            searchStatus: 'success',
            numberOfItems: response.numberOfRecords,
            currentPage: pageNumber || 1
          });
        })
        .catch(() => {
          this.setState({ searchStatus: 'error' });
        });
    } else {
      this.setState({ searchResults: [], searchStatus: 'idle' });
    }
  };

  handleChangeValue = (key, value) => {
    let state = {
      ...this.state,
      formData: {
        ...this.state.formData
      }
    };

    state.formData[key] = value;

    if (key === 'Condition') {
      state.formData.Mileage = '';
    }

    if (key === 'Class' && value === VehicleClassJs.touringCaravan) {
      state.formData.Vrm = '';
    }

    this.validator.validate(state.formData);

    this.setState({ ...state, searchStatus: key === 'Class' ? 'idle' : this.state.searchStatus });

    if (key === 'Class') {
      this.props.onUpdateVehicleClass(value);
      this.validationReactionDisposer();
      this.setUpValidation();
    }
  };

  selectVehicle = (selectedVehicle) => {
    return () => {
      let state = {
        ...this.state,
        formData: {
          ...this.state.formData
        },
        selectedVehicle
      };

      state.formData.Make = selectedVehicle.Make;
      state.formData.Model = selectedVehicle.Model;
      state.formData.Derivative = selectedVehicle.Derivative ?? selectedVehicle.Model;
      state.formData.GlassId = selectedVehicle.PrimaryTaxonomyId;

      this.setState({
        ...state
      });
    };
  };

  deselectVehicle = () => {
    this.setState({ selectedVehicle: null });
  };

  handleSubmit = () => {
    this.validator.validate(this.state.formData);
    if (!this.validator.errorCount) {
      this.props.onSubmit(this.state.formData);
    }
  };

  handleFetchVehicleSuccess = (data) => {
    this.handleChangeValue('Vin', data.Vin);
    if (data.CapData && data.CapData.length > 0) {
      this.handleChangeValue('RegistrationDate', moment(data.CapData[0].Registered).format('DD/MM/YYYY'));
    }
  };

  toggleManualEntry = () => {
    let state = {
      ...this.state,
      formData: {
        ...this.state.formData
      }
    };

    state.isManualEntry = !state.isManualEntry;
    state.formData.Make = '';
    state.formData.Model = '';
    state.formData.Derivative = '';

    this.validator.validate(state.formData);

    this.setState({
      ...state
    });
  };

  handlePageChange = (pageNumber) => {
    this.handleSearch('id', this.state.searchTerms, pageNumber);
  };

  renderVrmLookup() {
    const errors = this.validator.getErrors();
    let vrmError = Object.keys(this.state.vrmError).length ? this.state.vrmError : errors.Vrm;
    const showLookupButton = this.state.formData.Condition === 'used';
    const showVrmLookup =
      !this.props.appStore.uiState.isDe && this.state.formData.Class !== VehicleClassJs.touringCaravan;
    return (
      <div>
        {showVrmLookup && (
          <MobxFormFieldGroup error={vrmError}>
            <MobxFormLabel htmlFor="Vrm">Registration Number</MobxFormLabel>
            <VrmLookup
              id="Vrm"
              onChange={this.handleChangeValue}
              onFetchVehicle={this.handleFetchVehicle}
              onFetchVehicleSuccess={this.handleFetchVehicleSuccess}
              onFetchVehicleError={this.handleFetchVehicleError}
              value={this.state.formData.Vrm}
              lookupVrm={this.state.lookupVrm}
              dealershipId={this.props.dealershipId}
              disabled={this.props.options.disabledFields && this.props.options.disabledFields.RegNumber}
              showLookupButton={showLookupButton}
            />
          </MobxFormFieldGroup>
        )}

        <MobxFormFieldGroup error={errors.RegistrationDate}>
          <MobxFormLabel htmlFor="RegistrationDate">{this.props.t('VisQuotePage.registration_date')}</MobxFormLabel>
          <DateInput
            id="RegistrationDate"
            value={this.state.formData.RegistrationDate}
            onChange={this.handleChangeValue}
            disabled={this.props.options.disabledFields && this.props.options.disabledFields.RegDate}
          />
        </MobxFormFieldGroup>
      </div>
    );
  }

  renderMileage() {
    const showMileage =
      this.state.formData.Condition !== 'new' && this.state.formData.Class !== VehicleClassJs.touringCaravan;

    if (!showMileage) {
      return;
    }

    const errors = this.validator.getErrors();

    return (
      <MobxFormFieldGroup error={errors.Mileage}>
        <MobxFormLabel htmlFor="Mileage">{this.props.t('VisQuotePage.mileage')}</MobxFormLabel>
        <TextInput
          id="Mileage"
          value={this.state.formData.Mileage}
          onChange={this.handleChangeValue}
          disabled={this.props.options.disabledFields && this.props.options.disabledFields.Mileage}
          dataThook="Mileage"
        />
      </MobxFormFieldGroup>
    );
  }

  renderVIN() {
    const { Vin } = this.state.formData;
    const errors = this.validator.getErrors();

    return (
      <MobxFormFieldGroup information={Vin && this.handleVinFieldGroupWarning(Vin)} error={errors.Vin}>
        <MobxFormLabel htmlFor="Vin">{this.props.t('VisQuotePage.vin')}</MobxFormLabel>
        <TextInput
          id="Vin"
          value={Vin}
          onChange={this.handleChangeValue}
          disabled={this.props.options.disabledFields && this.props.options.disabledFields.Vin}
          dataThook="VIN"
        />
      </MobxFormFieldGroup>
    );
  }

  handleVinFieldGroupWarning = (Vin) => {
    if (Vin.length !== 17 && Vin !== '') {
      return 'VIN should be 17 characters';
    }
  };

  // renderManualEntry = () => {
  //     const errors = this.validator.getErrors();
  //     return (
  //         <>
  //             <MobxFormFieldGroup error={errors.Make}>
  //                 <MobxFormLabel htmlFor="Make">Make</MobxFormLabel>
  //                 <TextInput
  //                     id="Make"
  //                     value={this.state.formData.Make}
  //                     onChange={this.handleChangeValue}
  //                     disabled={this.props.options.disabledFields && this.props.options.disabledFields.Make}
  //                 />
  //             </MobxFormFieldGroup>

  //             <MobxFormFieldGroup error={errors.Model}>
  //                 <MobxFormLabel htmlFor="Model">Model</MobxFormLabel>
  //                 <TextInput
  //                     id="Model"
  //                     value={this.state.formData.Model}
  //                     onChange={this.handleChangeValue}
  //                     disabled={this.props.options.disabledFields && this.props.options.disabledFields.Model}
  //                 />
  //             </MobxFormFieldGroup>

  //             <MobxFormFieldGroup error={errors.Derivative}>
  //                 <MobxFormLabel htmlFor="Derivative">Derivative</MobxFormLabel>
  //                 <TextInput
  //                     id="Derivative"
  //                     value={this.state.formData.Derivative}
  //                     onChange={this.handleChangeValue}
  //                     disabled={this.props.options.disabledFields && this.props.options.disabledFields.Derivative}
  //                 />
  //             </MobxFormFieldGroup>
  //         </>
  //     );
  // };

  render() {
    let errors = this.validator.getErrors();

    return (
      <div className="visQuotesPage">
        <MobxForm focusOnNthElement={3} onSubmit={this.handleSubmit} className="quoteCostsForm">
          <MobxFormFieldGroup error={errors.Condition}>
            <MobxFormLabel htmlFor="Condition">{this.props.t('VisQuotePage.condition')}</MobxFormLabel>
            <SelectInput
              id="Condition"
              value={this.state.formData.Condition}
              onChange={this.handleChangeValue}
              options="VehicleType"
              emptyValue={false}
              disabled={this.props.options.disabledFields && this.props.options.disabledFields.Condition}
            />
          </MobxFormFieldGroup>

          <MobxFormFieldGroup error={errors.Class}>
            <MobxFormLabel htmlFor="Class">{this.props.t('VisQuotePage.class')}</MobxFormLabel>
            <SelectInput
              id="Class"
              value={this.state.formData.Class}
              onChange={this.handleChangeValue}
              options="VehicleClass"
              emptyValue={false}
              disabled={this.props.options.disabledFields && this.props.options.disabledFields.Class}
            />
          </MobxFormFieldGroup>

          {!this.state.selectedVehicle && (
            <div className="visQuotesPage__searchBar">
              <SearchBar
                id="motorhomes"
                value={this.state.searchTerms}
                onChange={this.handleSearch}
                placeholder={this.props.t('VisQuotePage.search_placeholder')}
                dataThook="SearchBar"
              />
              {/* <div className="visQuotesPage__manualSwitch">
                                    Can't find what you're looking for?{' '}
                                    <span
                                        onClick={this.toggleManualEntry}
                                        className="visQuotesPage__manualSwitch--link"
                                    >
                                        Enter manually
                                    </span>
                                </div> */}
              {this.state.searchStatus === 'error' && (
                <div className="visQuotesPage__searchStatus visQuotesPage__searchStatus--error">
                  {this.props.t('VisQuotePage.error_fetching_vehicles')}
                </div>
              )}
              {this.state.searchStatus === 'success' && this.state.searchResults.length === 0 && (
                <div className="visQuotesPage__searchStatus">{this.props.t('VisQuotePage.no_results_found')}</div>
              )}
              {this.state.searchStatus === 'loading' && (
                <div className="visQuotesPage__searchStatus">{this.props.t('VisQuotePage.loading')}</div>
              )}
            </div>
            // <>
            //     <div className="visQuotesPage__manualSwitch">
            //         Enter the vehicle details, or{' '}
          )
          //         <span
          //             onClick={this.toggleManualEntry}
          //             className="visQuotesPage__manualSwitch--link"
          //         >
          //             use our search instead
          //         </span>
          //     </div>
          //     {this.renderManualEntry()}
          // </>
          }

          <div className="visQuotesPage__searchResults">
            {this.state.selectedVehicle ? (
              <div className="visQuotesPage__selectedVehicle">
                <VisCard vehicle={this.state.selectedVehicle} onClick={this.deselectVehicle} isSelected />
              </div>
            ) : (
              this.state.searchResults.length > 0 && (
                <>
                  <div className="visQuotesPage__searchResultCards">
                    {this.state.searchResults.map((result, index) => (
                      <div className="visQuotesPage__resultCard" key={index}>
                        <VisCard vehicle={result} onClick={this.selectVehicle(result)} />
                      </div>
                    ))}
                  </div>

                  <div>
                    <Pagination
                      onChange={this.handlePageChange}
                      totalItems={this.state.numberOfItems}
                      pageSize={5}
                      currentPage={this.state.currentPage}
                    />
                  </div>
                </>
              )
            )}
          </div>

          {this.renderVrmLookup()}

          {this.renderVIN()}
          {this.renderMileage()}

          <div className="visQuotesPage__footer">
            <div className="visQuotesPage__button">
              <Button
                type="cancel"
                buttonStyle="cancel"
                to={this.props.onCancel ? undefined : '/'}
                onClick={this.props.onCancel}
              >
                {this.props.t('VisQuotePage.cancel')}
              </Button>
            </div>
            <div className="visQuotesPage__button">
              <Button type="submit" onClick={this.onSubmit}>
                {this.props.t('VisQuotePage.submit')}
              </Button>
            </div>
          </div>
        </MobxForm>
      </div>
    );
  }
}

VisQuotePage.propTypes = {
  searchTerms: PropTypes.string,
  appStore: PropTypes.object,
  dealershipId: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onUpdateVehicleClass: PropTypes.func,
  initialData: PropTypes.object,
  options: PropTypes.object,
  visLookup: PropTypes.func
};

VisQuotePage.defaultProps = {
  options: { disabledFields: {} }
};

export default withTranslation('VisQuoting')(inject(['appStore'])(observer(VisQuotePage)));
