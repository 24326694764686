import { ApiResponse, Dataset, SeriesTypeEnum, ChartVariantEnum, MetricToChartVariantMap, Metric } from '../types';
import { TFunction } from 'i18next';

type ChartJsConfig = {
  datasets: Array<{
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
  }>;
  labels: string[];
  lastModified: string;
  points: string[];
};

type DisplayStyle = {
  color: string;
  displayName: string;
};

const COLORS = {
  primary: '#1E90FF',
  secondary: '#F56565',
  tertiary: '#64BC46',
  fallback: '#4ac0c0'
};

const getDisplayStyles = (chartType: ChartVariantEnum): Record<string, DisplayStyle> => {
  switch (chartType) {
    case ChartVariantEnum.OnlineQuotes:
      return {
        [SeriesTypeEnum.count]: {
          color: COLORS.primary,
          displayName: 'all_sources'
        },
        [SeriesTypeEnum['count online']]: {
          color: COLORS.secondary,
          displayName: 'online'
        },
        [SeriesTypeEnum['count showroom']]: {
          color: COLORS.tertiary,
          displayName: 'showroom'
        }
      };

    default:
      return {
        default: {
          color: COLORS.fallback,
          displayName: ''
        }
      };
  }
};

export const transformToChartJsFormat = (
  data: ApiResponse,
  dataSet: 'sum' | 'avg' = 'sum',
  metric: Metric,
  t: TFunction
): ChartJsConfig => {
  const chartType = MetricToChartVariantMap[metric] || ChartVariantEnum.Default;
  const displayStyles = getDisplayStyles(chartType);

  const chartDatasets = data.datasets.map((dataset: Dataset) => {
    const style = displayStyles[dataset?.label] || displayStyles['default'];

    return {
      label: style?.displayName ? t(`ChartFilters.${style?.displayName}`) : dataSet,
      data: dataset[dataSet] || [],
      borderColor: style?.color || COLORS.fallback,
      backgroundColor: style?.color || COLORS.fallback
    };
  });

  return {
    datasets: chartDatasets,
    labels:
      data.points.length > 0 ? data.points : data.datasets[0]?.users?.map((_, index) => `Point ${index + 1}`) || []
  } as ChartJsConfig;
};
