import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Page from '../../Common/Page';
import TrackPageView from '../../Common/Tracking/TrackPageView';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Panel from '../../Common/Panel';
import PanelContent from '../../Common/PanelContent';
import PanelHeader from '../../Common/PanelHeader';
import PanelToolBar from '../../Common/PanelToolBar';
import VehicleReservations from '../components/VehicleReservations';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';
import Pagination from '../../Common/Pagination';
import '../components/vehicleReservations.scss';
import Select from '../../Common/Form/Select';
import Modal from '../../Common/Modal/Modal';
import { compose } from 'redux';
import { withNavigate, withParams, withQuery } from '../../../../src/hocs/router';
class ReserveNowContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reservationStatus: this.props.query.reservationStatus
    };
  }

  componentDidMount() {
    const defaultSearchParams = this.getDefaultSearchParams();
    this.updateQuery(defaultSearchParams);
    const activeVehicles = this.props.appStore.reserveNowStore.activeReservationsNumber > 0;

    if (this.state.reservationStatus === 'active' && !activeVehicles) {
      this.setState(
        {
          reservationStatus: 'all',
          page: '1'
        },
        () => {
          this.updateQuery({ ...this.state });
          this.props.appStore.reserveNowStore.getReservedVehiclesByDealer(
            this.props.params.dealershipId,
            defaultSearchParams.page,
            defaultSearchParams.pageSize,
            this.state.reservationStatus
          );
        }
      );
    } else {
      this.props.appStore.reserveNowStore.getReservedVehiclesByDealer(
        this.props.params.dealershipId,
        defaultSearchParams.page,
        defaultSearchParams.pageSize,
        defaultSearchParams.reservationStatus
      );
    }
  }

  getDefaultSearchParams = () => {
    const query = this.props.query;
    return {
      dealershipId: this.props.params.dealershipId,
      page: !!query.page ? query.page : '1',
      pageSize: !!query.pageSize ? query.pageSize : '5',
      reservationStatus: !!query.reservationStatus ? query.reservationStatus : 'all'
    };
  };
  fetchVehicles = (page) => {
    const query = this.props.query;
    this.props.appStore.reserveNowStore.getReservedVehiclesByDealer(
      this.props.params.dealershipId,
      page,
      query.pageSize,
      query.reservationStatus
    );
  };
  updateQuery = (newParams) => {
    this.props.navigate({
      pathname: `/d/${this.props.params.dealershipId}/reservenow`,
      query: { ...this.props.query, ...newParams }
    });
  };
  handlePageChange = (page) => {
    if (page !== parseInt(this.props.query.page)) {
      window.scrollTo(0, 0);
      this.fetchVehicles(page);
      this.updateQuery({
        page: page.toString()
      });
    }
  };
  handleFilter = (filter) => {
    this.setState(
      {
        reservationStatus: filter.currentTarget.value,
        page: '1'
      },
      () => {
        this.updateQuery({ ...this.state });
      }
    );
    this.setReserveItemGoogleAnalytics(`FilterReserveBy_${filter.currentTarget.value}`);
    const query = this.props.query;
    this.props.appStore.reserveNowStore.getReservedVehiclesByDealer(
      this.props.params.dealershipId,
      query.page,
      query.pageSize,
      filter.currentTarget.value
    );
  };
  setReserveItemGoogleAnalytics = (interaction) => {
    window && window.ga && window.ga('send', 'event', 'VehicleReservationItem', interaction);
  };
  handleRefundConfirm = () => {
    this.props.appStore.reserveNowStore.toggleRefundConfirmationMessage();
    this.props.appStore.reserveNowStore.setReservationRefundRequestForReserveItem().then(() => {
      this.handleRefundSuccess();
    });
  };
  handleRefundSuccess = () => {
    this.setReserveItemGoogleAnalytics(
      `RefundCustomerTypeConsumer${
        this.props.appStore.reserveNowStore.refundCustomer.data.overdue ? '_Overdue' : '_Active'
      }`
    );
    const defaultSearchParams = this.getDefaultSearchParams();
    this.props.appStore.reserveNowStore.getReservedVehiclesByDealer(
      this.props.params.dealershipId,
      defaultSearchParams.page,
      defaultSearchParams.pageSize,
      defaultSearchParams.reservationStatus
    );
    this.updateQuery();
  };
  handlePaymentAsDepositConfirm = () => {
    this.props.appStore.reserveNowStore.togglePaymentAsDepositMessage();
    this.props.appStore.reserveNowStore.setPaymentAsDeposit().then(() => {
      this.handlePaymentAsDepositSuccess();
    });
  };
  handlePaymentAsDepositSuccess = () => {
    this.setReserveItemGoogleAnalytics('SetPaymentAsDeposit');
    const defaultSearchParams = this.getDefaultSearchParams();
    this.props.appStore.reserveNowStore.getReservedVehiclesByDealer(
      this.props.params.dealershipId,
      defaultSearchParams.page,
      defaultSearchParams.pageSize,
      defaultSearchParams.reservationStatus
    );
    this.updateQuery();
  };
  handleRefundCancel = () => {
    this.props.appStore.reserveNowStore.toggleRefundConfirmationMessage();
  };
  handlePaymentAsDepositCancel = () => {
    this.props.appStore.reserveNowStore.togglePaymentAsDepositMessage();
  };

  render() {
    const query = this.props.query;
    const data = this.props.appStore.reserveNowStore.reservedData;
    const showRefundModal =
      this.props.appStore.reserveNowStore.refundIsLoading || this.props.appStore.reserveNowStore.showConfirmDialog;
    const showPaymentAsDepositModal =
      this.props.appStore.reserveNowStore.refundIsLoading ||
      this.props.appStore.reserveNowStore.showPaymentAsDepositMessage;
    return (
      <>
        <Page>
          <TrackPageView dealershipId={this.props.params.dealershipId} />
          <Breadcrumbs
            items={[
              {
                name: this.props.t('ReserveNowContainer.home'),
                path: `/d/${this.props.params.dealershipId}`
              },
              {
                name: this.props.t('ReserveNowContainer.vehicle_reservations')
              }
            ]}
          />
          <Panel>
            <PanelHeader>{this.props.t('ReserveNowContainer.vehicle_reservations')}</PanelHeader>
            <PanelToolBar>
              <div className="vehicleReservations__filter">
                <span>{this.props.t('ReserveNowContainer.filter_by')}</span>
                <Select
                  theme="alt"
                  emptyValue={false}
                  value={this.state.reservationStatus}
                  options="RefundFilterOptions"
                  onChange={this.handleFilter}
                  id="refundFilter"
                />
              </div>
            </PanelToolBar>
            <PanelContent>
              {this.props.appStore.reserveNowStore.isPageLoading ? (
                <div className="vehicleReservations__loading">
                  <LoadingSpinner />
                </div>
              ) : (
                <VehicleReservations
                  dealershipId={this.props.params.dealershipId}
                  reservationStatus={this.state.reservationStatus}
                  handleRefundCustomer={this.handleRefundCustomer}
                />
              )}
              {data.reservationCount > query.pageSize && (
                <Pagination
                  currentPage={query.page}
                  totalItems={data.reservationCount}
                  pageSize={query.pageSize}
                  onChange={this.handlePageChange}
                />
              )}
            </PanelContent>
          </Panel>
        </Page>
        <Modal
          isOpen={showRefundModal}
          onConfirm={this.handleRefundConfirm}
          onClose={this.handleRefundCancel}
          buttonText={this.props.t('ReserveNowContainer.confirm')}
          title={this.props.t('ReserveNowContainer.confirm_customer_refund')}
          id="ConfirmRefund"
        >
          <p
            style={{
              marginLeft: '12px'
            }}
          >
            {this.props.t('ReserveNowContainer.want_to_refund_this_customer')}
          </p>
        </Modal>

        <Modal
          isOpen={showPaymentAsDepositModal}
          onConfirm={this.handlePaymentAsDepositConfirm}
          onClose={this.handlePaymentAsDepositCancel}
          buttonText={this.props.t('ReserveNowContainer.confirm')}
          title={this.props.t('ReserveNowContainer.confirm_customer_deposit')}
          id="ConfirmPaymentAsDeposit"
        >
          <div>
            <p>{this.props.t('ReserveNowContainer.vehicle_will_remain_reserved')}</p>
            <p>{this.props.t('ReserveNowContainer.confirm_customer_proceeding_with_purchase')}</p>
          </div>
        </Modal>
      </>
    );
  }
}

ReserveNowContainer.propTypes = {
  appStore: PropTypes.object,
  params: PropTypes.shape({
    dealershipId: PropTypes.string
  })
};

export default compose(
  withNavigate,
  withParams,
  withQuery,
  withTranslation('ReserveNow'),
  inject(['appStore']),
  observer
)(ReserveNowContainer);
