import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './personalDetailsPanel.scss';
import Table from './SummaryTable/Table';
import TableRow from './SummaryTable/TableRow';
import OuterLeft from './SummaryTable/OuterLeft';
import OuterRight from './SummaryTable/OuterRight';
import { titleCase, splitCamelCaseToString } from '../../../core/helpers';
import OptionsValue from '../../Common/OptionsValue';
import TableHead from './SummaryTable/TableHead';
import TableCell from './SummaryTable/TableCell';
import WordBreak from '../../Common/WordBreak';

const PersonalDetailsPanel = ({ details, applicationFields }) => {
  const { t } = useTranslation('Reporting');
  return (
    <div className="personalDetailsPanel">
      <OuterLeft>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('PersonalDetailsPanel.name')}</TableHead>
            <TableCell>
              <WordBreak>
                {titleCase(
                  details.Title + ' ' + details.FirstName + ' ' + details.MiddleNames + ' ' + details.LastName
                )}
              </WordBreak>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('PersonalDetailsPanel.home_telephone')}</TableHead>
            <TableCell>{details.HomeTelephone}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('PersonalDetailsPanel.mobile_telephone')}</TableHead>
            <TableCell>{details.MobileTelephone}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('PersonalDetailsPanel.gender')}</TableHead>
            <TableCell>{splitCamelCaseToString(details.Gender)}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('PersonalDetailsPanel.date_of_birth')}</TableHead>
            <TableCell>{details.DateOfBirth}</TableCell>
          </TableRow>
          {applicationFields.BirthPlace && details.CountryOfBirth && (
            <>
              {details.CountryOfBirth.BornUK === 'false' && details.CountryOfBirth.BirthPlace && (
                <TableRow>
                  <TableHead width="50%">{t('PersonalDetailsPanel.country_of_birth')}</TableHead>
                  <TableCell>
                    <OptionsValue type={'Nationality'} value={details.CountryOfBirth.BirthPlace || 'GB'} />
                  </TableCell>
                </TableRow>
              )}
              {details.CountryOfBirth.BornUK === 'true' && (
                <TableRow>
                  <TableHead width="50%">{t('PersonalDetailsPanel.country_of_birth')}</TableHead>
                  <TableCell>
                    <OptionsValue type={'Nationality'} value="GB" />
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
          <TableRow>
            <TableHead width="50%">{t('PersonalDetailsPanel.nationality')}</TableHead>
            <TableCell>
              <OptionsValue type={'Nationality'} value={details.Nationality} />
            </TableCell>
          </TableRow>
          {details.DualNationality ? (
            <TableRow>
              <TableHead width="50%">{t('PersonalDetailsPanel.dual_nationality')}</TableHead>
              <TableCell>
                {details.DualNationality === 'notApplicable' ? (
                  <span>{t('PersonalDetailsPanel.not_applicable')}</span>
                ) : (
                  <OptionsValue type={'Nationality'} value={details.DualNationality} />
                )}
              </TableCell>
            </TableRow>
          ) : null}
        </Table>
      </OuterLeft>
      <OuterRight>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('PersonalDetailsPanel.marital_status')}</TableHead>
            <TableCell>{splitCamelCaseToString(details.MaritalStatus)}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('PersonalDetailsPanel.no_dependants')}</TableHead>
            <TableCell>{details.NoOfDependants}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('PersonalDetailsPanel.driving_licence')}</TableHead>
            <TableCell>{splitCamelCaseToString(details.DrivingLicence)}</TableCell>
          </TableRow>
          {details.DrivingLicenceNumber ? (
            <TableRow>
              <TableHead width="50%">{t('PersonalDetailsPanel.driving_licence_number')}</TableHead>
              <TableCell>{details.DrivingLicenceNumber}</TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableHead width="50%">{t('PersonalDetailsPanel.email')}</TableHead>
            <TableCell>
              <WordBreak>{details.Email}</WordBreak>
            </TableCell>
          </TableRow>
          {details.DirectorOrBoardMember && details.DirectorOrBoardMember.Director ? (
            <TableRow>
              <TableHead width="50%">{t('PersonalDetailsPanel.director')}</TableHead>
              <TableCell>
                <WordBreak>{details.DirectorOrBoardMember.Director === 'true' ? 'Yes' : 'No'}</WordBreak>
              </TableCell>
            </TableRow>
          ) : null}
          {details.DirectorOrBoardMember &&
          details.DirectorOrBoardMember.Director === 'true' &&
          details.DirectorOrBoardMember.CompanySector ? (
            <TableRow>
              <TableHead width="50%">{t('PersonalDetailsPanel.company_sector')}</TableHead>
              <TableCell>
                <OptionsValue type={'CompanySectorExtended'} value={details.DirectorOrBoardMember.CompanySector} />
              </TableCell>
            </TableRow>
          ) : null}
          {details.DirectorOrBoardMember &&
          details.DirectorOrBoardMember.Director === 'true' &&
          details.DirectorOrBoardMember.CountryOfOperation ? (
            <TableRow>
              <TableHead width="50%">{t('PersonalDetailsPanel.country_of_operation')}</TableHead>
              <TableCell>
                <OptionsValue type={'Nationality'} value={details.DirectorOrBoardMember.CountryOfOperation} />
              </TableCell>
            </TableRow>
          ) : null}
          {details.VatNumber ? (
            <TableRow>
              <TableHead width="50%">{t('PersonalDetailsPanel.vat_number')}</TableHead>
              <TableCell>{details.VatNumber}</TableCell>
            </TableRow>
          ) : null}
        </Table>
      </OuterRight>
    </div>
  );
};

PersonalDetailsPanel.propTypes = {
  details: PropTypes.object,
  applicationFields: PropTypes.object
};
PersonalDetailsPanel.defaultProps = {
  details: {},
  applicationFields: {}
};
export default PersonalDetailsPanel;
