import React from 'react';
import PropTypes from 'prop-types';
import './fieldError.scss';

const FieldError = ({ children, dataThook }) => {
  return (
    <div className="fieldError" data-thook={dataThook}>
      {children}
    </div>
  );
};

FieldError.propTypes = {
  children: PropTypes.node
};

export default FieldError;
