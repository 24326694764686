import { Component } from 'react';
import PropTypes from 'prop-types';
import QuotesListTable from './QuotesListTable';
import './quotesList.scss';

class QuotesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoverQuote: null,
      isHover: false
    };
  }

  handleHover = (quote, isHover) => {
    this.setState({
      hoverQuote: isHover ? quote : null
    });
  };

  render() {
    return (
      <div className="quotesList" data-th="QuotesList">
        <QuotesListTable
          quotes={this.props.quotes}
          hasAdjustedBalloon={this.props.hasAdjustedBalloon}
          hasCheckedEligibility={this.props.hasCheckedEligibility}
          onQuoteSelect={this.props.onQuoteSelect}
          onProductNameClick={this.props.onProductNameClick}
          schemesVisible={this.props.schemesVisible}
          onProceed={this.props.onProceed}
          onSave={this.props.onSave}
          isQuickQuote={this.props.isQuickQuote}
          isCustomerQuote={this.props.isCustomerQuote}
          onEligibilityMatchClick={this.props.onEligibilityMatchClick}
          onSortQuotes={this.props.onSortQuotes}
          sortField={this.props.sortField}
          sortDirection={this.props.sortDirection}
          proceedingState={this.props.proceedingState}
          chosenQuoteId={this.props.chosenQuoteId}
          handleHover={this.handleHover}
          vehicleClass={this.props.vehicleClass}
          showCombinedQuoteClick={this.props.showCombinedQuoteClick}
          proceedToBreakDown={this.props.proceedToBreakDown}
          showFullQuote={this.props.showFullQuote}
          newCarOffers={this.props.newCarOffers}
          vehicleCondition={this.props.vehicleCondition}
        />
      </div>
    );
  }
}

QuotesList.propTypes = {
  quotes: PropTypes.array,
  hasAdjustedBalloon: PropTypes.bool,
  hasCheckedEligibility: PropTypes.bool,
  onProceed: PropTypes.func,
  schemesVisible: PropTypes.bool,
  onQuoteSelect: PropTypes.func,
  onProductNameClick: PropTypes.func,
  enableShowroomCfc: PropTypes.bool,
  proceedingState: PropTypes.string,
  chosenQuoteId: PropTypes.string,
  onSave: PropTypes.func,
  onEligibilityMatchClick: PropTypes.func,
  isQuickQuote: PropTypes.bool,
  isCustomerQuote: PropTypes.bool,
  onSortQuotes: PropTypes.func,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  vehicleClass: PropTypes.string,
  showCombinedQuoteClick: PropTypes.func,
  proceedToBreakDown: PropTypes.func,
  showFullQuote: PropTypes.bool,
  newCarOffers: PropTypes.array
};

export default QuotesList;
