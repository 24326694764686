import defaultRules, { LenderConsumerConfig } from './default';
import { produce } from 'immer';

const STE = produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.AffordabilityDetails!.ForeseeFutureDownturnInFinances = false;
  draft.applicationFields.AffordabilityDetails!.DownturnReason = false;
  draft.applicationFields.AffordabilityDetails!.IsReplacementLoan = false;
  draft.applicationFields.AffordabilityDetails!.DetailsOfReplacementLoan = false;
  draft.applicationFields.EmploymentHistory!.EmploymentSector = true;

  draft.validationRules.AffordabilityDetails!.Income!.GrossAnnual = 'required, currency';
  draft.validationRules.EmploymentHistory!.Items![0]!.EmploymentSector = 'required';
  draft.validationRules.PersonalDetails!.MobileTelephone = 'required, mobile';
  draft.validationRules.PersonalDetails!.HomeTelephone = 'optional, landline';
  draft.validationRules.PersonalDetails!.Nationality = 'required';

  delete draft.validationRules.AffordabilityDetails!.ReplacementLoan!.IsReplacementLoan;
  delete draft.validationRules.AffordabilityDetails!.ReplacementLoan!.DetailsOfReplacementLoan;
  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.ForeseeFutureDownturnInFinances;
  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.DownturnReason;
});

export default STE;
