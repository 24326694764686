import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PendingDataSpinner } from '../../../../../../shared/components';
import classes from './updatingTag.module.scss';

const UpdatingTag = ({ onClick }) => {
  const { t } = useTranslation('Stock');
  return (
    <div className={classes.updatetagContainer}>
      <div onClick={onClick} className={classes.updateTag}>
        <div className={classes.updateTagSpinner}>
          <PendingDataSpinner />
        </div>
        <p className={classes.updateTagText}>{t('StockListItem.new_data_pending')}</p>
      </div>
    </div>
  );
};

UpdatingTag.propTypes = {
  onClick: PropTypes.func
};

export default UpdatingTag;
