import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './breadcrumbItem.scss';
import Icon from '../Common/Icon/Icon';
import { inject } from 'mobx-react';
import { objectToSearchParams } from 'utils/objectToSearchParams';

const BreadcrumbItem = (props) => {
  const liStyles = classnames({
    breadcrumbsItem__li: true,
    breadcrumbsItem__isFirst: props.isFirst,
    breadcrumbsItem__isLast: props.isLast,
    breadcrumbsItem__only: props.isFirst && props.isLast,
    breadcrumbsItem__isCollapsed: props.isCollapsed
  });
  return (
    <li className={liStyles}>
      <div className="breadcrumbsItem__text" title={props.item.name}>
        {props.isLast || !props.item.path ? (
          <span>{props.item.name}</span>
        ) : (
          <div>
            <Link
              to={{
                pathname: props.item.path,
                search: props.item.query ? objectToSearchParams(props.item.query).toString() : undefined
              }}
              className="breadcrumbsItem__link"
            >
              {props.item.name === 'Home' || props.item.isHome || props.isFirst ? (
                <div className="breadcrumbsItem__homeIcon">
                  <Icon name={props.appStore.uiState.canViewDashboard ? 'home-dashboard' : 'home'} />
                </div>
              ) : (
                <span>{props.item.name}</span>
              )}
            </Link>
            <div className="breadcrumbsItem__chevron">
              <Icon name="right" />
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

BreadcrumbItem.propTypes = {
  item: PropTypes.object,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  isCollapsed: PropTypes.bool
};

export default inject('appStore')(BreadcrumbItem);
