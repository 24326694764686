import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

const IndexContainer = (props) => {
  let dealershipId = props.session?.Dealerships?.[0]?.Id;

  if (dealershipId) {
    return <Navigate to={`/d/${dealershipId}`} replace />;
  }

  // hasError always returns true because isLoading is always undefined
  // The blow code is kept for reference from the rect router v6 migration
  if (props.hasError) {
    return <Navigate to="/auth/signin" replace />;
  }

  if (props.isLoading) {
    return <div />;
  }

  return <div />;
};

IndexContainer.propTypes = {
  session: PropTypes.object.isRequired,
  hasError: PropTypes.bool,
  isLoading: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
  return {};
}

function mapStateToProps(state) {
  return {
    session: state.session,
    isLoading: state.session.loadingState === 'loading',
    hasError: !state.session.isSignedIn && state.session.loadingState === 'error'
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IndexContainer);
