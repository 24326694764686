import { useFeatureIsOn } from '@growthbook/growthbook-react';
import TrackedPage from '../TrackedPage';
import QuickQuoteFindVehicle from './components/QuickQuoteFindVehicle';
import QuickQuoteVehicleDetails from './components/QuickQuoteVehicleDetails';

export const QuickQuoteVehicleRoutes = () => {
  const findaVehiclePage = useFeatureIsOn('rtl-findavehicle-page');
  return findaVehiclePage ? (
    <TrackedPage pageName="Find a vehicle - Quick Quote">
      <QuickQuoteFindVehicle />
    </TrackedPage>
  ) : (
    <TrackedPage pageName="Quick Quote">
      <QuickQuoteVehicleDetails />
    </TrackedPage>
  );
};
