import { TFunction } from 'i18next';

export const getDateDropdownOptions = (t: TFunction, includeDay: boolean = true) => {
  const options = [
    { value: 7, label: t('StockMetrics.last_week') },
    { value: 90, label: t('StockMetrics.last_3_months') }
  ];

  if (includeDay) {
    options.unshift({ value: 1, label: t('StockMetrics.last_day') });
  }

  return options;
};
