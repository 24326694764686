import * as actionTypes from '../actionTypes';
import _ from 'lodash';
import { validate } from '../../core/validators';
import { getLenderConfig } from '../../core/lenders';
import { isAllowed } from '../../core/sendDealProductBlacklist';

// TODO: This needs to be taken from the features.js file
// once RTL-1157 is merged
const hasCompanionQuotes = (quote) =>
  quote?.CompanionQuotes !== undefined && quote?.CompanionQuotes !== null && quote?.CompanionQuotes.length > 0;

const defaultState = {
  customerId: null,
  customerEmail: null,
  customerType: null,
  currentPath: '',
  previousView: null,
  currentView: null,
  vehicle: {},
  quoteRequest: {},
  restrictToProductType: null,
  restrictToFunderCode: null,
  restrictQuotesByProduct: null,
  isFetchingQuotes: false,
  returnedQuotes: [],
  quotesForCompare: [],
  productSettings: [],
  eligibilityCheckDetails: {
    PersonalDetails: {}
  },
  hasFetchedCfcPreFill: false,
  hasCheckedEligibility: false,
  hasAdjustedBalloon: false,
  craScorePercentage: null,
  personalScorePercentage: null,
  productInfoQuoteId: null,
  proceedingState: '',
  applyingState: '',
  savingState: '',
  sortField: 'FollowingPayments',
  sortDirection: 'asc',
  monthlyPayments: [],
  isFetchingMonthlyPayments: false,
  hasFetchMonthlyPaymentsError: false,
  isRequote: false
};

export default function quotesReducer(state = defaultState, action) {
  let newQuotes;
  let newQuotesForCompare;
  let hasAddressError;
  let eligibilityCheckDetails;

  switch (action.type) {
    case actionTypes.START_QUOTING:
      return Object.assign({}, defaultState, {
        customerId: action.customerId,
        customerEmail: action.customerEmail,
        customerType: action.customerType ? action.customerType.toLowerCase() : null,
        productSettings: action.productSettings,
        quoteRequest: action.quoteRequest,
        restrictToProductType: action.restrictToProductType,
        restrictToFunderCode: action.restrictToFunderCode,
        restrictQuotesByProduct: action.restrictQuotesByProduct,
        vehicle: action.vehicle,
        previousView: null,
        currentView: 'COSTS_VIEW',
        currentPath: action.pathName
      });

    case actionTypes.START_REQUOTING:
      return Object.assign({}, defaultState, {
        customerId: action.customerId,
        customerEmail: action.customerEmail,
        customerType: action.customerType ? action.customerType.toLowerCase() : null,
        productSettings: action.productSettings,
        quoteRequest: action.quoteRequest,
        vehicle: action.vehicle,
        currentView: 'LIST_VIEW',
        isRequote: true
      });

    case actionTypes.END_QUOTING:
      return Object.assign({}, state, {
        customerType: null
      });

    case actionTypes.CHANGE_QUOTING_VEHICLE:
      return Object.assign({}, state, { vehicle: action.vehicle });

    case actionTypes.CHANGE_COSTS:
      return Object.assign(
        {},
        state,
        action.customerType
          ? {
              isFetchingQuotes: true,
              quoteRequest: action.costs,
              customerType: action.customerType
            }
          : {
              isFetchingQuotes: true,
              quoteRequest: action.costs
            }
      );

    case actionTypes.CHANGE_COSTS_SUCCESS:
      return Object.assign({}, state, {
        isFetchingQuotes: false,
        currentView: (state.currentView !== state.previousView && state.previousView) || 'LIST_VIEW'
      });

    case actionTypes.CHANGE_QUOTE_VIEW:
      return Object.assign({}, state, { previousView: state.currentView, currentView: action.view });

    case actionTypes.FETCH_QUOTES:
      return Object.assign({}, state, { isStale: false });

    case actionTypes.FETCH_QUOTES_SUCCESS:
      newQuotes = _.cloneDeep(action.quotes);
      const isRequote = state.isRequote;

      newQuotes = _.map(newQuotes, (quote) => {
        let productSettings;

        if (quote.ProductId !== '00000000-0000-0000-0000-000000000000') {
          productSettings = _.find(state.productSettings, { ProductUid: quote.ProductId });
        } else {
          /*
           * Since Multi-schedule was introduced, now both BNP Combined quotes wil have a ProductId of '00000000-0000-0000-0000-000000000000'
           * but also the the aggregate quotes on a companion (multi-schedule) quote will have that ProductId.
           * Below we need to isolate the Product/QuoteId overrides to be just for BNP quotes and not for companion (multi-schedule) quotes.
           */
          if (!hasCompanionQuotes(quote)) {
            /*
             * This is a BNP specific solution
             * BNP Combined, Aggregate quotes have both a ProductId and a QuoteId of '00000000-0000-0000-0000-000000000000',
             * so if you have multiple quotes in the results, you cannot select them, because for the UI they have no unique identifiers.
             * This solution overrides the ProductId and the QuoteID just in the UI so that you can select quotes in the quote results (to compare them).
             * The assumption is that you will never get more than one quote per Product type (PCP/HP), otherwise it will not work.
             */
            if (quote.ProductName === 'Creation HP with Personal Loan') {
              quote.ProductId = '00000000-0000-0000-0000-000000000001';
              quote.QuoteId = '00000000-0000-0000-0000-000000000001';
              quote.FinanceType = 'HP';
            } else {
              quote.ProductId = '00000000-0000-0000-0000-000000000002';
              quote.QuoteId = '00000000-0000-0000-0000-000000000002';
              quote.FinanceType = 'PCP';
            }
          }
          productSettings = {
            Visible: true
          };
        }
        const productIsInCompare = _.find(state.quotesForCompare, { QuoteId: quote.QuoteId }) ? true : false;
        return {
          ...quote,
          isChecked: productIsInCompare && !quote.Errors,
          isVisible: productSettings && productSettings.Visible,
          isSendable: isAllowed(quote, action.CustomerType),
          isRequote: isRequote
        };
      });

      newQuotesForCompare = state.quotesForCompare
        .map((quote) => {
          const newQuote = _.find(newQuotes, { ProductName: quote.ProductName });

          if (!newQuote) return undefined;

          const isUnavailable = newQuote ? false : true;
          const isSendable = isAllowed(newQuote, action.CustomerType);

          return Object.assign(newQuote, { isUnavailable, isSendable, isRequote });
        })
        .filter((quote) => quote !== undefined && !quote.Errors);

      return Object.assign({}, state, {
        returnedQuotes: newQuotes,
        quotesForCompare: newQuotesForCompare,
        hasAdjustedBalloon: action.hasAdjustedBalloon
      });

    case actionTypes.FETCH_QUOTES_ERROR:
      return Object.assign({}, state, { returnedQuotes: {}, quotesForCompare: [] });

    case actionTypes.COMPARE_QUOTE_ADD:
      newQuotes = _.cloneDeep(state.returnedQuotes);
      newQuotesForCompare = _.cloneDeep(state.quotesForCompare);

      newQuotesForCompare.push(
        _.chain(newQuotes)
          .find({ QuoteId: action.quoteId })
          .cloneDeep()
          .value()
      );

      _.chain(newQuotes)
        .find({ QuoteId: action.quoteId })
        .set('isChecked', true)
        .value();

      return Object.assign({}, state, { returnedQuotes: newQuotes, quotesForCompare: newQuotesForCompare });

    case actionTypes.COMPARE_QUOTE_REMOVE:
      newQuotes = _.cloneDeep(state.returnedQuotes);
      newQuotesForCompare = _.cloneDeep(state.quotesForCompare);

      newQuotesForCompare = _.filter(newQuotesForCompare, (quote) => {
        return quote.QuoteId !== action.quoteId;
      });

      _.chain(newQuotes)
        .find({ QuoteId: action.quoteId })
        .set('isChecked', false)
        .value();

      return Object.assign({}, state, {
        returnedQuotes: newQuotes,
        quotesForCompare: newQuotesForCompare,
        currentView: newQuotesForCompare.length === 0 ? 'LIST_VIEW' : state.currentView
      });

    case actionTypes.COMPARE_QUOTES_CLEANUP:
      newQuotesForCompare = _.filter(state.quotesForCompare, (quote) => {
        return _.find(state.returnedQuotes, { QuoteId: quote.QuoteId });
      });

      return Object.assign({}, state, { quotesForCompare: newQuotesForCompare });

    case actionTypes.RESET_COMPARE_QUOTES:
      return Object.assign({}, state, { quotesForCompare: [] });

    case actionTypes.CHANGE_QUOTE_COMMISSIONS:
      let newState = _.cloneDeep(state);
      let options = action.settings;

      if (options.applyTo === 'all') {
        if (options.CommissionType === 'APR') {
          _.each(newState.productSettings, function(product) {
            _.set(product, 'CommissionType', options.CommissionType);
            _.set(product, 'Rate', options.Rate);
          });
        } else {
          _.chain(newState.productSettings)
            .filter({ AprOnly: false })
            .each(function(product) {
              _.set(product, 'CommissionType', options.CommissionType);
              _.set(product, 'Rate', options.Rate);
            })
            .value();
        }
      } else {
        _.chain(newState.productSettings)
          .find({ ProductUid: options.ProductUid })
          .set('CommissionType', options.CommissionType)
          .set('Rate', options.Rate)
          .value();
      }
      newState.previousView = newState.currentView;
      newState.isStale = true;

      return newState;

    //Eligibility

    case actionTypes.UPDATE_ELIGIBILITY_FORM_DATA:
      eligibilityCheckDetails = Object.assign({}, state.eligibilityCheckDetails, {
        [action.section]: action.data
      });
      eligibilityCheckDetails.validationErrors = validate(
        eligibilityCheckDetails,
        getLenderConfig('def', 'cfc', 'validationRules')
      );
      return Object.assign({}, state, { eligibilityCheckDetails });

    case actionTypes.GET_SHOWROOM_CFC_PREFILL:
      eligibilityCheckDetails = _.cloneDeep(state.eligibilityCheckDetails);
      _.set(eligibilityCheckDetails, 'PersonalDetails.Email', action.email);

      return Object.assign({}, state, {
        isLoadingCfcPreFill: true,
        eligibilityCheckDetails
      });

    case actionTypes.GET_SHOWROOM_CFC_PREFILL_SUCCESS:
      eligibilityCheckDetails = _.cloneDeep(action.response);
      _.set(eligibilityCheckDetails, 'PersonalDetails.Email', action.email);
      eligibilityCheckDetails.validationErrors = validate(
        eligibilityCheckDetails,
        getLenderConfig('def', 'cfc', 'validationRules')
      );

      return Object.assign({}, state, {
        eligibilityCheckDetails,
        isLoadingCfcPreFill: false,
        hasFetchedCfcPreFill: true
      });

    case actionTypes.GET_SHOWROOM_CFC_PREFILL_ERROR:
      eligibilityCheckDetails = _.cloneDeep(state.eligibilityCheckDetails);
      eligibilityCheckDetails.validationErrors = validate(
        eligibilityCheckDetails,
        getLenderConfig('def', 'cfc', 'validationRules')
      );
      return Object.assign({}, state, {
        eligibilityCheckDetails,
        isLoadingCfcPreFill: false
      });

    //Showroom CFC

    case actionTypes.CHECK_ELIGIBILITY:
      return Object.assign({}, state, { checkingEligibilityState: 'loading' });

    case actionTypes.CHECK_ELIGIBILITY_SUCCESS:
      let matchRates = action.response.QuoteResults;

      hasAddressError = !(action.response.FoundAddress && action.response.FoundProspectAtAddress);

      let augmentedQuotes = _.map(state.returnedQuotes, (quote) => {
        let cfcQuote = _.find(matchRates, { QuoteId: quote.QuoteId });

        return {
          ...quote,
          MatchRate: cfcQuote ? cfcQuote.MatchRate : undefined,
          DecisionMessages: cfcQuote ? cfcQuote.DecisionMessages : undefined
        };
      });

      return Object.assign({}, state, {
        returnedQuotes: augmentedQuotes,
        checkingEligibilityState: 'done',
        hasCheckedEligibility: true,
        craScorePercentage: action.response.CraScorePercentage,
        personalScorePercentage: action.response.PersonalScorePercentage
      });

    case actionTypes.CHECK_ELIGIBILITY_ERROR:
      hasAddressError = false;
      if (action.response) {
        hasAddressError = !(action.response.FoundAddress && action.response.FoundProspectAtAddress);
      }

      return Object.assign({}, state, { checkingEligibilityState: 'error', hasAddressError });

    case actionTypes.SAVE_CUSTOMER_QUOTE:
      return Object.assign({}, state, { savingState: 'loading', proceedingState: 'loading' });

    case actionTypes.SAVE_CUSTOMER_QUOTE_SUCCESS:
      return Object.assign({}, state, { savingState: 'done', proceedingState: 'done' });

    case actionTypes.SAVE_CUSTOMER_QUOTE_ERROR:
      return Object.assign({}, state, { savingState: 'error', proceedingState: 'error' });

    case actionTypes.CREATE_APPLICATION:
      return Object.assign({}, state, { applyingState: 'loading', proceedingState: 'loading' });

    case actionTypes.CREATE_APPLICATION_SUCCESS:
      return Object.assign({}, state, { applyingState: 'done', proceedingState: 'done' });

    case actionTypes.CREATE_APPLICATION_ERROR:
      return Object.assign({}, state, { applyingState: 'error', proceedingState: 'error' });

    case actionTypes.SORT_QUOTE_MODULE:
      let sortField = action.field;
      let sortDirection;

      if (state.sortField === sortField) {
        sortDirection = state.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        sortDirection = 'asc';
      }
      return Object.assign({}, state, { sortField, sortDirection });

    case actionTypes.FETCH_MONTHLY_PAYMENTS:
      return Object.assign({}, state, {
        isFetchingMonthlyPayments: true,
        hasFetchMonthlyPaymentsError: false,
        monthlyPayments: []
      });

    case actionTypes.FETCH_MONTHLY_PAYMENTS_SUCCESS:
      return Object.assign({}, state, { isFetchingMonthlyPayments: false, monthlyPayments: action.response });

    case actionTypes.FETCH_MONTHLY_PAYMENTS_ERROR:
      return Object.assign({}, state, { isFetchingMonthlyPayments: false, hasFetchMonthlyPaymentsError: true });

    case actionTypes.VIEW_MONTHLY_PAYMENTS:
      return Object.assign({}, state, { previousView: 'COMPARE_VIEW', currentView: 'MONTHLY_PAYMENTS_VIEW' });

    default:
      return state;
  }
}
