import usePastDays from './usePastDays';

const days = {
  day: {
    toDate: 0,
    fromDate: 1,
    previousToDate: 1,
    previousFromDate: 2
  },
  week: {
    toDate: 0,
    fromDate: 7,
    previousToDate: 7,
    previousFromDate: 14
  },
  month: {
    toDate: 0,
    fromDate: 30,
    previousToDate: 30,
    previousFromDate: 60
  }
} as const;

const useDates = (period: keyof typeof days) => {
  const fromDate = usePastDays(days[period].fromDate);
  const toDate = usePastDays(days[period].toDate);
  const previousFromDate = usePastDays(days[period].previousFromDate);
  const previousToDate = usePastDays(days[period].previousToDate);

  return { fromDate, toDate, previousFromDate, previousToDate };
};

export default useDates;
