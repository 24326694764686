import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import SaveCorporateQuickQuoteForm from '../forms/SaveCorporateQuickQuoteForm';
import Modal from '../../Common/Modal/Modal';
import { connect } from 'react-redux';
import * as modalActions from '../../../redux/modal/modalActions';
import './saveCorporateQuickQuoteModal.scss';

const SaveCorporateQuickQuoteModal = ({ closeModal, quote, vehicle, dealershipId, isOpen }) => {
  const { t } = useTranslation('QuickQuote');
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <div data-thook="QuickQuoteConsumerDetailsForm">
        <h1 className="saveCorporateQuickQuoteModal__header">
          {t('SaveCorporateQuickQuoteModal.save_quote_to_customer')}
        </h1>
        <SaveCorporateQuickQuoteForm
          quote={quote}
          vehicle={vehicle}
          dealershipId={dealershipId}
          closeModal={closeModal}
        />
      </div>
    </Modal>
  );
};

SaveCorporateQuickQuoteModal.propTypes = {
  closeModal: PropTypes.func,
  quote: PropTypes.object,
  vehicle: PropTypes.object,
  dealershipId: PropTypes.string,
  isOpen: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    isOpen: state.modals.saveCorporateQuickQuote ? state.modals.saveCorporateQuickQuote.isOpen : false
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => {
      dispatch(modalActions.close('saveCorporateQuickQuote'));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveCorporateQuickQuoteModal);
