import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.editSections!.employment = false;
  draft.editSections!.affordability = false;
  draft.editSections!.bankDetails = false;

  draft.applicationFields.AffordabilityDetails = {
    GrossAnnual: true,
    MortgageOrRental: true
  };

  draft.validationRules.AffordabilityDetails = {
    Income: {
      GrossAnnual: 'required, currency'
    },
    MonthlyExpenditure: {
      MortgageOrRental: 'required, currency'
    }
  };
});
