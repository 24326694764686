import { withTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import SelectMakeInput from '../Form/SelectMakeInput';
import SelectModelInput from '../Form/SelectModelInput';
import SelectDerivativeInput from '../Form/SelectDerivativeInput';
import TextInput from '../Form/TextInput';
import VrmLookup from '../../VrmLookup/VrmLookup';
import DateInput from '../Form/DateInput';
import FormFooter from '../Form/FormFooter';
import VehicleFormValidator from '../../../validators/VehicleFormValidator';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import InformationWarning from '../../Common/InformationWarning';
import { compose } from 'redux';
import { withNavigate, withQuery } from 'hocs/router';
import RadioButtonGroup from '../Form/RadioButtonGroup';

import './vehicleForm.scss';

class VehicleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchingMakes: false,
      fetchingModels: false,
      fetchingDerivatives: false,
      makes: {},
      models: {},
      derivatives: {},
      vehicle: {},
      optionsList: {},
      makeSuggestions: [],
      modelSuggestions: [],
      derivativeSuggestions: [],
      formData: {
        CapId: '',
        Condition: '',
        Class: '',
        Vrm: '',
        RegistrationDate: '',
        MakeId: '',
        Make: '',
        ModelId: '',
        Model: '',
        Derivative: '',
        Mileage: '',
        Vin: '',
        ...this.props.initialData,
        DerivativeId: this.props.initialData.DerivativeId || this.props.initialData.CapId || ''
      },
      vrmError: {},
      showMakeSuggestions: false,
      showModelSuggestions: false,
      showDerivativeSuggestions: false,
      lookupVrm: true
    };
    this.setUpValidation();
    this.validator.validate(this.state.formData);
  }

  vrmLookup = this.props.appStore.uiState.canUseVrmLookup;

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.initialData !== this.props.initialData &&
      this.props.submittingState !== 'loading' &&
      this.props.submittingState !== 'error'
    ) {
      this.resetForm();
    } else if (
      this.state.formData.Vrm &&
      (prevState.makes !== this.state.makes ||
        prevState.models !== this.state.models ||
        prevState.derivatives !== this.state.derivatives ||
        prevState.formData !== this.state.formData)
    ) {
      this.handleSuggestions();
    }

    if (prevProps.query?.vrm !== this.props.query?.vrm) {
      this.handleChangeValue('Vrm', this.props.query.vrm);
    }

    if (prevProps.query?.vehicleType !== this.props.query?.vehicleType) {
      this.handleChangeValue('Condition', this.props.query.vehicleType);
    }

    if (
      this.props.query?.vehicleType !== 'new' &&
      this.props.query?.vrm &&
      (!this.state.formData.Vrm || this.state.formData.Vrm === '')
    ) {
      this.setState((state) => {
        state.formData.Vrm = this.props.query.vrm.toUpperCase();
        return state;
      });
      this.setState({
        lookupVrm: true
      });
    }
  }

  handleSuggestions() {
    let showModelSuggestions = false;
    let showMakeSuggestions = false;
    let showDerivativeSuggestions = false;

    if (
      !this.state.fetchingMakes &&
      (this.state.formData.MakeId || this.state.formData.Make) &&
      !this.state.makes[this.state.formData.MakeId] &&
      Object.keys(this.state.makes).length
    ) {
      showMakeSuggestions = true;
    } else if (
      !this.state.fetchingModels &&
      (this.state.formData.ModelId || this.state.formData.Model) &&
      !this.state.models[this.state.formData.ModelId] &&
      Object.keys(this.state.models).length
    ) {
      showModelSuggestions = true;
    } else if (
      !this.state.fetchingDerivatives &&
      !this.state.formData.DerivativeId &&
      !this.state.derivatives[this.state.formData.DerivateId] &&
      Object.keys(this.state.derivatives).length
    ) {
      showDerivativeSuggestions = true;
    }

    this.setState({
      showModelSuggestions,
      showMakeSuggestions,
      showDerivativeSuggestions
    });
  }

  resetForm() {
    const formData = {
      CapId: '',
      Condition: '',
      Class: this.props.query?.class || '',
      Vrm: this.props.query?.vrm || '',
      RegistrationDate: '',
      MakeId: '',
      Make: '',
      ModelId: '',
      Model: '',
      DerivativeId: '',
      Derivative: '',
      Mileage: '',
      Vin: '',
      ...this.props.initialData
    };
    this.setState({
      fetchingMakes: false,
      fetchingModels: false,
      fetchingDerivatives: false,
      makes: {},
      models: {},
      derivatives: {},
      vehicle: {},
      makeSuggestions: [],
      modelSuggestions: [],
      derivativeSuggestions: [],
      formData,
      vrmError: {},
      showMakeSuggestions: false,
      showModelSuggestions: false,
      showDerivativeSuggestions: false,
      lookupVrm: !(this.props.query?.vehicleType === 'new')
    });

    if (this.props.query) {
      const query = { ...this.props.query };
      this.props.navigate({ query });
    }

    this.validator.validate(formData);
  }

  setUpValidation() {
    this.validator = new VehicleFormValidator();
    this.validationReactionDisposer = reaction(() => ({ ...this.formData }), this.validator.validate, {
      fireImmediately: true
    });
  }

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  handleFetchVehicle = () => {
    this.setState({
      showMakeSuggestions: false,
      showModelSuggestions: false,
      showDerivativeSuggestions: false,
      lookupVrm: false
    });
  };

  handleFetchVehicleSuccess = (data) => {
    let showMakeSuggestions = false;
    let showModelSuggestions = false;
    let showDerivativeSuggestions = false;
    let vehicle = { ...data.CapData[0] };
    vehicle.DerivativeId = vehicle.CapId;

    if (data.CapData.length > 1) {
      vehicle.DerivativeId = null;
      vehicle.Derivative = null;
      vehicle.Derivatives = this.refactorDerivatives(data.CapData);
    }

    let formData = {
      ...this.state.formData,
      CapId: vehicle.DerivativeId ? vehicle.DerivativeId.toString() : '',
      Class: vehicle.VehicleClass ? vehicle.VehicleClass.toLowerCase() : this.state.formData.Class,
      MakeId: vehicle.MakeId ? vehicle.MakeId.toString() : '',
      Make: vehicle.Make ? vehicle.Make : '',
      ModelId: vehicle.ModelId ? vehicle.ModelId.toString() : '',
      Model: vehicle.Model ? vehicle.Model : '',
      DerivativeId: vehicle.DerivativeId ? vehicle.DerivativeId.toString() : '',
      Derivative: vehicle.Derivative ? vehicle.Derivative : '',
      RegistrationDate: vehicle.Registered ? moment(vehicle.Registered).format('DD/MM/YYYY') : '',
      Vin: data.Vin ? data.Vin.toString() : ''
    };

    if (this.props.query) {
      const query = {
        ...this.props.query,
        class: vehicle.VehicleClass ? vehicle.VehicleClass.toLowerCase() : this.state.formData.Class
      };
      this.props.navigate({ query });
    }

    this.validator.validate(formData);
    this.handleFetchMakesSuccess(this.state.makes);
    this.setState({
      vehicle,
      vrmError: {},
      formData,
      showModelSuggestions,
      showMakeSuggestions,
      showDerivativeSuggestions
    });
  };

  handleFetchVehicleError = () => {
    this.setState({
      vrmError: {
        message: this.props.t('VehicleForm.unable_to_locate_a_record')
      }
    });
  };

  handleFetchMakes = () => {
    this.setState({
      fetchingMakes: true
    });
  };

  handleFetchMakesSuccess = (makes) => {
    let makeSuggestions = [];

    if (this.state.formData.Make) {
      makeSuggestions = Object.keys(makes).filter((makeId) => {
        return this.state.formData.Make.includes(makes[makeId]);
      });
    }

    this.setState({
      makeSuggestions,
      makes,
      fetchingMakes: false
    });
  };

  handleFetchModels = () => {
    this.setState({
      fetchingModels: true
    });
  };

  handleFetchModelsSuccess = (models) => {
    let modelSuggestions = [];

    if (this.state.formData.Model) {
      let model = this.state.formData.Model;
      modelSuggestions = Object.keys(models).filter((modelId) => {
        return model.includes(models[modelId]);
      });
    }

    this.setState({
      modelSuggestions,
      models,
      fetchingModels: false
    });
  };

  handleFetchDerivatives = () => {
    this.setState({
      fetchingDerivatives: true
    });
  };

  handleFetchDerivativesSuccess = (derivatives) => {
    let derivativeSuggestions = [];

    if (this.state.vehicle.Derivatives) {
      derivativeSuggestions = _.intersection(Object.keys(derivatives), Object.keys(this.state.vehicle.Derivatives));
    }

    this.setState({
      derivativeSuggestions,
      derivatives,
      fetchingDerivatives: false
    });
  };

  refactorDerivatives(vehicles) {
    let derivativeKeys = {};

    vehicles.forEach((vehicle) => {
      derivativeKeys[vehicle.CapId] = vehicle.Derivative;
    });

    return derivativeKeys;
  }

  handleChangeValue = (key, value) => {
    let state = { ...this.state, formData: { ...this.state.formData } };
    const queryKey = key === 'Condition' ? 'vehicleType' : _.camelCase(key);

    if (key === 'Vrm') {
      value = !!value ? value.toUpperCase() : '';
      this.state.formData.Condition === 'new'
        ? (state.formData = { ...state.formData })
        : (state.formData = {
            ...state.formData,
            CapId: '',
            MakeId: '',
            Make: '',
            ModelId: '',
            Model: '',
            DerivativeId: '',
            Derivative: '',
            Mileage: '',
            Vin: '',
            RegistrationDate: ''
          });
    }

    let query = {};

    if (this.props.query) {
      query = { ...this.props.query, [queryKey]: value };
    }

    state.formData[key] = value;

    if (key === 'Class') {
      this.setState({
        lookupVrm: false,
        derivatives: [],
        models: []
      });
      state.formData = {
        Condition: state.formData.Condition,
        Class: value,
        CapId: '',
        MakeId: '',
        Make: '',
        ModelId: '',
        Model: '',
        DerivativeId: '',
        Derivative: '',
        Mileage: '',
        Vin: '',
        Vrm: '',
        RegistrationDate: ''
      };
      query.vrm = '';
      this.props.onUpdateVehicleClass && this.props.onUpdateVehicleClass(value);
    }

    if (this.props.query) {
      this.props.navigate({ query });
    }

    this.validator.validate(state.formData);

    if (key === 'Class' || key === 'Condition') {
      key === 'Class' && this.props.onUpdateVehicleClass && this.props.onUpdateVehicleClass(value);
      let formData = {
        ...state.formData,
        CapId: '',
        Vrm: '',
        RegistrationDate: '',
        MakeId: '',
        Make: '',
        ModelId: '',
        Model: '',
        DerivativeId: '',
        Derivative: '',
        Vin: ''
      };
      this.validator.validate(formData);
      this.setState({
        formData,
        vrmError: {}
      });
    } else {
      this.setState({
        formData: state.formData,
        vrmError: {}
      });
    }
  };

  handleIdFieldChangeValue = (event) => {
    let state = { ...this.state, formData: { ...this.state.formData } };
    let valueKey = event.target.id.slice(0, -2); // Remove 'Id'; MakeId => Make

    if (event.target.id === 'DerivativeId') {
      state.formData.CapId = event.target.value;
    }

    state.formData[event.target.id] = event.target.value;
    state.formData[valueKey] = event.target.options[event.target.options.selectedIndex].innerText;
    this.validator.validate(state.formData);
    this.setState({
      formData: state.formData
    });
  };

  handleSubmit = () => {
    this.validator.validate(this.state.formData);

    if (!this.validator.errorCount) {
      this.props.onSubmit(this.state.formData);
    }
  };

  handleClickSuggestion = (event) => {
    let type = event.target.getAttribute('data-type');
    let id = event.target.getAttribute('data-id');
    let formData = { ...this.state.formData };
    formData[`${type}Id`] = id;
    formData[type] = this.state[`${type.toLowerCase()}s`][id];

    if (type === 'Derivative') {
      formData.CapId = formData.DerivativeId;
    }

    this.setState({
      formData
    });
  };

  handleVinFieldGroupWarning = (Vin) => {
    if (Vin.length !== 17 && Vin !== '') {
      return this.props.t('VehicleForm.vin_should_be_17_characters');
    }
  };

  renderSuggestions(type) {
    if (!this.state[`show${type}Suggestions`]) {
      return;
    }

    let content;

    if (this.state[`${type.toLowerCase()}Suggestions`].length > 0) {
      content = this.state[`${type.toLowerCase()}Suggestions`].map((id) => (
        <span
          key={id}
          data-type={type}
          data-id={id}
          onClick={this.handleClickSuggestion}
          className="vehicleForm__vehicleSuggestionLink"
        >
          {this.state[`${type.toLowerCase()}s`][id]}
        </span>
      ));
    } else if (this.state.vehicle[type]) {
      content = (
        <div className="vehicleForm__noSuggestions">
          <Trans
            ns="Common"
            i18nKey={'VehicleForm.no_suggestions_found_for'}
            components={{
              vehicleType: this.state.vehicle[type]
            }}
          />
          {/* {this.props.t('VehicleForm.no_suggestions_found_for')} {this.state.vehicle[type]}{' '}
          {this.props.t('VehicleForm.please_choose_from_the_dropdown_below')} */}
        </div>
      );
    } else {
      content = (
        <div className="vehicleForm__noSuggestions">
          {this.props.t('VehicleForm.no_suggestions_found_please_choose_from_the_dropdown_below')}
        </div>
      );
    }

    return (
      <div className="vehicleForm__vehicleSuggestionsOuter">
        <div className="vehicleForm__vehicleSuggestionsTitle">
          {this.props.t('VehicleForm.suggestions_for')} {type}
        </div>
        {content}
      </div>
    );
  }

  renderVrmLookup() {
    const showLookupButton = this.state.formData.Condition === 'used';
    const errors = this.validator.getErrors();
    let vrmError = Object.keys(this.state.vrmError).length ? this.state.vrmError : errors.Vrm;

    return (
      <div>
        <MobxFormFieldGroup isInline error={vrmError}>
          <MobxFormLabel htmlFor="Vrm">{this.props.t('VehicleForm.registration_number')}</MobxFormLabel>
          {this.vrmLookup ? (
            <VrmLookup
              id="Vrm"
              onChange={this.handleChangeValue}
              onClick={this.handleClick}
              onFetchVehicle={this.handleFetchVehicle}
              onFetchVehicleSuccess={this.handleFetchVehicleSuccess}
              onFetchVehicleError={this.handleFetchVehicleError}
              value={this.state.formData.Vrm}
              lookupVrm={this.state.lookupVrm}
              dealershipId={this.props.dealershipId}
              showLookupButton={showLookupButton}
              disabled={this.props.options.disabledFields && this.props.options.disabledFields.RegNumber}
            />
          ) : (
            <TextInput
              id="Vrm"
              value={this.state.formData.Vrm}
              onChange={this.handleChangeValue}
              dealershipId={this.props.dealershipId}
              disabled={this.props.options.disabledFields && this.props.options.disabledFields.RegNumber}
            />
          )}
        </MobxFormFieldGroup>

        <MobxFormFieldGroup isInline error={errors.RegistrationDate}>
          <MobxFormLabel htmlFor="RegistrationDate">{this.props.t('VehicleForm.registration_date')}</MobxFormLabel>
          <DateInput
            id="RegistrationDate"
            value={this.state.formData.RegistrationDate}
            onChange={this.handleChangeValue}
            disabled={this.props.options.disabledFields && this.props.options.disabledFields.RegDate}
          />
        </MobxFormFieldGroup>
      </div>
    );
  }

  renderMileage() {
    if (this.state.formData.Condition === 'new') {
      return;
    }

    const errors = this.validator.getErrors();

    return (
      <MobxFormFieldGroup isInline error={errors.Mileage}>
        <MobxFormLabel htmlFor="Mileage">{this.props.t('VehicleForm.mileage')}</MobxFormLabel>
        <TextInput
          type="tel"
          id="Mileage"
          value={this.state.formData.Mileage}
          onChange={this.handleChangeValue}
          disabled={this.props.options.disabledFields && this.props.options.disabledFields.Mileage}
          dataThook="Mileage"
        />
      </MobxFormFieldGroup>
    );
  }

  renderVIN() {
    const { Vin } = this.state.formData;
    return (
      <MobxFormFieldGroup isInline information={Vin && this.handleVinFieldGroupWarning(Vin)}>
        <MobxFormLabel htmlFor="Vin">{this.props.t('VehicleForm.vin')}</MobxFormLabel>
        <TextInput
          id="Vin"
          value={Vin}
          onChange={this.handleChangeValue}
          disabled={this.props.options.disabledFields && this.props.options.disabledFields.Vin}
          dataThook="VIN"
        />
      </MobxFormFieldGroup>
    );
  }

  render() {
    const cancelLabel = this.props.cancelLabel || this.props.t('VehicleForm.cancel');
    const submitLabel = this.props.submitLabel || this.props.t('VehicleForm.add_vehicle');
    const errors = this.validator.getErrors();

    const vehicleClassOptions = this.props.optionsList['VehicleClass'];
    const vehicleTypeOptions = this.props.optionsList['VehicleType'];

    return (
      <MobxForm onSubmit={this.handleSubmit} focusOnFirstElement className="vehicleForm">
        <div className={!this.props.fullWidth ? 'vehicleForm__inner' : ''}>
          <MobxFormFieldGroup isInline error={errors.Condition}>
            <MobxFormLabel>{this.props.t('VehicleForm.condition')}</MobxFormLabel>

            <RadioButtonGroup
              options={vehicleTypeOptions}
              name="Condition"
              onChange={(e) => this.handleChangeValue(e.target.name, e.target.value)}
              checkedValue={this.state.formData.Condition}
              isGroupDisabled={this.props.options.disabledFields && this.props.options.disabledFields.Condition}
              variant="inline"
            />
          </MobxFormFieldGroup>

          <MobxFormFieldGroup isInline error={errors.Class}>
            <MobxFormLabel>{this.props.t('VehicleForm.class')}</MobxFormLabel>

            <RadioButtonGroup
              options={vehicleClassOptions}
              name="Class"
              onChange={(e) => this.handleChangeValue(e.target.name, e.target.value)}
              checkedValue={this.state.formData.Class}
              isGroupDisabled={this.props.options.disabledFields && this.props.options.disabledFields.Class}
              variant="inline"
            />
          </MobxFormFieldGroup>

          {this.renderVrmLookup()}

          <MobxFormFieldGroup isInline error={errors.MakeId}>
            <MobxFormLabel htmlFor="MakeId">{this.props.t('VehicleForm.make')}</MobxFormLabel>
            {this.renderSuggestions('Make')}
            <SelectMakeInput
              id="MakeId"
              value={this.state.formData.MakeId}
              onChange={this.handleIdFieldChangeValue}
              vehicleClass={this.state.formData.Class}
              registrationDate={this.state.formData.RegistrationDate}
              onFetchMakes={this.handleFetchMakes}
              onFetchMakesSuccess={this.handleFetchMakesSuccess}
              disabled={this.props.options.disabledFields && this.props.options.disabledFields.Make}
            />
          </MobxFormFieldGroup>

          <MobxFormFieldGroup isInline error={errors.ModelId}>
            <MobxFormLabel htmlFor="ModelId">{this.props.t('VehicleForm.model')}</MobxFormLabel>
            {this.renderSuggestions('Model')}
            <SelectModelInput
              id="ModelId"
              value={this.state.formData.ModelId}
              onChange={this.handleIdFieldChangeValue}
              makeId={this.state.formData.MakeId}
              vehicleClass={this.state.formData.Class}
              registrationDate={this.state.formData.RegistrationDate}
              onFetchModels={this.handleFetchModels}
              onFetchModelsSuccess={this.handleFetchModelsSuccess}
              disabled={this.props.options.disabledFields && this.props.options.disabledFields.Model}
            />
          </MobxFormFieldGroup>

          <MobxFormFieldGroup isInline error={errors.DerivativeId}>
            <MobxFormLabel htmlFor="derivative">{this.props.t('VehicleForm.derivative')}</MobxFormLabel>
            {this.renderSuggestions('Derivative')}
            <SelectDerivativeInput
              id="DerivativeId"
              value={this.state.formData.DerivativeId}
              onChange={this.handleIdFieldChangeValue}
              makeId={this.state.formData.MakeId}
              modelId={this.state.formData.ModelId}
              vehicleClass={this.state.formData.Class}
              registrationDate={this.state.formData.RegistrationDate}
              onFetchDerivatives={this.handleFetchDerivatives}
              onFetchDerivativesSuccess={this.handleFetchDerivativesSuccess}
              disabled={this.props.options.disabledFields && this.props.options.disabledFields.Derivative}
            />
          </MobxFormFieldGroup>

          {this.renderVIN()}
          {this.renderMileage()}

          {this.props.options.showCombinedDealWarning && (
            <div className="vehicleForm__combinedDealWarning">
              <InformationWarning>{this.props.t('VehicleForm.any_changes_made_to_personal_loans')}</InformationWarning>
            </div>
          )}

          <FormFooter
            onCancel={this.props.onCancel}
            submitLabel={submitLabel}
            cancelLabel={cancelLabel}
            submittingState={this.props.submittingState}
            errorMessage={this.props.errorMessage}
            submitButtonTag="SubmitVehicleForm"
            trackingPage={this.props.trackingPage}
          />
        </div>
      </MobxForm>
    );
  }
}

VehicleForm.propTypes = {
  navigate: PropTypes.func,
  query: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  initialData: PropTypes.object,
  submitLabel: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  quickQuoteVrm: PropTypes.string,
  appStore: PropTypes.object,
  submittingState: PropTypes.string,
  errorMessage: PropTypes.string,
  dealershipId: PropTypes.string,
  fullWidth: PropTypes.bool,
  trackingPage: PropTypes.string,
  options: PropTypes.object,
  optionsList: PropTypes.object.isRequired
};

VehicleForm.defaultProps = {
  fullWidth: false,
  options: {
    disabledFields: {},
    showCombinedDealWarning: false
  }
};

function mapStateToProps(state) {
  return {
    optionsList: state.options
  };
}

export default compose(
  withQuery,
  withNavigate,
  withTranslation('Common'),
  connect(mapStateToProps),
  inject(['appStore']),
  observer
)(VehicleForm);
