import { Link, useLocation, useParams } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';

import { Tooltip } from 'components/Common/Tooltip/Tooltip';
import Arrow from '../../Arrow/Arrow';
import useMetricsCount from '../../../services/hooks/useMetricsCount';
import MetricCardSkeleton from '../../MetricCardSkeleton/MetricCardSkeleton';
import UiState from 'mobx-stores/UiState';

import { SubmittedApplicationsIcon } from '../../svgs';

import s from '../../MetricCards/metricCards.module.scss';

type Props = {
  appStore?: {
    uiState: UiState;
  };
};

const SubmittedApplications = (props: Props) => {
  const { pathname } = useLocation();
  const { dealershipId } = useParams<{ dealershipId: string }>();
  const countryCode = (props.appStore!.uiState.countryCode as unknown) as string;

  const { t } = useTranslation('Stock');

  const { data, isLoading } = useMetricsCount({
    dealershipId,
    metric: 'finance_application_initial_submission:count',
    period: 'week'
  });

  if (isLoading) {
    return <MetricCardSkeleton />;
  }

  return (
    <Link
      data-th="MetricCard-SubmittedApplications"
      to={`/d/${dealershipId}/stock/dealership-metrics/submitted-applications`}
      className={cn(s['card'], pathname.includes('dealership-metrics/submitted-applications') && s['card--active'])}
    >
      <div className={s['metric']}>
        <span className={s['statTitle']}>{t('StockMetrics.submitted_applications')}</span>
        <div className={cn(s['icon'], s['metricIcon'])}>
          <SubmittedApplicationsIcon />
        </div>
      </div>
      <div className={s['bigValue']}>{data?.total ? new Intl.NumberFormat(countryCode).format(data.total) : '0'}</div>
      {typeof data?.percentageChange === 'number' ? (
        <Tooltip
          content={t('StockMetrics.this_indicates_the_change_in_activity_based_on_last_weeks_data')}
          variant="metrics"
          open={undefined}
          defaultOpen={undefined}
          onOpenChange={undefined}
        >
          <div className={s['percentageChange']}>
            <Arrow change={data.percentageChange} />
            <span>
              {new Intl.NumberFormat(countryCode).format(data.percentageChange)}% {t('StockMetrics.vs_last_week')}
            </span>
          </div>
        </Tooltip>
      ) : (
        <div className={s['percentageChange']}>
          <span>{t('StockMetrics.last_week')}</span>
        </div>
      )}
    </Link>
  );
};

export default inject('appStore')(SubmittedApplications);
