import { observable, action } from 'mobx';
import * as api from '../api/reserveNow';
import Request from '../mobx-models/Request';
import * as debug from '../debug';

class ReserveNowStore {
  setReservationSettingsRequest = new Request();
  getReservationSettingsRequest = new Request();
  setReservationRefundReserveItemRequest = new Request();
  getReservedVehiclesByDealerRequest = new Request();
  getPrivateStripeKeyRequest = new Request();
  getActiveReservationsCountRequest = new Request();
  getOverdueReservationsCountRequest = new Request();
  setPaymentAsDepositRequest = new Request();
  @observable isPageLoading;
  @observable isSuccessful;
  @observable settingsData;
  @observable reservedData = [];
  @observable privateStripeKey;
  @observable activeReservationsNumber;
  @observable isReserveSettingsSet = false;
  @observable overdueReservationsNumber;
  @observable showConfirmDialog = false;
  @observable showPaymentAsDepositMessage = false;
  @observable refundCustomer = {};
  @observable page = 1;
  @observable pageSize = 5;
  @observable reserveFilter = 'all';
  @observable isPaymentAsDepositLoading = false;

  @action
  getReservationSettings = (dealershipId) => {
    this.isPageLoading = true;
    return this.getReservationSettingsRequest
      .onSuccess(
        action((response) => {
          this.settingsData = response;
          this.isPageLoading = false;
          this.isReserveSettingsSet = response.StripePublicKey === null ? false : true;
        })
      )
      .onError((e) => {
        this.isPageLoading = false;
        debug.error(e);
      })
      .call(api.getReservationSettings, dealershipId);
  };

  @action
  setReservationSettings = (dealershipId, data) => {
    return this.setReservationSettingsRequest
      .onSuccess(this.setReservationSuccess)
      .onError((e) => {
        debug.error(e);
      })
      .call(api.setReservationSettings, dealershipId, data);
  };

  @action
  setReservationSuccess = (response) => {
    this.isSuccessful = true;
  };

  @action
  getReservedVehiclesByDealer = (dealershipId, page, pagesize, reservationStatus) => {
    this.isPageLoading = true;
    return this.getReservedVehiclesByDealerRequest
      .onSuccess(this.getReservationByDealerSuccess)
      .onError((e) => {
        debug.error(e);
      })
      .call(api.getReservedVehiclesByDealer, dealershipId, page, pagesize, reservationStatus);
  };

  @action
  getReservationByDealerSuccess = (response) => {
    this.reservedData = response || [];
    this.isPageLoading = false;
  };

  @action
  getReservationsCount = (dealershipId, type) => {
    this.page = 1;
    this.pageSize = 3;
    this.reserveFilter = type;
    return this.getActiveReservationsCountRequest
      .onSuccess(this.getActiveReservationsCountSuccess)
      .onError((e) => {
        debug.error(e);
      })
      .call(api.getReservedVehiclesByDealer, dealershipId, this.page, this.pageSize, this.reserveFilter);
  };

  @action
  getActiveReservationsCountSuccess = (response) => {
    this.activeReservationsNumber = response && response.reservationCount;
  };

  @action
  getDashboardReserveTileState = (dealershipId) => {
    this.page = 1;
    this.pageSize = 3;
    this.reserveFilter = 'overdue';

    return this.getOverdueReservationsCountRequest
      .onSuccess(this.getOverdueReservationsCountSuccess)
      .onError((e) => {
        debug.error(e);
      })
      .call(api.getReservedVehiclesByDealer, dealershipId, this.page, this.pageSize, this.reserveFilter);
  };

  @action
  getOverdueReservationsCountSuccess = (response) => {
    this.overdueReservationsNumber = response && response.reservationCount;
  };

  @action
  toggleRefundConfirmationMessage = () => {
    this.showConfirmDialog = !this.showConfirmDialog;
  };

  @action
  togglePaymentAsDepositMessage = () => {
    this.showPaymentAsDepositMessage = !this.showPaymentAsDepositMessage;
  };

  @action
  setRefundCustomer = (dealershipId, data) => {
    this.refundCustomer = { dealershipId, data };
  };

  @action
  setReservationRefundRequestForReserveItem = () => {
    return this.setReservationRefundReserveItemRequest
      .onSuccess(this.setReservationRefundRequestForReserveItemSuccess)
      .onError((e) => {
        debug.error(e);
      })
      .call(api.refundCustomer, this.refundCustomer.dealershipId, this.refundCustomer.data.stripePaymentId);
  };

  @action
  setReservationRefundRequestForReserveItemSuccess = (response) => {};

  @action
  setPaymentAsDeposit = () => {
    this.isPaymentAsDepositLoading = true;
    return this.setPaymentAsDepositRequest
      .onSuccess(this.setPaymentAsDepositSuccess)
      .onError((e) => {
        debug.error(e);
        this.isPaymentAsDepositLoading = false;
      })
      .call(api.setPaymentAsDeposit, this.refundCustomer.dealershipId, this.refundCustomer.data.stripePaymentId);
  };

  @action
  setPaymentAsDepositSuccess = (response) => {
    this.isPaymentAsDepositLoading = false;
  };
}

export default ReserveNowStore;
