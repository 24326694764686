import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import FormFooter from '../../Common/Form/FormFooter';
import YearsMonthsField from '../../Common/Form/YearsMonthsField';
import ApplicationCard from '../../Application/components/ApplicationCard';
import MiniPanel from '../../Common/MiniPanel';
import MiniPanelHeader from '../../Common/MiniPanelHeader';
import ExclamationWarningWithButton from '../../Common/ExclamationWarningWithButton';
import Button from '../../Common/Button/Button';
import { calculateTotalMonthsFromCollection } from '../../../core/helpers';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import SelectInput from '../../Common/Form/SelectInput';
import Validator from '../../../validators/Validator';
import './cfcEmploymentHistoryForm.scss';

class CFCEmploymentHistoryForm extends React.Component {
  defaultFormData() {
    return {
      OccupationBasis: '',
      OccupationType: '',
      TimeAtEmployment: {
        Years: 0
      },
      isEditing: true
    };
  }

  UNSAFE_componentWillMount() {
    this.setupValidation(this.props.validationRules.Items[0]);
    this.setInitialValues(this.props.defaultValues);
  }

  setupValidation(validationRules) {
    if (this.props.isVatAssist) {
      validationRules.VatNumber = 'number:int, maxlength:9, minlength:9, required';
    }

    this.validator = new Validator();
    this.validator.setRules(validationRules);
  }

  setInitialValues = (data) => {
    const Items = {
      Items: [this.defaultFormData()],
      totalMonths: ''
    };
    const newData = data && data.Items.length > 0 ? data : Items;
    this.setState(
      () => {
        const defaultData = {
          formData: newData,
          isFormSubmitted: false
        };
        defaultData.formData.Items.length > 1
          ? this.setEditingFalse()
          : (defaultData.formData.Items[0].isEditing = true);
        return { ...defaultData };
      },
      () => {
        this.validator.validate(this.state.formData.Items[this.getEditingIndex()]);
      }
    );
  };
  handleFieldChange = (id, value, index) => {
    const currentState = this.state.formData;
    const newState = currentState.Items.map((item, i) => (i === index ? { ...item, [id]: value } : item));
    this.setState((prevState) => {
      this.validator.validate(newState[index]);
      return { ...prevState, formData: { ...prevState.formData, Items: newState } };
    });
  };
  handleFieldChangeYM = (id, value, index) => {
    let years = this.state.formData.Items[index].TimeAtEmployment.Years;
    let months = this.state.formData.Items[index].TimeAtEmployment.Months;

    if (value > 11) {
      years = Math.floor(value / 12);
      months = value % 12;
    } else {
      years = 0;
      months = value;
    }

    this.setState((prevState) => {
      const currentState = prevState.formData.Items[index];
      Object.assign(currentState, {
        ...currentState,
        TimeAtEmployment: {
          [id]: value,
          Years: years,
          Months: months
        }
      });
      this.validator.validate(currentState);
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          totalMonths: calculateTotalMonthsFromCollection(prevState.formData.Items, 'TimeAtEmployment')
        }
      };
    });
  };
  calculateTotalMonths = () => {
    this.setState((prevState) => {
      let updatedtotalMonths = 0;
      updatedtotalMonths += prevState.formData.TimeAtBank.TotalMonths;
      return { ...prevState, formData: { ...prevState.formData, totalMonths: updatedtotalMonths } };
    });
  };
  handleEditAddress = (index) => {
    this.setEditingFalse();
    this.setState((prevState) => {
      const newState = [...prevState.formData.Items];
      const currentState = prevState.formData.Items[index];
      const updatedItem = { ...currentState, isEditing: true };
      newState[index] = updatedItem;
      this.validator.validate(updatedItem);
      return { ...prevState, formData: { ...prevState.formData, Items: newState } };
    });
  };
  handleAddItem = () => {
    this.setState((prevState) => {
      const newArray = prevState.formData.Items.map((item) => ({ ...item, isEditing: false }));
      this.defaultFormData && newArray.push(this.defaultFormData());
      return {
        ...prevState,
        formData: { ...prevState.formData, Items: newArray },
        showTimeAtEmploymentWarning: false,
        isFormSubmitted: false
      };
    });
    this.setErrorsToState();
  };
  setErrorsToState = () => {
    let newState = this.state.formData.Items;

    if (this.state.formData.Items.length) {
      this.setState((prevState) => {
        prevState.formData.Items.forEach((currentItem, i) => {
          this.validator.validate(currentItem);
          const updatedItem = Object.assign(currentItem, {
            validationErrors: this.validator.getErrors()
          });
          newState.splice(i, 1, updatedItem);
        });
        return { ...prevState, formData: { ...prevState.formData, Items: [...newState] } };
      });
    }

    this.validator.validate(this.state.formData.Items[this.getEditingIndex()]);
  };

  getEditingIndex() {
    let currentlyEditingItem;
    this.state.formData.Items.forEach((item, i) => {
      if (item.isEditing === true) {
        currentlyEditingItem = i;
      }
    });
    return currentlyEditingItem;
  }

  setEditingFalse = () => {
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        Items: prevState.formData.Items.map((item) => ({ ...item, isEditing: false }))
      }
    }));
  };
  handleRemoveAddress = (index) => {
    let itemsCopy = this.state.formData.Items;
    itemsCopy = itemsCopy.filter((item, i) => i !== index);
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        Items: itemsCopy,
        totalMonths: calculateTotalMonthsFromCollection(itemsCopy, 'TimeAtEmployment')
      }
    }));
    this.validator.validate(this.state.formData.Items[this.getEditingIndex()]);
  };
  countErrors = (index, count = 0) => {
    const validationErrors = this.state.formData.Items[index].validationErrors;

    if (typeof validationErrors === 'object') {
      return (count = Object.keys(validationErrors).length);
    } else {
      return 0;
    }
  };
  submitForm = (event) => {
    if (this.state.formData.totalMonths < 36) {
      this.setState({
        showTimeAtEmploymentWarning: true,
        isFormSubmitted: true
      });
    } else {
      this.setState(
        {
          showTimeAtEmploymentWarning: false,
          isFormSubmitted: true
        },
        () => !this.validator.errorCount && this.props.onSubmit(this.state.formData)
      );
    }

    this.setErrorsToState();
  };

  render() {
    const errors = this.validator.getErrors();
    let formData = this.state.formData;
    return (
      <MobxForm onSubmit={this.submitForm}>
        {formData.Items.map((value, index) => {
          let totalMonths = 0;

          if (!value.TimeAtEmployment.TotalMonths) {
            totalMonths = value.TimeAtEmployment.Years * 12 + value.TimeAtEmployment.Months;
          } else {
            totalMonths = value.TimeAtEmployment.TotalMonths;
          }

          return (
            <MiniPanel key={index}>
              <MiniPanelHeader title={'Employment ' + (index + 1)} />

              {value.isEditing ? (
                <div className="cfcEmploymentHistoryForm" data-th="cfcEmploymentHistoryForm">
                  <div className="cfcEmploymentHistoryForm__formContainer" key={index}>
                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={this.state.isFormSubmitted}
                      error={errors.OccupationBasis}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('CFCEmploymentHistoryForm.occupation_basis')}</div>
                      </MobxFormLabel>
                      <SelectInput
                        id="OccupationBasis"
                        options="EmploymentBasis"
                        value={value.OccupationBasis}
                        onChange={(id, val) => this.handleFieldChange(id, val, index)}
                      />
                    </MobxFieldGroup>
                    <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.OccupationType}>
                      <MobxFormLabel>
                        <div>{this.props.t('CFCEmploymentHistoryForm.occupation_type')}</div>
                      </MobxFormLabel>
                      <SelectInput
                        id="OccupationType"
                        type="select"
                        options="OccupationType"
                        value={value.OccupationType}
                        onChange={(id, val) => this.handleFieldChange(id, val, index)}
                      />
                    </MobxFieldGroup>
                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={this.state.isFormSubmitted}
                      error={errors.TimeAtEmployment && errors.TimeAtEmployment.TotalMonths}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('CFCEmploymentHistoryForm.time_at_employment')}</div>
                      </MobxFormLabel>
                      <YearsMonthsField
                        id="TotalMonths"
                        name="TotalMonths"
                        value={totalMonths}
                        onChange={(val) => this.handleFieldChangeYM('TotalMonths', val, index)}
                      />
                    </MobxFieldGroup>
                  </div>
                  <div className="cfcEmploymentHistoryForm__removeContainer">
                    <div className="cfcEmploymentHistoryForm__remove">
                      {index > 0 ? (
                        <Button
                          type="button"
                          buttonStyle="cancel"
                          size="small"
                          key={'remove_' + index}
                          onClick={() => this.handleRemoveAddress(index)}
                        >
                          {this.props.t('CFCEmploymentHistoryForm.remove')}
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <ApplicationCard
                  type="employment"
                  data={value}
                  edit={() => this.handleEditAddress(index)}
                  remove={() => this.handleRemoveAddress(index)}
                  errorCount={this.countErrors(index)}
                  submitted={this.state.formSubmitted}
                  showRemove={index > 0 ? true : false}
                />
              )}
            </MiniPanel>
          );
        })}

        {this.state.showTimeAtEmploymentWarning && (
          <ExclamationWarningWithButton
            title={this.props.t('CFCEmploymentHistoryForm.thank_you')}
            message={this.props.t(
              'CFCEmploymentHistoryForm.but_you_need_to_give_at_least_3_years_of_employment_history'
            )}
            onClick={this.handleAddItem}
            buttonText={this.props.t('CFCEmploymentHistoryForm.add_new_employment')}
          />
        )}

        <FormFooter
          onCancel={this.props.onCancel}
          submittingState={this.props.submittingState}
          savingState={this.props.savingState}
        />
      </MobxForm>
    );
  }
}

CFCEmploymentHistoryForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  validationRules: PropTypes.object.isRequired,
  savingState: PropTypes.string,
  submittingState: PropTypes.string
};
export default withTranslation('Quoting')(CFCEmploymentHistoryForm);
