import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.AffordabilityDetails!.ForeseeFutureDownturnInFinances = false;
  draft.applicationFields.AffordabilityDetails!.DownturnReason = false;
  draft.applicationFields.AffordabilityDetails!.MortgageOrRental = true;
  draft.applicationFields.AffordabilityDetails!.Other = true;
  draft.applicationFields.AffordabilityDetails!.ChangesExpected = true;
  draft.applicationFields.AffordabilityDetails!.DetailsOfExpectedChanges = true;
  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.ForeseeFutureDownturnInFinances;
  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.DownturnReason;
  draft.validationRules.AffordabilityDetails!.PersonalCircumstances!.ChangesExpected = 'required';
  draft.validationRules.AffordabilityDetails!.MonthlyExpenditure!.MortgageOrRental = 'required, currency';
  draft.validationRules.AffordabilityDetails!.MonthlyExpenditure!.Other = 'required, currency';
  draft.validationRules.EmploymentHistory!.Items![0]!.OccupationType =
    'validateFieldBasedOnOtherFieldValue:ReceivingDisabilityBenefit:OccupationBasis:Unemployed';
});
