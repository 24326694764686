import PropTypes, { InferProps } from 'prop-types';
import { InputHTMLAttributes, useRef } from 'react';
import cn from 'classnames';
import { isIOS } from 'react-device-detect';
import './radioButton.scss';

const RadioButton = ({
  id,
  onChange,
  checked = false,
  children,
  disabled = false,
  trackingPage,
  className,
  variant = 'stacked',
  ...props
}: RadioButtonPropTypes) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const handleChange: typeof onChange = (e) => {
    onChange?.(e);

    if (trackingPage) {
      window.ga?.('send', 'event', trackingPage, 'ButtonPress', id);
    }
  };

  const radioButtonClasses = cn('radioButton__content', {
    'radioButton__content--inline': variant === 'inline',
    'radioButton__content--selected': checked,
    'radioButton__content--selected-ios': checked && isIOS,
    'radioButton__content--disabled': disabled
  });

  return (
    <label
      htmlFor={id}
      className={cn(className, {
        radioButton: !isIOS,
        // make the label look like the fake radio button on iOS
        [radioButtonClasses]: isIOS
      })}
      data-thook={`${id}-RadioButton`}
    >
      <input
        {...props}
        className={cn({
          radioButton__input: !isIOS
        })}
        id={id}
        onChange={handleChange}
        type="radio"
        checked={checked}
        disabled={disabled}
        ref={checkboxRef}
      />

      {isIOS ? (
        <span>{children}</span>
      ) : (
        <div className={radioButtonClasses}>
          <span className="radioButton__dot" />
          <span>{children}</span>
        </div>
      )}
    </label>
  );
};

const RadioButtonProps = {
  children: PropTypes.node,
  trackingPage: PropTypes.string,
  variant: PropTypes.oneOf(['inline', 'stacked'] as const)
};

export type RadioButtonPropTypes = InferProps<typeof RadioButtonProps> & InputHTMLAttributes<HTMLInputElement>;

RadioButton.propTypes = RadioButtonProps;

export default RadioButton;
