import platformApiUtils from '@ivendi/platform-api-utils';
import * as actionTypes from '../actionTypes';
import { schemaMap } from '../../core/schema';
import platformUpdatesSchema from '../../core/schemas/platformUpdatesSchema';

export function fetchPlatformUpdates() {
  return (dispatch, getState) => {
    let state = getState();
    const options = {
      page: state.platformUpdates.Page,
      pageSize: state.platformUpdates.PageSize,
      sortDirection: state.platformUpdates.SortDirection
    };
    dispatch({
      types: [
        actionTypes.GET_PLATFORM_UPDATES,
        actionTypes.GET_PLATFORM_UPDATES_SUCCESS,
        actionTypes.GET_PLATFORM_UPDATES_ERROR
      ],
      callAPI: () => platformApiUtils.get('v1/whatsnew', options),
      parseResponse: (data) => schemaMap(data, platformUpdatesSchema)
    });
  };
}

export function changePage(newPage) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_PLATFORM_UPDATES_PAGE,
      page: newPage
    });

    dispatch(fetchPlatformUpdates());
  };
}

export function resetOptions() {
  return {
    type: actionTypes.RESET_PLATFORM_UPDATES_OPTIONS
  };
}
