import PropTypes from 'prop-types';
import ConsumerVehicleInfo, { ConsumerVehicleInfoVariantType } from '../Customer/components/ConsumerVehicleInfo';
import './consumerVehicleInfoPanel.scss';

export type ConsumerVehicleCondition = 'New' | 'Used' | 'new' | 'used';
export type ConsumerVehicleType = {
  Make: string;
  Vrm: string;
  VehicleVrm?: string;
  Vin?: string;
  Model: string;
  Derivative: string;
  Condition: ConsumerVehicleCondition;
};
export type ConsumerVehicleQuoteType = {
  VehicleVrm: string;
  VehicleMake: string;
  VehicleModel: string;
  VehicleDerivative: string;
  Condition: ConsumerVehicleCondition;
};
type ConsumerVehicleInfoPanelProps = {
  vehicle: ConsumerVehicleType;
  quote?: ConsumerVehicleQuoteType;
};

const ConsumerVehicleInfoPanel = (props: ConsumerVehicleInfoPanelProps) => {
  const { vehicle, quote } = props;
  return (
    <div className="consumerVehicleInfoPanel">
      <ConsumerVehicleInfo vehicle={vehicle} quote={quote} variant="row" />
    </div>
  );
};

ConsumerVehicleInfoPanel.propTypes = {
  vehicle: PropTypes.object
};

export default ConsumerVehicleInfoPanel;
