import { Trans, withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../Common/Panel';
import Button from '../../Common/Button/Button';
import PanelHeader from '../../Common/PanelHeader';
import LenderNotes from './ApplicationLenderNotes';
import SquareActionButton from '../../Common/SquareActionButton';
import Breadcrumbs from '../../Common/Breadcrumbs';
import Page from '../../Common/Page';
import ConsumerVehicleInfoPanel from '../../Common/ConsumerVehicleInfoPanel';
import AssignLeadContainer from '../../AssignLead/containers/AssignLeadContainer';
import PanelToolBar from '../../Common/PanelToolBar';
import NoProceedNotice from '../../ApplicationStatus/NoProceedNotice';
import ConsumerStatus from '../../ApplicationStatus/ConsumerStatus';
import LargeButtonLayout from '../../ApplicationStatus/Layout/LargeButtonLayout';
import Modal from '../../Common/Modal/Modal';
import { observer, inject } from 'mobx-react';
import TrackEvent from '../../Common/Tracking/TrackEvent';
import RelatedApplicationStatus from '../../ApplicationStatus/RelatedApplicationStatus';
import InformationWarning from '../../Common/InformationWarning';
import LoadingDots from '../../Common/Loading/LoadingDots';
import EditNewDecision from './EditNewDecision';
import BoldHeader from '../../Common/BoldHeader';
import CancelApplicationModal from '../../ApplicationStatus/CancelApplicationModal';
import { ProceedAndRequestModalContents, SubmitEsignRequest } from './ProceedAndRequestPaperworkContent';
import { compose } from 'redux';
import { withRouter } from 'hocs/router';
import moment from 'moment';
import AlertCard from 'components/Common/AlertCard/AlertCard';
import { canSeeInterceptor } from '~/features';
import { isQuoteAvailable } from '~/core/helpers';
import { trackRequoteNewRateSelected } from '~/tracking/avoTracking';
import RequoteAlertCard from '../alerts/RequoteAlertCard';
import { getRequoteData } from '../alerts/utils';
import './applicationStatus.scss';
import PreApprovalDeclinedAlertCard from '../alerts/PreApprovalDeclinedAlertCard';

const isCancelButtonAllowedByStatus = (applicationStatus) => {
  const statusesNotAllowed = ['PaidOut', 'Rejected', 'NotTakenUp', 'SentForPayout', 'Error', 'Cancelled', 'Submitting'];
  const containsStatus = statusesNotAllowed.indexOf(applicationStatus) > -1;
  return !containsStatus;
};

const shouldHideBNPEditCTA = (t, application) => {
  const { lenderNotes, Quote } = application;
  let shouldHideEdit = false;
  if (Quote.FunderCode === 'CRE' && lenderNotes?.length) {
    lenderNotes.forEach((note) => {
      if (
        // TODO: i18n check
        note.ExtraInfo.includes(
          t(
            'ApplicationStatus.these_applications_are_for_personal_loans_to_cover_the_cost_s_of_any_va_ps_and_or_negative_equity'
          )
        )
      ) {
        shouldHideEdit = true;
      }
    });
    return shouldHideEdit;
  }
};

class ApplicationStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isCancelModalOpen: false,
      isEsignModalOpen: false,
      newDecisionOption: undefined
    };
  }

  fetchUpdates = () => {
    this.props.fetchApplicationUpdates(this.props.params.applicantId);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.intervalId = setInterval(this.fetchUpdates, 15000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  showModal = () => {
    this.setState({
      isModalOpen: true
    });
  };
  closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  };
  showCancelModal = () => {
    this.setState({
      isCancelModalOpen: true
    });
  };
  closeCancelModal = () => {
    this.setState({
      isCancelModalOpen: false
    });
    this.props.appStore.applicationStatusStore.resetModalStatus();
  };
  toggleEsignModal = () => {
    this.setState({
      isEsignModalOpen: !this.state.isEsignModalOpen
    });
  };

  UNSAFE_componentWillMount() {
    this.setState({
      openModal: false
    });

    if (this.props.application.Tags && this.props.application.Tags.multiQuoteRefId) {
      this.props.appStore.customerStore.fetchCustomerData(this.props.consumer.Id);
    }
  }

  handleHideFromDashboard = (value) => {
    this.props.showHideLeadOnDashboard(value);
  };
  handleChangeNewDecision = (option) => {
    this.setState({
      newDecisionOption: option
    });
  };
  handleSubmitNewDecision = () => {
    const randomAgreementNumber = parseInt(
      (Math.random() * 9000000000)
        .toString()
        .replace('.', '')
        .slice(0, 16)
    );
    this.props.appStore.customerStore.sendChangeApplicantDecision(
      this.state.newDecisionOption,
      this.props.params.consumerId,
      this.props.params.applicantId,
      randomAgreementNumber
    );
  };
  onCancelApplication = () => {
    this.props.appStore.applicationStatusStore.setCancelApplication(this.props.application.Id);
  };

  getBreadCrumbs = () => {
    let crumbs = [
      {
        name: this.props.t('ApplicationStatus.home'),
        path: `/d/${this.props.params.dealershipId}`
      }
    ];

    if (this.props.query.order) {
      crumbs.push({
        name: this.props.t('ApplicationStatus.orders_deals'),
        path: `/d/${this.props.params.dealershipId}/orders-and-deals`,
        query: {
          page: 1,
          pageSize: 5,
          dealStatus: 'all',
          sortBy: 'sentAt'
        }
      });
      crumbs.push({
        name: this.props.t('ApplicationStatus.order_summary'),
        path: `/d/${this.props.params.dealershipId}/orders-and-deals/order/${this.props.query.order}`
      });
    } else {
      crumbs.push({
        name: this.props.t('ApplicationStatus.customer_list'),
        path: `/d/${this.props.params.dealershipId}/consumers`
      });
      crumbs.push({
        name: this.props.t('ApplicationStatus.consumer'),
        path: `/d/${this.props.params.dealershipId}/consumers/${this.props.consumer.Id}`
      });
    }

    crumbs.push({
      name: this.props.t('ApplicationStatus.application_status')
    });
    return crumbs;
  };

  displayEsignAlert = () => {
    return (
      <div className="applicationStatus__alert">
        <AlertCard
          iconName="information"
          title={this.props.t('ApplicationStatus.to_proceed_with_this_application')}
          paragraph={
            <Trans
              ns="Application"
              i18nKey="ApplicationStatus.please_note_when_choosing_esign"
              components={{
                ul: <ul />,
                li: <li />
              }}
            />
          }
        />
      </div>
    );
  };

  getStellantisEsignDetails = () => {
    const { Agreements, Arranged, DealershipId } = this.props.application;

    if (Agreements.length > 0) {
      const { DistanceSale } = Arranged;
      const { iVendiAgreementNumber, LenderAgreementNumber } = Agreements[0];
      return {
        IvendiAgreementNumber: iVendiAgreementNumber,
        LenderAgreementNumber,
        IsDistanceSale: !!DistanceSale,
        DealershipId
      };
    }
  };

  canEditWithFinalDecision = (application) => {
    if (application.FinalDecisionsReceived === true && canSeeInterceptor()) {
      return application.isEditableWhenFinalDecisionIsReceived;
    }
    return true;
  };

  render() {
    if (this.props.application.Id !== this.props.params.applicantId) {
      this.props.changeApplication(this.props.params.applicantId);
    }

    const application = this.props.application;
    const { uiState } = this.props.appStore;
    const deliveryDate = moment(application.DeliveryDate);

    const { dealershipId, consumerId, applicantId } = this.props.params;
    const customerName =
      this.props.consumer.CustomerType.toLowerCase() === 'consumer'
        ? `${this.props.consumer.Firstname} ${this.props.consumer.Surname}`
        : `${this.props.consumer.TradingName}`;
    const shouldHideEditForBNP = shouldHideBNPEditCTA(this.props.t, application);

    const handleRequoteClick = (application, origin) => {
      trackRequoteNewRateSelected(getRequoteData(application, origin));
      this.props.navigate(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/requote`);
    };

    const shouldUseDocumentLink =
      (application.Status === 'Accepted' || application.Status === 'ConditionalAccept') &&
      (application.Quote.FunderCode === 'BAR' || application.Quote.FunderCode === 'V12') &&
      application.DocumentLinks &&
      application.DocumentLinks.length > 0;

    const associatedApplications = [];

    if (application.Tags && application.Tags.multiQuoteRefId && this.props.appStore.customerStore.customer) {
      this.props.appStore.customerStore.customer.FinanceApplications.map((app) => {
        if (
          app.Tags &&
          application.Tags.multiQuoteRefId === app.Tags.multiQuoteRefId &&
          app.ApplicantId !== application.Id
        ) {
          associatedApplications.push(app);
        }
      });
    }

    const { NewAPR: newPreApprovalRate = null, ExpiryDate, Success: preApprovalSuccessful = null } =
      this.props.application?.Agreements?.[0]?.PreApprovalData || {};
    const isIntercepted = canSeeInterceptor() && newPreApprovalRate && application.Status === 'Intercepted';
    const isPreApprovalRejected =
      canSeeInterceptor() && preApprovalSuccessful === false && application.Status === 'Rejected';

    /*
     * TEMPORARY: remove isDevOrLocalEnv when we can submit ADM applications again.
     *            While we are blocked, a hardcoded future ExpiryDate is needed to unlock
     *            the requote journey in dev and local environments.
     */
    const isDevOrLocalEnv =
      process.env.REACT_APP_ENVIRONMENT === 'development' || process.env.REACT_APP_ENVIRONMENT === 'local';
    const canRequote = isQuoteAvailable(isDevOrLocalEnv ? '2024-10-26T00:00:00Z' : ExpiryDate);

    const canRepropose = uiState.canRepropose && associatedApplications.length <= 0;
    const thisAppSaysNoContinue = application.Status === 'Error' || application.Status === '';
    const disableContinueWithLoans = application.Quote.FinanceType !== 'FS' && thisAppSaysNoContinue;
    const isCombinedApplication = associatedApplications.length > 0;
    const mainLoanLoading =
      application.Status === 'Submitting' || application.Status === 'Not Submitted' || application.Status === 'Pending';

    const canViewAndCreateApplications = uiState.canViewConsumerApplications && uiState.canCreateApplications;

    const canEditWithFinalDecision = this.canEditWithFinalDecision(application);

    const canDisplayResolveEditCTA =
      canViewAndCreateApplications && application.isError && application.isEditable && canEditWithFinalDecision;
    const canDisplayReproposeCTA =
      uiState.canCreateApplications && application.isReproposable && !application.isError && canRepropose;
    const canDisplayResubmitCTA =
      uiState.canCreateApplications && application.isEditable && !application.isError && !shouldHideEditForBNP;
    const canDisplayRequoteCTA = canViewAndCreateApplications && isIntercepted;
    const canDisplayViewApplicationSummaryCTA =
      uiState.canViewConsumerApplications && (!application.isError || !canEditWithFinalDecision);
    const canDisplayProceedAndRequestPaperworkCTA = application.isComplete && !application.isError;
    const canDisplayRequestESignCTA =
      application.Status === 'Accepted' && application.Quote.FunderCode === 'STE' && deliveryDate.isAfter(moment());

    const canDisplayTryOtherLendersCTA =
      uiState.canCreateApplications && application.isReproposable && !application.isError && canRepropose;

    if (this.props.appStore.customerStore.isLoadingCustomer) {
      return (
        <Page>
          <Breadcrumbs items={this.getBreadCrumbs()} consumer={this.props.consumer} />
          <ConsumerVehicleInfoPanel vehicle={this.props.vehicle} quote={application.Quote} />
          <Panel>
            <PanelHeader>{this.props.t('ApplicationStatus.application_status')}</PanelHeader>
            <div className="applicationStatus__status">
              <LoadingDots />
            </div>
          </Panel>
        </Page>
      );
    }

    return (
      <Page>
        <Breadcrumbs items={this.getBreadCrumbs()} consumer={this.props.consumer} />
        <ConsumerVehicleInfoPanel vehicle={this.props.vehicle} quote={application.Quote} />
        <Panel>
          <PanelHeader>{this.props.t('ApplicationStatus.application_status')}</PanelHeader>
          <PanelToolBar>
            {uiState.canCloseDeals && (
              <div className="applicationStatus__button">
                <Button
                  buttonStyle="secondary"
                  to={
                    application.Status === 'PaidOut'
                      ? {
                          pathname: `/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}/vehicle/${application.Vehicle.VehicleId}/paidout`,
                          search: `?vehicleId=${application.Vehicle.VehicleId}`
                        }
                      : {
                          pathname: `/d/${this.props.params.dealershipId}/consumers/${this.props.params.consumerId}`,
                          search: `vehicleId=${this.props.application.Vehicle.VehicleId}`
                        }
                  }
                >
                  {!this.props.application.Vehicle.Closed
                    ? this.props.t('ApplicationStatus.close_deal')
                    : this.props.t('ApplicationStatus.edit_closed_deal')}
                </Button>
              </div>
            )}
            <div className="applicationStatus__button">
              <AssignLeadContainer
                dealershipId={this.props.params.dealershipId}
                customerId={this.props.consumer.Id}
                customerName={customerName}
                customerType={this.props.consumer.CustomerType.toLowerCase()}
                initialAssignees={this.props.consumer.assignedTo}
              />
            </div>
          </PanelToolBar>

          <div className="applicationStatus__status">
            {isCombinedApplication && (
              <div className="applicationStatus__combinedInfoMessage">
                <InformationWarning>
                  {this.props.t(
                    'ApplicationStatus.this_is_a_combined_deal_each_loan_application_has_to_be_submitted_separately_and_has_its_own_application_status'
                  )}
                </InformationWarning>
              </div>
            )}
            {application.Status === 'Accepted' && application.Quote.FunderCode === 'STE' && this.displayEsignAlert()}
            {isIntercepted && <RequoteAlertCard {...getRequoteData(application, 'url')} canRequote={canRequote} />}
            {isPreApprovalRejected && <PreApprovalDeclinedAlertCard canRepropose={canDisplayTryOtherLendersCTA} />}

            <ConsumerStatus
              application={this.props.application}
              consumer={this.props.consumer}
              checkboxOnChange={this.handleHideFromDashboard}
              combined={isCombinedApplication}
              dealershipId={this.props.params.dealershipId}
            />
            {isCombinedApplication && (
              <>
                <h2 className="applicationStatus__combinedTitle">
                  {this.props.t('ApplicationStatus.connected_applications')}
                </h2>
                <div className="applicationStatus__extraLoans">
                  {mainLoanLoading && <div className="applicationStatus__loadingSpinner" />}
                  <div className={mainLoanLoading ? 'applicationStatus__loadingLoan' : ''}>
                    {associatedApplications.map((app, index) => (
                      <RelatedApplicationStatus
                        key={'related-app-status_' + index}
                        application={app}
                        dealershipId={this.props.params.dealershipId}
                        customerId={this.props.consumer.Id}
                        disabled={
                          (disableContinueWithLoans || (app.Quote.FinanceType === 'FS' && thisAppSaysNoContinue)) &&
                          !mainLoanLoading
                        }
                      />
                    ))}
                  </div>
                </div>
              </>
            )}
            <LenderNotes
              application={application}
              updateDecision={this.props.updateDecision}
              hasAssociatedApplications={associatedApplications.length > 0}
            />
            {process.env.REACT_APP_SHOW_NEWDECISION === 'true' && (
              <EditNewDecision
                optionValue={this.state.newDecisionOption}
                handleChange={this.handleChangeNewDecision}
                handleSubmit={this.handleSubmitNewDecision}
                isLoading={
                  this.props.appStore.customerStore.isLoadingCustomer ||
                  this.props.appStore.customerStore.isLoadingDevStatus ||
                  mainLoanLoading
                }
                hasError={this.props.appStore.customerStore.hasError}
              />
            )}
            <BoldHeader text={this.props.t('ApplicationStatus.what_can_i_do_now')} />
            <div>
              {!uiState.canViewConsumerApplications && (
                <NoProceedNotice
                  text={this.props.t('ApplicationStatus.you_do_not_have_the_required_permissions_to_view_applications')}
                />
              )}
              {!uiState.canCreateApplications && (
                <NoProceedNotice
                  text={this.props.t(
                    'ApplicationStatus.you_do_not_have_the_required_permissions_to_create_or_edit_applications'
                  )}
                />
              )}
              {application.isAdjusted && (
                <NoProceedNotice
                  text={this.props.t(
                    'ApplicationStatus.this_proposal_has_been_adjusted_by_the_lender_and_edits_are_not_allowed'
                  )}
                />
              )}
              {application.isSubmitting && (
                <NoProceedNotice
                  text={this.props.t(
                    'ApplicationStatus.your_application_is_now_being_submitted_to_the_lender_updates_are_not_allowed_at_this_time'
                  )}
                />
              )}
              {application.isPending && (
                <NoProceedNotice
                  text={this.props.t(
                    'ApplicationStatus.your_application_is_pending_a_decision_from_the_lender_and_therefore_cannot_be_updated_at_this_time'
                  )}
                />
              )}
              {application.isPaidOut && (
                <NoProceedNotice
                  text={this.props.t(
                    'ApplicationStatus.your_application_has_been_paid_out_and_cannot_be_updated_at_this_time'
                  )}
                />
              )}
            </div>
            <LargeButtonLayout>
              {application.Quote.FunderCode === 'CRE' &&
                isCancelButtonAllowedByStatus(this.props.application.Status) && (
                  <SquareActionButton
                    onClick={this.showCancelModal}
                    text={this.props.t('ApplicationStatus.cancel_application')}
                    ctaText={this.props.t('ApplicationStatus.cancel_application')}
                    type="proceed"
                    iconName="cross"
                  />
                )}
              {canDisplayViewApplicationSummaryCTA && (
                <SquareActionButton
                  to={`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/viewapplicationsummary`}
                  text={this.props.t('ApplicationStatus.view_the_application_summary')}
                  ctaText={this.props.t('ApplicationStatus.view_application')}
                  type="view"
                  iconName="application"
                />
              )}
              {canDisplayProceedAndRequestPaperworkCTA && !shouldUseDocumentLink && (
                <SquareActionButton
                  onClick={this.showModal}
                  text={
                    application.Quote.FunderCode === 'STE'
                      ? this.props.t('ApplicationStatus.submit_delivery_date')
                      : this.props.t('ApplicationStatus.proceed_and_request_paperwork')
                  }
                  ctaText={this.props.t('ApplicationStatus.proceed')}
                  type="proceed"
                  iconName="proceed"
                />
              )}

              {canDisplayProceedAndRequestPaperworkCTA && shouldUseDocumentLink && (
                <TrackEvent
                  linkTo={application.DocumentLinks[0]}
                  interactionName={`Lender Portal Paperwork Link (${application.Quote.FunderCode})`}
                >
                  <SquareActionButton
                    href={application.DocumentLinks[0]}
                    text={this.props.t('ApplicationStatus.proceed_and_request_paperwork')}
                    ctaText={this.props.t('ApplicationStatus.proceed')}
                    type="proceed"
                    iconName="proceed"
                  />
                </TrackEvent>
              )}

              {canDisplayRequestESignCTA && (
                <SquareActionButton
                  onClick={this.toggleEsignModal}
                  text={this.props.t('ApplicationStatus.request_e_sign')}
                  ctaText={this.props.t('ApplicationStatus.proceed')}
                  type="e-sign"
                  iconName="e-sign"
                  iconClassName="--e-sign"
                />
              )}

              {canDisplayResolveEditCTA && (
                <SquareActionButton
                  to={`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/applicationsummary`}
                  text={this.props.t('ApplicationStatus.review_application_and_resolve_issues')}
                  ctaText={this.props.t('ApplicationStatus.edit')}
                  type="error"
                  iconName="rejected"
                />
              )}

              {canDisplayRequoteCTA && (
                <SquareActionButton
                  onClick={() => handleRequoteClick(application, 'cta')}
                  text={this.props.t('ApplicationStatus.requote_the_application')}
                  ctaText={this.props.t('ApplicationStatus.requote')}
                  type="intercepted"
                  iconName="edit"
                  disabled={!canRequote}
                  dataThook="requoteCTA"
                />
              )}

              {canDisplayReproposeCTA && (
                <SquareActionButton
                  to={`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/repropose`}
                  text={this.props.t('ApplicationStatus.try_alternative_lenders')}
                  ctaText={this.props.t('ApplicationStatus.repropose')}
                  type="proceed"
                  iconName="repropose"
                  isBold
                />
              )}
              {canDisplayResubmitCTA && (
                <SquareActionButton
                  to={`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/edit`}
                  text={this.props.t('ApplicationStatus.edit_the_application_and_re_submit')}
                  ctaText={this.props.t('ApplicationStatus.edit')}
                  type="edit"
                  iconName="edit"
                />
              )}
            </LargeButtonLayout>
          </div>
        </Panel>
        <Modal isOpen={this.state.isModalOpen} onClose={this.closeModal}>
          <ProceedAndRequestModalContents application={application} />
        </Modal>
        <Modal isOpen={this.state.isCancelModalOpen} onClose={this.closeCancelModal}>
          <CancelApplicationModal
            quote={this.props.application.Quote}
            consumer={this.props.consumer}
            modifiedDate={this.props.application.LastModified}
            applicationStatus={this.props.application.Status}
            onClose={this.closeCancelModal}
            onCancelApplication={this.onCancelApplication}
          />
        </Modal>
        <Modal isOpen={this.state.isEsignModalOpen} onClose={this.toggleEsignModal}>
          <SubmitEsignRequest eSignDetails={this.getStellantisEsignDetails()} />
        </Modal>
      </Page>
    );
  }
}

ApplicationStatus.propTypes = {
  params: PropTypes.object,
  query: PropTypes.object,
  appStore: PropTypes.object,
  session: PropTypes.object,
  formData: PropTypes.object,
  sections: PropTypes.object,
  consumer: PropTypes.object,
  application: PropTypes.object,
  options: PropTypes.object,
  vehicle: PropTypes.object,
  fetchApplicationUpdates: PropTypes.func,
  updateDecision: PropTypes.func,
  showHideLeadOnDashboard: PropTypes.func
};

export default compose(withRouter, withTranslation('Application'), inject(['appStore']), observer)(ApplicationStatus);
