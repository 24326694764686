import platformApiUtils from '@ivendi/platform-api-utils';
import * as actionTypes from '../actionTypes';
import uuid from 'uuid';
import moment from 'moment';
import pubNubPost from '../../utils/pubNubPost';
import { push } from '../../routerHistory';
import { objectToSearchParams } from 'utils/objectToSearchParams';
import { trackVehicleFinanceApplicationStarted } from '../../tracking/avoTracking';

export function createApplicantFromCfcProspect(prospectId, quoteId, quote, dealershipId, customerId) {
  let applicantId = uuid.v4();

  return (dispatch) => {
    dispatch({
      types: [
        actionTypes.CREATE_APPLICANT_FROM_CFC,
        actionTypes.CREATE_APPLICANT_FROM_CFC_SUCCESS,
        actionTypes.CREATE_APPLICANT_FROM_CFC_ERROR
      ],
      callAPI: () =>
        platformApiUtils.post('v1/dealershipconsumer/cfcapplicant', {
          ApplicantId: applicantId,
          CfcProspectid: prospectId,
          QuoteId: quoteId
        }),
      onSuccess: () => {
        trackVehicleFinanceApplicationStarted({
          dealershipId,
          financeFunderCode: quote.FunderCode,
          financeFunderProductId: quote.FunderProductUID,
          financeProductType: quote.FinanceType,
          financeApplicationId: applicantId
        });
        push(`/d/${dealershipId}/consumers/${customerId}/application/${applicantId}/applicationsummary`);
      }
    });
  };
}

export function addNoteToVehicle(customerId, vehicleId, formData, customerType) {
  return (dispatch, getState) => {
    const state = getState();
    let session = state.session;

    dispatch({
      types: [
        actionTypes.ADD_COMMENT_TO_CONSUMER,
        actionTypes.ADD_COMMENT_TO_CONSUMER_SUCCESS,
        actionTypes.ADD_COMMENT_TO_CONSUMER_ERROR
      ],
      callAPI: () =>
        platformApiUtils.post(`v1/customer/${customerId}/dealernote`, {
          VehicleId: vehicleId,
          MessageBody: formData,
          CustomerType: customerType
        }),
      payload: {
        customerId: customerId,
        VehicleId: vehicleId,
        Comment: formData,
        Initials: session.Initials,
        Firstname: session.Firstname,
        Surname: session.LastName,
        Created: moment().toISOString()
      }
    });
  };
}

export function showHideLeadOnDashboard(value) {
  return (dispatch, getState) => {
    const state = getState();
    const customerId = state.application.CustomerId;

    dispatch({
      types: [
        actionTypes.SHOW_HIDE_LEAD_ON_DASHBOARD,
        actionTypes.SHOW_HIDE_LEAD_ON_DASHBOARD_SUCCESS,
        actionTypes.SHOW_HIDE_LEAD_ON_DASHBOARD_ERROR
      ],
      payload: { value },
      callAPI: () =>
        platformApiUtils.post(`v1/customer/${customerId}/changevisibility`, {
          HiddenOnDashboard: value,
          CustomerType: state.application.CustomerType,
          VehicleId: state.application.VehicleId
        })
    });
  };
}

export function reopenLead(vehicleId, customerId, customerType) {
  return (dispatch, getState) => {
    const state = getState();

    dispatch({
      types: [actionTypes.REOPEN_LEAD, actionTypes.REOPEN_LEAD_SUCCESS, actionTypes.REOPEN_LEAD_ERROR],
      callAPI: () =>
        pubNubPost(
          `v1/customer/${customerId}/close`,
          {
            CustomerType: customerType,
            ConsumerId: customerId,
            Closed: false,
            VehicleId: vehicleId,
            NewStatus: null,
            RemoveFromDashboard: false
          },
          'DealershipConsumerReadModelPublisher/DealershipConsumerLeadClosed'
        ),
      onSuccess: () => push(`/d/${state.dealership.Id}/consumers/${customerId}`)
    });
  };
}

export function updateCustomerAssignedTo(customerId, newAssignees) {
  return {
    type: actionTypes.CUSTOMER_UPDATE_ASSIGNED_TO,
    customerId,
    newAssignees
  };
}

export function sortSection(vehicleId, section, field) {
  return {
    type: actionTypes.SORT_CUSTOMER_SECTION,
    vehicleId,
    section,
    field
  };
}

export function saveCustomerQuote(quote, customerId) {
  return (dispatch, getState) => {
    const state = getState();
    const dealershipId = state.dealership.Id;
    const vehicle = state.quotes.vehicle;

    dispatch({
      types: [
        actionTypes.SAVE_CUSTOMER_QUOTE,
        actionTypes.SAVE_CUSTOMER_QUOTE_SUCCESS,
        actionTypes.SAVE_CUSTOMER_QUOTE_ERROR
      ],
      callAPI: () =>
        platformApiUtils.post('v1/customer/' + customerId + '/quote', {
          QuoteId: quote.QuoteId,
          VehicleId: vehicle.VehicleId,
          Vehicle: {
            Make: vehicle.Make,
            Model: vehicle.Model,
            Derivative: vehicle.Derivative
          }
        }),
      onSuccess: () => {
        push({
          pathname: `/d/${dealershipId}/consumers/${customerId}`,
          search: objectToSearchParams({
            selectLatestVehicle: true,
            selectedFinanceTab: 'SavedQuotes'
          }).toString()
        });
      }
    });
  };
}

export function selectCustomerTab(name) {
  return {
    type: actionTypes.SELECT_CUSTOMER_FINANCE_TAB,
    name
  };
}

export function selectVehicle(vehicle) {
  return {
    type: actionTypes.SELECT_CUSTOMER_VEHICLE,
    vehicle
  };
}

export function selectCfcCheck(selectedCfcCheckIndex) {
  return {
    type: actionTypes.SELECT_CUSTOMER_CFC_CHECK,
    selectedCfcCheckIndex
  };
}

export function selectSentDeal(selectedSentDealIndex) {
  return {
    type: actionTypes.SELECT_CUSTOMER_SENT_DEAL,
    selectedSentDealIndex
  };
}
