import React from 'react';
import PropTypes from 'prop-types';
import './textInput.scss';
import classnames from 'classnames';
import ManagedInput from './ManagedInput';

class TextInput extends React.Component {
  handleChange = (fieldName, value) => {
    this.props.onChange && this.props.onChange(fieldName, value);
  };

  handleBlur = (fieldName, value) => {
    this.props.onBlur && this.props.onBlur(fieldName, value);
  };

  render() {
    let isValid = this.props.isValid;
    let showError = this.props.showError;

    const inputStyles = classnames(
      'textInput__input',
      this.props.selectCustomerStyles && 'textInput__pushDealSelectCustomer',
      this.props.selectCustomerStyles && this.props.isHovered && 'textInput__pushDealSelectCustomer--hover',
      this.props.selectCustomerStyles && this.props.hasFocus && 'textInput__pushDealSelectCustomer--focus',
      showError && isValid && !this.props.disabled && 'textInput__valid',
      showError && !isValid && !this.props.disabled && 'textInput__invalid',
      this.props.disabled && 'textInput__disabled'
    );

    const dataAttrs = {};

    if (isValid === false) {
      dataAttrs['data-is-invalid'] = true;
    }

    return (
      <div className="textInput">
        <ManagedInput
          ref="input"
          className={inputStyles}
          id={this.props.id}
          placeholder={this.props.placeholder}
          value={this.props.value}
          type={this.props.type}
          autoComplete={this.props.autoComplete}
          onFocus={this.props.onFocus}
          onKeyDown={this.props.onKeyDown}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          disabled={this.props.disabled}
          maxLength={this.props.maxLength}
          dataThook={this.props.dataThook}
          {...dataAttrs}
        />
      </div>
    );
  }
}

TextInput.defaultProps = {
  type: 'text'
};

TextInput.propTypes = {
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  showError: PropTypes.bool,
  hasFocus: PropTypes.bool,
  maxLength: PropTypes.number,
  dataThook: PropTypes.string,
  autoComplete: PropTypes.string
};

export default TextInput;
