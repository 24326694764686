import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import ApplicationPage from './ApplicationPage';
import PersonalDetailsForm from '../forms/PersonalDetailsForm';
import { withOutletContextProps, withParams } from 'hocs/router';
import { compose } from 'redux';

class PersonalDetails extends React.Component {
  static propTypes = {
    params: PropTypes.object,
    application: PropTypes.object,
    validationRules: PropTypes.object,
    submitApplicationSection: PropTypes.func,
    saveApplicationSection: PropTypes.func,
    applicationFields: PropTypes.object
  };
  handleSubmit = (formData) => {
    const { dealershipId, consumerId, applicantId } = this.props.params;
    this.props.submitApplicationSection(
      'PersonalDetails',
      this.props.params.applicantId,
      formData,
      `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/addresshistory`
    );
  };
  handleSave = (formData) => {
    this.props.saveApplicationSection('PersonalDetails', this.props.params.applicantId, formData);
  };

  render() {
    let isVatAssist = this.props.application.Quote.ProductCode.includes('VAT Assist');
    return (
      <ApplicationPage title={this.props.t('PersonalDetails.personal_details')}>
        <PersonalDetailsForm
          defaultValues={this.props.application.PersonalDetails}
          onSubmit={this.handleSubmit}
          onSave={this.handleSave}
          validationRules={this.props.validationRules.PersonalDetails}
          savingState={this.props.application.sectionSavingState}
          submittingState={this.props.application.sectionSubmittingState}
          applicationFields={this.props.applicationFields.PersonalDetails}
          funderCode={this.props.application.Quote.FunderCode}
          isVatAssist={isVatAssist}
        />
      </ApplicationPage>
    );
  }
}

export default compose(withOutletContextProps, withParams, withTranslation('Application'))(PersonalDetails);
