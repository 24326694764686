import { useEffect } from 'react';
import { trackFinanceProductSuitabilityContentViewed, trackPageViewed } from './avoTracking';
import { ConsumerDutyProductDetails } from '../api/contentService';

export const useTrackFinanceProductSuitabilityContentViewed = (
  consumerDutyContent: ConsumerDutyProductDetails | null | undefined,
  dealershipId: string
) => {
  useEffect(() => {
    if (consumerDutyContent) {
      trackFinanceProductSuitabilityContentViewed({
        dealershipId,
        consumerDutyContent
      });
    }
  }, [consumerDutyContent, dealershipId]);
};

export const useTrackPageViewed = (pageName: string, dealershipId: string) => {
  useEffect(() => {
    trackPageViewed(pageName, dealershipId);
  }, [pageName, dealershipId]);
};
