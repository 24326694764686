import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  delete draft.applicationStatuses!.NotProceeding;
  delete draft.applicationStatuses!.Other;
  delete draft.applicationStatuses!.ProblemPayout;
  delete draft.applicationStatuses!.Prospect;
  delete draft.applicationStatuses!.SentForPayout;
  delete draft.applicationStatuses!.Unknown;
  draft.applicationStatuses.Accepted!.isEditable = false;
  draft.applicationStatuses.ConditionalAccept!.isEditable = false;
  draft.applicationStatuses.WaitingForFurtherInformation!.isEditable = false;
  draft.applicationStatuses.WaitingForFurtherInformation!.isReproposable = true;
  draft.applicationStatuses.WaitingForFurtherInformation!.isComplete = true;
  draft.applicationStatuses.Error!.isEditable = false;
  draft.applicationStatuses.Error!.isReproposable = false;
  draft.applicationStatuses.NoDecisionYet!.isEditable = false;
  draft.applicationStatuses.NotTakenUp!.isEditable = false;
  draft.applicationStatuses.Pending!.isEditable = false;
  draft.applicationStatuses.Pending!.isPending = true;
  draft.applicationStatuses.Referred!.isEditable = false;
  draft.applicationStatuses.Referred!.isReproposable = false;
  draft.applicationStatuses.Rejected!.isEditable = false;
  draft.applicationStatuses.Submitted!.isEditable = false;
  draft.applicationStatuses.Submitted!.isSubmitting = true;
  draft.applicationFields.AffordabilityDetails!.NetMonthly = false;
  draft.applicationFields.AffordabilityDetails!.ForeseeFutureDownturnInFinances = false;
  draft.applicationFields.AffordabilityDetails!.DownturnReason = false;
  draft.applicationFields.AffordabilityDetails!.IsReplacementLoan = false;
  draft.applicationFields.AffordabilityDetails!.DetailsOfReplacementLoan = false;

  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances;
  delete draft.validationRules.AffordabilityDetails!.ReplacementLoan;
  draft.validationRules.AffordabilityDetails!.Income!.GrossAnnual = 'required,currency';
});
