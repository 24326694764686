import RadioButtonGroup from 'components/Common/Form/RadioButtonGroup';
import MobxFieldGroup from 'components/MobxForm/MobxFieldGroup';
import MobxFormLabel from 'components/MobxForm/MobxFormLabel';
import { useTranslation } from 'react-i18next';
import { getLenderVapValue } from 'utils/quoteUtils';

const createLenderVaps = ({ gap, rsv }) => {
  let newLenderVaps = [];

  if (gap) {
    newLenderVaps.push({
      Name: gap,
      ProductTypeText: 'GAP',
      Price: 0
    });
  }

  if (rsv) {
    newLenderVaps.push({
      Name: rsv,
      ProductTypeText: 'RSV',
      Price: 0
    });
  }

  return newLenderVaps;
};

const BDKLenderVaps = ({ LenderVaps, onChange }) => {
  const { t } = useTranslation('Quoting');
  const gap = getLenderVapValue(LenderVaps, 'GAP');
  const rsv = getLenderVapValue(LenderVaps, 'RSV');
  const rsvOptions = [
    { value: '', label: t('BDKLenderVaps.no_rsv') },
    { value: 'RSV', label: t('BDKLenderVaps.rsv') },
    { value: 'RSVPlus', label: t('BDKLenderVaps.rsv_plus') }
  ];

  return (
    <>
      <MobxFieldGroup isInline>
        <MobxFormLabel htmlFor="Gap">Gap</MobxFormLabel>
        <RadioButtonGroup
          variant="inline"
          options={[
            { value: '', label: t('BDKLenderVaps.no_gap') },
            { value: 'GAP', label: t('BDKLenderVaps.gap') },
            { value: 'GAPPlus', label: t('BDKLenderVaps.gap_plus') }
          ]}
          name="Gap"
          checkedValue={gap}
          onChange={(e) => onChange(createLenderVaps({ gap: e.target.value, rsv }))}
        />
      </MobxFieldGroup>

      <MobxFieldGroup isInline>
        <MobxFormLabel htmlFor="RSV">RSV</MobxFormLabel>
        <RadioButtonGroup
          variant="inline"
          options={rsvOptions}
          name="RSV"
          checkedValue={rsv}
          onChange={(e) => onChange(createLenderVaps({ gap, rsv: e.target.value }))}
        />
      </MobxFieldGroup>
    </>
  );
};

export default BDKLenderVaps;
