import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import Breadcrumbs from '../../Common/Breadcrumbs';
import ConsumerVehicleInfoPanel from '../../Common/ConsumerVehicleInfoPanel';
import InformationWarning from '../../Common/InformationWarning';
import ApplicationFormWrapper from '../../Common/layouts/ApplicationFormWrapper';
import ApplicationNavigationWrapper from '../../Common/layouts/ApplicationNavigationWrapper';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import PanelHeader from '../../Common/PanelHeader';
import PanelToolBar from '../../Common/PanelToolBar';
import ApplicationNavigation from './ApplicationNavigation';
import DateFormat from '../../Common/DateFormat';

import './applicationFormlayout.scss';
import { withRouter } from 'hocs/router';
import { Outlet } from 'react-router-dom';
import { compose } from 'redux';

class ApplicationFormLayout extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const scrollOpts = {
        top: 0,
        behaviour: 'smooth'
      };

      if (window && window.scrollTo) {
        window.scrollTo(scrollOpts);
      }
    }
  }

  getBreadCrumbs = () => {
    let crumbs = [
      {
        name: this.props.t('ApplicationFormLayout.home'),
        path: `/d/${this.props.params.dealershipId}`
      }
    ];

    if (this.props.query.order) {
      crumbs.push({
        name: this.props.t('ApplicationFormLayout.orders_deals'),
        path: `/d/${this.props.params.dealershipId}/orders-and-deals`,
        query: {
          page: 1,
          pageSize: 5,
          dealStatus: 'all',
          sortBy: 'sentAt'
        }
      });
      crumbs.push({
        name: this.props.t('ApplicationFormLayout.order_summary'),
        path: `/d/${this.props.params.dealershipId}/orders-and-deals/order/${this.props.query.order}`
      });
    } else {
      crumbs.push({
        name: this.props.t('ApplicationFormLayout.customer_list'),
        path: `/d/${this.props.params.dealershipId}/consumers`
      });
      crumbs.push({
        name: this.props.t('ApplicationFormLayout.consumer'),
        path: `/d/${this.props.params.dealershipId}/consumers/${this.props.consumer.Id}`
      });
    }

    crumbs.push({
      name: this.props.t('ApplicationFormLayout.application_form')
    });
    return crumbs;
  };

  render() {
    const { ...childrenProps } = this.props;

    childrenProps.progress = this.props.application.progress;

    return (
      <Page>
        <Breadcrumbs items={this.getBreadCrumbs()} consumer={this.props.consumer} />
        <ConsumerVehicleInfoPanel vehicle={this.props.vehicle} />
        <Panel>
          <PanelHeader>{this.props.t('ApplicationFormLayout.application_form')}</PanelHeader>
          {this.props.application.Tags && this.props.application.Tags.multiQuoteRefId && (
            <PanelToolBar>
              <div className="applicationFormlayout__applicationNumber">
                <InformationWarning>
                  {this.props.t('ApplicationFormLayout.part_of_a_deal_created')}{' '}
                  <DateFormat value={this.props.application.CreatedDate} format="DD/MM/YYYY - HH:mm" />
                </InformationWarning>
              </div>
            </PanelToolBar>
          )}
          <div className="application__outer">
            <ApplicationNavigationWrapper>
              <ApplicationNavigation
                progress={this.props.application.progress}
                applicationType={this.props.application.CustomerType}
              />
            </ApplicationNavigationWrapper>
            <ApplicationFormWrapper>
              <Outlet context={childrenProps} />
            </ApplicationFormWrapper>
          </div>
        </Panel>
      </Page>
    );
  }
}

ApplicationFormLayout.propTypes = {
  dispatch: PropTypes.func,
  application: PropTypes.object,
  dealership: PropTypes.object,
  consumer: PropTypes.object,
  options: PropTypes.object,
  vehicle: PropTypes.object,
  validationRules: PropTypes.object,
  applicationFields: PropTypes.object
};

export default compose(withRouter, withTranslation('Application'))(ApplicationFormLayout);
