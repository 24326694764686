export type Dataset = {
  avg: number[];
  label: DataSetLabel;
  max: number[];
  min: number[];
  sum: number[];
  users: number[];
  dimension?: string;
};

export type DataSetLabel = SeriesType | string;
export type ApiResponse = {
  lastModified: string;
  points: string[];
  datasets: Dataset[];
};

export enum SeriesTypeEnum {
  'count' = 'count',
  'count online' = 'count online',
  'count showroom' = 'count showroom'
}

export type SeriesType = keyof typeof SeriesTypeEnum;
