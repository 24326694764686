import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { canValidatePhoneNumbersWithLibPhoneNumber } from '~/features';
import appStore from '~/mobx-stores/appStore';

// Formats phone number to either national or international format depending on whether country code is present
// e.g. Internation -> +44 161 790 6666
// e.g. National -> 0161 790 6666
export const formatPhoneNumber = (value: string) => {
  if (canValidatePhoneNumbersWithLibPhoneNumber() === false || value === '') {
    return value;
  }

  // @ts-ignore
  const countryCode = appStore.uiState.countryCode.toUpperCase();
  const phoneUtil = PhoneNumberUtil.getInstance();

  const isNumber = phoneUtil.isPossibleNumberString(value, countryCode);
  if (!isNumber) {
    return value;
  }

  const phoneNo = phoneUtil.parseAndKeepRawInput(value, countryCode);
  const countryCodeSource = phoneNo.getCountryCodeSource();

  return phoneUtil.format(
    phoneNo,
    countryCodeSource === PhoneNumber.CountryCodeSource.FROM_DEFAULT_COUNTRY
      ? PhoneNumberFormat.NATIONAL
      : PhoneNumberFormat.INTERNATIONAL
  );
};
