import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import FormFooter from '../../Common/Form/FormFooter';
import PostcodeLookup from '../../Common/PostcodeLookup/PostcodeLookup';
import YearsMonthsField from '../../Common/Form/YearsMonthsField';
import ApplicationCard from '../components/ApplicationCard';
import MiniPanel from '../../Common/MiniPanel';
import MiniPanelHeader from '../../Common/MiniPanelHeader';
import ExclamationWarningWithButton from '../../Common/ExclamationWarningWithButton';
import Button from '../../Common/Button/Button';
import Validator from '../../../validators/Validator';
import { observer } from 'mobx-react';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import SelectInput from '../../Common/Form/SelectInput';
import { calculateTotalMonthsFromCollection } from '../../../core/helpers';
import './businessAddressForm.scss';

class BusinessAddressForm extends React.Component {
  UNSAFE_componentWillMount() {
    this.setupValidation(this.props.validationRules.Items[0]);
    this.setInitialFormData(this.props.defaultValues);
  }

  defaultFormData() {
    return {
      BuildingName: '',
      BuildingNumber: '',
      Country: '',
      County: '',
      District: '',
      Organisation: '',
      PostTown: '',
      Postcode: '',
      PremisesType: '',
      Street: '',
      SubBuilding: '',
      TimeAtAddress: {
        Months: 0,
        TotalMonths: 0,
        Years: 0
      },
      isEditing: true
    };
  }

  setInitialFormData = (data) => {
    const Items = {
      Items: [this.defaultFormData()]
    };
    const newData = data && data.Items.length > 0 ? data : Items;
    this.setState(
      () => {
        const defaultData = {
          formData: newData,
          isFormSubmitted: false
        };
        defaultData.formData.Items.length > 1
          ? this.setEditingFalse()
          : (defaultData.formData.Items[0].isEditing = true);
        return { ...defaultData };
      },
      () => {
        this.validator.validate(this.state.formData.Items[this.getEditingIndex()]);
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.validationRules !== prevProps.validationRules) {
      this.setupValidation(this.props.validationRules.totalMonths);
    }
  }

  setupValidation(fieldValidationRules) {
    this.validator = new Validator();
    this.validator.setRules(fieldValidationRules);
  }

  getEditingIndex() {
    let currentlyEditingItem;
    this.state.formData.Items.forEach((item, i) => {
      if (item.isEditing === true) {
        currentlyEditingItem = i;
      }
    });
    return currentlyEditingItem;
  }

  handleFieldChange = (id, value, index) => {
    this.setState((prevState) => {
      const currentState = prevState.formData.Items[index];
      const newState = Object.assign(currentState, {
        [id]: value
      });
      this.validator.validate(newState);
      return { ...newState };
    });
  };
  handleFieldChangeYM = (id, value, index) => {
    let years = this.state.formData.Items[index].TimeAtAddress.Years;
    let months = this.state.formData.Items[index].TimeAtAddress.Months;

    if (value > 11) {
      years = Math.floor(value / 12);
      months = value % 12;
    } else {
      years = 0;
      months = value;
    }

    this.setState((prevState) => {
      const currentState = prevState.formData.Items[index];
      Object.assign(currentState, {
        ...currentState,
        TimeAtAddress: {
          [id]: value,
          Years: years,
          Months: months
        }
      });
      this.validator.validate(currentState);
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          totalMonths: calculateTotalMonthsFromCollection(prevState.formData.Items, 'TimeAtAddress')
        }
      };
    });
  };
  handleAddItem = () => {
    this.setState((prevState) => {
      const newArray = prevState.formData.Items.map((item) => ({ ...item, isEditing: false }));
      this.defaultFormData && newArray.push(this.defaultFormData());
      return {
        ...prevState,
        formData: { ...prevState.formData, Items: newArray },
        showTimeAtAddressWarning: false,
        isFormSubmitted: false
      };
    });
    this.setErrorsToState();
  };
  handleRemoveAddress = (index) => {
    let itemsCopy = this.state.formData.Items;
    itemsCopy = itemsCopy.filter((item, i) => i !== index);
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        Items: itemsCopy,
        totalMonths: calculateTotalMonthsFromCollection(itemsCopy, 'TimeAtAddress')
      }
    }));
    this.validator.validate(this.state.formData.Items[this.getEditingIndex()]);
  };
  setEditingFalse = () => {
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        Items: prevState.formData.Items.map((item) => ({ ...item, isEditing: false }))
      }
    }));
  };
  handleEditAddress = (index) => {
    this.setEditingFalse();
    this.setState((prevState) => {
      const newState = [...prevState.formData.Items];
      const currentState = prevState.formData.Items[index];
      const updatedItem = { ...currentState, isEditing: true };
      newState[index] = updatedItem;
      this.validator.validate(updatedItem);
      return { ...prevState, formData: { ...prevState.formData, Items: newState } };
    });
  };
  handlePostCodeLookup = (address, index) => {
    this.setState((prevState) => {
      const newArray = [...prevState.formData.Items];
      const updatedItem = Object.assign({}, newArray[index], address);
      newArray[index] = updatedItem;
      this.validator.validate(updatedItem);
      return { ...prevState, formData: { ...prevState.formData, Items: newArray } };
    });
  };
  setErrorsToState = () => {
    let newState = this.state.formData.Items;

    if (this.state.formData.Items.length) {
      this.setState((prevState) => {
        prevState.formData.Items.forEach((currentItem, i) => {
          this.validator.validate(currentItem);
          const updatedItem = Object.assign(currentItem, {
            validationErrors: this.validator.getErrors()
          });
          newState.splice(i, 1, updatedItem);
        });
        return { ...prevState, formData: { ...prevState.formData, Items: [...newState] } };
      });
    }

    this.validator.validate(this.state.formData.Items[this.getEditingIndex()]);
  };
  countErrors = (index, count = 0) => {
    const validationErrors = this.state.formData.Items[index].validationErrors;

    if (typeof validationErrors === 'object') {
      return (count = Object.keys(validationErrors).length);
    } else {
      return 0;
    }
  };
  onSave = () => {
    if (this.state.formData.totalMonths < 36) {
      this.setState(
        {
          showTimeAtAddressWarning: true,
          isFormSubmitted: true
        },
        () => this.props.onSave(this.state.formData)
      );
    } else {
      this.setState(
        {
          showTimeAtAddressWarning: false,
          isFormSubmitted: true
        },
        () => this.props.onSave(this.state.formData)
      );
    }

    this.setErrorsToState();
  };
  submitForm = () => {
    if (this.state.formData.totalMonths < 36) {
      this.setState({
        showTimeAtAddressWarning: true,
        isFormSubmitted: true
      });
    } else {
      this.setState(
        {
          showTimeAtAddressWarning: false,
          isFormSubmitted: true
        },
        () => !this.validator.errorCount && this.props.onSubmit(this.state.formData)
      );
    }

    this.setErrorsToState();
  };

  render() {
    const errors = this.validator.getErrors();
    return (
      <MobxForm onSubmit={this.submitForm} autoComplete="off">
        {this.state.formData.Items.map((value, index) => {
          return (
            <MiniPanel key={'mini_' + index}>
              <MiniPanelHeader title={'Address ' + (index + 1)} />
              {value.isEditing === true ? (
                <div key={'editForm' + index} ref={'formInner' + index}>
                  <div className="businessAddressForm" key={'formContainer' + index}>
                    <PostcodeLookup
                      inline
                      addressType="corporate"
                      onSelection={(val) => this.handlePostCodeLookup(val, index)}
                      defaultValue={value.Postcode}
                      dealershipId={this.props.dealershipId}
                      quoteId={this.props.quoteId}
                    />

                    <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.BuildingName}>
                      <MobxFormLabel>
                        <div>{this.props.t('BusinessAddressForm.building_name')}</div>
                      </MobxFormLabel>
                      <TextInputWrappingInput
                        value={value.BuildingName}
                        onChange={(val) => this.handleFieldChange('BuildingName', val, index)}
                      />
                    </MobxFieldGroup>
                    <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.BuildingNumber}>
                      <MobxFormLabel>
                        <div>{this.props.t('BusinessAddressForm.building_number')}</div>
                      </MobxFormLabel>
                      <TextInputWrappingInput
                        value={value.BuildingNumber}
                        onChange={(val) => this.handleFieldChange('BuildingNumber', val, index)}
                      />
                    </MobxFieldGroup>
                    <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.SubBuilding}>
                      <MobxFormLabel>
                        <div>{this.props.t('BusinessAddressForm.room_floor')}</div>
                      </MobxFormLabel>
                      <TextInputWrappingInput
                        value={value.SubBuilding}
                        onChange={(val) => this.handleFieldChange('SubBuilding', val, index)}
                      />
                    </MobxFieldGroup>
                    <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.Street}>
                      <MobxFormLabel>
                        <div>{this.props.t('BusinessAddressForm.street')}</div>
                      </MobxFormLabel>
                      <TextInputWrappingInput
                        value={value.Street}
                        onChange={(val) => this.handleFieldChange('Street', val, index)}
                      />
                    </MobxFieldGroup>
                    <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.District}>
                      <MobxFormLabel>
                        <div>{this.props.t('BusinessAddressForm.district')}</div>
                      </MobxFormLabel>
                      <TextInputWrappingInput
                        value={value.District}
                        onChange={(val) => this.handleFieldChange('District', val, index)}
                      />
                    </MobxFieldGroup>
                    <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.PostTown}>
                      <MobxFormLabel>
                        <div>{this.props.t('BusinessAddressForm.city_town')}</div>
                      </MobxFormLabel>
                      <TextInputWrappingInput
                        value={value.PostTown}
                        onChange={(val) => this.handleFieldChange('PostTown', val, index)}
                      />
                    </MobxFieldGroup>
                    <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.Postcode}>
                      <MobxFormLabel>
                        <div>{this.props.t('BusinessAddressForm.postcode')}</div>
                      </MobxFormLabel>
                      <TextInputWrappingInput
                        value={value.Postcode}
                        onChange={(val) => this.handleFieldChange('Postcode', val, index)}
                      />
                    </MobxFieldGroup>
                    <MobxFieldGroup isInline isFormSubmitted={this.state.isFormSubmitted} error={errors.PremisesType}>
                      <MobxFormLabel>
                        <div>{this.props.t('BusinessAddressForm.premises_type')}</div>
                      </MobxFormLabel>
                      <SelectInput
                        id="PremisesType"
                        options="PremisesType"
                        value={value.PremisesType}
                        onChange={(id, val) => this.handleFieldChange(id, val, index)}
                      />
                    </MobxFieldGroup>
                    <MobxFieldGroup
                      isInline
                      isFormSubmitted={this.state.isFormSubmitted}
                      error={errors.TimeAtAddress && errors.TimeAtAddress.TotalMonths}
                    >
                      <MobxFormLabel>
                        <div>{this.props.t('BusinessAddressForm.time_at_address')}</div>
                      </MobxFormLabel>
                      <YearsMonthsField
                        id="TimeAtAddress"
                        name="TotalMonths"
                        value={value.TimeAtAddress.TotalMonths}
                        onChange={(val) => this.handleFieldChangeYM('TotalMonths', val, index)}
                      />
                    </MobxFieldGroup>
                  </div>
                  <div className="businessAddressForm__removeContainer">
                    <div className="businessAddressForm__remove">
                      {this.state.formData.Items.length > 1 && (
                        <Button
                          type="button"
                          buttonStyle="cancel"
                          size="small"
                          key={'remove_' + index}
                          onClick={() => this.handleRemoveAddress(index)}
                        >
                          {this.props.t('BusinessAddressForm.remove')}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <ApplicationCard
                  type="address"
                  data={value}
                  ref={'formInner' + index}
                  edit={() => this.handleEditAddress(index)}
                  remove={() => this.handleRemoveAddress(index)}
                  showRemove={this.state.formData.Items.length > 1 ? true : false}
                  errorCount={this.countErrors(index)}
                  submitted={this.state.formSubmitted}
                  key={'card' + index}
                />
              )}
            </MiniPanel>
          );
        })}

        {this.state.showTimeAtAddressWarning && (
          <ExclamationWarningWithButton
            title={this.props.t('BusinessAddressForm.thank_you')}
            message={this.props.t('BusinessAddressForm.but_you_need_to_give_at_least_3_years_of_address_history')}
            onClick={this.handleAddItem}
            buttonText={this.props.t('BusinessAddressForm.add_new_address')}
          />
        )}

        <FormFooter
          onSave={this.onSave}
          submittingState={this.props.submittingState}
          savingState={this.props.savingState}
        />
      </MobxForm>
    );
  }
}

BusinessAddressForm.propTypes = {
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  validationRules: PropTypes.object.isRequired,
  savingState: PropTypes.string.isRequired,
  submittingState: PropTypes.string,
  dealershipId: PropTypes.string,
  quoteId: PropTypes.string
};
export default withTranslation('Application')(observer(BusinessAddressForm));
