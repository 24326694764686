import PropTypes from 'prop-types';
import MiniPanel from '../Common/MiniPanel';
import MiniPanelHeader from '../Common/MiniPanelHeader';
import VehiclePanel from '../Reporting/PartialSummarySections/VehiclePanel';
import PersonalDetailsPanel from '../Reporting/PartialSummarySections/PersonalDetailsPanel';
import AddressHistoryPanel from '../Reporting/PartialSummarySections/AddressHistoryPanel';
import EmploymentHistoryPanel from './EmploymentHistoryPanel';
import AccessoriesPanel from '../Reporting/PartialSummarySections/AccessoriesPanel';
import DealerInfo from '../Common/DealerInfo';
import { hasCompanionQuotes } from '~/features';
import { findCompanionQuoteByType } from '~/components/QuoteCard/utils/getQuoteDisplaySchema';
import { LoanTypeJs, QuoteSummaryVariantTypeJs } from '~/components/QuoteCard/types';
import QuotePanelV2 from '~/components/Reporting/PartialSummarySections/QuotePanelV2';

import './cfcCheckDetailsPanels.scss';

const CfcCheckDetailsPanels = ({ dealership, cfcCheckDetails }) => {
  const dealer = dealership;
  const checks = cfcCheckDetails;
  const quote = checks.Quote;

  let vehicleLoan;
  let vapsLoan;
  let negativeEquityLoan;
  if (hasCompanionQuotes(quote)) {
    vehicleLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanTypeJs.vehicleLoan);
    vapsLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanTypeJs.vapsLoan);
    negativeEquityLoan = findCompanionQuoteByType(quote.CompanionQuotes, LoanTypeJs.negativeEquityLoan);
  }

  return (
    <div className="cfcCheckDetailsPanels__outer">
      <DealerInfo dealership={dealer} />
      <MiniPanel>
        <MiniPanelHeader title="Vehicle Details" />
        <VehiclePanel vehicle={checks.Vehicle} quote={checks.Quote} />
      </MiniPanel>
      {quote.Insurance === 0 && quote.NonVatableItems === 0 && quote.OtherAccessories === 0 && quote.Warranty === 0 ? (
        ''
      ) : (
        <MiniPanel>
          <MiniPanelHeader title="Vehicle Accessories" />
          <AccessoriesPanel quote={checks.Quote} />
        </MiniPanel>
      )}
      <MiniPanel>
        <MiniPanelHeader title="Personal Details" />
        <PersonalDetailsPanel details={checks.PersonalDetails} />
      </MiniPanel>
      {/* Standard or Aggregate Quote Start */}
      <MiniPanel>
        <MiniPanelHeader title="Quote Details" />
        <QuotePanelV2
          quote={quote}
          loanType={LoanTypeJs.standardLoan}
          variant={QuoteSummaryVariantTypeJs.CarFinanceCheckSummary}
        />
      </MiniPanel>
      {/* Standard or Aggregate Quote END */}

      {/* Companion Quotes START */}
      {vehicleLoan && (vapsLoan || negativeEquityLoan) && (
        <>
          <div className="SelfServiceQuote__MultiFinance">
            This Quote is made up of Multiple Finance Components that will be collected as one payment
          </div>
          <MiniPanel>
            <MiniPanelHeader title="Vehicle" />
            <QuotePanelV2
              quote={vehicleLoan}
              loanType={LoanTypeJs.vehicleLoan}
              variant={QuoteSummaryVariantTypeJs.CarFinanceCheckSummary}
              hasNegativeEquityLoan={negativeEquityLoan !== undefined}
            />
          </MiniPanel>
        </>
      )}
      {negativeEquityLoan && (
        <MiniPanel>
          <MiniPanelHeader title="Negative Equity" />
          <QuotePanelV2
            quote={negativeEquityLoan}
            loanType={LoanTypeJs.negativeEquityLoan}
            variant={QuoteSummaryVariantTypeJs.CarFinanceCheckSummary}
          />
        </MiniPanel>
      )}
      {vapsLoan && (
        <MiniPanel>
          <MiniPanelHeader title="Value Added Products" />
          <QuotePanelV2
            quote={vapsLoan}
            loanType={LoanTypeJs.vapsLoan}
            variant={QuoteSummaryVariantTypeJs.CarFinanceCheckSummary}
          />
        </MiniPanel>
      )}
      {/* Companion Quotes END */}
      <MiniPanel>
        <MiniPanelHeader title="Address History" />
        <AddressHistoryPanel addresses={checks.AddressHistory} />
      </MiniPanel>
      <MiniPanel>
        <MiniPanelHeader title="Employment History" />
        <EmploymentHistoryPanel employment={checks.EmploymentHistory} />
      </MiniPanel>
    </div>
  );
};

CfcCheckDetailsPanels.propTypes = {
  dealership: PropTypes.object,
  cfcCheckDetails: PropTypes.object
};

export default CfcCheckDetailsPanels;
