import platformApiUtils from '@ivendi/platform-api-utils';
import uuid from 'uuid';
import { trackVehicleFinanceApplicationStarted } from '../tracking/avoTracking';

export const corporateQuickQuote = (quickQuote, requestType) => {
  const applicationId = uuid.v4();
  const customerId = uuid.v4();
  const vehicleId = uuid.v4();

  return new Promise(function(resolve, reject) {
    platformApiUtils
      .post('v2/quick-quote', {
        ApplicationId: applicationId,
        CustomerId: customerId,
        VehicleId: vehicleId,
        ApplicationRequestType: requestType,
        
        DealershipId: quickQuote.dealershipId,
        CustomerType: 'corporate',
        QuoteId: quickQuote.quote.QuoteId,

        CapId: quickQuote.vehicle.CapId,
        MakeId: quickQuote.vehicle.MakeId,
        ModelId: quickQuote.vehicle.ModelId,
        CapMakeId: quickQuote.vehicle.CapMakeId,
        CapModelId: quickQuote.vehicle.CapModelId,
        Make: quickQuote.vehicle.Make,
        Model: quickQuote.vehicle.Model,
        Derivative: quickQuote.vehicle.Derivative,
        Class: quickQuote.vehicle.Class,
        Condition: quickQuote.vehicle.Condition,
        Vrm: quickQuote.vehicle.Vrm,
        Vin: quickQuote.vehicle.Vin,
        SanitizedVrm: quickQuote.vehicle.SanitizedVrm,
        RegistrationDate: quickQuote.vehicle.RegistrationDate,
        Mileage: quickQuote.vehicle.Mileage,
        CdnVehicleImageUrl: quickQuote.vehicle.CdnVehicleImageUrl,

        TradingName: quickQuote.tradingName,
        Title: quickQuote.title,
        Firstname: quickQuote.firstName,
        Middlenames: quickQuote.middleName,
        Surname: quickQuote.lastName,
        Position: quickQuote.position,
        Email: quickQuote.email,
        Landline: quickQuote.businessTelephone,
        Mobile: quickQuote.mobileTelephone,
        AssignToSelf: quickQuote.assignToSelf

        //api doesn't return CustomerId when saving corporate quick quote, pretend it does
        //so we can navigate to the customer record / application page afterwards
      })
      .then((response) => {
        trackVehicleFinanceApplicationStarted({
          dealershipId: quickQuote.dealershipId,
          financeFunderCode: quickQuote.quote.FunderCode,
          financeFunderProductId: quickQuote.quote.FunderProductUID,
          financeProductType: quickQuote.quote.FinanceType,
          financeApplicationId: applicationId
        });
        return resolve(
          Object.assign(
            {},
            response,
            { ApplicationId: applicationId },
            requestType === 1 ? { CustomerId: customerId } : undefined
          )
        );
      })
      .catch((error) => reject(error));
  });
};
