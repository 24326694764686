import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserHeader from './UserHeader';
import DealershipDropdown from './DealershipDropdown';
import QuickQuoteBar from './QuickQuoteBar';
import './header.scss';
import { observer, inject } from 'mobx-react';
import _ from 'lodash';
import { compose } from 'redux';
import { withParams } from 'hocs/router';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
      isQuickQuoteOpen: false
    };
  }

  handleQuickQuoteStateChange = (isOpen) => {
    this.setState({
      isQuickQuoteOpen: isOpen,
      isDropdownOpen: false
    });
  };
  handleDealershipDropdownStateChange = (isOpen) => {
    this.setState({
      isDropdownOpen: isOpen,
      isQuickQuoteOpen: false
    });
  };

  render() {
    let uiState = this.props.appStore.uiState;
    let logo = 'ivendi';
    let ivendiLink = '//ivendi.com/';

    if (uiState.hasMotonovoTheme && !uiState.hasDefaultTheme) {
      logo = 'motonovo';
    } else if (uiState.hasBnppTheme && !uiState.hasDefaultTheme) {
      logo = 'bnpp';
    } else if (uiState.hasBdkTheme) {
      logo = 'bdk';
      ivendiLink = '//ivendi.com/de';
    }

    let logoMove = this.props.desktopsidebarIsOpen ? 'header__logoAnimationEnd' : '';

    const settings = _.chain(this.props.dealerships)
      .find({
        Id: this.props.params.dealershipId
      })
      .get('Settings')
      .value();

    return (
      <div className="header" data-th="Header">
        <div className={`header__logo header__${logo}Logo header__logoAnimationStart ${logoMove}`}>
          <span className={`header__${logo}LogoInner`}>
            <Link to={`/d/${this.props.currentDealershipId}`} className="header__logoLink" />
          </span>
          {logo !== 'ivendi' && (
            <div className="header__poweredBy">
              <p className="header__poweredByText">
                {this.props.t('Header.powered_by')}{' '}
                <a className="header__poweredByLink" href={ivendiLink}>
                  iVendi
                </a>
              </p>
            </div>
          )}
        </div>

        <div className="header__centerSection">
          {this.props.appStore.uiState.canGenerateQuotes && !this.props.appStore.uiState.isBdk && (
            <QuickQuoteBar
              onStateChange={this.handleQuickQuoteStateChange}
              isOpen={this.state.isQuickQuoteOpen}
              dealershipId={this.props.currentDealershipId}
              vehicleCondition={settings.VehicleType}
              vehicleClass={settings.VehicleClass}
            />
          )}
        </div>
        {this.props.dealerships.length > 1 ? (
          <DealershipDropdown
            onStateChange={this.handleDealershipDropdownStateChange}
            isOpen={this.state.isDropdownOpen}
            dealerships={this.props.dealerships}
            current={this.props.currentDealershipId}
          />
        ) : (
          ''
        )}
        <UserHeader
          firstName={this.props.firstName}
          lastName={this.props.lastName}
          dealerships={this.props.dealerships}
          current={this.props.currentDealershipId}
        />
      </div>
    );
  }
}

Header.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  dealerships: PropTypes.array,
  currentDealershipId: PropTypes.string,
  onChangeDealership: PropTypes.func,
  params: PropTypes.object,
  appStore: PropTypes.object
};

export default compose(withParams, withTranslation('Header'), inject(['appStore']), observer)(Header);
