import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import MobxForm from '../../MobxForm/MobxForm';
import MobxFormFieldGroup from '../../MobxForm/MobxFieldGroup';
import MobxFormLabel from '../../MobxForm/MobxFormLabel';
import TextInputWrappingInput from '../../Common/Form/TextInputWrappingInput';
import { action, reaction, extendObservable } from 'mobx';
import { observer, inject } from 'mobx-react';
import Validator from '../../../validators/Validator';
import SelectInput from '../../Common/Form/SelectInput';
import PostcodeLookup from '../../Common/PostcodeLookup/PostcodeLookup';
import Button from '../../Common/Button/Button';
import SplitButton from '../../Common/SplitButton';
import SplitButtonOptionDefault from '../../Common/SplitButtonOptionDefault';
import SplitButtonOption from '../../Common/SplitButtonOption';
import './editConsumerForm.scss';
import TradingNameLookup from '../../Common/TradingNameLookup';
import EmailLookup from '../../Common/EmailLookup';
import './editCorporateForm.scss';
import { trimDeepObjectStrings } from '../../../core/helpers';
import { compose } from 'redux';
import { withNavigate } from '../../../hocs/router';
import { PhoneNumberInput } from '~/components/Common/Form/PhoneNumberInput';
class EditCorporateForm extends React.Component {
  constructor(props) {
    super();
    const validationRules = {
      title: 'required',
      firstName: 'required, maxlength:50',
      middleName: 'maxlength:50',
      surname: 'required, maxlength:50',
      position: 'required',
      email: 'required, email',
      landline: 'required:unless:mobile, businessPhone',
      mobile: 'required:unless:landline, mobile',
      faxNumber: 'phone',
      tradingName: 'required, maxlength:75',
      buildingName: 'maxlength:75',
      buildingNumber: 'maxlength:75',
      subBuilding: 'maxlength:75',
      street: 'maxlength:75, lenderAlphanumeric',
      district: 'maxlength:75, lenderAlphanumeric',
      postTown: 'maxlength:75',
      postCode: 'alphaNumSpace'
    };
    extendObservable(this, {
      formData: {
        customerType: 'corporate',
        email: props.customer.Email,
        title: props.customer.Title,
        firstName: props.customer.Firstname,
        middleName: props.customer.Middlenames,
        surname: props.customer.Surname,
        landline: props.customer.Landline,
        mobile: props.customer.Mobile,
        buildingName: props.customer.Address.BuildingName,
        buildingNumber: props.customer.Address.BuildingNumber,
        subBuilding: props.customer.Address.SubBuilding,
        street: props.customer.Address.Street,
        district: props.customer.Address.District,
        postTown: props.customer.Address.PostTown,
        postCode: props.customer.Address.Postcode,
        position: props.customer.Position,
        faxNumber: props.customer.FaxNumber,
        tradingName: props.customer.TradingName,
        company: props.customer.CompanyName
      }
    });
    this.setUpValidation(validationRules);
  }

  setUpValidation(validationRules) {
    this.validator = new Validator();
    this.validator.setRules(validationRules);
    this.validationReactionDisposer = reaction(() => ({ ...this.formData }), this.validator.validate, {
      fireImmediately: true
    });
  }

  @action
  cleanupFormData = () => {
    this.formData = trimDeepObjectStrings(this.formData);
    this.validator.validate(this.formData);
  };
  handleSubmit = () => {
    const {
      appStore: { customerListStore }
    } = this.props;
    this.cleanupFormData();

    if (!this.validator.errorCount) {
      customerListStore.updateCustomer(this.formData, this.props.customer.Id).then(() => {
        this.props.navigate(`/d/${this.props.dealershipId}/consumers/${this.props.customer.Id}`);
      });
    }
  };
  handleDeleteCustomer = () => {
    this.props.navigate(`/d/${this.props.dealershipId}/consumers/${this.props.customer.Id}/deletecustomer`);
  };
  handleCancel = () => {
    this.props.navigate(-1);
  };

  componentWillUnmount() {
    this.validationReactionDisposer();
  }

  @action
  handlePostCodeLookup = (address) => {
    this.formData.company = address.Company;
    this.formData.buildingName = address.BuildingName;
    this.formData.buildingNumber = address.BuildingNumber;
    this.formData.subBuilding = address.SubBuilding;
    this.formData.street = address.Street;
    this.formData.district = address.District;
    this.formData.postTown = address.PostTown;
    this.formData.postCode = address.Postcode;
    this.cleanupFormData();
  };
  @action
  setEmail = (email) => (this.formData.email = email);
  @action
  setTitle = (fieldName, title) => (this.formData.title = title);
  @action
  setFirstName = (firstName) => (this.formData.firstName = firstName);
  @action
  setMiddleName = (middleName) => (this.formData.middleName = middleName);
  @action
  setSurname = (surname) => (this.formData.surname = surname);
  @action
  setPosition = (fieldName, position) => (this.formData.position = position);
  @action
  setLandline = (landline) => (this.formData.landline = landline);
  @action
  setMobile = (mobile) => (this.formData.mobile = mobile);
  @action
  setFaxNumber = (faxNumber) => (this.formData.faxNumber = faxNumber);
  @action
  setTradingName = (tradingName) => (this.formData.tradingName = tradingName);
  @action
  setCompany = (company) => (this.formData.company = company);
  @action
  setBuildingName = (buildingName) => (this.formData.buildingName = buildingName);
  @action
  setBuildingNumber = (buildingNumber) => (this.formData.buildingNumber = buildingNumber);
  @action
  setSubBuilding = (subBuilding) => (this.formData.subBuilding = subBuilding);
  @action
  setStreet = (street) => (this.formData.street = street);
  @action
  setDistrict = (district) => (this.formData.district = district);
  @action
  setPostTown = (postTown) => (this.formData.postTown = postTown);
  @action
  setPostCode = (postCode) => (this.formData.postCode = postCode);

  render() {
    const errors = this.validator.getErrors();
    const {
      appStore: { customerListStore, uiState }
    } = this.props;
    const loadingState = customerListStore.updateCustomerRequest.isLoading ? 'loading' : '';
    const funderCode = uiState.getPlatformCode;

    return (
      <MobxForm onSubmit={this.handleSubmit} focusOnFirstElement className="editCorporateForm">
        <section className="editCorporateForm__personalDetails">
          <h2 className="editCorporateForm__header editCorporateForm__personalDetailsHeader">
            {this.props.t('EditCorporateForm.business_contact_details')}
          </h2>

          <div className="editCorporateForm__fields">
            <MobxFormFieldGroup error={errors.email}>
              <MobxFormLabel htmlFor="email">{this.props.t('EditCorporateForm.email')}</MobxFormLabel>
              <EmailLookup
                sector="corporate"
                dealershipId={this.props.dealershipId}
                onChange={this.setEmail}
                value={this.formData.email}
                autoComplete="none"
                placeholder={this.props.t('EditCorporateForm.enter_customer_email_address')}
                currentEmail={this.props.customer.Email}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.title}>
              <MobxFormLabel htmlFor="title">{this.props.t('EditCorporateForm.title')}</MobxFormLabel>
              <SelectInput
                id="title"
                value={this.formData.title}
                onChange={this.setTitle}
                options="CustomerTitles"
                funderCode={funderCode}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.firstName}>
              <MobxFormLabel htmlFor="firstName">{this.props.t('EditCorporateForm.first_name')}</MobxFormLabel>
              <TextInputWrappingInput id="firstName" value={this.formData.firstName} onChange={this.setFirstName} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.middleName}>
              <MobxFormLabel htmlFor="middleName">{this.props.t('EditCorporateForm.middle_name')}</MobxFormLabel>
              <TextInputWrappingInput id="middleNames" value={this.formData.middleName} onChange={this.setMiddleName} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.surname}>
              <MobxFormLabel htmlFor="surname">{this.props.t('EditCorporateForm.last_name')}</MobxFormLabel>
              <TextInputWrappingInput id="surname" value={this.formData.surname} onChange={this.setSurname} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.position}>
              <MobxFormLabel htmlFor="position">{this.props.t('EditCorporateForm.position')}</MobxFormLabel>
              <SelectInput
                id="position"
                value={this.formData.position}
                onChange={this.setPosition}
                options="Position"
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup
              error={errors.landline}
              errorMessage={
                errors.landline?.error === 'required' &&
                this.props.t('EditCorporateForm.business_telephone_or_mobile_required')
              }
            >
              <MobxFormLabel htmlFor="landline">{this.props.t('EditCorporateForm.business_telephone')}</MobxFormLabel>
              <PhoneNumberInput id="landline" value={this.formData.landline} onChange={this.setLandline} type="tel" />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup
              error={errors.mobile}
              errorMessage={
                errors.mobile?.error === 'required' &&
                this.props.t('EditCorporateForm.business_telephone_or_mobile_required')
              }
            >
              <MobxFormLabel htmlFor="mobile">{this.props.t('EditCorporateForm.or_mobile_telephone')}</MobxFormLabel>
              <PhoneNumberInput id="mobile" value={this.formData.mobile} onChange={this.setMobile} type="tel" />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.faxNumber}>
              <MobxFormLabel htmlFor="mobile">{this.props.t('EditCorporateForm.fax_number')}</MobxFormLabel>
              <PhoneNumberInput id="faxNumber" value={this.formData.faxNumber} onChange={this.setFaxNumber} />
            </MobxFormFieldGroup>
          </div>
        </section>

        <section className="editCorporateForm__addressDetails">
          <h2 className="editCorporateForm__header editCorporateForm__addressDetailsHeader">
            {this.props.t('EditCorporateForm.business_address_details')}
          </h2>

          <div className="editCorporateForm__fields">
            <MobxFormFieldGroup error={errors.tradingName}>
              <MobxFormLabel htmlFor="tradingName">{this.props.t('EditCorporateForm.trading_name')}</MobxFormLabel>
              <TradingNameLookup
                dealershipId={this.props.dealershipId}
                onChange={this.setTradingName}
                value={this.formData.tradingName}
              />
            </MobxFormFieldGroup>

            <PostcodeLookup
              addressType="corporate"
              onSelection={this.handlePostCodeLookup}
              dealershipId={this.props.dealershipId}
            />

            <MobxFormFieldGroup error={errors.company}>
              <MobxFormLabel htmlFor="company">{this.props.t('EditCorporateForm.company_name')}</MobxFormLabel>
              <TextInputWrappingInput id="company" value={this.formData.company} onChange={this.setCompany} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.subBuilding}>
              <MobxFormLabel htmlFor="subBuilding">{this.props.t('EditCorporateForm.room_floor')}</MobxFormLabel>
              <TextInputWrappingInput
                id="subBuilding"
                value={this.formData.subBuilding}
                onChange={this.setSubBuilding}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.buildingName}>
              <MobxFormLabel htmlFor="buildingName">{this.props.t('EditCorporateForm.building_name')}</MobxFormLabel>
              <TextInputWrappingInput
                id="buildingName"
                value={this.formData.buildingName}
                onChange={this.setBuildingName}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.buildingNumber}>
              <MobxFormLabel htmlFor="buildingNumber">
                {this.props.t('EditCorporateForm.building_number')}
              </MobxFormLabel>
              <TextInputWrappingInput
                id="buildingNumber"
                value={this.formData.buildingNumber}
                onChange={this.setBuildingNumber}
              />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.street}>
              <MobxFormLabel htmlFor="street">{this.props.t('EditCorporateForm.street')}</MobxFormLabel>
              <TextInputWrappingInput id="street" value={this.formData.street} onChange={this.setStreet} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.district}>
              <MobxFormLabel htmlFor="district">{this.props.t('EditCorporateForm.district')}</MobxFormLabel>
              <TextInputWrappingInput id="district" value={this.formData.district} onChange={this.setDistrict} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.postTown}>
              <MobxFormLabel htmlFor="postTown">{this.props.t('EditCorporateForm.city_town')}</MobxFormLabel>
              <TextInputWrappingInput id="postTown" value={this.formData.postTown} onChange={this.setPostTown} />
            </MobxFormFieldGroup>

            <MobxFormFieldGroup error={errors.postCode}>
              <MobxFormLabel htmlFor="postCode">{this.props.t('EditCorporateForm.postcode')}</MobxFormLabel>
              <TextInputWrappingInput id="postCode" value={this.formData.postCode} onChange={this.setPostCode} />
            </MobxFormFieldGroup>
          </div>
        </section>

        <div className="editConsumerForm__footer">
          <div className="editConsumerForm__footerCancel">
            <Button onClick={this.handleCancel} buttonStyle="cancel">
              {this.props.t('EditCorporateForm.cancel')}
            </Button>
          </div>
          <div className="editConsumerForm__footerSubmit">
            {this.props.appStore.uiState.canDeleteConsumers ? (
              <SplitButton state={loadingState}>
                <SplitButtonOptionDefault onClick={this.handleSubmit}>
                  {this.props.t('EditCorporateForm.update_customer_contact')}
                </SplitButtonOptionDefault>
                <SplitButtonOption onClick={this.handleDeleteCustomer} iconName="bin">
                  {this.props.t('EditCorporateForm.delete_customer')}
                </SplitButtonOption>
              </SplitButton>
            ) : (
              <Button isLoading={loadingState === 'loading'} onClick={this.handleSubmit}>
                {this.props.t('EditCorporateForm.update_customer_contact')}
              </Button>
            )}
          </div>
        </div>
      </MobxForm>
    );
  }
}

EditCorporateForm.propTypes = {
  dealershipId: PropTypes.string,
  appStore: PropTypes.object,
  customer: PropTypes.object
};
export default compose(
  withNavigate,
  withTranslation('EditCustomer'),
  inject(['appStore']),
  observer
)(EditCorporateForm);
