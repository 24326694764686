import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
} from 'chart.js';
import useChartData, { ChartDimensions } from '../../services/hooks/useChartData';
import useNow from '../../services/hooks/useNow';
import usePastDays from '../../services/hooks/usePastDays';
import { transformToChartJsFormat } from '../../helpers/transformToChartJsFormat';
import { useParams } from 'react-router-dom';
import { defaultLineChartData, getChartOptions, legendPadding } from '../../helpers/lineChartDefaults';
import { MetricToChartVariantMap, FiltersState, ChartJsData, Metric, SeriesTypeEnum, ApiResponse } from '../../types';
import { getDataWithRequiredDatasets } from '../../services/utils/getDataWithRequiredDatasets';
import s from './lineChart.module.scss';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  legendPadding
);

interface LineChartProps {
  metric: Metric;
  filters?: FiltersState;
  dataSet?: 'sum' | 'avg';
  showLegend?: boolean;
}

const LineChart = ({ metric, filters = {}, dataSet = 'sum', showLegend = false }: LineChartProps) => {
  const { dealershipId } = useParams<{ dealershipId: string }>();
  const { t } = useTranslation('Stock');
  const isOnlineQuotesChart = MetricToChartVariantMap[metric] === 'OnlineQuotes';
  const getDimensions = ({ origins }: { origins: string[] }): ChartDimensions[] => {
    const dimensions = [ChartDimensions.Date];
    if (isOnlineQuotesChart && !origins?.includes(SeriesTypeEnum['count'])) {
      dimensions.push(ChartDimensions.Origin);
    }
    return dimensions;
  };

  const { data } = useChartData({
    metric: metric,
    dimensions: getDimensions({ origins: filters?.origins || [ChartDimensions.Date] }),
    dealershipId: dealershipId,
    fromDate: usePastDays(filters?.period || 7),
    toDate: useNow(),
    datePeriod: filters?.period === 1 ? 'Hour' : 'Day' || 'Day'
  });

  const getFormattedData = (
    data: ApiResponse = defaultLineChartData,
    dataSet: 'sum' | 'avg' = 'sum',
    metric: Metric
  ): ChartJsData => {
    const formattedData =
      isOnlineQuotesChart && filters.origins ? getDataWithRequiredDatasets(data, filters.origins) : data;

    return transformToChartJsFormat(formattedData, dataSet, metric, t);
  };

  const chartOptions = getChartOptions(showLegend, data);
  return (
    <div className={s['chartContainer']}>
      <Line data={getFormattedData(data, dataSet, metric)} options={chartOptions} />
    </div>
  );
};
export default LineChart;
