import _ from 'lodash';
import platformApiUtils from '@ivendi/platform-api-utils';
import * as actionTypes from '../actionTypes';
import moment from 'moment';
import uuid from 'uuid';
import { schemaMap } from '../../core/schema';
import applicationSchema from '../../core/schemas/applicationSchema';
import { parseApplication } from '../../core/apiDataParsers/applicationDataParser';
import { submitFullApplication } from '../../api/application';
import { ArrangedFlags } from '../../constants';
import { push } from 'routerHistory';
import { trackVehicleFinanceApplicationStarted } from '../../tracking/avoTracking';

export function createApplication(vehicle, quote, customerId, customerType) {
  var applicantId = uuid.v4();

  return (dispatch, getState) => {
    const state = getState();
    const dealershipId = state.dealership.Id;

    dispatch({
      types: [
        actionTypes.CREATE_APPLICATION,
        actionTypes.CREATE_APPLICATION_SUCCESS,
        actionTypes.CREATE_APPLICATION_ERROR
      ],
      callAPI: () =>
        platformApiUtils.post('v1/customer/' + customerId + '/application', {
          ApplicationId: applicantId,
          DealershipConsumerId: customerId,
          VehicleId: vehicle.VehicleId,
          QuoteId: quote.QuoteId,
          CustomerType: customerType
        }),
      onSuccess: () => {
        trackVehicleFinanceApplicationStarted({
          dealershipId,
          financeFunderCode: quote.FunderCode,
          financeFunderProductId: quote.FunderProductUID,
          financeProductType: quote.FinanceType,
          financeApplicationId: applicantId
        });
        push(`/d/${dealershipId}/consumers/${customerId}/application/${applicantId}`);
      }
    });
  };
}

export function cloneApplication(dealershipId, consumer, vehicle, quote, existingApplicationId, customerType) {
  var applicantId = uuid.v4();

  return (dispatch) => {
    dispatch({
      types: [
        actionTypes.CLONE_APPLICATION,
        actionTypes.CLONE_APPLICATION_SUCCESS,
        actionTypes.CLONE_APPLICATION_ERROR
      ],
      callAPI: () => {
        return platformApiUtils
          .post('v1/customer/' + consumer.Id + '/application', {
            ApplicationId: applicantId,
            CustomerId: consumer.Id,
            VehicleId: vehicle.VehicleId,
            QuoteId: quote.QuoteId,
            CustomerType: customerType,
            CopyApplicationFrom: existingApplicationId
          })
          .then(() => {
            trackVehicleFinanceApplicationStarted({
              dealershipId,
              financeFunderCode: quote.FunderCode,
              financeFunderProductId: quote.FunderProductUID,
              financeProductType: quote.FinanceType,
              financeApplicationId: applicantId
            });
          });
      },
      payload: { consumer, consumerId: consumer.Id, vehicle, quote, applicantId, dealershipId }
    });
  };
}

export function resetEditVehicle() {
  return {
    type: actionTypes.RESET_EDIT_VEHICLE
  };
}

//Note: this function will eventually be superseded by PubNbub Messages
export function fetchApplicationUpdates(applicantId) {
  return {
    types: [
      actionTypes.FETCH_APPLICATION_UPDATES,
      actionTypes.FETCH_APPLICATION_UPDATES_SUCCESS,
      actionTypes.FETCH_APPLICATION_UPDATES_ERROR
    ],
    callAPI: () => platformApiUtils.get('v1/customerapplication/' + applicantId),
    parseResponse: (response) => parseApplication(schemaMap(response, applicationSchema))
  };
}

export function saveApplicationSection(sectionName, applicantId, formData) {
  var callAPI;

  switch (sectionName) {
    case 'PersonalDetails':
      callAPI = () => saveApplicationPersonalDetails(applicantId, formData);
      break;

    case 'AddressHistory':
      callAPI = () => saveApplicationAddressHistory(applicantId, formData);
      break;

    case 'EmploymentHistory':
      callAPI = () => saveApplicationEmploymentHistory(applicantId, formData);
      break;

    case 'AffordabilityDetails':
      callAPI = () => saveApplicationAffordabilityDetails(applicantId, formData);
      break;

    case 'BusinessDetails':
      callAPI = () => saveApplicationBusinessDetails(applicantId, formData);
      break;

    case 'BusinessAddress':
      callAPI = () => saveApplicationBusinessAddress(applicantId, formData);
      break;

    case 'OrganisationContacts':
      callAPI = () => saveApplicationOrganisationContacts(applicantId, formData);
      break;

    default:
      throw new Error('Invalid section name passed to saveApplicationSection: ' + sectionName);
  }

  return {
    types: [
      actionTypes.SAVE_APPLICATION_SECTION,
      actionTypes.SAVE_APPLICATION_SECTION_SUCCESS,
      actionTypes.SAVE_APPLICATION_SECTION_ERROR
    ],
    callAPI: callAPI,
    payload: { section: sectionName, formData }
  };
}

export function submitApplicationSection(sectionName, applicantId, formData, nextPage) {
  var callAPI;

  switch (sectionName) {
    case 'PersonalDetails':
      callAPI = () => saveApplicationPersonalDetails(applicantId, formData);
      break;

    case 'AddressHistory':
      callAPI = () => saveApplicationAddressHistory(applicantId, formData);
      break;

    case 'EmploymentHistory':
      callAPI = () => saveApplicationEmploymentHistory(applicantId, formData);
      break;

    case 'AffordabilityDetails':
      callAPI = () => saveApplicationAffordabilityDetails(applicantId, formData);
      break;

    case 'BusinessDetails':
      callAPI = () => saveApplicationBusinessDetails(applicantId, formData);
      break;

    case 'BusinessAddress':
      callAPI = () => saveApplicationBusinessAddress(applicantId, formData);
      break;

    case 'OrganisationContacts':
      callAPI = () => saveApplicationOrganisationContacts(applicantId, formData);
      break;

    default:
      throw new Error('Invalid section name passed to submitApplicationSection: ' + JSON.stringify(sectionName));
  }

  return function(dispatch) {
    dispatch({
      types: [
        actionTypes.SUBMIT_APPLICATION_SECTION,
        actionTypes.SUBMIT_APPLICATION_SECTION_SUCCESS,
        actionTypes.SUBMIT_APPLICATION_SECTION_ERROR
      ],
      callAPI: callAPI,
      onSuccess: () => {
        push(nextPage);
      },
      payload: { section: sectionName, formData }
    });
  };
}

function saveApplicationPersonalDetails(applicantId, data) {
  return platformApiUtils.post('v1/customerapplication/' + applicantId + '/personaldetails', {
    Title: data.Title,
    FirstName: data.FirstName,
    MiddleNames: data.MiddleNames,
    LastName: data.LastName,
    Email: data.Email,
    HomeTelephone: data.HomeTelephone,
    MobileTelephone: data.MobileTelephone,
    Gender: data.Gender,
    DateOfBirth: data.DateOfBirth ? moment.utc(data.DateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
    NoOfDependants: data.NoOfDependants,
    MaritalStatus: data.MaritalStatus,
    DrivingLicence: data.DrivingLicence,
    DrivingLicenceNumber: data.DrivingLicenceNumber,
    Nationality: data.Nationality,
    DualNationality: data.DualNationality,
    CountryOfBirth: data.CountryOfBirth.BornUK === 'false' ? data.CountryOfBirth.BirthPlace : 'GB',
    DirectorOrBoardMemberCountryOfOperation:
      data.DirectorOrBoardMember.Director === 'false' ? null : data.DirectorOrBoardMember.CountryOfOperation,
    DirectorOrBoardMemberCompanySector:
      data.DirectorOrBoardMember.Director === 'false' ? null : data.DirectorOrBoardMember.CompanySector,
    VatNumber: data.VatNumber
  });
}

function saveApplicationBusinessDetails(applicantId, data) {
  return platformApiUtils.post('v1/customerapplication/' + applicantId + '/businessdetails', {
    TradingName: data.TradingName,
    RegisteredName: data.RegisteredName,
    CompanyType: data.CompanyType,
    CompanyRegistrationNumber: data.CompanyRegistrationNumber,
    CompanySector: data.CompanySector,
    DateEstablished: data.DateEstablished ? moment.utc(data.DateEstablished, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
    VatNumber: data.VatNumber,
    NumberOfDirectorsOrPartners: data.NumberOfDirectorsOrPartners,
    NumberOfEmployees: data.NumberOfEmployees,
    EmailAddress: data.EmailAddress,
    Landline: data.Landline,
    Mobile: data.Mobile,
    FaxNumber: data.FaxNumber
  });
}

function saveApplicationAddressHistory(applicantId, data) {
  var postData = _(data)
    .map(function(address) {
      if (address.TimeAtAddress && address.TimeAtAddress.Years === '') {
        address.TimeAtAddress.Years = null;
      }

      if (address.TimeAtAddress && address.TimeAtAddress.Months === '') {
        address.TimeAtAddress.Months = null;
      }

      return {
        Residency: address.Residency,
        TimeAtAddress: {
          Years: address.TimeAtAddress && address.TimeAtAddress.Years,
          Months: address.TimeAtAddress && address.TimeAtAddress.Months
        },
        BuildingName: address.BuildingName,
        BuildingNumber: address.BuildingNumber,
        Country: address.Country,
        County: address.County,
        District: address.District,
        Postcode: address.Postcode,
        PostTown: address.PostTown,
        Street: address.Street,
        SubBuilding: address.SubBuilding
      };
    })
    .value();

  return platformApiUtils.post('v1/customerapplication/' + applicantId + '/consumer/addresshistory', postData);
}

function saveApplicationBusinessAddress(applicantId, data) {
  var postData = _(data.Items)
    .map(function(address) {
      if (address.TimeAtAddress && address.TimeAtAddress.Years === '') {
        address.TimeAtAddress.Years = null;
      }

      if (address.TimeAtAddress && address.TimeAtAddress.Months === '') {
        address.TimeAtAddress.Months = null;
      }

      return {
        Residency: address.PremisesType,
        TimeAtAddress: {
          Years: address.TimeAtAddress && address.TimeAtAddress.Years,
          Months: address.TimeAtAddress && address.TimeAtAddress.Months
        },
        BuildingName: address.BuildingName,
        BuildingNumber: address.BuildingNumber,
        SubBuilding: address.SubBuilding,
        District: address.District,
        Postcode: address.Postcode,
        PostTown: address.PostTown,
        Street: address.Street
      };
    })
    .value();

  return platformApiUtils.post('v1/customerapplication/' + applicantId + '/corporate/AddressHistory', postData);
}

function saveApplicationEmploymentHistory(applicantId, data) {
  var employmentData = _(data)
    .map(function(employment) {
      if (employment.TimeAtEmployment && employment.TimeAtEmployment.Years === '') {
        employment.TimeAtEmployment.Years = null;
      }

      if (employment.TimeAtEmployment && employment.TimeAtEmployment.Months === '') {
        employment.TimeAtEmployment.Months = null;
      }

      return {
        Employer: employment.Employer,
        EmploymentSector: employment.EmploymentSector,
        Occupation: employment.Occupation,
        OccupationBasis: employment.OccupationBasis,
        OccupationType: employment.OccupationType,
        TimeAtEmployment: {
          Years: employment.TimeAtEmployment && employment.TimeAtEmployment.Years,
          Months: employment.TimeAtEmployment && employment.TimeAtEmployment.Months
        },
        TelephoneNumber: employment.TelephoneNumber,
        BuildingName: employment.BuildingName,
        BuildingNumber: employment.BuildingNumber,
        Country: employment.Country,
        County: employment.County,
        District: employment.District,
        Postcode: employment.Postcode,
        PostTown: employment.PostTown,
        Street: employment.Street,
        SubBuilding: employment.SubBuilding,
        GrossAnnual: employment.GrossAnnual
      };
    })
    .value();

  return platformApiUtils.post('v1/customerapplication/' + applicantId + '/employmenthistory', employmentData);
}

function saveApplicationBankDetails(applicantId, data, customerType) {
  if (data.TimeAtBank && data.TimeAtBank.Years === '') {
    data.TimeAtBank.Years = null;
  }

  if (data.TimeAtBank && data.TimeAtBank.Months === '') {
    data.TimeAtBank.Months = null;
  }

  return platformApiUtils.post('v1/customerapplication/' + applicantId + '/' + customerType + '/bankdetails', {
    AccountName: data.AccountName,
    AccountNumber: data.AccountNumber,
    SortCode: data.SortCode,
    TimeAtBank: {
      Years: data.TimeAtBank && data.TimeAtBank.Years,
      Months: data.TimeAtBank && data.TimeAtBank.Months
    },
    Bank: data.Bank,
    BankBIC: data.BankBIC,
    Branch: data.Branch,
    BranchBIC: data.BranchBIC,
    CHAPSSupported: data.CHAPSSupported,
    ContactAddressLine1: data.ContactAddressLine1,
    ContactAddressLine2: data.ContactAddressLine2,
    ContactFax: data.ContactFax,
    ContactPhone: data.ContactPhone,
    ContactPostTown: data.ContactPostTown,
    ContactPostcode: data.ContactPostcode,
    CorrectedAccountNumber: data.CorrectedAccountNumber,
    CorrectedSortCode: data.CorrectedSortCode,
    FasterPaymentsSupported: data.FasterPaymentsSupported,
    IBAN: data.IBAN,
    IsCorrect: data.IsCorrect,
    IsDirectDebitCapable: data.IsDirectDebitCapable,
    Sortcode: data.SortCode,
    StatusInformation: data.StatusInformation
  });
}

const saveApplicationAffordabilityDetails = (applicantId, data) => {
  const {
    Income: incomeData = {},
    ReplacementLoan: replacementLoanData = {},
    PersonalCircumstances: personalCircumstancesData = {},
    MonthlyExpenditure: monthlyExpenditureData = {}
  } = data;

  const { GrossAnnual, NetMonthly, HasOtherHouseholdIncome, OtherHouseholdIncome } = incomeData;
  const { DetailsOfReplacementLoan, IsReplacementLoan } = replacementLoanData;
  const {
    ForeseeFutureDownturnInFinances,
    DownturnReason,
    ForeseenYearlyIncome,
    ChangesExpected,
    DetailsOfExpectedChanges
  } = personalCircumstancesData;
  const { MortgageOrRental, Other } = monthlyExpenditureData;

  const isChangesExpected = ChangesExpected === 'true' || ChangesExpected === true;
  const isForeseeFutureDownturnInFinances =
    ForeseeFutureDownturnInFinances === 'true' || ForeseeFutureDownturnInFinances === true;
  const isReplacementLoan = IsReplacementLoan === 'true' || IsReplacementLoan === true;

  return platformApiUtils.post('v1/customerapplication/' + applicantId + '/affordabilitydetails', {
    Income: {
      GrossAnnual: GrossAnnual,
      NetMonthly: NetMonthly,
      HasOtherHouseholdIncome: HasOtherHouseholdIncome ?? undefined,
      OtherHouseholdIncome: OtherHouseholdIncome ?? undefined
    },
    PersonalCircumstances: {
      ChangesExpected: isChangesExpected,
      DetailsOfExpectedChanges: isChangesExpected ? DetailsOfExpectedChanges : undefined,
      DownturnReason: isForeseeFutureDownturnInFinances ? DownturnReason : undefined,
      ForeseenYearlyIncome: isForeseeFutureDownturnInFinances ? ForeseenYearlyIncome : undefined,
      ForeseeFutureDownturnInFinances
    },
    ReplacementLoan: {
      DetailsOfReplacementLoan: isReplacementLoan ? DetailsOfReplacementLoan : undefined,
      IsReplacementLoan
    },
    MonthlyExpenditure: {
      MortgageOrRental,
      Other
    }
  });
};

function saveApplicationOrganisationContacts(applicantId, data) {
  var postData = _(data)
    .map(function(contact) {
      var addressHistory = _(contact.Items).map(function(address) {
        if (address.TimeAtAddress && address.TimeAtAddress.Years === '') {
          address.TimeAtAddress.Years = null;
        }

        if (address.TimeAtAddress && address.TimeAtAddress.Months === '') {
          address.TimeAtAddress.Months = null;
        }

        return {
          Residency: address.Residency,
          TimeAtAddress: {
            Years: address.TimeAtAddress && address.TimeAtAddress.Years,
            Months: address.TimeAtAddress && address.TimeAtAddress.Months
          },
          BuildingName: address.BuildingName,
          BuildingNumber: address.BuildingNumber,
          Country: address.Country,
          County: address.County,
          District: address.District,
          Postcode: address.Postcode,
          PostTown: address.PostTown,
          Street: address.Street,
          SubBuilding: address.SubBuilding
        };
      });

      return {
        Title: contact.Title,
        FirstName: contact.FirstName,
        MiddleNames: contact.MiddleNames,
        LastName: contact.LastName,
        Position: contact.Position,
        DateOfBirth: contact.DateOfBirth ? moment.utc(contact.DateOfBirth, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
        CountryOfBirth: contact.CountryOfBirth,
        Nationality: contact.Nationality,
        Gender: contact.Gender,
        NoOfDependants: contact.NoOfDependants,
        MaritalStatus: contact.MaritalStatus,
        Email: contact.Email,
        WorkTelephone: null,
        HomeTelephone: contact.HomeTelephone,
        MobileTelephone: contact.MobileTelephone,
        AddressHistory: addressHistory
      };
    })
    .value();

  return platformApiUtils.post('v1/customerapplication/' + applicantId + '/businesscontacts', postData);
}

function validateBankDetails(sortCode, accountNumber, quoteId) {
  return new Promise(function(resolve, reject) {
    var onSuccess = function(response) {
      let bankDetails = response && response.Items && response.Items.length && response.Items[0];

      if (bankDetails && bankDetails.IsCorrect) {
        resolve(bankDetails);
      } else {
        reject(bankDetails && bankDetails.StatusInformation);
      }
    };

    platformApiUtils
      .post('v1/validate-account', {
        SortCode: sortCode,
        AccountNumber: accountNumber,
        quoteId
      })
      .then(onSuccess, reject);
  });
}

export function saveBankDetails(applicantId, data, customerType) {
  return function(dispatch, getState) {
    const quoteId = _.get(getState(), 'application.Quote.QuoteId');

    dispatch({ type: actionTypes.SAVE_BANK_DETAILS });

    validateBankDetails(data.SortCode, data.AccountNumber, quoteId).then(
      function(bankDetails) {
        var newData = Object.assign({}, data, bankDetails);

        saveApplicationBankDetails(applicantId, newData, customerType).then(
          () => dispatch({ type: actionTypes.SAVE_BANK_DETAILS_SUCCESS, formData: newData }),
          () => dispatch({ type: actionTypes.SAVE_BANK_DETAILS_ERROR })
        );
      },
      () => dispatch({ type: actionTypes.SAVE_BANK_DETAILS_ERROR, error: 'BankLookup' })
    );
  };
}

export function submitBankDetails(data, nextPage) {
  return function(dispatch, getState) {
    const state = getState();
    const applicantId = state.application.Id;
    const customerType = state.application.CustomerType;
    const quoteId = _.get(state, 'application.Quote.QuoteId');

    dispatch({ type: actionTypes.SUBMIT_BANK_DETAILS });

    validateBankDetails(data.SortCode, data.AccountNumber, quoteId).then(
      function(bankDetails) {
        var newData = Object.assign({}, data, bankDetails);

        saveApplicationBankDetails(applicantId, newData, customerType).then(
          () => {
            dispatch({ type: actionTypes.SUBMIT_BANK_DETAILS_SUCCESS, formData: newData });
            push(nextPage);
          },
          () => dispatch({ type: actionTypes.SUBMIT_BANK_DETAILS_ERROR })
        );
      },
      () => dispatch({ type: actionTypes.SUBMIT_BANK_DETAILS_ERROR, error: 'BankLookup' })
    );
  };
}

export function submitApplication(
  applicantId,
  nextPage,
  customerType,
  customerLocation,
  marketingPreference,
  marketingVersion,
  quoteId,
  distanceSale = null
) {
  const arrangedFlags = {
    [ArrangedFlags.Showroom]: false,
    [ArrangedFlags.Phone]: false,
    [ArrangedFlags.Online]: false,
    [ArrangedFlags.DistanceSale]: distanceSale
  };

  arrangedFlags[customerLocation] = true;

  return (dispatch) => {
    dispatch({
      types: [
        actionTypes.SUBMIT_APPLICATION,
        actionTypes.SUBMIT_APPLICATION_SUCCESS,
        actionTypes.SUBMIT_APPLICATION_ERROR
      ],
      payload: { applicantId },
      callAPI: () =>
        platformApiUtils.post(
          'v1/customerapplication/' + applicantId + '/' + customerType + '/submit?quoteId=' + quoteId,
          {
            ArrangedFlags: arrangedFlags,
            PrivacyPreferences: {
              LenderMarketingByEmailConsent: marketingPreference,
              LenderMarketingDisclaimerVersionId: marketingVersion
            }
          }
        ),
      onSuccess: () => {
        push(nextPage);
      }
    });
  };
}

export function changeApplicationQuotePreSubmission(applicantId, quote, quoteRequest) {
  return (dispatch, getState) => {
    const state = getState();
    const application = state.application;
    const dealershipId = application.DealershipId;
    const customerId = application.CustomerId;
    const vehicle = application.Vehicle;
    const customerType = application.CustomerType;

    // The quote in params is a QW quote and the one in state is a quote from our API. The QW quote doesn't
    // provide the vehicle mileage, this takes the mileage from the application quote so it doesn't show as
    // zero until page is reloaded
    const quoteWithMileage = { ...quote, VehicleMileage: quote.VehicleMileage || application.Quote.VehicleMileage };

    dispatch({
      types: [
        actionTypes.CHANGE_APPLICATION_QUOTE_PRE_SUBMISSION,
        actionTypes.CHANGE_APPLICATION_QUOTE_PRE_SUBMISSION_SUCCESS,
        actionTypes.CHANGE_APPLICATION_QUOTE_PRE_SUBMISSION_ERROR
      ],
      payload: { applicantId, vehicle, quote: quoteWithMileage, quoteRequest },
      callAPI: () =>
        platformApiUtils.post(
          'v1/customerapplication/' + applicantId + '/' + customerType + '/setquote/' + quote.QuoteId
        ),
      onSuccess: () => {
        push(`/d/${dealershipId}/consumers/${customerId}/application/${applicantId}/applicationsummary`);
      }
    });
  };
}

export function changeApplicationQuote(
  applicantId,
  vehicle,
  quote,
  customerType,
  dealershipId,
  customerId,
  quoteRequest
) {
  return (dispatch) => {
    dispatch({
      types: [
        actionTypes.CHANGE_APPLICATION_QUOTE,
        actionTypes.CHANGE_APPLICATION_QUOTE_SUCCESS,
        actionTypes.CHANGE_APPLICATION_QUOTE_ERROR
      ],
      payload: { applicantId, vehicle, quote, quoteRequest },
      callAPI: () =>
        platformApiUtils.post(
          'v1/customerapplication/' + applicantId + '/' + customerType + '/setquote/' + quote.QuoteId
        ),
      onSuccess: () => {
        push(`/d/${dealershipId}/consumers/${customerId}/application/${applicantId}/edit`);
      }
    });
  };
}

export function resubmitApplication(
  dealershipId,
  consumerId,
  applicantId,
  customerType,
  marketingPreference,
  marketingVersion,
  distanceSale = null,
  customerLocation
) {
  const arrangedFlags = {
    [ArrangedFlags.Showroom]: false,
    [ArrangedFlags.Phone]: false,
    [ArrangedFlags.Online]: false,
    [ArrangedFlags.DistanceSale]: distanceSale
  };

  arrangedFlags[customerLocation] = true;

  return (dispatch) => {
    dispatch({
      types: [
        actionTypes.RESUBMIT_APPLICATION,
        actionTypes.RESUBMIT_APPLICATION_SUCCESS,
        actionTypes.RESUBMIT_APPLICATION_ERROR
      ],
      payload: { applicantId },
      callAPI: () =>
        platformApiUtils.post('v1/customerapplication/' + applicantId + '/' + customerType + '/resubmit', {
          ArrangedFlags: arrangedFlags,
          PrivacyPreferences: {
            LenderMarketingByEmailConsent: marketingPreference,
            LenderMarketingDisclaimerVersionId: marketingVersion
          }
        }),
      onSuccess: () => {
        push(`/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/status`);
      }
    });
  };
}

export function updateDecision(applicantId, customerType) {
  return {
    types: [actionTypes.UPDATE_DECISION, actionTypes.UPDATE_DECISION_SUCCESS, actionTypes.UPDATE_DECISION_ERROR],
    payload: { applicantId },
    callAPI: () =>
      platformApiUtils.post('v1/customerapplication/' + applicantId + '/' + customerType + '/update-decisions')
  };
}

export function changeApplicationVehicle(vehicle) {
  return {
    type: actionTypes.CHANGE_APPLICATION_VEHICLE,
    payload: { vehicle }
  };
}

export function readyChangeApplication() {
  return {
    type: actionTypes.FETCH_APPLICATION
  };
}

export function changeApplication(application) {
  return {
    type: actionTypes.FETCH_APPLICATION_SUCCESS,
    response: application
  };
}

export function fullSubmit(
  application,
  redirectUrl,
  customerLocation,
  marketingPreference,
  marketingVersion,
  distanceSale = null
) {
  const arrangedFlags = {
    [ArrangedFlags.Showroom]: false,
    [ArrangedFlags.Phone]: false,
    [ArrangedFlags.Online]: false,
    [ArrangedFlags.DistanceSale]: distanceSale
  };

  arrangedFlags[customerLocation] = true;

  return (dispatch) => {
    dispatch({
      types: [
        actionTypes.SUBMIT_APPLICATION,
        actionTypes.SUBMIT_APPLICATION_SUCCESS,
        actionTypes.SUBMIT_APPLICATION_ERROR
      ],
      callAPI: () =>
        submitFullApplication({
          arrangedFlags,
          marketingPreference,
          marketingVersion,
          ...application
        }),
      onSuccess: () => {
        push(redirectUrl);
      }
    });
  };
}
