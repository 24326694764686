import { ConsumerDutyDetails, FunderContentFilters } from './utils';
import platformApiUtils from '@ivendi/platform-api-utils';
import { z } from 'zod';

export const FacilityTypeSchema = z.union([
  z.literal('hp'),
  z.literal('pcp'),
  z.literal('cs'),
  z.literal('lp'),
  z.literal('fs'),
  z.literal('sc'),
  z.literal('pch'),
  z.literal('ch'),
  z.literal('fl'),
  z.literal('ol'),
  z.literal('cp'),
  z.literal('bp'),
  z.literal('default')
]);

// http://content-service-api-svc.content-service-gb-dev.svc.cluster.local/swagger/index.html
export const ConsumerDutyProductSchema = z.object({
  id: z.string().uuid(),
  language: z.string().nullable(),
  version: z.number(),
  dateCreated: z.string().datetime(),
  isPublished: z.boolean(),
  funderId: z.string().uuid(),
  funderCode: z.string().nullable(),
  funderName: z.string().nullable(),
  funderProductId: z.string().uuid(),
  funderProductCode: z.string().nullable(),
  customerTypeId: z.union([z.literal(1), z.literal(2)]),
  facilityTypeId: z.number(),
  facilityTypeName: FacilityTypeSchema,
  facilityTypeDescription: z.string().nullable(),
  introductionStatement: z.string(),
  targetGroup: z.string(),
  benefits: z.string(),
  considerations: z.string(),
  support: z.string(),
  questions: z
    .string()
    .array()
    .nullable(),
  videoId: z.string().nullable(),
  videoLocation: z.string().nullable(),
  videoFileName: z.string().nullable(),
  productUrl: z.string().nullable(),
  commissionStatus: z.union([z.literal(0), z.literal(1), z.literal(2)]),
  isRiskBasedPricing: z.boolean(),
  rateForRiskPriorToHardSearch: z.boolean(),
  dpnUrl: z.string(),
  maxNumberOfSearches: z.number(),
  interceptConditions: z.string().nullable()
});

export const ConsumerDutyResponseSchema = z.object({
  status: z.string(),
  message: z.string(),
  generatedAt: z.string().datetime(),
  data: ConsumerDutyProductSchema,
  count: z.number()
});

export const FunderDetailsSchema = z.object({
  Code: z.string(),
  Name: z.string().nullable(),
  Organisation: z.string().nullable(),
  Postcode: z.string().nullable(),
  District: z.string().nullable(),
  PostTown: z.string().nullable(),
  Street: z.string().nullable(),
  BuildingNumber: z.string().nullable(),
  BuildingName: z.string().nullable(),
  SubBuilding: z.string().nullable(),
  Country: z.string().nullable(),
  Disclaimer: z.string().nullable(),
  FunderType: z.string().nullable(),
  PhoneNumber: z.string().nullable(),
  Email: z.string().nullable()
});

export const SupportServiceContentTypesSchema = z.union([z.literal(1), z.literal(2), z.literal(3)]);
export const SupportServiceSectionResourceSchema = z.object({
  type: SupportServiceContentTypesSchema,
  label: z.string(),
  value: z.string()
});

export const SupportServiceSectionSchema = z.object({
  description: z.string().optional(),
  resources: z.array(SupportServiceSectionResourceSchema)
});

export const SupportServiceContentSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  language: z.string(),
  dateCreated: z.string().datetime(),
  sections: z.array(SupportServiceSectionSchema)
});

export const CSFunderContentSchema = z.object({
  id: z.string(),
  language: z.string(),
  dateCreated: z.string(),
  version: z.number(),
  isPublished: z.boolean(),
  funderId: z.string(),
  funderCode: z.string(),
  regulatoryText: z.string(),
  disclaimer: z.string(),
  preApprovalDisclaimer: z.string().nullable(),
  supportServiceContentIds: z.array(z.string()),
  supportServices: SupportServiceContentSchema.array()
});

export const CSFunderContentResponseSchema = z.object({
  status: z.string(),
  message: z.string(),
  generatedAt: z.string().datetime(),
  data: CSFunderContentSchema,
  count: z.number()
});

export type ConsumerDutyProductDetails = z.infer<typeof ConsumerDutyProductSchema>;
export type FunderDetails = z.infer<typeof FunderDetailsSchema>;
export type ConsumerDutyResponse = z.infer<typeof ConsumerDutyResponseSchema>;
export type FunderDetailsResponse = z.infer<typeof FunderDetailsSchema>;
export type CSFunderContentResponse = z.infer<typeof CSFunderContentResponseSchema>;
export type CSFunderContent = z.infer<typeof CSFunderContentSchema>;
export type SupportServiceContent = z.infer<typeof SupportServiceContentSchema>;
export type SupportServiceSection = z.infer<typeof SupportServiceSectionSchema>;
export type SupportServiceSectionResource = z.infer<typeof SupportServiceSectionResourceSchema>;

const CONTENT_SERVICE_API = '/api-module/ls/content/api/v1/public';

// If the funderProductUID is missing from the quote
// use a default one which will return iVendi content
const getFunderProductUIDWithFallback = (funderProductUID: string | null | undefined) => {
  return funderProductUID === '' || funderProductUID === null || funderProductUID === undefined
    ? '00000000-0000-0000-0000-000000000000'
    : funderProductUID;
};

// Example: https://dev.ivendi.co/api-module/ls/content/api/v1/public/en-GB/consumer-duty/MOT/2/1/44ff3a6c-6dba-4c6e-9e7e-9b1bd547b7c1
export const getFunderContent = async (consumerDutyDetails: ConsumerDutyDetails) => {
  const { funderCode, facilityTypeId, funderProductUID, customerTypeId, language } = consumerDutyDetails;

  const response = await fetch(
    `${CONTENT_SERVICE_API}/${language}/consumer-duty/${funderCode}/${facilityTypeId}/${customerTypeId}/${getFunderProductUIDWithFallback(
      funderProductUID
    )}`,

    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  if (!response.ok) {
    throw new Error(response.status.toString());
  }
  const funders = await response.json();
  return (funders as ConsumerDutyResponse).data;
};

export const getFunderDetails = async (funderCode: string) => {
  const response: FunderDetailsResponse = await platformApiUtils.get(`v1/public/funders/${funderCode}`);
  return response;
};

export const getCSFundersContent = async (funderContentFilters: FunderContentFilters) => {
  const { language, funderCode } = funderContentFilters;
  const response = await fetch(`${CONTENT_SERVICE_API}/${language}/funders/${funderCode}`);
  const fundersContent = await response.json();
  return (fundersContent as CSFunderContentResponse).data;
};
