import { useTranslation } from 'react-i18next';
import { useFundersContent } from '~/api/contentService/hooks';
import { getFundersContentFilters } from '~/api/contentService/utils';
import { formatAddress } from '~/core/helpers';
import { canSeeInterceptor } from '~/features';
import { FunderType } from '../types';

export type QuoteDisclaimerProps = {
  funder: FunderType;
  isBdk: boolean;
  isPreApproved: boolean;
};

// Quote cards where we need to show the pre-approval disclaimer

const QuoteDisclaimer: React.FC<QuoteDisclaimerProps> = ({ funder, isBdk, isPreApproved }) => {
  const { t } = useTranslation('QuoteCard');
  const { data: funderContent, isLoading, isError } = useFundersContent(getFundersContentFilters(funder?.Code));
  const showPreApprovalDisclaimer =
    canSeeInterceptor() && isPreApproved && funderContent?.preApprovalDisclaimer && !isLoading && !isError;

  // Default disclaimer text
  let disclaimerWithFunderAddress = '';
  if (isBdk) {
    disclaimerWithFunderAddress = t(`QuoteDisclaimer.bdk_disclaimer`);
  } else if (funder?.Code === 'ADM') {
    disclaimerWithFunderAddress = t(`QuoteDisclaimer.adm_disclaimer`);
  } else {
    disclaimerWithFunderAddress = t('QuoteDisclaimer.finance_is_provided_by', {
      funderAddress: funder && formatAddress(funder)
    });
  }

  return (
    <span className="quoteDisclaimer" data-th="quote-disclaimer">
      {showPreApprovalDisclaimer ? funderContent.preApprovalDisclaimer : disclaimerWithFunderAddress}
      {funder?.Code === 'MOT' && <p data-th="quote-commission">{t('QuoteDisclaimer.we_will_recieve_commission')}</p>}
    </span>
  );
};
export default QuoteDisclaimer;
