import { observable, action, computed } from 'mobx';
import Request from '../mobx-models/Request';
import { fetchQuotes } from '../utils/quoteUtils';
import { parsePaymentSchedules } from '../core/apiDataParsers/quoteParser';
import { parseVisibleQuotes } from '../core/apiDataParsers/quotesParser';
import * as dataLayer from '../api/repExample';
import { fetchUser } from '../api/users';
import _ from 'lodash';

class RepExampleStore {
  @observable
  vehicle = {
    CapId: '',
    Condition: 'used',
    Class: 'Car',
    Vrm: '',
    RegistrationDate: '',
    MakeId: '',
    Make: '',
    ModelId: '',
    Model: '',
    DerivativeId: '',
    Derivative: '',
    Mileage: '',
    Vin: ''
  };
  @observable priceDetailsSubmitted = false;
  @observable quotes = [];
  @observable quoteRequest;
  @observable quoteeUid;
  @observable productSettings;
  @observable
  pricingForm = {
    VehiclePrice: '',
    AnnualDistance: '',
    CashDeposit: '',
    Term: '',
    Apr: ''
  };
  @observable updatedProductSettings;
  @observable existingRepExample = {};
  @observable previouslySetByUser = null;
  @observable quoteId = null;
  @observable quote = {};
  @observable newQuote = {};
  @observable repExampleSelected = null;
  @observable initialRepExample = null;
  @observable customPage = '';
  @observable newRepTimestamp;
  quoteRequest = new Request();
  setRepExample = new Request();
  getExistingRepExample = new Request();

  @computed
  get quoteFigures() {
    return {
      CashDeposit: this.pricingForm.CashDeposit,
      VehiclePrice: this.pricingForm.VehiclePrice,
      Term: this.pricingForm.Term,
      AnnualDistance: this.pricingForm.AnnualDistance
    };
  }

  @action setInitialPricingValues = (values) => {
    this.pricingForm = { ...this.pricingForm, ...values };
  };

  @action
  setVehicle = (vehicle) => {
    this.vehicle = vehicle;
  };

  @action
  updateProductApr = () => {
    const updatedProductSettings = this.productSettings.map((product) => {
      return {
        Name: product.Name,
        AprOnly: product.AprOnly,
        ProductUid: product.ProductUid,
        CommissionType: 'APR',
        Rate: this.pricingForm.Apr,
        Visible: product.Settings.Visible,
        FunderCode: product.FunderCode,
        FunderProductCode: product.FunderProductCode
      };
    });
    this.updatedProductSettings = updatedProductSettings;
    this.priceDetailsSubmitted = true;
    this.fetchQuotes();
  };

  @action
  handlePricingFormChange = (field, value) => {
    this.pricingForm[field] = value;
  };

  @action
  fetchQuotes = () => {
    this.quoteRequest
      .onSuccess(this.onFetchQuoteSuccess)
      .onError(this.handleQuotesError)
      .call(fetchQuotes, this.quoteeUid, this.vehicle, this.quoteFigures, this.updatedProductSettings, 'vehicle');
  };

  @action
  handleQuotesError = () => {
    this.setCustomPage('vehicleForm');
  };

  @action
  setQuote = (dealershipId, quoteId, repType, date) => {
    this.setRepExample
      .onSuccess((data) => this.setNewRepExample(date))
      .onError(this.handleQuotesError)
      .call(dataLayer.setRepExample, dealershipId, quoteId, repType);
  };

  @action
  setNewRepExample = (date) => {
    this.newRepTimestamp = date;
  };

  @action
  saveQuoteId = (quote) => {
    quote.PaymentSchedule = parsePaymentSchedules(quote);
    this.newQuote = quote;
  };

  @action
  clearQuote = () => {
    this.newQuote = {};
  };

  @action
  fetchExistingRepExample = (dealershipId) => {
    this.getExistingRepExample
      .onSuccess((data) => this.setExistingRepExample(data, dealershipId))
      .onError(this.handleExistingRepExampleError)
      .call(dataLayer.fetchExistingRepExample, dealershipId);
  };

  @action
  handleExistingRepExampleError = () => {
    this.setCustomPage('vehicleForm');
  };

  @action
  setRepExampleType = (value) => {
    this.repExampleSelected = value;
  };

  @action
  setExistingRepExample = (data, dealershipId) => {
    data.PaymentSchedule = parsePaymentSchedules(data);
    this.quote = data;
    this.initialRepExample = data.RepresentativeExampleType;
    if (this.initialRepExample === 3) {
      this.setRepExampleType('custom');
    } else if (this.initialRepExample === 2) {
      this.setRepExampleType('lowest');
    } else if (this.initialRepExample === 1) {
      this.setRepExampleType('hp');
    }
    if (data.UpdatingUserId) {
      return fetchUser(dealershipId, data.UpdatingUserId).then(
        action((user) => {
          this.previouslySetByUser = user.Firstname + ' ' + user.Surname;
        })
      );
    }
  };

  @action
  resetPreviouslySetByUser = () => {
    this.previouslySetByUser = null;
  };

  @action
  onFetchQuoteSuccess = (quotes) => {
    const sortedQuotes = _.orderBy(quotes, 'Errors', 'desc');
    this.quotes = parseVisibleQuotes(sortedQuotes, this.updatedProductSettings);
  };

  @action
  submitExampleQuote = (dealershipId) => {
    const repType = this.repExampleSelected === 'custom' && 'ExampleQuote';
    this.saveQuoteId(this.newQuote);
    this.setQuote(dealershipId, this.newQuote.QuoteId, repType, Date.now());
    this.setCustomPage('');
    this.setNewInitialValue();
  };

  @action
  resetRepExampleForms = () => {
    this.pricingForm = {
      VehiclePrice: '',
      AnnualDistance: '',
      CashDeposit: '',
      Term: '',
      Apr: ''
    };
    this.vehicle = {
      CapId: '',
      Condition: 'used',
      Class: 'Car',
      Vrm: '',
      RegistrationDate: '',
      MakeId: '',
      Make: '',
      ModelId: '',
      Model: '',
      DerivativeId: '',
      Derivative: '',
      Mileage: '',
      Vin: ''
    };
    this.newQuote = {};
  };

  @action
  resetQuote = () => {
    this.quote = {};
  };

  @action
  setCustomPage = (page) => {
    this.customPage = page;
  };

  @action
  setNewInitialValue = () => {
    this.initialRepExample = 3;
  };
}

export default RepExampleStore;
