import platformApiUtils from '@ivendi/platform-api-utils';
import * as debug from '../debug';

const generateImageCountUrl = (imgPth) => {
  const path = imgPth.replace(/^\//, '');
  return `${process.env.REACT_APP_STOCK_IMAGE_CLOUDFRONT}/${path}/count.txt`;
};

// need as URLSearchParams does not allow multiple values with the same name.
class SearchParamsBuilder {
  constructor() {
    this.params = [];
  }

  add(name, value) {
    this.params.push(`${name}=${value}`);
  }

  toString() {
    return this.params.join('&');
  }
}

export const stockModuleVisRequest = async (
  { pageSize = 5, page = 1, query = '', class: klass = '', secondaryTaxonomyIds = [], dealershipId },
  init
) => {
  const searchParams = new SearchParamsBuilder();
  searchParams.add('pageSize', pageSize);
  searchParams.add('page', page - 1);
  searchParams.add('query', query);
  searchParams.add('class', klass);

  secondaryTaxonomyIds.forEach((secondaryTaxonomyId) => {
    searchParams.add('secondaryTaxonomyId', secondaryTaxonomyId);
  });

  const response = await fetch(
    `${process.env.REACT_APP_STOCK_API_PATH}/DealerVis/${dealershipId}/search?${searchParams.toString()}`,
    {
      headers: {
        'content-type': 'application/json'
      },
      method: 'GET',
      ...init
    }
  );
  return response.json();
};

export const createVehicle = async (
  {
    attentionGrabber,
    bodyStyle,
    class: klass,
    colour,
    condition,
    dealerReference,
    derivative,
    dealershipId,
    description,
    doors,
    fuel,
    make,
    mileage,
    model,
    noOfSeats,
    numberOfPreviousKeepers,
    options,
    origin,
    price,
    primaryId,
    provider,
    registrationDate,
    secondaryId,
    trans,
    vatStatus,
    vin,
    vrm,
    auctionId,
    mileageUnit,
    currency,
    drivingPosition,
    consumptionInner,
    consumptionOuter,
    consumptionCombined,
    includesVat,
    vatRate,
    emission,
    energyEfficiencyClass
  },
  init
) => {
  const dealer = { dealerReference };
  const identity = { vrm, vin, condition };
  const taxonomy = { origin, provider, primaryId, secondaryId, class: klass };
  const taxonomyDetails = { make, model, derivative, bodyStyle, fuel, trans, noOfSeats, doors };
  const advertInformation = {
    attentionGrabber,
    colour,
    description,
    mileage,
    numberOfPreviousKeepers,
    options,
    price,
    registrationDate,
    vatStatus,
    consumptionInner,
    consumptionOuter,
    consumptionCombined,
    includesVat,
    vatRate,
    emission,
    energyEfficiencyClass
  };
  const auctionInformation = {
    auctionId,
    mileageUnit,
    currency,
    drivingPosition
  };

  const response = await fetch(
    `${process.env.REACT_APP_STOCK_API_PATH}/DealerPlatformVehicle/${dealershipId}/vehicle`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ dealer, identity, taxonomy, taxonomyDetails, advertInformation, ...auctionInformation }),
      ...init
    }
  );

  return response.json();
};

export const updateVehicle = async (
  {
    attentionGrabber,
    bodyStyle,
    class: klass,
    colour,
    condition,
    dealerReference,
    derivative,
    dealershipId,
    description,
    doors,
    fuel,
    make,
    mileage,
    model,
    noOfSeats,
    numberOfPreviousKeepers,
    options,
    origin,
    price,
    primaryId,
    provider,
    registrationDate,
    secondaryId,
    trans,
    vatStatus,
    vehicleId,
    vin,
    vrm,
    auctionId,
    mileageUnit,
    currency,
    drivingPosition,
    consumptionInner,
    consumptionOuter,
    consumptionCombined,
    includesVat,
    vatRate,
    emission,
    energyEfficiencyClass
  },
  init
) => {
  const dealer = { dealerReference };
  const identity = { vrm, vin, condition };
  const taxonomy = { origin, provider, primaryId, secondaryId, class: klass };
  const taxonomyDetails = { make, model, derivative, bodyStyle, fuel, trans, noOfSeats, doors };

  const advertInformation = {
    attentionGrabber,
    colour,
    description,
    mileage,
    numberOfPreviousKeepers,
    options,
    price,
    registrationDate,
    vatStatus,
    consumptionInner,
    consumptionOuter,
    consumptionCombined,
    includesVat,
    vatRate,
    emission,
    energyEfficiencyClass
  };

  const auctionInformation = {
    auctionId,
    mileageUnit,
    currency,
    drivingPosition
  };

  const response = await fetch(
    `${process.env.REACT_APP_STOCK_API_PATH}/DealerPlatformVehicle/${dealershipId}/vehicle/${vehicleId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ dealer, identity, taxonomy, taxonomyDetails, advertInformation, ...auctionInformation }),
      ...init
    }
  );

  return response.json();
};

export const updateFeedVehicle = async (
  {
    attentionGrabber,
    description,
    options,
    dealerReference,

    dealershipId,
    vehicleId,

    auctionId,
    mileageUnit,
    currency,
    drivingPosition
  },
  init
) => {
  const jsonBody = JSON.stringify({
    attentionGrabber,
    description,
    options: [options],
    dealerReference,

    auctionId,
    mileageUnit,
    currency,
    drivingPosition
  });

  const response = await fetch(
    `${process.env.REACT_APP_STOCK_API_PATH}/FeedVehicle/${dealershipId}/vehicle/${vehicleId}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: jsonBody,
      ...init
    }
  );

  return response.json();
};

const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const checkVehicle = async (dealershipId, vehicleId) => {
  const endpoint = `${process.env.REACT_APP_STOCK_API_PATH}/DealerPlatformVehicle/${dealershipId}/vehicle/${vehicleId}`;
  const timeout = 3000;
  const startTime = +new Date();
  let delay = 100;

  while (+new Date() - startTime < timeout) {
    const response = await fetch(endpoint);

    if (response.status !== 204) {
      return Promise.resolve();
    }
    await wait(delay);
    delay *= 1.2;
  }

  return Promise.reject();
};

export const fetchVehicle = async (vrmVin) => platformApiUtils.get(`v1/vehiclelookup/${vrmVin}`);

export const fetchVisVehicle = async (primaryId, secondaryId, klass = 'car') =>
  platformApiUtils.get(
    `v1/vis/taxonomy?country=uk&lender=multilender&class=${klass}&primaryTaxonomyId=${primaryId}&secondaryTaxonomyId=${secondaryId}`
  );

export const fetchVehicleData = async ({ dealershipId, vehicleId }, init) => {
  const url = `${process.env.REACT_APP_STOCK_API_PATH}/vehicle/${dealershipId}/${vehicleId}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      ...init
    });

    if (response && response.status > 399) {
      throw Error(response.statusText);
    } else {
      const jsonResponse = await response.json();
      return jsonResponse;
    }
  } catch (err) {
    debug.error(err);
    return Promise.reject();
  }
};

export const updateImages = async (dealerId, vehicleId, imageMetadata) => {
  const url = `${process.env.REACT_APP_STOCK_API_PATH}/DealerPlatformVehicle/${dealerId}/vehicle/${vehicleId}/images/metadata`;
  const body = {
    metadata: imageMetadata
  };
  const stringifiedBody = JSON.stringify(body);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: stringifiedBody
    });
    if (response.status > 399) {
      throw Error(response.statusText);
    } else return response.status;
  } catch (err) {
    debug.error(err);
  }
};

export const deleteImage = async (dealerId, vehicleId, imageName) => {
  const body = { fileName: imageName };
  const stringifiedBody = JSON.stringify(body);
  const url = `${process.env.REACT_APP_STOCK_API_PATH}/DealerPlatformVehicle/${dealerId}/vehicle/${vehicleId}/image/delete`;

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
      body: stringifiedBody
    });
    if (response && response.status > 399) {
      throw Error(response.statusText);
    } else return response.status;
  } catch (err) {
    debug.error(err);
  }
};

export const deleteVehicle = async (dealerId, vehicleId) => {
  const url = `${process.env.REACT_APP_STOCK_API_PATH}/DealerPlatformVehicle/${dealerId}/vehicle/${vehicleId}`;

  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response && response.status > 399) {
      throw Error(response.statusText);
    } else return response.status;
  } catch (err) {
    debug.error(err);
  }
};

export const updateVideos = async (dealerId, vehicleId, videoUrls) => {
  const url = `${process.env.REACT_APP_STOCK_API_PATH}/Vehicle/${dealerId}/${vehicleId}/videos`;
  const body = {
    videoUrls
  };
  const stringifiedBody = JSON.stringify(body);

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: stringifiedBody
    });

    if (response.status > 399) {
      throw Error(response.statusText);
    } else return response.status;
  } catch (err) {
    debug.error(err);
    throw err;
  }
};

export const fetchImageCount = async (imgPth) => {
  const url = generateImageCountUrl(imgPth);
  try {
    const response = await fetch(url);
    const number = Number(await response.text());

    return Number.isNaN(number) ? 0 : number;
  } catch (err) {
    debug.error(err);
  }
};
