import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './applicationNavigation.scss';
import ApplicationNavigationItem from './ApplicationNavigationItem';
import { compose } from 'redux';
import { withRouter } from '../../../hocs/router';

class ApplicationNavigation extends React.Component {
  static propTypes = {
    progress: PropTypes.object.isRequired,
    applicationType: PropTypes.string,
    params: PropTypes.object
  };

  getNavigationItems(applicationType) {
    let navigationItems;
    const { dealershipId, consumerId, applicantId } = this.props.params;

    if (applicationType === 'corporate') {
      navigationItems = [
        {
          id: 'personal_details',
          label: this.props.t('ApplicationNavigation.business_details'),
          image: 'dealership',
          link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/businessdetails`,
          progress: this.props.progress.businessDetails
        },
        {
          id: 'address',
          label: this.props.t('ApplicationNavigation.business_address'),
          image: 'location',
          link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/businessaddress`,
          progress: this.props.progress.addressHistory
        },
        {
          id: 'employment',
          label: this.props.t('ApplicationNavigation.organisation_contacts'),
          image: 'customers',
          link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/organisationcontacts`,
          progress: this.props.progress.organisationContacts
        },
        {
          id: 'bank',
          label: this.props.t('ApplicationNavigation.bank_details'),
          image: 'bank',
          link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/bankdetails`,
          progress: this.props.progress.bank
        },
        {
          id: 'summary',
          label: this.props.t('ApplicationNavigation.summary'),
          image: 'summary',
          link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/applicationsummary`,
          progress: this.props.progress.total
        }
      ];
    } else {
      navigationItems = [
        {
          id: 'personal_details',
          label: this.props.t('ApplicationNavigation.personal_details'),
          image: 'consumer',
          link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/personaldetails`,
          progress: this.props.progress.personal
        },
        {
          id: 'address',
          label: this.props.t('ApplicationNavigation.address'),
          image: 'location',
          link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/addresshistory`,
          progress: this.props.progress.address
        },
        {
          id: 'employment',
          label: this.props.t('ApplicationNavigation.employment'),
          image: 'employment',
          link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/employmenthistory`,
          progress: this.props.progress.employment
        },
        {
          id: 'bank',
          label: this.props.t('ApplicationNavigation.bank_details'),
          image: 'bank',
          link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/bankdetails`,
          progress: this.props.progress.bank
        },
        {
          id: 'bank',
          label: this.props.t('ApplicationNavigation.affordability'),
          image: 'affordability',
          link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/affordability`,
          progress: this.props.progress.affordability
        },
        {
          id: 'summary',
          label: this.props.t('ApplicationNavigation.summary'),
          image: 'summary',
          link: `/d/${dealershipId}/consumers/${consumerId}/application/${applicantId}/applicationsummary`,
          progress: this.props.progress.total
        }
      ];
    }

    return navigationItems;
  }

  render() {
    const applicationType = this.props.applicationType && this.props.applicationType.toLowerCase();
    let navigationItems = this.getNavigationItems(applicationType);

    return (
      <div>
        <ul className="applicationNavigation">
          {navigationItems.map((item, index) => (
            <ApplicationNavigationItem
              key={index}
              link={item.link}
              isActive={item.isActive}
              imageName={item.image}
              text={item.label}
              progress={item.progress}
              numItems={navigationItems.length}
              isLast={index === navigationItems.length - 1}
            />
          ))}
        </ul>
      </div>
    );
  }
}

export default compose(withRouter, withTranslation('Application'))(ApplicationNavigation);
