import { useTranslation, withTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { inject } from 'mobx-react';
import MediaQuery from 'react-responsive';
import { withNavigate, withParams } from 'hocs/router';
import platformApiUtils from '@ivendi/platform-api-utils';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { useNavigate } from 'hooks/useNavigate';
import Panel from '~Common/Panel';
import PanelHeader from '~Common/PanelHeader';
import Page from '~Common/Page';
import VRMPlate from '~Common/VrmPlate';
import PanelContent from '~Common/PanelContent';
import Icon from '~Common/Icon/Icon';
import Button from '~Common/Button/Button';
import TrackPageView from '~Common/Tracking/TrackPageView';
import { getLocalStorageValue, isDefined, setLocalStorageValue } from '~/core/helpers';
import * as quickQuoteActions from '~/redux/quickQuote/quickQuoteActions.js';

import { StockBreadcrumbs, VehicleNumber } from '../../../../../../shared/components';
import { observerForHooks } from '../../../../../../shared/hocs';
import { getMismatchingKeys } from './helpers';
import { ComparisonCards, FixIssuesAlertCard, FixIssuesVehicleCard } from './components';
import { useSelectedVehicle, useMetaData, useSearchState, useSearchQuery } from '~Common/VisLookup/hooks';
import { Hits, SearchBox, Pagination } from '~Common/VisLookup/components';
import { useSortedVehicleData } from './hooks';

import styles from './stockListFixIssuesContainer.module.scss';
import { compareVehicleSpecs } from './helpers/compareVehicleSpecs/compareVehicleSpecs';

const StockListFixIssuesContainer = ({ changeQuickQuoteVehicle, ...props }) => {
  const params = useParams();
  const { dealershipId, vehicleId } = props.params;
  const {
    mediaCompanyVehicleData,
    iVendiCompanyVehicleData,
    loadingState,
    dealerPlatformUpload
  } = useSortedVehicleData({ dealershipId, vehicleId });
  const searchBarRef = useRef(null);
  const comparisonCards = useRef(null);
  const { t } = useTranslation('Stock');
  useEffect(() => {
    // set up local storage vehicleIds so we can optimistically render that they are updating
    const updatedVehicleIds = localStorage.getItem('updatedVehicleIds');
    updatedVehicleIds === null && localStorage.setItem('updatedVehicleIds', JSON.stringify([]));
    document
      .getElementsByClassName('globalLayout__content-inner')[0]
      .children.item(0)
      .scrollIntoView();
  }, [dealershipId]);
  const [confirmingState, setConfirmingState] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const { selectedVehicleId, refine: refineSelected } = useSelectedVehicle();
  const { noResults, vehicles, selectedVehicle } = useMetaData();
  const { refine: refineQuery } = useSearchQuery();
  const searchState = useSearchState();
  const navigate = useNavigate();

  const displayPagination = !selectedVehicleId && vehicles.length > 0 && searchState === 'idle';
  const visVehicleSelected = !!selectedVehicleId;
  const visVehicleNotSelected = !visVehicleSelected;

  useEffect(() => {
    if (loadingState === 'error' || dealerPlatformUpload) {
      navigate(`/d/${dealershipId}/stock/list`);
    }
  }, [dealerPlatformUpload, dealershipId, loadingState, navigate]);

  const selectedTaxonomyDetails = useMemo(() => {
    return selectedVehicle.length > 0 ? { ...selectedVehicle[0] } : null;
  }, [selectedVehicle]);
  // Search VIS for make Model on pageLoad
  useEffect(() => {
    if (!!mediaCompanyVehicleData) {
      const { make, model, derivative, fuel, vrmYear } = mediaCompanyVehicleData;
      const onlyYear = new Date(vrmYear)?.getFullYear();
      const initSearchQuery = [make, model, derivative, fuel, onlyYear].filter(Boolean).join(' ');
      refineQuery(initSearchQuery);
    }
  }, [mediaCompanyVehicleData, refineQuery]);
  const handleTabsChange = (index) => {
    setTabIndex(index);
  };
  const quoteVehicle = () => {
    changeQuickQuoteVehicle({ selectedTaxonomyDetails, mediaCompanyVehicleData });
  };
  const addVehicleIdToLocalStorage = (vehicle) => {
    const ids = getLocalStorageValue('updatedVehicleIds');
    const currentVehicleIndex = ids?.findIndex((entry) => entry.vehicleId === vehicle.vehicleId);
    // There should only be one entry per vehicleId
    if (currentVehicleIndex >= 0) {
      ids[currentVehicleIndex] = vehicle;
    } else {
      ids.push(vehicle);
    }
    // add vehicleId to local storage
    setLocalStorageValue('updatedVehicleIds', ids);
  };
  const fixIssues = async () => {
    const payload = {
      VehicleId: vehicleId,
      SecondaryId: selectedTaxonomyDetails?.SecondaryTaxonomyId,
      DealershipId: dealershipId,
      Provider: selectedTaxonomyDetails?.DataSource,
      PrimaryId: selectedTaxonomyDetails?.PrimaryTaxonomyId,
      Class: selectedTaxonomyDetails?.Class
    };
    setConfirmingState('loading');
    // try catch moved to handleNextAction bc of conditional navigation
    await platformApiUtils.post(`v1/stock/taxonomy`, payload);
    addVehicleIdToLocalStorage({
      vehicleId,
      primaryId: payload?.PrimaryId,
      secondaryId: payload?.SecondaryId,
      class: payload?.Class
    });
    setConfirmingState('done');
  };
  const handleNextAction = async (action) => {
    try {
      await fixIssues();
      if (action === 'fixAndExit') {
        navigate(`/d/${dealershipId}/stock/list`);
      }
      if (action === 'fixAndQuote') {
        quoteVehicle();
        navigate(`/d/${dealershipId}/quickQuote/results`);
      }
    } catch {
      setConfirmingState('error');
    }
  };
  const { showVRM, showVehicleNumber } = props.uiState;
  const mappedTranslations = {
    make: t('StockVehicleFormFields.make'),
    model: t('StockVehicleFormFields.model'),
    bodyStyle: t('StockVehicleFormFields.body_style'),
    fuel: t('StockVehicleFormFields.fuel_type'),
    trans: t('StockVehicleFormFields.transmission'),
    doors: t('StockVehicleFormFields.doors')
  };
  const handleRemoveSelectedVehicle = () => {
    refineSelected(null);
  };
  const handleBackClick = (isConfirmState) => {
    if (isConfirmState) {
      handleRemoveSelectedVehicle();
    } else {
      navigate(`/d/${dealershipId}/stock/list`);
    }
  };

  const mediaCompanyVehicleCardData = {
    make: mediaCompanyVehicleData?.make ?? '',
    model: mediaCompanyVehicleData?.model ?? '',
    fuel: mediaCompanyVehicleData?.fuel ?? '',
    bodyStyle: mediaCompanyVehicleData?.bodyStyle ?? '',
    doors: mediaCompanyVehicleData?.doors ?? '',
    trans: mediaCompanyVehicleData?.trans ?? ''
  };
  const ivendiVehicleCardData = {
    make: iVendiCompanyVehicleData?.make ?? '',
    model: iVendiCompanyVehicleData?.model ?? '',
    fuel: iVendiCompanyVehicleData?.fuel ?? '',
    bodyStyle: iVendiCompanyVehicleData?.bodyStyle ?? '',
    doors: iVendiCompanyVehicleData?.doors ?? '',
    trans: iVendiCompanyVehicleData?.trans ?? ''
  };
  const selectedSessionVehicleCardData = {
    make: selectedTaxonomyDetails?.Make ?? '',
    model: selectedTaxonomyDetails?.Model ?? '',
    fuel: selectedTaxonomyDetails?.FuelType ?? '',
    bodyStyle: selectedTaxonomyDetails?.BodyStyle ?? '',
    doors: selectedTaxonomyDetails?.Doors ?? '',
    trans: selectedTaxonomyDetails?.Transmission ?? ''
  };
  const renderDynamicTranslations = () => {
    const translations = getMismatchingKeys(mediaCompanyVehicleCardData, ivendiVehicleCardData).map(
      (key) => mappedTranslations[key]
    );
    // this formats list of values with commas and language appropriate conjunctions
    return t('StockListFixIssuesContainer.vehicleDetailsList', { val: translations });
  };
  const handleScrollTo = (ref) => {
    handleTabsChange(1);
    ref.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const handlePaginationChange = () => {
    scrollToRef(searchBarRef);
  };
  const vrm = mediaCompanyVehicleData?.vrm ?? '';
  const vin = mediaCompanyVehicleData?.vin ?? '';
  const dealerReference = mediaCompanyVehicleData?.dealerReference ?? '';
  const fuel = mediaCompanyVehicleData?.fuel ?? '';
  const shouldDisplayFixIssuesCardAtStart =
    !compareVehicleSpecs(mediaCompanyVehicleCardData, ivendiVehicleCardData) && !visVehicleSelected;
  return (
    <Page>
      {/* TODO: handle vehicles without vrm or remove implementation */}
      {vrm && <TrackPageView dealershipId={dealershipId} vrm={vrm} />}
      <StockBreadcrumbs
        items={[
          {
            name: t('Common.stock_list'),
            path: `/d/${dealershipId}/stock/list`
          },
          {
            name: t('Common.fix_issues')
          }
        ]}
      />
      {showVRM && vrm && (
        <div className={styles.vrmCard}>
          <VRMPlate vrm={vrm} fuel={fuel} />
        </div>
      )}
      {showVehicleNumber && (dealerReference || vin) && (
        <div className={styles.vrmCard}>
          <VehicleNumber value={dealerReference || vin} />
        </div>
      )}
      {shouldDisplayFixIssuesCardAtStart ? (
        <FixIssuesAlertCard
          iconName="alert"
          title={t('StockListFixIssuesContainer.there_is_a_problem')}
          paragraph={t(
            'StockListFixIssuesContainer.your_supplied_vehicle_does_not_match_with_what_we_use_for_quoting_please_select_the_correct_information_to_be_able_to_continue_with_your_quote'
          )}
        >
          <span
            className={styles.alertLink}
            onClick={() => handleScrollTo(comparisonCards.current)}
          >{`${renderDynamicTranslations()} ${t(
            'StockListFixIssuesContainer.dont_match_the_supplied_vehicle_information'
          )}`}</span>
        </FixIssuesAlertCard>
      ) : null}
      <Panel>
        <PanelHeader>
          {visVehicleNotSelected
            ? t('StockListFixIssuesContainer.please_select_the_correct_vehicle_to_quote_with')
            : null}
        </PanelHeader>
        <PanelContent noPadding>
          <div className={styles.comparisonOuter}>
            <div className={styles.information}>
              {visVehicleNotSelected ? (
                <>
                  <Icon name="information" />
                  <p className={styles.comparisonTitle}>
                    {t(
                      'StockListFixIssuesContainer.your_supplied_vehicle_does_not_match_with_what_we_use_for_quoting_please_select_the_correct_information_to_be_able_to_continue_with_your_quote'
                    )}
                  </p>
                </>
              ) : null}
            </div>
            <div ref={comparisonCards}>
              <MediaQuery minWidth={650}>
                <span className={styles.comparisonCardOuter}>
                  <ComparisonCards
                    isVehicleSelected={visVehicleSelected}
                    mediaCompanyVehicleCardData={mediaCompanyVehicleCardData}
                    ivendiVehicleCardData={ivendiVehicleCardData}
                    selectedSessionVehicleCardData={selectedSessionVehicleCardData}
                  />
                </span>
              </MediaQuery>
              <MediaQuery maxWidth={649}>
                <span className={styles.comparisonTabOuter}>
                  <ComparisonCards
                    isVehicleSelected={visVehicleSelected}
                    tabs
                    handleTabsChange={handleTabsChange}
                    tabIndex={tabIndex}
                    mediaCompanyVehicleCardData={mediaCompanyVehicleCardData}
                    ivendiVehicleCardData={ivendiVehicleCardData}
                    selectedSessionVehicleCardData={selectedSessionVehicleCardData}
                  />
                </span>
              </MediaQuery>
            </div>
            <div ref={searchBarRef} />
            {/* Search State */}
            {visVehicleNotSelected ? (
              <>
                <div id="searchBarContainer" className={styles.searchBar}>
                  <SearchBox />
                  <p className={styles.confirmVehicleText}>
                    {t('StockListFixIssuesContainer.confirm_your_vehicle_derivative_from_the_search_results_below')}
                  </p>
                </div>
                <div className={styles.visResults}>
                  <Hits />
                  {noResults && (
                    <p className={styles.noResults}>{t('StockListFixIssuesContainer.no_vehicles_found')}</p>
                  )}
                  <div className={styles.buttonContainer}>
                    <div className={styles.button}>
                      <Button buttonStyle="secondary" onClick={() => handleBackClick(visVehicleSelected)}>
                        {t('StockListFixIssuesContainer.back')}
                      </Button>
                    </div>
                  </div>
                </div>
                {displayPagination && <Pagination onChange={handlePaginationChange} />}
              </>
            ) : null}
            {/* Search disappears and selected Vis Card is Displayed */}
            {visVehicleSelected ? (
              <>
                <FixIssuesAlertCard
                  iconName="warning"
                  title={t('StockListFixIssuesContainer.warning')}
                  className={styles.warningPositioning}
                  paragraph={t(
                    'StockListFixIssuesContainer.fixing_this_issue_will_only_change_the_vehicle_information_we_use_for_quoting_it_will_not_change_the_information_displayed_from_your_stock_feed'
                  )}
                />
                <div className={styles.visResults}>
                  <FixIssuesVehicleCard onClick={() => null} vehicle={selectedTaxonomyDetails} isSelected={true} />
                </div>
                <div className={styles.mobileButtonGrid}>
                  <Button className={styles.quoteButton} onClick={() => handleNextAction('fixAndQuote')}>
                    {t('StockListFixIssuesContainer.quote')}
                  </Button>
                  <Button
                    className={styles.backButton}
                    buttonStyle="secondary"
                    onClick={() => handleBackClick(visVehicleSelected)}
                  >
                    {t('StockListFixIssuesContainer.back')}
                  </Button>
                  <Button
                    className={styles.saveExitButton}
                    buttonStyle="secondary"
                    state={confirmingState}
                    onClick={() => handleNextAction('fixAndExit')}
                  >
                    {t('StockListFixIssuesContainer.save_exit')}
                  </Button>
                </div>
              </>
            ) : null}
          </div>
        </PanelContent>
      </Panel>
    </Page>
  );
};

StockListFixIssuesContainer.propTypes = {
  appStore: PropTypes.object,
  stockStore: PropTypes.object,
  currentVehicle: PropTypes.object,
  params: PropTypes.object,
  dealershipId: PropTypes.string,
  vehicleVrm: PropTypes.string,
  getVisVehicles: PropTypes.func,
  visVehicles: PropTypes.array
};

function mapDispatchToProps(dispatch) {
  return {
    changeQuickQuoteVehicle: ({ selectedTaxonomyDetails, mediaCompanyVehicleData }) => {
      const capId =
        selectedTaxonomyDetails?.DataSource?.toLowerCase() === 'cap'
          ? selectedTaxonomyDetails.SecondaryTaxonomyId
          : null;
      const vehicle = {
        CapId: capId,
        Class: selectedTaxonomyDetails.Class,
        Derivative: selectedTaxonomyDetails.Derivative,
        Make: selectedTaxonomyDetails.Make,
        Mileage: isDefined(mediaCompanyVehicleData.mileage) && mediaCompanyVehicleData.mileage.toString(),
        Model: selectedTaxonomyDetails.Model,
        Condition: mediaCompanyVehicleData?.condition || 'used', // AlgoliaVehicle.js,
        Price: mediaCompanyVehicleData.price,
        RegistrationDate: moment(mediaCompanyVehicleData?.vrmYear).format('DD/MM/YYYY'),
        Vrm: mediaCompanyVehicleData.vrm,
        // MakeId, ModelId might be depreciated, not seen on VIS, Algolia or StockModule responses
        MakeId: selectedTaxonomyDetails.MakeId,
        ModelId: selectedTaxonomyDetails.ModelId,
        // same as CapId.  ref:addVehicleActions.js, QuickQuoteContainer.js, repExampleModule.js
        DerivativeId: capId
      };

      if (vehicle?.Class?.toLowerCase() === 'van') {
        vehicle.Class = 'lcv';
      }
      dispatch(quickQuoteActions.changeQuickQuoteVehicle(vehicle));
    }
  };
}

export default compose(
  withNavigate,
  withParams,
  withTranslation('Stock'),
  inject('appStore'),
  observerForHooks({
    uiState: (props) => props.appStore.uiState
  }),
  connect(null, mapDispatchToProps)
)(StockListFixIssuesContainer);
