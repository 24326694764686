import React from 'react';
import PropTypes from 'prop-types';
import DateFormat from '../Common/DateFormat';
import MarkDown from '../Common/MarkDown';
import './platformUpdatesItem.scss';

const PlatformUpdatesItem = ({ update }) => {
  return (
    <div className="platformUpdatesItem">
      <div className="platformUpdatesItem__date">
        <DateFormat value={update.CreatedDate} parse="utc" format="MMMM YYYY" />
      </div>
      <div className="platformUpdatesItem__title">{update.Title}</div>
      <MarkDown>{update.Content}</MarkDown>
    </div>
  );
};

PlatformUpdatesItem.propTypes = {
  update: PropTypes.object
};

export default PlatformUpdatesItem;
