import { withTranslation } from 'react-i18next';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import VehicleForm from '../../Common/forms/VehicleForm';
import RepExampleSelectQuoteForm from './RepExampleSelectQuoteForm';
import RepExampleVehiclePriceForm from './RepExampleVehiclePriceForm.js';
import './repExampleCustom.scss';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';

class RepExampleCustom extends Component {
  componentDidMount() {
    this.pageChangeReaction = reaction(
      () => this.props.appStore.repExampleStore.customPage,
      () => document.getElementsByClassName('repExampleCustom__repExampleContainer')[0].scrollIntoView(),
      {
        fireImmediately: true
      }
    );
  }

  componentWillUnmount() {
    this.pageChangeReaction();
  }

  handleVehicleSubmit = (vehicle) => {
    this.props.appStore.repExampleStore.setVehicle(vehicle);
    this.props.appStore.repExampleStore.setCustomPage('pricingForm');
    window.ga && window.ga('send', 'event', 'RepExSelectVehicle', 'Next', 'RepExampleVehicleDetailsSubmit');
  };
  handleCancel = () => {
    window.history.back();
    window.ga && window.ga('send', 'event', 'RepExSelect', 'CancelCustomRepExample', 'RepExampleCancelCustom');
  };
  handleSelectFormGoBack = () => {
    this.props.appStore.repExampleStore.setCustomPage('pricingForm');
  };
  handleVehicleGoBack = () => {
    this.props.appStore.repExampleStore.setCustomPage('');
  };

  render() {
    return (
      <div className="repExampleCustom__repExampleContainer" data-th="RepExampleCustom">
        {this.props.appStore.repExampleStore.quoteRequest.hasError &&
          this.props.appStore.repExampleStore.customPage === 'vehicleForm' && (
            <div className="repExampleCustom__error">
              {this.props.t('RepExampleCustom.there_was_an_error_fetching_the_quote_please_try_again')}
            </div>
          )}
        {this.props.appStore.repExampleStore.setRepExample.hasError &&
          this.props.appStore.repExampleStore.customPage === 'vehicleForm' && (
            <div className="repExampleCustom__error">
              {this.props.t('RepExampleCustom.there_was_an_error_saving_the_quote_please_try_again')}
            </div>
          )}
        {this.props.appStore.repExampleStore.customPage === 'vehicleForm' && (
          <div className="repExampleCustom__selectRepVehicle">
            <div className="repExampleCustom__title">
              {this.props.t('RepExampleCustom.select_a_representative_vehicle')}
            </div>
            <VehicleForm
              initialData={this.props.appStore.repExampleStore.vehicle}
              onCancel={this.handleVehicleGoBack}
              onSubmit={this.handleVehicleSubmit}
              autoLookup
              submitLabel={this.props.t('RepExampleCustom.next')}
              cancelLabel={this.props.t('RepExampleCustom.go_back')}
              fullWidth
            />
          </div>
        )}
        {this.props.appStore.repExampleStore.customPage === 'pricingForm' && (
          <div className="repExampleCustom__selectQuoteDetails">
            <div className="repExampleCustom__title">{this.props.t('RepExampleCustom.select_price_details')}</div>
            <RepExampleVehiclePriceForm />
          </div>
        )}
        {this.props.appStore.repExampleStore.customPage === 'showQuotes' && (
          <div className="repExampleCustom__selectRepQuote">
            <div className="repExampleCustom__title">
              {this.props.t('RepExampleCustom.select_representative_quote')}
            </div>
            {this.props.appStore.repExampleStore.quoteRequest.isLoading ? (
              <div className="repExampleCustom__loader">
                <LoadingSpinner />
              </div>
            ) : (
              <RepExampleSelectQuoteForm
                handleSubmit={this.props.setNewCurrentRepExample}
                dealershipId={this.props.dealershipId}
                handleCancel={this.handleSelectFormGoBack}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

RepExampleCustom.propTypes = {
  appStore: PropTypes.object,
  setNewCurrentRepExample: PropTypes.func,
  dealershipId: PropTypes.string
};
export default withTranslation('RepExampleSettings')(inject(['appStore'])(observer(RepExampleCustom)));
