import React from 'react';
import PropTypes from 'prop-types';
import './hiddenField.scss';

class HiddenField extends React.Component {
  getHiddenValue = (value) => {
    return value.split('').map(() => '*');
  };

  render() {
    const { label, children, value, isHidden } = this.props;
    return (
      <div className="hiddenField">
        {isHidden ? (
          <div className="hiddenField__inner">
            {label && <div className="hiddenField__label">{label}</div>}
            <div className="hiddenField__input">
              <div className="hiddenField__inputInner">{this.getHiddenValue(value)}</div>
            </div>
          </div>
        ) : (
          <div>{React.cloneElement(children, this.props)}</div>
        )}
      </div>
    );
  }
}

HiddenField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.node,
  isHidden: PropTypes.bool
};

export default HiddenField;
