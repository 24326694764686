import React from 'react';
import './loadingDots.scss';

const LoadingDots = () => {
  return (
    <div className="loadingIndicator">
      <div className="loadingIndicator__segment" />
      <div className="loadingIndicator__segment" />
      <div className="loadingIndicator__segment" />
    </div>
  );
};

export default LoadingDots;
