import 'requestanimationframe';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Provider as MobxStoreProvider } from 'mobx-react';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import store from './configureStore';
import * as debug from './debug';
import dealershipEventEmitter from './utils/dealershipEventEmitter';
import routes from './routes/routes';
import FastClick from 'fastclick';
import 'whatwg-fetch';
import appStore from './mobx-stores/appStore';
import { useStrict } from 'mobx';
import Avo from './lib/Avo';
import './styles/app.scss';
import * as snowplow from '@ivendi/snowplow-js';
import './console.image';
import ErrorBoundary from './components/Errors/ErrorBoundary';
import i18n, { i18nInit } from './i18n';
import { recreateOptions } from './redux/options/optionsActions';
import LoadingDots from './components/Common/Loading/LoadingDots';
import { appendHubWidgets } from './components/Webshop/PushDealHubWidgets';
import { basename, history } from './routerHistory';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { avoEnv } from './utils/env';
import { initialize } from './redux/app/appActions';
import { destinationInterface } from 'lib/RudderstackDestination';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import trackOutboundLinks from './tracking/trackOutboundLinks';

const SNOWPLOW_URL = process.env.REACT_APP_SNOWPLOW_URL;

// eslint-disable-next-line react-hooks/rules-of-hooks
useStrict(true);

let showDebugger =
  typeof window !== 'undefined' &&
  typeof navigator !== 'undefined' &&
  (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') &&
  !navigator.userAgent.toLowerCase().includes('headless');

Avo.initAvo(
  {
    env: avoEnv,
    webDebugger: showDebugger
  },
  {
    cookieConsentAnalyticalGranted: true,
    cookieConsentFunctionalGranted: true,
    cookieConsentMarketingGranted: true,
    countryCode: process.env.REACT_APP_REGION,
    platformName: 'dealer-platform',
    platformId: 'ivendi-dealer-platform',
    platformVersion: '2' // TECH DEBT: This should be the commit hash of the current build
  },
  {},
  destinationInterface
);

FastClick.attach(document.body);

debug.init();

snowplow.config({
  snowplowUrl: SNOWPLOW_URL,
  appVersion: '2',
  appId: 'ivendi-dealer-platform',
  debug: (log) => {
    debug.log(log);
  }
});

snowplow.init().catch(() => {
  debug.error('Snowplow tracking disabled');
});

if (process.env.REACT_APP_CAN_USE_HOTJAR === 'true') {
  (function(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: process.env.REACT_APP_HOTJAR_ID, hjsv: process.env.REACT_APP_HOTJAR_SNIPPER_VERSION };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}

dealershipEventEmitter.debug(function(log) {
  debug.log(log);
});

history.listen(({ location }) => {
  var path = location.pathname.replace('/dealer/platform', '');
  path = location.pathname.replace('/dealer/beta', '');
  if (window.ga) {
    window.ga('send', 'pageview', path);
  } else {
    debug.error('Google analytics failed to load.');
  }
});

//Draw iVendi logo in console
if (window.console && typeof window.console === 'object' && window.console.image) {
  /* eslint-disable */
  console.image(process.env.PUBLIC_URL + '/images/ivendi_logo.png', 364, 174);
  /* eslint-enable */
}

appendHubWidgets(appStore, store);

i18n.on('initialized', function() {
  store.dispatch(recreateOptions());
});

i18n.on('languageChanged', function() {
  store.dispatch(recreateOptions());
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
});

export const growthbook = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: true,
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {}
});

store.dispatch(initialize());

document.addEventListener('click', trackOutboundLinks);

i18nInit.finally(() => {
  ReactDOM.render(
    <Suspense fallback={<LoadingDots />}>
      <QueryClientProvider client={queryClient}>
        <GrowthBookProvider growthbook={growthbook}>
          <Provider store={store}>
            <MobxStoreProvider appStore={appStore}>
              <TooltipProvider>
                <HistoryRouter history={history} basename={basename}>
                  <ErrorBoundary>{routes}</ErrorBoundary>
                </HistoryRouter>
              </TooltipProvider>
            </MobxStoreProvider>
          </Provider>
        </GrowthBookProvider>
      </QueryClientProvider>
    </Suspense>,
    document.getElementById('root')
  );
});
