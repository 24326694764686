import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../Common/Panel';
import PanelContent from '../../Common/PanelContent';
import { observer, inject } from 'mobx-react';
import LenderPrivacy from '../components/LenderPrivacy';
import LenderContent from '../components/LenderContent';
import LenderQuestionnaire from '../components/LenderQuestionnaire';
import { getConsumerDutyDetails } from '../../../api/contentService/utils';
import SuitabilityQuestions from '../components/SuitabilityQuestions';
import { canSeeConsumerDutyContent as isConsumerDutyEnabled, hasCompanionQuotes } from '~/features';
import { findCompanionQuoteByType } from '~/components/QuoteCard/utils/getQuoteDisplaySchema';
import { LoanTypeJs } from '~/components/QuoteCard/types';

class ConsumerDutyContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentView: 'initialising'
    };
  }

  canSeeConsumerDutyContent = !this.props.appStore.uiState.hideConsumerDutyContent && isConsumerDutyEnabled();

  componentDidMount = () => {
    // Needed to hide the consumer duty content for BNP that have ValueAddedProduct or NegativeEquity
    const isBnppNoneVehicleLoan =
      this.props.appStore.uiState.isBnpp &&
      (this.props.chosenQuote.LoanType === 'ValueAddedProduct' || this.props.chosenQuote.LoanType === 'NegativeEquity');

    const isPreApproval = this.props.chosenQuote.SupportsPreApproval && !this.props.isRequote;

    if (isBnppNoneVehicleLoan || isPreApproval) {
      this.canSeeConsumerDutyContent = false;
    }
    this.canSeeConsumerDutyContent
      ? this.setState({ currentView: 'content' })
      : this.setState({ currentView: 'privacyPolicy' });
  };

  render() {
    const { chosenQuote, customerType, dealershipId } = this.props;
    const isCorporate = customerType.toLowerCase() === 'corporate';
    const isCombinedQuote = chosenQuote.PersonalLoan || chosenQuote.NegativeEquityLoan;

    let consumerDutyDetails;
    let financeType = chosenQuote.FinanceType;

    if (isCombinedQuote) {
      consumerDutyDetails = getConsumerDutyDetails(chosenQuote.VehicleLoan, customerType.toLowerCase(), dealershipId);
    } else if (hasCompanionQuotes(chosenQuote)) {
      const vehicleLoan = findCompanionQuoteByType(chosenQuote.CompanionQuotes, LoanTypeJs.vehicleLoan);
      consumerDutyDetails = getConsumerDutyDetails(vehicleLoan, customerType.toLowerCase(), dealershipId);
      financeType = vehicleLoan.FinanceType;
    } else {
      consumerDutyDetails = getConsumerDutyDetails(chosenQuote, customerType.toLowerCase(), dealershipId);
    }

    return (
      <>
        <Panel>
          <PanelContent>
            {this.canSeeConsumerDutyContent && (
              <>
                {this.state.currentView === 'content' && (
                  <LenderContent
                    consumerDutyDetails={consumerDutyDetails}
                    onCancel={this.props.onCancel}
                    onContinue={() => this.setState({ currentView: 'questionnaire' })}
                  ></LenderContent>
                )}

                {this.state.currentView === 'questionnaire' && (
                  <LenderQuestionnaire
                    consumerDutyDetails={consumerDutyDetails}
                    onCancel={() => this.setState({ currentView: 'content' })}
                    onContinue={() => {
                      this.setState({ currentView: !isCorporate ? 'suitabilityQuestions' : 'privacyPolicy' });
                    }}
                  ></LenderQuestionnaire>
                )}

                {this.state.currentView === 'suitabilityQuestions' && (
                  <SuitabilityQuestions
                    quote={chosenQuote}
                    financeType={financeType}
                    dealershipId={dealershipId}
                    onCancel={() => {
                      this.setState({ currentView: 'questionnaire' });
                    }}
                    onContinue={() => this.setState({ currentView: 'privacyPolicy' })}
                  ></SuitabilityQuestions>
                )}
              </>
            )}

            {this.state.currentView === 'privacyPolicy' && (
              <LenderPrivacy
                dealershipId={dealershipId}
                funderCode={this.props.chosenQuote.FunderCode}
                onCancel={() => {
                  if (this.canSeeConsumerDutyContent && !isCorporate) {
                    this.setState({ currentView: 'suitabilityQuestions' });
                  } else if (this.canSeeConsumerDutyContent) {
                    this.setState({ currentView: 'questionnaire' });
                  } else {
                    this.props.onCancel();
                  }
                }}
                onContinue={() => this.props.onContinue()}
              ></LenderPrivacy>
            )}
          </PanelContent>
        </Panel>
      </>
    );
  }
}

ConsumerDutyContainer.propTypes = {
  chosenQuote: PropTypes.object,
  customerType: PropTypes.string,
  onCancel: PropTypes.func,
  onContinue: PropTypes.func,
  appStore: PropTypes.object,
  dealershipId: PropTypes.string,
  isRequote: PropTypes.bool
};

export default withTranslation('Quoting')(inject('appStore')(observer(ConsumerDutyContainer)));
