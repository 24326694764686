import { useQuery, UseQueryResult } from '@tanstack/react-query';
import handleRequest from '../utils/handleRequest';
import handleErrorResponse, { FetchError } from '../utils/handleErrorResponse';
import { Metric, ApiResponse } from '../../types';

export enum ChartDimensions {
  Date = 'Date',
  Origin = 'Origin',
  Vehicle = 'Vehicle'
}
export interface ChartQueryProps {
  metric: Metric;
  dimensions: ChartDimensions[];
  fromDate: string;
  toDate: string;
  dealershipId: string | undefined;
  datePeriod?: 'Hour' | 'Day' | 'Month' | 'Auto';
}
const useChartData = ({
  metric,
  dimensions = [ChartDimensions.Date],
  fromDate,
  toDate,
  datePeriod = 'Auto',
  dealershipId
}: ChartQueryProps): UseQueryResult<ApiResponse, FetchError> => {
  const stringifiedBody = JSON.stringify({
    metric,
    dimensions,
    fromDate,
    toDate,
    datePeriod
  });

  return useQuery(['chartData', metric, dimensions, fromDate, toDate, datePeriod], async () => {
    try {
      const response = await handleRequest(`/api-module/analytics/metric/${dealershipId}/query`, {
        method: 'POST',
        body: stringifiedBody
      });

      if (!response.ok) {
        throw await handleErrorResponse(response);
      }

      return await response.json();
    } catch (error) {
      if (error instanceof FetchError) {
        throw error;
      }
      throw new Error('An unexpected error occurred');
    }
  });
};

export default useChartData;
