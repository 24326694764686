import { cloneDeep } from 'lodash';
import { toJS } from 'mobx';
import appStore from '~/mobx-stores/appStore';
import AlgoliaVehicle from '~/mobx-models/AlgoliaVehicle';
import { createImageSource } from '../../StockListVehicleImage/StockListVehicleImage';

interface PushDealVehicle extends AlgoliaVehicle {
  imageSource?: string;
}

interface Props {
  vehicle: AlgoliaVehicle;
  countryCode: string | null;
}
export const pushDeal = ({ vehicle, countryCode }: Props) => {
  let pushDealVehicle: PushDealVehicle = cloneDeep(vehicle);
  /*
   * For the German market, we cannot rely on the taxonomy data and it is more likely to be missing,
   * instead, we need to use the dealer provided vehicle data first, as it's more reliable. If that's missing
   * we can fallback to the taxonomy data.
   * Not having valid vehicle data breaks pre-populating the push deal form with vehicle information.
   */
  if (countryCode?.toLowerCase() === 'de') {
    pushDealVehicle.Model = pushDealVehicle.SModel ?? pushDealVehicle.Model;
    pushDealVehicle.Make = pushDealVehicle.SMake ?? pushDealVehicle.Make;
    pushDealVehicle.Derivative = pushDealVehicle.SDerivative ?? pushDealVehicle.Derivative;
    pushDealVehicle.Class = pushDealVehicle.SClass ?? pushDealVehicle.Class;
  }

  if (pushDealVehicle.Class === 'van') {
    pushDealVehicle.Class = 'lcv';
  }

  pushDealVehicle.imageSource = createImageSource({
    vrm: pushDealVehicle.Vrm,
    vin: pushDealVehicle.Vin,
    dealerReference: pushDealVehicle.DealerReference,
    dealerPlatformUpload: pushDealVehicle.DealerPlatformUpload,
    imagesMetadata: toJS(vehicle.ImagesMetadata), // cloneDeep fails to copy MobX observable arrays
    imgPth: pushDealVehicle.ImgPth
  });

  appStore.pushDealStore.setVehicleFromStock(pushDealVehicle, pushDealVehicle.Price);
};
