import { TFunction } from 'i18next';
import AlgoliaVehicle from '~/mobx-models/AlgoliaVehicle';
import { isBike } from '../../../../../../../shared/helpers/transformTaxonomyData';

interface Props {
  vehicle: AlgoliaVehicle;
  t: TFunction;
  lng: string;
}
export const getVehicleStatsString = ({ vehicle, t, lng }: Props) => {
  let vehicleStats = [];

  const shouldDisplaySeats = !isBike(vehicle?.Class);

  if (vehicle.DealerPlatformUpload) {
    vehicleStats.push(vehicle?.RegistrationDate?.split('/')[2] || t('StockListItem.date_of_reg_missing'));
    vehicle.Mileage && vehicleStats.push(t('StockListItem.miles', { value: vehicle.Mileage }));
    vehicle.Fuel && vehicleStats.push(vehicle.Fuel);
    vehicle.Doors && vehicle.Doors !== '0' && vehicleStats.push(t('StockListItem.doors', { value: vehicle.Doors }));
    vehicle.Transmission && vehicleStats.push(vehicle.Transmission);
    vehicle.BodyStyle && vehicleStats.push(vehicle.BodyStyle);
    vehicle.CC && vehicleStats.push(t('StockListItem.cc', { value: vehicle.CC }));
    vehicle.BadgeEngineSize &&
      Number.isInteger(vehicle.BadgeEngineSize) &&
      vehicleStats.push(t('StockListItem.engineSize', { value: vehicle.BadgeEngineSize.toFixed(1) }));
    vehicle.NoOfSeats &&
      shouldDisplaySeats &&
      vehicleStats.push(t('StockListItem.seats', { value: vehicle.NoOfSeats }));

    if (lng === 'en') {
      vehicle.Bhp && vehicleStats.push(t('StockListItem.bhp', { value: vehicle.Bhp }));
    }
    if (lng === 'de') {
      vehicle.PwrKW && vehicleStats.push(t('StockListItem.kw', { value: vehicle.PwrKW }));
    }
  }
  if (!vehicle.DealerPlatformUpload) {
    vehicleStats.push(vehicle?.RegistrationDate?.split('/')[2] || t('StockListItem.date_of_reg_missing'));
    vehicle.Mileage && vehicleStats.push(t('StockListItem.miles', { value: vehicle.Mileage }));
    vehicle.SFuel && vehicleStats.push(vehicle.SFuel);
    vehicle.Doors && vehicle.Doors !== '0' && vehicleStats.push(t('StockListItem.doors', { value: vehicle.Doors }));
    vehicle.STransmission && vehicleStats.push(vehicle.STransmission);
    vehicle.BodyStyle && vehicleStats.push(vehicle.BodyStyle);
    vehicle.CC && vehicleStats.push(t('StockListItem.cc', { value: vehicle.CC }));
    vehicle.BadgeEngineSize &&
      Number.isInteger(vehicle.BadgeEngineSize) &&
      vehicleStats.push(t('StockListItem.engineSize', { value: vehicle.BadgeEngineSize.toFixed(1) }));
    vehicle.NoOfSeats &&
      shouldDisplaySeats &&
      vehicleStats.push(t('StockListItem.seats', { value: vehicle.NoOfSeats }));

    if (lng === 'en') {
      vehicle.Bhp && vehicleStats.push(t('StockListItem.bhp', { value: vehicle.Bhp }));
    }
    if (lng === 'de') {
      vehicle.PwrKW && vehicleStats.push(t('StockListItem.kw', { value: vehicle.PwrKW }));
    }
  }
  return vehicleStats.join(' | ');
};
