import { ApiResponse } from '../types';

const sumDataSet = (apiResponse: ApiResponse, dataSet: 'sum' | 'avg' = 'sum') => {
  if (dataSet === 'avg') {
    const average = apiResponse.datasets[0].avg;

    return average[average.length - 1];
  }

  return apiResponse.datasets[0].sum.reduce((a, b) => a + b, 0);
};

export default sumDataSet;
