import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  delete draft.applicationStatuses!.Other;
  delete draft.applicationStatuses!.Prospect;
  delete draft.applicationStatuses!.Unknown;
  draft.applicationStatuses.WaitingForFurtherInformation!.isReproposable = true;
  draft.applicationStatuses.WaitingForFurtherInformation!.isComplete = true;
  draft.applicationStatuses.Error!.isReproposable = false;
  draft.applicationStatuses.NotTakenUp!.isEditable = false;
  draft.applicationStatuses.NotProceeding!.isEditable = false;
  draft.applicationStatuses.NotProceeding!.isReproposable = true;
  draft.applicationStatuses.Pending!.isPending = true;
  draft.applicationStatuses.ProblemPayout!.isReproposable = true;
  draft.applicationStatuses.Referred!.isEditable = false;
  draft.applicationStatuses.Referred!.isReproposable = false;
  draft.applicationStatuses.Rejected!.isEditable = false;
  draft.applicationStatuses.SentForPayout!.isEditable = false;
  draft.applicationStatuses.Submitted!.isSubmitting = true;
  draft.applicationStatuses.Submitting!.isEditable = true;
  draft.editSections!.employment = false;
  draft.applicationFields.AffordabilityDetails!.NetMonthly = true;
  draft.applicationFields.AffordabilityDetails!.ForeseeFutureDownturnInFinances = false;
  draft.applicationFields.AffordabilityDetails!.DownturnReason = false;
  draft.applicationFields.AffordabilityDetails!.IsReplacementLoan = false;
  draft.applicationFields.AffordabilityDetails!.DetailsOfReplacementLoan = false;

  delete draft.validationRules.AffordabilityDetails!.PersonalCircumstances;
  delete draft.validationRules.AffordabilityDetails!.ReplacementLoan;
  draft.validationRules.AffordabilityDetails!.Income!.GrossAnnual = 'currency, required';
  draft.validationRules.AffordabilityDetails!.Income!.NetMonthly = 'required, currency';
});
