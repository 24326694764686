import { withTranslation } from 'react-i18next';
import { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../Common/Form/Checkbox';
import QuoteFunderLogo from '../../QuotesList/components/QuoteFunderLogo';
import ProductName from '../../QuotesList/components/ProductName';
import MoneyFormat from '../../Common/MoneyFormat';
import QuoteSmallPrint from './QuoteSmallPrint';
import ErroredQuote from '../../QuotesList/components/ErroredQuote';
import Schemes from '../../QuotesList/components/Schemes';
import QuoteSplitButton from '../../QuotesList/components/QuoteSplitButton';
import QuoteButton from '../../QuotesList/components/QuoteButton';
import EligibilityMatch from '../../Common/EligibilityMatch';
import CombinedQuoteMessage from '../../Common/CombinedQuoteMessage';
import FieldToggle from '../../Common/Form/FieldToggle';
import EligibilityWrapper from '../../QuotesList/mediaComponents/EligibilityWrapper';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import Icon from '../../Common/Icon/Icon';
import Modal from '../../Common/Modal/Modal';
import { fundersToShowPublicWarning } from '../../../core/fundersPublicWarning';
import { addPoundSymbolToString } from '../../../core/helpers';
import _ from 'lodash';
import FormatAPR from './FormatAPR';
import QuoteNotification from '../../../components/QuotesList/components/QuoteNotification';
import './quotesListTableItem.scss';
import { hasExtendedTermLength } from '~/utils/quoteUtils';

@inject('appStore')
@observer
class QuotesListTableItem extends Component {
  state = {
    showItemFullQuote: this.props.showFullQuote,
    modalIsOpen: false
  };

  handleQuoteToggle = () => {
    this.setState((prevState) => ({
      showItemFullQuote: !prevState.showItemFullQuote
    }));
  };

  componentDidUpdate(prevProps) {
    if (this.props.showFullQuote !== prevProps.showFullQuote) {
      this.setState({
        showItemFullQuote: this.props.showFullQuote
      });
    }
  }

  handleMouseOver = () => {
    this.props.handleHover && this.props.handleHover(this.props.quote, true);
  };

  handleMouseOut = () => {
    this.props.handleHover && this.props.handleHover(this.props.quote, false);
  };

  handleProductNameClick = () => {
    const productId = this.props.quote.VehicleLoan
      ? this.props.quote.VehicleLoan.ProductId
      : this.props.quote.ProductId;
    this.props.onProductNameClick(productId);
  };

  handleModal = () => {
    this.setState({
      modalIsOpen: !this.state.modalIsOpen
    });
  };

  handleShowCombined = () => {
    this.props.showCombinedQuoteClick(this.props.quote);
  };

  onProceed = () => {
    if (this.props.quote.PersonalLoan || this.props.quote.NegativeEquityLoan) {
      this.props.proceedToBreakDown(this.props.quote);
    } else {
      this.props.onProceed(this.props.quote);
    }
  };

  handleQuoteSave = () => {
    this.props.onSave(this.props.quote);
  };

  renderQuoteButton = () => {
    let isCombinedQuote = this.props.quote.PersonalLoan || this.props.quote.NegativeEquityLoan;
    const isQuickQuoteAndExtendedTermVehicle =
      this.props.isQuickQuote && hasExtendedTermLength(this.props.vehicleClass);

    if (this.props.quoteButtonChild) {
      return this.props.quoteButtonChild;
    } else if (
      ((!this.props.isQuickQuote || isQuickQuoteAndExtendedTermVehicle) && !this.props.isCustomerQuote) ||
      isCombinedQuote
    ) {
      return (
        <>
          {this.props.appStore.uiState.canCreateApplications && (
            <QuoteButton
              label={this.props.proceedLabel}
              loadingState={this.props.chosenQuoteId === this.props.quote.QuoteId ? this.props.proceedingState : ''}
              onClick={this.onProceed}
              isSelected={this.props.quote.isChecked}
            />
          )}
        </>
      );
    } else if (this.props.appStore.uiState.canCreateApplications) {
      return (
        <QuoteSplitButton
          onSave={this.handleQuoteSave}
          onProceed={this.onProceed}
          state={this.props.chosenQuoteId === this.props.quote.QuoteId ? this.props.proceedingState : ''}
          isQuickQuote={this.props.isQuickQuote}
          isSelected={this.props.quote.isChecked}
        />
      );
    } else {
      return (
        <QuoteButton
          label={this.props.t('QuotesListTableItem.save')}
          loadingState={this.props.chosenQuoteId === this.props.quote.QuoteId ? this.props.proceedingState : ''}
          onClick={this.handleQuoteSave}
          isSelected={this.props.quote.isChecked}
        />
      );
    }
  };

  render() {
    const quote = this.props.quote;
    const rbpLabel = this.props.appStore.uiState.dealershipModules.includes('multilender');
    const isRbp = quote.CreditTier && quote.CreditTier !== 'None' && rbpLabel && this.props.appStore.uiState.canUseRbp;
    let isCombinedQuote = quote.PersonalLoan || quote.NegativeEquityLoan;
    const { showItemFullQuote } = this.state;

    const showFunderWarning = (funder) => {
      return funder === quote.FunderCode;
    };

    const publicWarnings = quote?.ConsumerWarnings?.PublicWarnings;

    const hasWarningTwoHundred = _.some(publicWarnings, {
      Number: 200
    });

    const publicWarningsCreationAdvanceErrorMessageNumber = 506;
    const advanceWarningPoundSignPosition = 32;

    const creationAdvancePublicWarningMessage = _.find(publicWarnings, {
      Number: publicWarningsCreationAdvanceErrorMessageNumber
    });

    const creationMessageWithPoundSymbol = addPoundSymbolToString(
      creationAdvancePublicWarningMessage && creationAdvancePublicWarningMessage.Message,
      advanceWarningPoundSignPosition
    );

    const showCombinedDealDisclaimer = this.props.showCombinedDealDisclaimer;
    const showInsuranceWarning = fundersToShowPublicWarning.findIndex(showFunderWarning) !== -1 && hasWarningTwoHundred;
    const expandQuotesButtonClasses = classnames(
      'quotesListTableItem__smallPrintButtonWrap',
      showInsuranceWarning && 'quotesListTableItem__smallPrintButtonWrap--withWarning'
    );

    if (quote.Errors) {
      return (
        <div className="quotesListTableItem" data-thook={this.props.trackingPage}>
          <div className="quotesListTableItem__erroredQuote">
            <div className="quotesListTableItem__errorQuote--funderLogo">
              <QuoteFunderLogo funder={quote.FunderCode} />
            </div>

            <div className="quotesListTableItem__errorQuoteInfo--product">
              <div className="quotesListTableItem__quoteInfo--header">
                {this.props.t('QuotesListTableItem.product')}
              </div>

              <div className="quotesListTableItem__quoteInfo--erroredName quotesListTableItem__quoteInfo--productName">
                <ProductName
                  disabled={this.props.disableProductNameClick || this.props.appStore.uiState.isBdk}
                  productName={quote.ProductName}
                  onClick={this.handleProductNameClick}
                />
              </div>
            </div>

            <div className="quotesListTableItem__errorQuoteInfo--messages">
              {quote.Errors.map((error, index) => (
                <ErroredQuote errorNumber={error.Number} errorMessage={error.Message} key={index} noBorder />
              ))}
            </div>
          </div>
        </div>
      );
    }

    const isSelectedClasses = classnames('quotesListTableItem', quote.isChecked && 'quotesListTableItem__selected');

    return (
      <>
        <div
          className={isSelectedClasses}
          onMouseEnter={this.handleMouseOver}
          onMouseLeave={this.handleMouseOut}
          data-thook={this.props.trackingPage}
        >
          {this.props.hasCheckedEligibility && (
            <EligibilityWrapper>
              <EligibilityMatch
                matchrate={quote.MatchRate}
                onClick={() => this.props.onEligibilityMatchClick(quote.MatchRate, quote.DecisionMessages)}
                personalScorePercentage={quote.personalScorePercentage}
                craScorePercentage={quote.craScorePercentage}
                forceShowViewDetails={true}
              />
            </EligibilityWrapper>
          )}

          <div className="quotesListTableItem__top">
            <div className="quotesListTableItem__checkbox">
              {!this.props.hideCheckbox && (
                <Checkbox
                  id={'compare' + quote.QuoteId}
                  onChange={(checked) => this.props.onQuoteSelect(quote.ProductId, checked)}
                  value={quote.isChecked}
                />
              )}
            </div>
          </div>

          <div className="quotesListTableItem__quoteInfo">
            <div className="quotesListTableItem__funderLogo">
              <QuoteFunderLogo funder={quote.FunderCode} />
            </div>

            <div className="quotesListTableItem__quoteInfo--spacer" />

            <div className="quotesListTableItem__quoteInfo--col quotesListTableItem__quoteInfo--product">
              <div className="quotesListTableItem__quoteInfo--header">
                <div>{this.props.t('QuotesListTableItem.product')}</div>
              </div>

              <div className="quotesListTableItem__quoteInfo--figure quotesListTableItem__quoteInfo--productName">
                <ProductName
                  disabled={
                    !this.props.appStore.uiState.canEditQuotingCommissions ||
                    this.props.disableProductNameClick ||
                    this.props.appStore.uiState.isBdk
                  }
                  productName={quote.ProductName}
                  onClick={this.handleProductNameClick}
                />
              </div>
            </div>

            <div className="quotesListTableItem__quoteInfo--spacer" />

            <div className="quotesListTableItem__quoteInfo--col quotesListTableItem__quoteInfo--apr">
              <div className="quotesListTableItem__quoteInfo--header">{this.props.t('QuotesListTableItem.apr')}</div>
              <div className="quotesListTableItem__quoteInfo--figure">
                <FormatAPR value={quote?.RepresentativeApr} />
              </div>
            </div>

            <div className="quotesListTableItem__quoteInfo--spacer" />
            <div className="quotesListTableItem__quoteInfo--col quotesListTableItem__quoteInfo--totalPayable">
              <div className="quotesListTableItem__quoteInfo--header">
                {this.props.t('QuotesListTableItem.total_amount_payable')}
              </div>

              <div className="quotesListTableItem__quoteInfo--figure">
                <MoneyFormat value={quote.TotalAmountPayable} />
              </div>
            </div>

            <div className="quotesListTableItem__quoteInfo--spacer" />

            <div className="quotesListTableItem__quoteInfo--col">
              <div className="quotesListTableItem__quoteInfo--header">
                {this.props.t('QuotesListTableItem.agreement_term')}
              </div>

              <div className="quotesListTableItem__quoteInfo--figure">
                {quote.Term + ' ' + this.props.t('QuotesListTableItem.months')}
              </div>
            </div>

            <div className="quotesListTableItem__quoteInfo--spacer" />

            <div className="quotesListTableItem__quoteInfo--col">
              <div className="quotesListTableItem__quoteInfo--header">
                {this.props.t('QuotesListTableItem.regular_monthly_payment')}
              </div>

              <div className="quotesListTableItem__quoteInfo--figure">
                <MoneyFormat value={quote.FollowingPayments} />
              </div>
            </div>

            {this.props.hideButton !== true && (
              <div className="quotesListTableItem__quoteInfo--quoteButton">{this.renderQuoteButton()}</div>
            )}
          </div>

          <div className="quotesListTableItem__quoteWarningWrap">
            {publicWarnings &&
              publicWarnings.map(({ Message }, index) => (
                <QuoteNotification errorTitle={Message} key={`quote-box-public-warning-${index}`} />
              ))}
          </div>

          <div className="quotesListTableItem__quoteSmallPrint">
            <QuoteSmallPrint
              quote={quote}
              vehicleClass={this.props.vehicleClass}
              showFullQuote={showItemFullQuote}
              isPersonalQuote={this.props.isPersonalQuote}
              newCarOfferText={this.props.newCarOffer && this.props.newCarOffer.OfferText}
            />
            <div className="quotesListTableItem__smallPrintButtons">
              <div className="quotesListTableItem__combinedQuoteMessage">
                {showCombinedDealDisclaimer && (
                  <div className="quotesListTableItem__warningOuter" onClick={this.handleModal}>
                    <div className="quotesListTableItem__warningIcon">
                      <Icon name="alert" />
                    </div>

                    <Modal isOpen={this.state.modalIsOpen} onClose={this.handleModal}>
                      <div className="quotesListTableItem__modalOuter">
                        <div className="quotesListTableItem__modalTop">
                          {this.props.t('QuotesListTableItem.this_deal_may_require_restructuring_before_proposal')}
                        </div>
                        <div className="quotesListTableItem__modalBottom">
                          {this.props.t(
                            'QuotesListTableItem.the_customer_can_complete_their_finance_application_via_their_account_but_deals_which_include_the_funding_of_value_added_products_or_negative_equity_may_need_to_be_restructured_afterwards_into_separate_quotes_and_applications_before_submission_to_creation_consumer_finance_limited'
                          )}
                        </div>
                      </div>
                    </Modal>

                    <div className="quotesListTableItem__warning">
                      {this.props.t('QuotesListTableItem.this_deal_may_require_restructuring_before_proposal')}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="quotesListTableItem__quoteSmallPrint--footer">
              {showInsuranceWarning && (
                <div className="quotesListTableItem__warningOuter" onClick={this.handleModal}>
                  <div className="quotesListTableItem__warningIcon">
                    <Icon name="alert" />
                  </div>

                  <Modal isOpen={this.state.modalIsOpen} onClose={this.handleModal}>
                    <div className="quotesListTableItem__modalOuter">
                      <div className="quotesListTableItem__modalTop">
                        {this.props.t('QuotesListTableItem.this_lender_does_not_finance_insurance_products')}
                      </div>
                      <div className="quotesListTableItem__modalBottom">
                        {this.props.t(
                          'QuotesListTableItem.if_insurance_has_been_included_in_your_finance_example_this_may_need_to_be_recalculated_before_submission'
                        )}
                      </div>
                    </div>
                  </Modal>

                  <div className="quotesListTableItem__warning">
                    {this.props.t(
                      'QuotesListTableItem.this_lender_does_not_finance_insurance_products_if_insurance_has_been_included_in_your_finance_example_this_may_need_to_be_recalculated_before_submission'
                    )}
                  </div>
                </div>
              )}

              <div className="quotesListTableItem__combinedQuoteMessage">
                {creationAdvancePublicWarningMessage && (
                  <div className="quotesListTableItem__warningOuter">
                    <div className="quotesListTableItem__warningIcon">
                      <Icon name="alert" />
                    </div>
                    <div>{creationMessageWithPoundSymbol}</div>
                  </div>
                )}

                {isCombinedQuote && (
                  <CombinedQuoteMessage
                    numberOfIcons={quote.PersonalLoan && quote.NegativeEquityLoan ? 3 : 2}
                    onClick={this.handleShowCombined}
                    colour="grey"
                  />
                )}

                <div>
                  {isRbp && (
                    <div className="quotesListTableItem__rbp">
                      {this.props.t('QuotesListTableItem.this_apr_is_based_on_the_customers_credit_rating')}
                    </div>
                  )}
                </div>
              </div>

              <div className={expandQuotesButtonClasses}>
                <FieldToggle
                  showHide={showItemFullQuote}
                  text={this.props.t('QuotesListTableItem.full_quote')}
                  onClick={this.handleQuoteToggle}
                  trackingPage={this.props.trackingPage}
                  trackingItem={'IndividualQuote'}
                />
              </div>

              <div className="quotesListTableItem__quoteInfo--schemes quotesListTableItem__quoteInfo--figure">
                <Schemes
                  label={this.props.t('QuotesListTableItem.schemes')}
                  showSchemes={this.props.schemesVisible}
                  commissions={quote.Commissions}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

QuotesListTableItem.propTypes = {
  quote: PropTypes.object,
  hasCheckedEligibility: PropTypes.bool,
  onQuoteSelect: PropTypes.func,
  onProductNameClick: PropTypes.func,
  schemesVisible: PropTypes.bool,
  onProceed: PropTypes.func,
  onSave: PropTypes.func,
  isQuickQuote: PropTypes.bool,
  isCustomerQuote: PropTypes.bool,
  sortDirection: PropTypes.string,
  sortField: PropTypes.string,
  onSortQuotes: PropTypes.func,
  onEligibilityMatchClick: PropTypes.func,
  proceedingState: PropTypes.string,
  chosenQuoteId: PropTypes.string,
  handleHover: PropTypes.func,
  vehicleClass: PropTypes.string,
  showCombinedQuoteClick: PropTypes.func,
  proceedToBreakDown: PropTypes.func,
  showFullQuote: PropTypes.bool,
  canCreateApplications: PropTypes.bool,
  hideCheckbox: PropTypes.bool,
  quoteButtonChild: PropTypes.node,
  disableProductNameClick: PropTypes.bool,
  proceedLabel: PropTypes.string,
  isPersonalQuote: PropTypes.bool,
  trackingPage: PropTypes.string,
  hideButton: PropTypes.bool
};
QuotesListTableItem.defaultProps = {
  disableProductNameClick: false,
  hideCheckbox: false,
  proceedLabel: 'Proceed',
  isPersonalQuote: true,
  trackingPage: 'QuotesListView'
};
export default withTranslation('Quoting')(QuotesListTableItem);
