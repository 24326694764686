import { useTranslation } from 'react-i18next';
import Page from '../../Common/Page';
import Panel from '../../Common/Panel';
import Breadcrumbs from '../../Common/Breadcrumbs';
import PanelHeader from '../../Common/PanelHeader';
import PanelContent from '../../Common/PanelContent';
import Input from '../../Common/Form/Input';
import Button from '../../Common/Button/Button';
import PanelFooter from '../../Common/PanelFooter';
import { useQuery } from '@tanstack/react-query';
import FieldGroup from '../../Common/Form/FieldGroup';
import LoadingSpinner from '../../Common/Loading/LoadingSpinner';
import { getStatus } from 'api/utils';
import { stockSettingsQueries, useModifyStockSettings } from 'api/stockSettings/hooks';
import useGlobalParams from 'hooks/useGlobalParams';

const FormContent = ({ stockSettings }: { stockSettings?: QueryFactoryData<typeof stockSettingsQueries.detail> }) => {
  const { t } = useTranslation('Settings');

  return (
    <FieldGroup id="mobileDe" label={t('StockSettings.mobile_de_account_num')}>
      <Input id="mobileDe" defaultValue={stockSettings?.mobileDeAccountNumber || ''} />
    </FieldGroup>
  );
};

const StockSettingsPage = () => {
  const { t } = useTranslation('Settings');
  const { dealershipId } = useGlobalParams();
  const { data: stockSettings, error: stockSettingsError, isLoading } = useQuery({
    ...stockSettingsQueries.detail(dealershipId),
    keepPreviousData: true
  });
  const { mutate: modifyStockSettings, isLoading: isUpdating, isError } = useModifyStockSettings();
  const hasLoadingError = !!stockSettingsError && getStatus(stockSettingsError) !== 404;

  return (
    <Page>
      <div className="stockSettings__breadcrumbs">
        <Breadcrumbs
          items={[
            {
              name: t('StockSettings.home'),
              path: `/d/${dealershipId}`
            },
            {
              name: t('StockSettings.settings'),
              path: `/d/${dealershipId}/settings`
            },
            {
              name: t('StockSettings.stock_settings'),
              path: `/d/${dealershipId}/settings/stocksettings`
            }
          ]}
        />
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          modifyStockSettings({
            dealershipId: dealershipId,
            stockSettings: {
              mobileDeAccountNumber: e.currentTarget.mobileDe.value as string
            }
          });
        }}
      >
        <Panel>
          <PanelHeader>{t('StockSettings.stock_settings')}</PanelHeader>
          <PanelContent>
            {!isLoading && !hasLoadingError && <FormContent stockSettings={stockSettings} />}
            {hasLoadingError && <div>{t('StockSettings.error')}</div>}
            {isLoading && <LoadingSpinner center />}
          </PanelContent>
          <PanelFooter
            cancel={
              <Button to={`/d/${dealershipId}/settings`} buttonStyle="cancel">
                {t('StockSettings.back')}
              </Button>
            }
            save={
              <Button
                isLoading={isUpdating}
                hasError={isError}
                disabled={isUpdating || hasLoadingError}
                buttonStyle="primary"
              >
                {t('StockSettings.save')}
              </Button>
            }
          />
        </Panel>
      </form>
    </Page>
  );
};

export default StockSettingsPage;
