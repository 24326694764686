import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './vehiclePanel.scss';
import Table from './SummaryTable/Table';
import TableRow from './SummaryTable/TableRow';
import OuterLeft from './SummaryTable/OuterLeft';
import OuterRight from './SummaryTable/OuterRight';
import { commafy, getOptionKey } from '../../../core/helpers';
import TableHead from './SummaryTable/TableHead';
import TableCell from './SummaryTable/TableCell';
import MoneyFormat from '../../Common/MoneyFormat';

const VehiclePanel = ({ vehicle, quote }) => {
  const { t } = useTranslation('Reporting');
  return (
    <div className="vehiclePanel" data-th="VehiclePanel">
      <OuterLeft>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('VehiclePanel.make')}</TableHead>
            <TableCell>{vehicle.Make}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('VehiclePanel.model')}</TableHead>
            <TableCell>{vehicle.Model}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('VehiclePanel.derivative')}</TableHead>
            <TableCell>{vehicle.Derivative}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('VehiclePanel.date_of_registration')}</TableHead>
            <TableCell>{vehicle.RegistrationDate} </TableCell>
          </TableRow>
        </Table>
      </OuterLeft>
      <OuterRight>
        <Table>
          <TableRow>
            <TableHead width="50%">{t('VehiclePanel.condition')}</TableHead>
            <TableCell>{vehicle.Condition ? getOptionKey(vehicle.Condition).text : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">{t('VehiclePanel.class')}</TableHead>
            <TableCell>{vehicle.Class ? getOptionKey(vehicle.Class.toLowerCase()).text : ''}</TableCell>
          </TableRow>
          <TableRow>
            <TableHead width="50%">
              {!quote || quote.VehiclePrice ? t('VehiclePanel.vehicle_price') : t('VehiclePanel.total_price')}
            </TableHead>
            <TableCell>
              <MoneyFormat value={quote ? quote.VehiclePrice || quote.CashPrice : vehicle.CashPrice} />
            </TableCell>
          </TableRow>
          {(quote?.VehicleVrm || vehicle?.Vrm) && (
            <TableRow>
              <TableHead width="50%">{t('VehiclePanel.registration_number')}</TableHead>
              <TableCell>{quote?.VehicleVrm || vehicle?.Vrm}</TableCell>
            </TableRow>
          )}
          {quote?.VehicleMileage ? (
            <TableRow>
              <TableHead width="50%">{t('VehiclePanel.mileage')}</TableHead>
              <TableCell>{commafy(quote.VehicleMileage)}</TableCell>
            </TableRow>
          ) : null}
        </Table>
      </OuterRight>
    </div>
  );
};

VehiclePanel.propTypes = {
  vehicle: PropTypes.object,
  quote: PropTypes.object
};
VehiclePanel.defaultProps = {
  vehicle: {},
  quote: {}
};
export default VehiclePanel;
