import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { whoAmI } from '../../../redux/session/sessionActions';
import { Outlet } from 'react-router-dom';
import DealerAccessDeniedPage from '~/components/Common/DealerAccessDeniedPage';

class SignedInContainer extends React.Component {
  UNSAFE_componentWillMount() {
    if (!this.props.isSignedIn) {
      this.props.fetchSession();
    }
  }

  render() {
    if (!this.props.isSignedIn) {
      return <DealerAccessDeniedPage />;
    }

    return <Outlet {...this.props} />;
  }
}

SignedInContainer.propTypes = {
  isSignedIn: PropTypes.bool,
  fetchSession: PropTypes.func
};

function mapStateToProps(state) {
  return {
    session: state.session,
    isSignedIn: state.session.isSignedIn
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSession: () => dispatch(whoAmI())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignedInContainer);
