import PropTypes from 'prop-types';
import Icon from './Icon/Icon';
import './splitButtonOption.scss';
import classnames from 'classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const SplitButtonOption = ({ isActive, onClick, iconName, children, dataThook, type = 'button' }) => {
  let optionClasses = classnames('splitButtonOption__option', isActive && 'splitButtonOption__activeOption');

  return (
    <DropdownMenu.Item asChild onSelect={onClick}>
      <button type={type} className={optionClasses} data-thook={dataThook} data-th="SplitButtonOption">
        <span className="splitButtonOption__optionIcon">
          <Icon name={iconName} />
        </span>
        <span className="splitButtonOption__optionText">{children}</span>
      </button>
    </DropdownMenu.Item>
  );
};

SplitButtonOption.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  children: PropTypes.node,
  dataThook: PropTypes.string
};

export default SplitButtonOption;
