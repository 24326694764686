import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { inject } from 'mobx-react';

import Button from '~Common/Button/Button';
import Modal from '~Common/Modal/Modal';
import { deleteVehicle } from '~/api/stockModule';
import * as debug from '~/debug';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

import classes from './DeleteButton.module.scss';

const DeleteButton = ({ vehicleId, appStore }) => {
  const { t } = useTranslation('Stock');
  const params = useParams();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [formState, setFormState] = useState('idle'); // idle|submitting|error
  const dealershipId = params.dealershipId;
  const { stockStore } = appStore;
  const { pushDangerNotification, pushSuccessNotification } = appStore.notificationStore;
  const isLoading = formState === 'loading';
  const hasError = formState === 'error';

  const showDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    setFormState('loading');
    try {
      await deleteVehicle(dealershipId, vehicleId);
      setFormState('idle');
      closeDeleteModal();

      const deletedVehiclesIds = JSON.parse(sessionStorage.getItem('deletedVehiclesIds')) || [];
      deletedVehiclesIds.push(vehicleId);
      sessionStorage.setItem('deletedVehiclesIds', JSON.stringify(deletedVehiclesIds));

      stockStore.resetFacets();
      stockStore.fetchStock();
      window.scrollTo(0, 0);
      pushSuccessNotification(t('DeleteButton.vehicle_deleted'), t('DeleteButton.success'));
    } catch (e) {
      debug.error(e);
      setFormState('error');
      pushDangerNotification(t('DeleteButton.problem_getting_vehicle'), t('DeleteButton.danger'));
    }
  };

  const loadingState = isLoading ? 'loading' : hasError ? 'error' : 'default';
  return (
    <>
      <Button
        dataThook={'deleteVehicle'}
        buttonStyle="custom"
        className={classes.deleteButton}
        type="button"
        onClick={showDeleteModal}
      >
        <div className={classes.iconTextWrapper}>
          <span className={cn('icon icon-bin', classes.deleteIcon)} />
          <span className={classes.deleteText}>{t('DeleteButton.delete')}</span>
        </div>
      </Button>
      <Modal
        title={t('DeleteButton.are_you_sure')}
        onClose={closeDeleteModal}
        isOpen={isDeleteModalOpen}
        onConfirm={handleDelete}
        buttonText={t('DeleteButton.delete_vehicle')}
        isLoading={loadingState === 'loading'}
        hasError={loadingState === 'error'}
      >
        <div className={classes.confirmationModal}>
          {t('DeleteButton.if_you_proceed_this_vehicle_will_be_permanently_deleted_this_action_is_irreversible')}
          <br />
          <br />
          {t('DeleteButton.vehicles_may_take_several_minutes_to_be_removed_from_the_stock_list')}
        </div>
      </Modal>
    </>
  );
};

export default inject('appStore')(DeleteButton);
