import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes as MobXPropTypes, inject } from 'mobx-react';
import PropTypes from 'prop-types';

import { BroadcastStockListItem, Modal, SubModal } from './components';

import Button from '~Common/Button/Button';
import LoadingDots from '~Common/Loading/LoadingDots';

import './stockListV2.scss';
import StockListItemV2 from './components/StockListItemV2/StockListItemV2';
import DeleteModal from './components/DeleteModal';
import StockListDataAccuracyModal from '../StockListDataAccuracyModal';

const StockList = ({
  vehicles,
  isLoadingStock,
  handleNewDataPending,
  isBroadcast,
  appStore,
  onSelectVehicle,
  selectedVehicles
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModalContent, setDeleteModalContent] = useState(null); // null or vehicleId to be deleted
  const [modalContent, setModalContent] = useState([]);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(null);
  const [isDataAccuracyModalOpen, setIsDataAccuracyModalOpen] = React.useState(false);
  const { t } = useTranslation('Stock');
  const { stockListScrollPosition, setStockListScrollPosition } = appStore.stockStore;

  useEffect(() => {
    if (stockListScrollPosition !== null && !isLoadingStock) {
      setCurrentScrollPosition(stockListScrollPosition);
      setStockListScrollPosition(null);
    }
    return () => {
      setCurrentScrollPosition(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let stockListScrollContainer = document.querySelector('.globalLayout__content-inner');
    if (currentScrollPosition !== null && !isLoadingStock) {
      stockListScrollContainer?.scrollTo(0, currentScrollPosition);
    } else if (currentScrollPosition === null && isLoadingStock) {
      stockListScrollContainer?.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScrollPosition]);

  const openDataIssueModal = (errors = []) => {
    setIsModalOpen(true);
    setModalContent(errors);
  };
  const closeDataIssueModal = () => {
    setIsModalOpen(false);
    setModalContent([]);
  };

  const renderParagraphs = (para) => {
    if (para.length) {
      return para.map((text, i) => <p key={i}>{text}</p>);
    } else return '';
  };

  if (isLoadingStock && !currentScrollPosition) {
    return (
      <div className="stockList">
        <LoadingDots />
      </div>
    );
  }

  if (isLoadingStock === false && !vehicles.length) {
    return <div className="stockList__vehiclesNotFound">{t('StockList.no_vehicles_found')}</div>;
  }

  const showDeleteModal = (vehicleId) => {
    setDeleteModalContent(vehicleId);
  };

  const closeDeleteModal = () => {
    setDeleteModalContent(null);
  };

  return (
    <div className="stockList">
      <Modal
        title={t('StockList.this_current_vehicle_is_not_advertised')}
        isOpen={isModalOpen}
        onDismiss={closeDataIssueModal}
        paragraph={renderParagraphs(modalContent)}
        dataTh="dataIssuesModal"
      >
        <SubModal
          title={t('StockList.important_information')}
          paragraph={t(
            'StockList.to_change_other_vehicle_information_you_will_need_to_change_this_in_your_source_inventory_management_system'
          )}
        >
          <Button
            dataThook={'closeDataIssueModal'}
            className="stockList__modalButton"
            data-th="closeDataIssuesModalButton"
            onClick={closeDataIssueModal}
          >
            {t('StockList.close')}
          </Button>
        </SubModal>
      </Modal>

      <DeleteModal
        closeDeleteModal={closeDeleteModal}
        vehicleId={deleteModalContent}
        isDeleteModalOpen={deleteModalContent}
      />

      <Modal
        dataTh="dataAccuracyModal"
        isOpen={isDataAccuracyModalOpen}
        onDismiss={() => setIsDataAccuracyModalOpen(false)}
      >
        <StockListDataAccuracyModal />
      </Modal>

      {isBroadcast
        ? vehicles.map((vehicle, index) => (
            <BroadcastStockListItem
              key={index}
              vehicle={vehicle}
              openModal={() => setIsDataAccuracyModalOpen(true)}
              handleNewDataPending={handleNewDataPending}
              appStore={appStore}
              onSelectVehicle={onSelectVehicle}
              isVehicleSelected={selectedVehicles && selectedVehicles.includes(vehicle.Id)}
            />
          ))
        : null}

      {!isBroadcast ? (
        <>
          <div className="stockList__listItemContainer">
            {vehicles.map((vehicle, index) => (
              <StockListItemV2
                openDataIssueModal={openDataIssueModal}
                setIsDataAccuracyModalOpen={() => setIsDataAccuracyModalOpen(true)}
                showDeleteModal={showDeleteModal}
                vehicle={vehicle}
                key={index}
              />
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

StockList.propTypes = {
  vehicles: MobXPropTypes.observableArray.isRequired,
  isLoadingStock: PropTypes.bool,
  openModal: PropTypes.func,
  handleNewDataPending: PropTypes.func,
  isBroadcast: PropTypes.bool
};

export default inject('appStore')(StockList);
