import { connect } from 'react-redux';
import { signIn } from '../../../redux/session/sessionActions';
import LoginPage from '../components/Login';

function mapDispatchToProps(dispatch) {
  return {
    signIn: (username, password, returnpath) => {
      dispatch(signIn(username, password, returnpath));
    }
  };
}

function mapStateToProps(state) {
  return {
    session: state.session,
    isSignedIn: state.session.isSignedIn
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
