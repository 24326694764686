import * as dataLayer from '../api/valueAddedProducts';
import { fetchOverrideOptionsRequest } from '../api/options';
import { observable, action, computed } from 'mobx';
import _ from 'lodash';
import Request from '../mobx-models/Request';
import * as helpers from '../core/helpers';
import uuid from 'uuid';
import { growthbook } from 'index';
import { VapProductTypes } from '../constants';

class VapStore {
  productTypes;
  parsedOptions;
  @observable productTypeOverrides;
  @observable valueAddedProducts;
  fetchProductTypeOverridesRequest = new Request();
  fetchVapsRequest = new Request();
  fetchProductTypesRequest = new Request();
  addProductRequest = new Request();
  editProductRequest = new Request();
  removeProductRequest = new Request();
  updateVisibilityRequest = new Request();
  updateDefaultRequest = new Request();

  constructor(store) {
    this.store = store;
    this.sendVisibility = _.debounce(this.sendVisibility, 300);
    this.sendDefault = _.debounce(this.sendDefault, 300);
  }

  getById = (id) => {
    return _.find(this.valueAddedProducts, { Id: id });
  };

  getTaxTypeForProductType = (productType) =>
    _.find(this.productTypes, (product) => product.ProductTypeCode === parseInt(productType, 10)).TaxTypeText;

  getProductTextForProductType = (productType) =>
    _.find(this.productTypes, (product) => product.ProductTypeCode === parseInt(productType, 10)).ProductTypeText;

  @action
  fetchProductTypeOverrides = (dealershipId) => {
    return this.fetchProductTypeOverridesRequest
      .onSuccess(
        action((response) => {
          this.productTypeOverrides = response;
        })
      )
      .call(fetchOverrideOptionsRequest, dealershipId);
  };

  @action
  fetchValueAddedProducts = (dealershipId) => {
    return this.fetchVapsRequest
      .onSuccess(
        action((response) => {
          this.fetchProductTypeOverrides();
          this.valueAddedProducts = response;
        })
      )
      .call(dataLayer.fetchValueAddedProducts, dealershipId);
  };

  @action
  fetchProductTypes = () => {
    return this.fetchProductTypesRequest
      .onSuccess(
        action((response) => {
          this.productTypes = response.VapProductTypes;

          // Motonovo does not support all VAP categories
          // This is where we override the default VAP categories with ones specific to Motonovo
          // So that dealerships cannot add VAPs into those categories in their settings
          if (
            this.store.uiState.isMotonovoOnlyDealership &&
            this.productTypeOverrides.MOT.VapProductTypes &&
            growthbook.isOn('rtl-mnf-vaps-override')
          ) {
            this.productTypes = this.productTypeOverrides.MOT.VapProductTypes;
          }
          this.parsedOptions = this.parseOptions(this.productTypes);
        })
      )
      .call(dataLayer.fetchProductTypes);
  };

  parseOptions = (rawOptions) => {
    let options = rawOptions.map((product) => {
      return {
        key: product.ProductTypeCode,
        value: product.ProductTypeText
      };
    });

    // Hack To Remove
    if (this.store.uiState.isBnpp || this.store.uiState.isBdk) {
      options = options.filter((product) => {
        return (
          product.key !== VapProductTypes.DeliveryCharges &&
          product.key !== VapProductTypes.AdministrationFee &&
          product.key !== VapProductTypes.VehicleAccessories &&
          product.key !== VapProductTypes.Fuel &&
          product.key !== VapProductTypes.MotorcycleExtras &&
          product.key !== VapProductTypes.OtherStandardVatExtras &&
          product.key !== VapProductTypes.RoadFundLicence &&
          product.key !== VapProductTypes.MotorcycleHelmet &&
          product.key !== VapProductTypes.OtherExtrasVatExempt
        );
      });
    }

    return options;
  };

  @action
  setVisibility = (id, checked, dealershipId) => {
    _.find(this.valueAddedProducts, (vap) => vap.Id === id).Visible = checked;
    this.sendVisibility(dealershipId, id, checked);
  };

  @action
  sendVisibility = (dealershipId, id, checked) => {
    this.updateVisibilityRequest
      .onError(() => (_.find(this.valueAddedProducts, (vap) => vap.Id === id).Visible = !checked))
      .call(dataLayer.setVisibility, dealershipId, id, checked)
      .then(() => {
        setTimeout(() => {
          this.updateVisibilityRequest.reset();
        }, 3000);
      });
  };

  @action
  setDefault = (id, checked, dealershipId) => {
    _.find(this.valueAddedProducts, (vap) => vap.Id === id).Default = checked;
    this.sendDefault(dealershipId, id, checked);
  };

  @action
  setDefaultFromModal = (id, checked, dealershipId) => {
    _.find(this.valueAddedProducts, (vap) => vap.Id === id).Default = checked;

    this.updateDefaultRequest
      .onError(() => (_.find(this.valueAddedProducts, (vap) => vap.Id === id).Default = !checked))
      .call(dataLayer.setDefault, dealershipId, id, checked)
      .then(() => {
        this.updateDefaultRequest.reset();
      });
  };

  @action
  sendDefault = (dealershipId, id, checked) => {
    this.updateDefaultRequest
      .onError(() => (_.find(this.valueAddedProducts, (vap) => vap.Id === id).Default = !checked))
      .call(dataLayer.setDefault, dealershipId, id, checked)
      .then(() => {
        setTimeout(() => {
          this.updateDefaultRequest.reset();
        }, 3000);
      });
  };

  generateProduct = (vap) => {
    return {
      productTypeCode: vap.productType,
      price: parseFloat(vap.price),
      name: vap.name,
      description: vap.description,
      id: vap.id,
      visibility: vap.visibility,
      isDefault: vap.isDefault
    };
  };

  onSubmitAdd = (dealershipId, vap) => {
    vap.id = uuid.v4();
    return this.addProductRequest
      .onSuccess(
        action(() => {
          this.valueAddedProducts.push({
            ProductTypeCode: parseInt(vap.productType, 10),
            ProductTypeText: vap.productTypeText,
            Price: parseFloat(vap.price),
            Name: vap.name,
            Description: vap.description,
            Id: vap.id,
            Visible: vap.visibility,
            Default: vap.isDefault,
            TaxTypeText: vap.taxType
          });
        })
      )
      .call(dataLayer.addProduct, dealershipId, this.generateProduct(vap));
  };

  onSubmitEdit = (dealershipId, vap) => {
    return this.editProductRequest
      .onSuccess(
        action(() => {
          Object.assign(
            this.valueAddedProducts.find((x) => x.Id === vap.id),
            {
              ProductTypeCode: parseInt(vap.productType, 10),
              ProductTypeText: this.parsedOptions.find((x) => x.key === parseInt(vap.productType, 10)).value,
              Price: parseFloat(vap.price),
              Name: vap.name,
              Description: vap.description,
              Id: vap.id,
              Visible: vap.visibility,
              Default: vap.isDefault,
              TaxTypeText: vap.taxType
            }
          );
        })
      )
      .call(dataLayer.editProduct, dealershipId, this.generateProduct(vap));
  };

  onDeleteProduct = (dealershipId, vapId) => {
    return this.removeProductRequest
      .onSuccess(
        action(() => {
          this.valueAddedProducts = this.valueAddedProducts.filter((vap) => vap.Id !== vapId);
        })
      )
      .call(dataLayer.removeProduct, dealershipId, vapId);
  };

  @computed
  get visibleProducts() {
    return _.chain(this.valueAddedProducts)
      .filter((option) => option.Visible)
      .orderBy((option) => option && option.Name && option.Name.toLowerCase())
      .value();
  }

  filterByVehicleClass(vehicleClass) {
    if (!vehicleClass) {
      vehicleClass = '';
    }
    let visibleProducts = this.visibleProducts;
    if (vehicleClass.toLowerCase() === 'car' || vehicleClass.toLowerCase() === 'lcv') {
      visibleProducts = visibleProducts.filter(
        (option) => option.ProductTypeCode !== 13 && option.ProductTypeCode !== 16
      );
    } else if (vehicleClass.toLowerCase() === 'bike') {
      visibleProducts = visibleProducts.filter((option) => option.ProductTypeCode !== 11);
    }

    // Hack To Remove
    if (this.store.uiState.isBnpp || this.store.uiState.isBdk) {
      visibleProducts = visibleProducts.filter((product) => {
        return (
          product.ProductTypeCode !== VapProductTypes.DeliveryCharges &&
          product.ProductTypeCode !== VapProductTypes.AdministrationFee &&
          product.ProductTypeCode !== VapProductTypes.VehicleAccessories &&
          product.ProductTypeCode !== VapProductTypes.Fuel &&
          product.ProductTypeCode !== VapProductTypes.MotorcycleExtras &&
          product.ProductTypeCode !== VapProductTypes.OtherStandardVatExtras &&
          product.ProductTypeCode !== VapProductTypes.RoadFundLicence &&
          product.ProductTypeCode !== VapProductTypes.MotorcycleHelmet &&
          product.ProductTypeCode !== VapProductTypes.OtherVatExemptExtras
        );
      });
    }

    return visibleProducts;
  }

  getVisibleDefaultProductsClassFiltered(vehicleClass) {
    return this.filterByVehicleClass(vehicleClass).filter((product) => product.Default);
  }

  @computed
  get visibleProductTypeOptions() {
    return _.chain(this.visibleProducts)
      .map((option) => ({ key: option.ProductTypeCode, value: option.ProductTypeText }))
      .uniqBy('key')
      .value();
  }

  getVisibleProductTypeOptionsClassFiltered(vehicleClass) {
    return _.chain(this.filterByVehicleClass(vehicleClass))
      .map((option) => ({ key: option.ProductTypeCode, value: option.ProductTypeText }))
      .uniqBy('key')
      .value();
  }

  getVisibleProductsForType(productTypeCode) {
    return this.visibleProducts.filter((product) => product.ProductTypeCode === parseInt(productTypeCode, 10));
  }

  getVisibleProductOptionsForType(productTypeCode) {
    return this.getVisibleProductsForType(productTypeCode).map((option) => ({
      key: option.Id,
      value: option.Description
        ? `${option.Name} | ${option.Description} | ${helpers.formatMoney(
            option.Price,
            this.store.uiState.countryCode
          )}`
        : `${option.Name} | ${helpers.formatMoney(option.Price, this.store.uiState.countryCode)}`
    }));
  }

  @computed
  get isLoading() {
    return this.fetchVapsRequest.isLoading || this.fetchProductTypesRequest.isLoading;
  }

  @computed
  get hasLoadingError() {
    return this.fetchVapsRequest.hasError || this.fetchProductTypesRequest.hasError;
  }
}

export default VapStore;
