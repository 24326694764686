import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import Page from '../Common/Page';
import Breadcrumbs from '../Common/Breadcrumbs';
import Panel from '../Common/Panel';
import PanelHeader from '../Common/PanelHeader';
import PanelContent from '../Common/PanelContent';
import PlatformUpdatesItem from './PlatformUpdatesItem';
import PropTypes from 'prop-types';
import './platformUpdates.scss';
import { compose } from 'redux';
import { withParams } from 'hocs/router';

class PlatformUpdates extends Component {
  componentDidMount() {
    this.props.resetOptions();
    this.props.fetchPlatformUpdates();
  }

  handlePageChange = (page) => {
    this.props.changePage(page);
  };

  render() {
    const updateResults = this.props.platformUpdates.Results;
    return (
      <Page>
        <Breadcrumbs
          items={[
            {
              name: this.props.t('PlatformUpdates.home'),
              path: `/d/${this.props.params.dealershipId}`
            },
            {
              name: this.props.t('PlatformUpdates.whats_new')
            }
          ]}
        />
        <Panel>
          <PanelHeader>{this.props.t('PlatformUpdates.whats_new')}</PanelHeader>
          <PanelContent>
            <div className="platformUpdates__inner">
              <div className="platformUpdates__container" key="cont">
                {updateResults && updateResults.length > 0 && (
                  <PlatformUpdatesItem update={updateResults[0]} index={0} />
                )}
              </div>
            </div>
          </PanelContent>
        </Panel>
      </Page>
    );
  }
}

PlatformUpdates.propTypes = {
  markAsRead: PropTypes.func,
  params: PropTypes.object,
  fetchPlatformUpdates: PropTypes.func,
  platformUpdates: PropTypes.object,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalUpdates: PropTypes.number,
  changePage: PropTypes.func,
  resetOptions: PropTypes.func
};
export default compose(withParams, withTranslation('PlatformUpdates'))(PlatformUpdates);
