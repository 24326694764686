import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';

import TrackEvent from '~Common/Tracking/TrackEvent';
import Button from '~Common/Button/Button';

import { DeleteButton } from './components';

import classes from './stockListItemButtons.module.scss';

const StockListItemButtons = ({
  dealerPlatformUpload,
  id,
  hasDataIssues,
  trackingContext,
  onQuoteClick,
  onEditClick,
  appStore
}) => {
  const { t } = useTranslation('Stock');
  const { canFixIssues, canAddVehicles, canGenerateQuotes, isBdk } = appStore.uiState;
  const deleteEnabled = canAddVehicles && dealerPlatformUpload;

  if (canAddVehicles) {
    return (
      <>
        {hasDataIssues && canFixIssues && (
          <div className={classes.buttonContainer}>
            <TrackEvent
              featureName={`StockModuleRefineVehicleDetails--fixIssues`}
              interactionName={`click`}
              eventContent={id}
              context={trackingContext}
            >
              <Button
                type="button"
                buttonStyle="cancel"
                onClick={() => onEditClick(dealerPlatformUpload ? 'dpData' : 'mediaData')}
              >
                {t('StockListItem.fix_issues')}
              </Button>
            </TrackEvent>
          </div>
        )}

        {(isBdk || (!hasDataIssues && canGenerateQuotes)) && (
          <div className={classes.buttonContainer}>
            <TrackEvent
              featureName={`StockModuleRefineVehicleDetails--quote`}
              interactionName={`click`}
              eventContent={id}
              context={trackingContext}
            >
              <Button
                dataThook={'quote'}
                className={classes.quoteButton}
                buttonStyle="primary"
                onClick={onQuoteClick}
                type="button"
              >
                {t('StockListItem.quote')}
              </Button>
            </TrackEvent>
          </div>
        )}

        <div className={classes.buttonContainer}>
          <TrackEvent
            featureName={`StockModuleRefineVehicleDetails--amend`}
            interactionName={`click`}
            eventContent={id}
            context={trackingContext}
          >
            <Button
              className={classes.secondaryButton}
              buttonStyle="secondary"
              onClick={() => onEditClick('dpData')}
              iconName="edit"
              dataThook={'amend'}
              type="button"
            >
              {t('StockListItem.amend')}
            </Button>
          </TrackEvent>
        </div>

        {deleteEnabled && <DeleteButton vehicleId={id} />}
      </>
    );
  } else {
    return (
      <>
        {(isBdk || (!hasDataIssues && canGenerateQuotes)) && (
          <div className={classes.buttonContainer}>
            <TrackEvent
              featureName={`StockModuleRefineVehicleDetails--quote`}
              interactionName={`click`}
              eventContent={id}
              context={trackingContext}
            >
              <Button
                dataThook={'quote'}
                type="button"
                className={classes.quoteButton}
                buttonStyle="primary"
                onClick={onQuoteClick}
              >
                {t('StockListItem.quote')}
              </Button>
            </TrackEvent>
          </div>
        )}

        {hasDataIssues && canFixIssues && (
          <div className={classes.buttonContainer}>
            <TrackEvent
              featureName={`StockModuleRefineVehicleDetails--fixIssues`}
              interactionName={`click`}
              eventContent={id}
              context={trackingContext}
            >
              <Button
                type="button"
                dataThook={'fixIssues'}
                buttonStyle="cancel"
                onClick={() => onEditClick('mediaData')}
              >
                {t('StockListItem.fix_issues')}
              </Button>
            </TrackEvent>
          </div>
        )}

        {/* Same as the button above, the only difference is button styles */}
        {!hasDataIssues && canFixIssues && (
          <TrackEvent
            featureName={`StockModuleRefineVehicleDetails--fixIssues`}
            interactionName={`click`}
            eventContent={id}
            context={trackingContext}
          >
            <Button
              className={classes.secondaryButton}
              buttonStyle="secondary"
              onClick={() => onEditClick('mediaData')}
              iconName="edit"
              dataThook={'amend'}
            >
              {t('StockListItem.amend')}
            </Button>
          </TrackEvent>
        )}

        {deleteEnabled && <DeleteButton vehicleId={id} />}
      </>
    );
  }
};

StockListItemButtons.propTypes = {
  id: PropTypes.string,
  hasDataIssues: PropTypes.bool,
  canAddVehicles: PropTypes.bool,
  trackingContext: PropTypes.object,
  onQuoteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  dealerPlatformUpload: PropTypes.bool
};

export default inject('appStore')(StockListItemButtons);
