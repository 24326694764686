import { ApiResponse } from '../../types';

export const getDataWithRequiredDatasets = (apiResponse: ApiResponse, origins: string[]): ApiResponse => {
  if (!origins || origins.length === 0) {
    return apiResponse;
  }

  const filteredDatasets = apiResponse.datasets
    .filter((dataset) => origins.includes(dataset.label))
    .map((dataset) => ({
      ...dataset
    }));

  return {
    ...apiResponse,
    datasets: filteredDatasets
  };
};
