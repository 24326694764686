import { Link, useLocation, useParams } from 'react-router-dom';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';

import { Tooltip } from 'components/Common/Tooltip/Tooltip';
import Arrow from '../../Arrow/Arrow';
import useMetricsCount from '../../../services/hooks/useMetricsCount';
import MetricCardSkeleton from '../../MetricCardSkeleton/MetricCardSkeleton';
import UiState from 'mobx-stores/UiState';

import { AverageDaysInStockIcon } from '../../svgs';

import s from '../../MetricCards/metricCards.module.scss';

type Props = {
  appStore?: {
    uiState: UiState;
  };
};

const AverageDaysInStock = (props: Props) => {
  const { pathname } = useLocation();
  const { dealershipId } = useParams<{ dealershipId: string }>();
  const countryCode = (props.appStore!.uiState.countryCode as unknown) as string;

  const { data, isLoading } = useMetricsCount({
    dealershipId,
    metric: 'dealer_vehicle_snapshot:days_in_stock',
    period: 'day',
    dataSet: 'avg',
    datePeriod: 'Day'
  });

  const { t } = useTranslation('Stock');

  if (isLoading) {
    return <MetricCardSkeleton />;
  }

  return (
    <Link
      to={`/d/${dealershipId}/stock/dealership-metrics/average-days-in-stock`}
      className={cn(s['card'], pathname.includes('dealership-metrics/average-days-in-stock') && s['card--active'])}
      data-th='MetricCard-AverageDaysInStock'
    >
      <div className={s['metric']}>
        <span className={s['statTitle']}>{t('StockMetrics.average_vehicle_days_in_stock')}</span>
        <div className={cn(s['icon'], s['metricIcon'])}>
          <AverageDaysInStockIcon />
        </div>
      </div>
      <div className={s['bigValue']}>
        {data?.total
          ? new Intl.NumberFormat(countryCode, { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(
              data.total
            )
          : '0'}
      </div>
      {typeof data?.percentageChange === 'number' ? (
        <Tooltip
          content={t('StockMetrics.this_indicates_the_change_in_activity_based_on_the_previous_24_hours_data')}
          variant="metrics"
          open={undefined}
          defaultOpen={undefined}
          onOpenChange={undefined}
        >
          <div className={s['percentageChange']}>
            <Arrow change={data.percentageChange} />
            <span>
              {new Intl.NumberFormat(countryCode, { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(
                data.percentageChange
              )}
              % {t('StockMetrics.vs_the_previous_24_hours')}
            </span>
          </div>
        </Tooltip>
      ) : (
        <div className={s['percentageChange']}>
          <span>{t('StockMetrics.last_24_hours')}</span>
        </div>
      )}
    </Link>
  );
};

export default inject('appStore')(AverageDaysInStock);
