import ValueAddedProduct from './ValueAddedProduct';
import FinanceDepositAllowance from './FinanceDepositAllowance';
import { observable } from 'mobx';
class PushDealQuote {
  AcceptanceFee;
  AcceptanceFeeFirst;
  AcceptanceFeeLast;
  AnnualDistanceQuoted;
  AnnualRate;
  BalancePayable;
  CashDeposit;
  CashPrice;
  Deposit;
  FinalPayment;
  FirstPayment;
  FollowingPayments;
  Funder;
  Funder = {
    Name: ''
  };
  FunderCode;
  InterestCharges;
  OptionToPurchaseFee;
  OutstandingSettlement;
  PartExchange;
  PlatformMeta;
  ProductName;
  RepresentativeApr;
  RequestedDeposit;
  Term;
  TotalAmountPayable;
  TotalDeposit;
  TotalFees;
  @observable FinanceDepositAllowances = [];
  @observable ValueAddedProducts = [];
  ValueAddedProductsTotal;
  WashoutNegativeEquityAmount;

  updateQuote(quote) {
    this.AcceptanceFee = quote.acceptanceFee;
    this.AcceptanceFeeFirst = quote.acceptanceFeeFirst;
    this.AcceptanceFeeLast = quote.acceptanceFeeLast;
    this.AnnualDistanceQuoted = quote.annualDistanceQuoted;
    this.AnnualRate = quote.annualRate;
    this.BalancePayable = quote.balancePayable;
    this.CashDeposit = quote.cashDeposit;
    this.CashPrice = quote.cashPrice;
    this.Deposit = quote.deposit;
    this.FinalPayment = quote.finalPayment;
    this.FirstPayment = quote.firstPayment;
    this.FollowedBy = quote.followedBy;
    this.FollowingPayments = quote.followingPayments;
    this.Funder = quote.funder;
    this.Funder.Name = quote.funder.organisation;
    this.FunderCode = quote.funderCode;
    this.InterestCharges = quote.interestCharges;
    this.OptionToPurchaseFee = quote.optionToPurchaseFee;
    this.OutstandingSettlement = quote.outstandingSettlement;
    this.PartExchange = quote.partExchange;
    this.PlatformMeta = JSON.parse(quote.platformMeta);
    this.ProductName = quote.productName;
    this.RepresentativeApr = quote.representativeApr;
    this.RequestedDeposit = quote.requestedDeposit;
    this.Term = quote.term;
    this.TotalAmountPayable = quote.totalAmountPayable;
    this.TotalDeposit = quote.totalDeposit;
    this.TotalFees = quote.totalFees;
    this.CompanionQuotes = quote.companionQuotes;
    this.FinanceDepositAllowances = quote.financeDepositAllowances?.map((item) => {
      const fda = new FinanceDepositAllowance(item);
      fda.updateFromJSON(item);
      return fda;
    });
    this.ValueAddedProducts = quote.valueAddedProducts?.map((item) => {
      const vap = new ValueAddedProduct(item);
      vap.updateFromJSON(item);
      return vap;
    });
    this.WashoutNegativeEquityAmount = quote.washoutNegativeEquityAmount;
  }
}

export default PushDealQuote;
