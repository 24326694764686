export type Metric =
  | 'dealer_search_impression:count'
  | 'finance_eligibility_check_activated:count'
  | 'finance_eligibility_check_completed:count'
  | 'finance_eligibility_check_failed:count'
  | 'finance_eligibility_check_submitted:count'
  | 'finance_product_suitability_content_viewed:count'
  | 'finance_product_suitability_question_and_answer:count'
  | 'finance_product_suitability_question_and_answer:negative_count'
  | 'finance_product_suitability_question_and_answer:positive_count'
  | 'finance_product_suitability_video_viewed:count'
  | 'finance_product_suitability_video_viewed:seconds_viewed'
  | 'finance_product_suitability_vulnerability_content_viewed:count'
  | 'finance_product_suitability_vulnerability_outbound_link:count'
  | 'finance_quotes_displayed:count'
  | 'vehicle_finance_application_started:count'
  | 'finance_application_initial_submission:count'
  | 'dealer_vehicle_snapshot:days_in_stock';

export enum ChartVariantEnum {
  AverageDaysInStock = 'AverageDaysInStock',
  EligibilityChecks = 'EligibilityChecks',
  OnlineQuotes = 'OnlineQuotes',
  SearchImpressions = 'SearchImpressions',
  SubmittedApplications = 'SubmittedApplications',
  Default = 'Default'
}

export const MetricToChartVariantMap: Partial<Record<Metric, ChartVariantEnum>> = {
  'dealer_search_impression:count': ChartVariantEnum.SearchImpressions,
  'finance_eligibility_check_completed:count': ChartVariantEnum.EligibilityChecks,
  'dealer_vehicle_snapshot:days_in_stock': ChartVariantEnum.AverageDaysInStock,
  'finance_quotes_displayed:count': ChartVariantEnum.OnlineQuotes,
  'finance_application_initial_submission:count': ChartVariantEnum.SubmittedApplications
};
