import _ from 'lodash';
import moment from 'moment';
import appStore from '../mobx-stores/appStore';
import { PhoneNumberType, PhoneNumberUtil } from 'google-libphonenumber';
import { canValidatePhoneNumbersWithLibPhoneNumber } from '~/features';

const validators = {
  required: function(value, condition, comparator, allowedValues, data) {
    if (condition === 'iftrue') {
      let comparatorValue = _.get(data, comparator);
      return comparatorValue === 'true' || comparatorValue === true ? value : true;
    } else if (condition === 'iffalse') {
      let comparatorValue = _.get(data, comparator);
      return comparatorValue === 'false' || comparatorValue === false ? value : true;
    } else if (condition === 'ifoneof') {
      let comparatorValue = _.get(data, comparator);
      let parsedAllowedValues = JSON.parse(allowedValues);
      return parsedAllowedValues.includes(comparatorValue) ? value : true;
    } else if (condition === 'unless') {
      return value || _.get(data, comparator) ? true : false;
    } else if (condition === 'de') {
      return appStore.uiState.countryCode === 'de' ? value : true;
    } else {
      return !(value === undefined || value === null || (typeof value === 'string' && value.trim() === ''));
    }
  },

  email: function(value) {
    return /^$|^([\wZÄäÖöÜüẞß\.%\+\-]+)@([\wZÄäÖöÜüẞß\-]+\.)+([\w]{2,})$/.test(value); //eslint-disable-line
  },

  date: function(value) {
    if (value === '') {
      return true;
    }

    return /^$|^(\d{2}\/\d{2}\/\d{4})$/.test(value) && moment(value, 'DD/MM/YYYY').isValid();
  },

  datePast: function(value) {
    return !(
      moment(value, 'DD/MM/YYYY').valueOf() >
      moment()
        .endOf('day')
        .valueOf()
    );
  },

  dateNotPast: function(value) {
    return (
      moment(value, 'DD/MM/YYYY').valueOf() >=
      moment()
        .startOf('day')
        .valueOf()
    );
  },

  decimalPlaces: function(value, expectedDecimalPlaces) {
    const stringValue = value.toString();
    // Disallow any characters other than digits, '.', and ','
    if (/[^0-9.,]/.test(stringValue)) {
      return false;
    }
    // Checks there are the digits to exactly the expectedDecimalPlaces after '.' or ','
    const regex = new RegExp(`\\d+[\\.,]\\d{${expectedDecimalPlaces}}(?![\\d])`);
    return regex.test(stringValue);
  },

  youngerThan99: function(dateOfBirth) {
    const date = moment(dateOfBirth, 'DD/MM/YYYY', true);
    const oldest = moment().subtract(99, 'years');

    if (date.isBefore(oldest)) {
      return false;
    } else {
      return true;
    }
  },

  timeAtAddressMax: function(value, expected) {
    return parseFloat(value, 10) <= parseFloat(expected, 10);
  },

  timeAtAddressMin: function(value, expected) {
    return parseFloat(value, 10) >= parseFloat(expected, 10);
  },

  olderThan18: function(dateOfBirth) {
    const date = moment(dateOfBirth, 'DD/MM/YYYY', true);
    const youngest = moment().subtract(18, 'years');

    if (date.isAfter(youngest)) {
      return false;
    } else {
      return true;
    }
  },

  landline: (value) => validatePhoneNumber(value, [PhoneNumberType.FIXED_LINE]),

  mobile: (value) => validatePhoneNumber(value, [PhoneNumberType.MOBILE]),

  phone: (value) => validatePhoneNumber(value),

  businessPhone: (value) =>
    validatePhoneNumber(value, [
      PhoneNumberType.FIXED_LINE,
      PhoneNumberType.MOBILE,
      PhoneNumberType.TOLL_FREE,
      PhoneNumberType.UAN
    ]),

  alphaWithPunctuationAndSpaces: function(value) {
    const nameValidation = /^[a-zA-Z\s\-']+$/;

    return value === '' || nameValidation.test(value);
  },
  alphaAndWhitespace: function(value) {
    return value === '' || /^[a-zA-Z\s]+$/.test(value);
  },
  lenderAlphanumeric: function(value) {
    switch (appStore.uiState.countryCode) {
      case 'de':
        return /^$|^[a-zA-ZÄäÖöÜüẞß]([a-zA-Z0-9ÄäÖöÜüẞß\.\/\-' ]*)$/.test(value);
      case 'fr':
      case 'es':
      case 'it':
      case 'en':
      default:
        return /^$|^[a-zA-Z]([a-zA-Z0-9\.\/\-' ]*)$/.test(value);
    }
  },
  //same as default/en lenderAlphanumeric but also allows ampersands
  extendedLenderAlphanumeric: function(value) {
    return /^[a-zA-Z0-9 '&/.-]*$/.test(value);
  },

  number: function(value, condition) {
    if (value === '') {
      return true;
    }

    const stringVal = value.toString();

    if (condition === 'int') {
      const intRegex = /^[\d]+$/;
      return intRegex.test(stringVal) && !isNaN(parseInt(stringVal, 10));
    } else {
      const floatRegex = /^[\d\.]+$/; //eslint-disable-line
      return floatRegex.test(stringVal) && !isNaN(parseFloat(stringVal));
    }
  },

  currency: function(value) {
    return /^[0-9]*(\.[0-9]{1,2})?$/.test(value);
  },

  min: function(value, expected) {
    return parseFloat(value, 10) >= parseFloat(expected, 10);
  },

  max: function(value, expected) {
    return parseFloat(value, 10) <= parseFloat(expected, 10);
  },

  minlength: function(value, length) {
    if (value === '') {
      return true;
    }

    return value.length >= length;
  },

  maxlength: function(value, length) {
    if (value === '') {
      return true;
    }

    return value.length <= length;
  },

  sortCode: function(value) {
    return /^(?!(?:0{6}|00-00-00))(?:\d{6}|\d\d-\d\d-\d\d)$/.test(value);
  },

  accountNumber: function(value) {
    return /^(\d){7,8}$/.test(value);
  },

  equals: function(value, otherField, unused, allowedValues, data) {
    let comparatorValue = _.get(data, otherField);
    return value === comparatorValue;
  },

  alphaNum: function(value) {
    return /^[a-z\d]*$/i.test(value);
  },

  alphaNumSpace: function(value) {
    return /^[a-z\d\s]*$/i.test(value);
  },

  alphanumericWithDigitsPunctuationAndSpaces: function(value) {
    return /^[\p{L}\p{N}\p{Sc}:;()'""&%\/\\\-\.,\s]*$/u.test(value); //eslint-disable-line
  },

  doesNotStartWithSpace: function(value) {
    return /^$|^[^\s][\s\S]*$/.test(value);
  },

  doesNotStartOrEndWithSpace: function(value) {
    return !(value.startsWith(' ') || value.endsWith(' '));
  },

  acceptedTerms: function(value, expected) {
    return value === Boolean(expected);
  },

  doesNotStartWithSpecialCharacter: function(value) {
    return value === '' || /^[A-Za-z0-9]/.test(value);
  },

  publishableStripeKey: function(value) {
    return value.startsWith('pk_live_') || value.startsWith('pk_test_');
  },

  secretStripeKey: function(value) {
    return value.startsWith('sk_live_') || value.startsWith('sk_test_');
  },

  gender: function(gender, comparator, notUsed, alsoNotUsed, data) {
    let title = _.get(data, comparator);
    if (!gender || !title || title === 'Dr' || title === 'Professor') {
      return true;
    } else if (gender === 'Male') {
      if (title === 'Mr') {
        return true;
      } else {
        return false;
      }
    } else if (gender === 'NonBinary' || gender === 'PreferNotToSay') {
      if (title === 'Mx') {
        return true;
      } else {
        return false;
      }
    } else {
      if (title === 'Mrs' || title === 'Ms' || title === 'Miss') {
        return true;
      } else {
        return false;
      }
    }
  },

  title: function(title, comparator, notUsed, alsoNotUsed, data) {
    let gender = _.get(data, comparator);
    if (!title || !gender || title === 'Dr' || title === 'Professor') {
      return true;
    } else if (gender === 'Male') {
      if (title === 'Mr') {
        return true;
      } else {
        return false;
      }
    } else if (gender === 'NonBinary' || gender === 'PreferNotToSay') {
      if (title === 'Mx') {
        return true;
      } else {
        return false;
      }
    } else {
      if (title === 'Mrs' || title === 'Ms' || title === 'Miss') {
        return true;
      } else {
        return false;
      }
    }
  },

  lowercaseUppercaseLetterNumberSymbol: function(value) {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[@$!%*#?&.])/.test(value);
  },

  /**
   * If the current value equals fieldOneConditionalValue then the current value of otherField must be set to fieldTwoMustEqualValue.
   *
   * Example use case on the quote application form employment history section:
   * "Receiving Disability Benefit" is only a valid "Occupation Type" selection if the "Occupation Basis" field is set to "Unemployed".
   */
  validateFieldBasedOnOtherFieldValue: function(
    value,
    fieldOneConditionalValue,
    otherField,
    fieldTwoMustEqualValue,
    data
  ) {
    let otherFieldValue = _.get(data, otherField);

    if (value === fieldOneConditionalValue) {
      if (otherFieldValue === fieldTwoMustEqualValue) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  },

  optional: () => true
};

function validateRule(rule, value, data) {
  const ruleParts = rule.split(':');
  const ruleName = ruleParts[0];

  if (validators[ruleName]) {
    let passedValue = value === undefined || value === null ? '' : value;
    passedValue = typeof passedValue === 'number' ? passedValue.toString() : passedValue;
    return !validators[ruleName].call({}, passedValue, ruleParts[1], ruleParts[2], ruleParts[3], data);
  } else {
    return null;
  }
}

function validateField(value, ruleSet, data) {
  const rules = ruleSet.replace(/\s/g, '').split(/,(?![^[]*\])/);
  let error;

  _.forEach(rules, function(rule) {
    const ruleParts = rule.split(':');
    const ruleName = ruleParts[0];

    const rVal = validateRule(rule, value, data);

    if (rVal) {
      error = ruleName;
      return false;
    }
  });

  return error;
}

function traverseRuleSet(ruleSet, data, path = '') {
  if (_.isArray(ruleSet)) {
    let rVal = [];

    const subData = path === '' ? data : _.get(data, path);
    _.forEach(subData, function(item, index) {
      let errors = traverseRuleSet(ruleSet[0], data, path + '.' + index);
      if (errors) {
        rVal[index] = errors;
      }
    });

    return rVal.length ? rVal : null;
  } else if (_.isObject(ruleSet)) {
    let rVal = {};

    _.forEach(ruleSet, function(subRuleSet, key) {
      let newPath = path ? path + '.' + key : key;
      let validationError = traverseRuleSet(subRuleSet, data, newPath);

      if (validationError) {
        rVal[key] = validationError;
      }
    });

    return _.keys(rVal).length ? rVal : null;
  } else {
    let value = _.get(data, path);
    let formData = data;

    if (path.indexOf('.') !== -1) {
      let containingPath = path.replace(/\.[a-zA-Z]+$/, '');
      formData = _.get(data, containingPath);
    }

    return validateField(value, ruleSet, formData);
  }
}

function validatePhoneNumber(value, numberTypes = [PhoneNumberType.FIXED_LINE_OR_MOBILE]) {
  if (canValidatePhoneNumbersWithLibPhoneNumber() === false) {
    const withoutSpaces = value.replace(/ /g, '');
    return value === '' || /^[0-9+() \-]{8,16}$/g.test(withoutSpaces);
  }

  if (value === '') return true;

  const countryCode = appStore.uiState.countryCode.toUpperCase();
  const phoneUtil = PhoneNumberUtil.getInstance();

  const isNumber = phoneUtil.isPossibleNumberString(value, countryCode);
  if (!isNumber) return false;

  const phoneNo = phoneUtil.parse(value, countryCode);
  const isValid = phoneUtil.isValidNumberForRegion(phoneNo, countryCode);
  if (!isValid) return false;

  const phoneNumberType = phoneUtil.getNumberType(phoneNo);
  const validNumberTypes = numberTypes.flatMap((numberType) =>
    numberType === PhoneNumberType.FIXED_LINE_OR_MOBILE
      ? [PhoneNumberType.FIXED_LINE, PhoneNumberType.MOBILE]
      : [numberType]
  );

  return validNumberTypes.includes(phoneNumberType);
}

export function validate(data, rules) {
  //console.time('validation');
  const rVal = traverseRuleSet(rules, data);
  //console.timeEnd('validation');
  return rVal;
}

export default validators;
