import { withTranslation } from 'react-i18next';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import classNames from 'classnames';
import moment from 'moment';
import Icon from '../../Common/Icon/Icon';
import MoneyFormat from '../../Common/MoneyFormat';
import VehicleImageWithVrm from '../../Common/VehicleImageWithVrm';
import SentDealTag from './SentDealTag';
import SentDealMultipleDealsItem from './SentDealMultipleDealsItem';
import './sentDealsItem.scss';
import { Link } from 'react-router-dom';
export function setAdditionalDeals(deals) {
  let additionalDeals = [];

  if (deals.length > 1) {
    additionalDeals = [...deals];
    additionalDeals.shift();
  }

  return additionalDeals;
}

class SentDealsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false
    };
  }

  toggleDealDetails = (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      return {
        showDetails: !prevState.showDetails
      };
    });
  };
  setDealLink = (customerId, dealId) => {
    return `/d/${this.props.dealershipId}/consumers/${customerId}/send-deal/${dealId}`;
  };

  getCurrentDeal() {
    return this.props.customer.deals[0];
  }

  getVehiclePrice() {
    const { vehicleCashPrice, vehicleDiscountFromListPrice } = this.getCurrentDeal();
    return vehicleDiscountFromListPrice ? vehicleCashPrice + vehicleDiscountFromListPrice : vehicleCashPrice;
  }

  getOfferPrice() {
    const { vehicleCashPrice, vehicleDiscountFromListPrice } = this.getCurrentDeal();
    return vehicleDiscountFromListPrice ? vehicleCashPrice : null;
  }

  render() {
    const { showDetails } = this.state;
    const { customer } = this.props;
    const { deals, firstName, lastName } = customer;
    const deal = this.getCurrentDeal();
    const { addedAt, openedAt, dealStatus, vehicle, vehicleImageUrls, responsibleUserName, dealId } = deal;
    const additionalDeals = setAdditionalDeals(deals);
    const showAdditionalDeals = additionalDeals.length > 0;
    const maxDealHeight = 300;
    const bottomContentScrollHeight = showDetails ? additionalDeals.length * maxDealHeight : 0;
    const vehicleCashPrice = this.getVehiclePrice();
    const offerPrice = this.getOfferPrice();
    const shouldShowMultipleDealsIcon = deals.length > 1;
    const shouldShowOfferPrice = offerPrice > 0;
    const arrowClassname = classNames(
      'sentDealsItem__expandButtonArrow',
      showDetails && 'sentDealsItem__expandButtonArrow--rotate'
    );
    const dealsItemBottomClassname = classNames('sentDealsItem__bottom', showDetails && 'sentDealsItem__bottom--open');
    const dealsItemBottomStyles = {
      maxHeight: bottomContentScrollHeight + 'px'
    };
    return (
      <>
        <div className="sentDealsItem">
          <Link to={this.setDealLink(customer.customerId, dealId)} className="sentDealsItem__link">
            <div className="sentDealsItem__header">
              <div className="sentDealsItem__orderIcon">
                <Icon name="send-deal" />
              </div>
              <div className="sentDealsItem__orderRef">{this.props.t('SentDealsItem.digital_deal')}</div>
              <div className="sentDealsItem__orderHeaderItem">{moment(addedAt).format('D MMM YY HH:mm')}</div>
              <div className="sentDealsItem__sentBy">
                <div className="sentDealsItem__subHeader">{this.props.t('SentDealsItem.sent_by')} </div>
                <div className="sentDealsItem__statusUserEmail">{responsibleUserName}</div>
              </div>
            </div>
            <div className="sentDealsItem__content">
              <div className="sentDealsItem__vehicle">
                <VehicleImageWithVrm
                  vrm={vehicle.vrm}
                  image={vehicleImageUrls && vehicleImageUrls[0]}
                  vehicleClass={vehicle.class}
                />
              </div>
              <div className="sentDealsItem__info">
                <div
                  className="sentDealsItem__infoItem sentDealsItem__customerName sentDealsItem__customerName"
                  data-Thook="CustomerName"
                >
                  {firstName} {lastName}
                  {shouldShowMultipleDealsIcon && (
                    <div className="sentDealsItem__multipleDeals">
                      <div className="sentDealsItem__dealIcon">
                        <Icon name="deals_icon" />
                      </div>
                      <div className="sentDealsItem__dealTotal">{deals.length}</div>
                    </div>
                  )}
                </div>
                <div className="sentDealsItem__infoItem" data-Thook="VehicleInfo">
                  <span>{vehicle.make}</span> <span>{vehicle.model} </span>
                  <div>{vehicle.derivative}</div>
                </div>
                <div className="sentDealsItem__infoItem" data-Thook="VehiclePrice">
                  <div className="sentDealsItem__vehiclePrice">
                    <MoneyFormat value={shouldShowOfferPrice ? offerPrice : vehicleCashPrice} />
                  </div>
                </div>
              </div>
              <div className="sentDealsItem__otherInfo">
                <div className="sentDealsItem__status">
                  <div className="sentDealsItem__subHeader">{this.props.t('SentDealsItem.deal_status')}</div>
                  <div className="sentDealsItem__statusTagOuter">
                    <SentDealTag status={dealStatus} openedAt={openedAt} />
                  </div>
                </div>
              </div>
            </div>
          </Link>
          {showAdditionalDeals && (
            <>
              <div className="sentDealsItem__expandButton" onClick={this.toggleDealDetails}>
                <div className={arrowClassname}>
                  <span className="sentDealsItem__expandButtonArrow--outer">
                    <Icon name="down" />
                  </span>
                </div>
              </div>
              <div className={dealsItemBottomClassname} style={dealsItemBottomStyles}>
                <div className="sentDealsItem__expandedConnectorLine" />
                {additionalDeals.map((deal, i) => (
                  <SentDealMultipleDealsItem
                    key={i}
                    deal={deal}
                    vehicle={deal.vehicle}
                    viewDealLink={this.setDealLink(customer.customerId, deal.dealId)}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}

SentDealsItem.propTypes = {
  appStore: PropTypes.object,
  customer: PropTypes.object,
  dealershipId: PropTypes.string
};
export default withTranslation('DigitalDeals')(inject(['appStore'])(observer(SentDealsItem)));
