import React from 'react';
import PropTypes from 'prop-types';
import { reaction } from 'mobx';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import LoadingSpinner from '../Loading/LoadingSpinner';
import './button.scss';
import Icon from '../Icon/Icon';

const DEFAULT_STATE = 0;
const LOADING_STATE = 1;
const SUCCESS_STATE = 2;
const ERROR_STATE = 3;

class Button extends React.Component {
  completionTimer = null;
  autorunDisposer = null;

  constructor(props) {
    super(props);

    this.state = {
      loadingStage: DEFAULT_STATE
    };
  }

  UNSAFE_componentWillMount() {
    this.autorunDisposer = reaction(
      () => this.props.isLoading,
      () => this.updateLoadingState()
    );
  }

  componentWillUnmount() {
    this.autorunDisposer && this.autorunDisposer();
    clearTimeout(this.completionTimer);
  }

  updateLoadingState() {
    let isLoading = this.props.isLoading;
    let hasError = this.props.hasError;
    let wasLoading = this.state.loadingStage === LOADING_STATE && !isLoading;

    if (isLoading) {
      this.setState({ loadingStage: LOADING_STATE });
    } else if (wasLoading && hasError) {
      this.setState({ loadingStage: ERROR_STATE });
      this.onCompletion();
    } else if (wasLoading) {
      this.setState({ loadingStage: SUCCESS_STATE });
      this.onCompletion();
    } else {
      this.setState({ loadingStage: DEFAULT_STATE });
    }
  }

  onCompletion = () => {
    this.completionTimer = window.setTimeout(() => {
      this.setState({ loadingStage: DEFAULT_STATE });
    }, 2000);
  };

  handleClick = (e) => {
    this.props.onClick && this.props.onClick(e);
    this.props.trackingPage &&
      window.ga &&
      window.ga('send', 'event', this.props.trackingPage, 'ButtonPress', this.props.id);
  };

  render() {
    let {
      type,
      buttonStyle,
      size,
      children,
      onClick,
      to,
      href,
      disabled,
      tabIndex,
      id,
      className,
      dataThook,
      dataTestId
    } = this.props;

    let classes = classNames(
      'button',
      className,
      !disabled && `button--${buttonStyle}`,
      `button--${size}`,
      this.state.loadingStage === LOADING_STATE && 'button--isLoading',
      disabled && `button--${buttonStyle}Disabled`,
      this.state.loadingStage === ERROR_STATE && 'hasError'
    );

    let labelClasses = classNames('button__text', this.state.loadingStage !== DEFAULT_STATE && 'isHidden');

    let isDisabled = disabled || this.state.loadingStage !== DEFAULT_STATE;

    if (to && !isDisabled) {
      return (
        <Link
          className={classes}
          to={to}
          onClick={onClick}
          tabIndex={tabIndex}
          data-thook={id && id.replace(/ /g, '') + 'Button'}
        >
          {children}
        </Link>
      );
    }

    if (href) {
      return (
        <a className={classes} href={href} onClick={onClick} tabIndex={tabIndex}>
          {children}
        </a>
      );
    }

    return (
      <button
        className={classes}
        type={type}
        onClick={this.handleClick}
        disabled={isDisabled}
        tabIndex={undefined}
        id={id}
        data-thook={this.props.dataThook}
        data-th={`${dataTestId || dataThook}Button` || 'Button'}
      >
        <span className={labelClasses}>{children}</span>

        <TransitionGroup>
          {this.state.loadingStage === DEFAULT_STATE && (
            <CSSTransition key="text" classNames="button-animation" timeout={{ enter: 400, exit: 400 }}>
              <div className="button__state">{children}</div>
            </CSSTransition>
          )}
          {this.state.loadingStage === LOADING_STATE && (
            <CSSTransition key="loading" classNames="button-animation" timeout={{ enter: 400, exit: 400 }}>
              <div className="button__state">
                <div className="button__spinner">
                  <LoadingSpinner size={this.props.size === 'large' ? 'normal' : 'small'} />
                </div>
              </div>
            </CSSTransition>
          )}
          {this.state.loadingStage === SUCCESS_STATE && (
            <CSSTransition key="tick" classNames="button-animation" timeout={{ enter: 400, exit: 400 }}>
              <div className="button__state">
                <div className="button__icon">
                  <Icon name="validation-tick" />
                </div>
              </div>
            </CSSTransition>
          )}
          {this.state.loadingStage === ERROR_STATE && (
            <CSSTransition key="cross" classNames="button-animation" timeout={{ enter: 400, exit: 400 }}>
              <div className="button__state" key="cross">
                <div className="button__icon">
                  <Icon name="validation-cross" />
                </div>
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </button>
    );
  }
}

Button.defaultProps = {
  type: 'submit',
  buttonStyle: 'primary',
  size: 'normal'
};

Button.propTypes = {
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.any,
  buttonStyle: PropTypes.oneOf(['primary', 'secondary', 'cancel', 'custom', 'select']),
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  tabIndex: PropTypes.number,
  id: PropTypes.string,
  trackingPage: PropTypes.string,
  dataThook: PropTypes.string,
  dataTestId: PropTypes.string
};

export default observer(Button);
