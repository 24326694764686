import React from 'react';
import PropTypes from 'prop-types';
import helpIcon from '../../styles/icons/i.svg';
import Image from './Image';
import './inlineExclamationWarning.scss';

const InlineExclamationWarning = (props) => {
  return (
    <div className="inlineExclamationWarning">
      <div className="inlineExclamationWarning__imageContainer">
        <div className="inlineExclamationWarning__image">
          <Image imageName={helpIcon} />
        </div>
      </div>

      <div className="inlineExclamationWarning__body">
        {props.title && <span className="inlineExclamationWarning__title">{props.title}</span>}
        {props.message && <span>{props.message}</span>}
        {props.children && <span>{props.children}</span>}
      </div>
    </div>
  );
};

InlineExclamationWarning.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string
};

export default InlineExclamationWarning;
