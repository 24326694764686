import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Common/Modal/Modal';
import HelpVideoModal from '../HelpVideos/components/HelpVideoModal';
import WhatsNewSidebarLink from './WhatsNewSidebarLink';
import classnames from 'classnames';
import Rounder from '../../styles/icons/sidebar/Rounder';
import './sidebar.scss';
import SidebarItem from './SidebarItem';
import Icon from '../Common/Icon/Icon';
import { observer, inject } from 'mobx-react';
import TrackEvent from '../Common/Tracking/TrackEvent';
import { canLoad as canLoadWebshopModule } from '../Webshop/Webshop';
import { WebshopIcon } from '../../styles/icons/webshop';
import { compose } from 'redux';
import { withParams } from 'hocs/router';
import LanguageSelectTrigger from '../LanguageSelect/LanguageSelectTrigger';
import HelpNoVideoBDK from '../HelpVideos/components/HelpNoVideoBDK';
import LoadingSpinner from '../Common/Loading/LoadingSpinner';
import LanguageFlag from '../LanguageSelect/LanguageFlag';

class Sidebar extends React.Component {
  constructor() {
    super();
    this.state = {
      videoModalOpen: false,
      bdkHelpModalOpen: false
    };
  }

  handleModalOpen = () => {
    if (this.props.appStore.uiState.isBdk) {
      this.setState({
        bdkHelpModalOpen: true
      });
    } else {
      this.setState({
        videoModalOpen: true
      });
    }
    this.props.closeSidebar();
    this.props.handleStateChange();
  };

  handleLanguageModalOpen = () => {
    this.props.closeSidebar();
    this.props.handleStateChange();
  };
  handleModalClose = () => {
    this.setState({
      videoModalOpen: false,
      bdkHelpModalOpen: false
    });
  };
  handleClick = () => {
    this.props.handleStateChange();
  };
  signOut = (event) => {
    event.stopPropagation();
    this.props.onSignOut();
  };
  handlePushDealClick = () => {
    window.ga && window.ga('send', 'event', 'PushDealSidebar', 'ButtonPress', `PushDealSidebar__link`);
    this.props.handleStateChange();
  };

  componentDidMount() {
    this.props.appStore.reserveNowStore.getReservationSettings(this.props.params.dealershipId);
  }

  getReservationProps = () => {
    const { isDealershipAdmin } = this.props.appStore.uiState;
    const { isReserveSettingsSet } = this.props.appStore.reserveNowStore;
    const canSetupReservations = !isReserveSettingsSet && isDealershipAdmin;

    return {
      path: canSetupReservations ? 'settings/reserve' : 'reservenow',
      interactionName: canSetupReservations ? 'ReservationsSettingsSidebarClick' : 'ReservationsSidebarClick',
      text: canSetupReservations ? this.props.t('Sidebar.setup_reservations') : this.props.t('Sidebar.reservations')
    };
  };

  render() {
    const defaultSearchParams = {
      sortField: 'lastModified',
      sortDirection: 'desc',
      page: '1',
      pageSize: '25',
      q: undefined,
      from: undefined,
      to: undefined,
      timePeriod: '',
      filter: ''
    };
    const {
      canViewMetricsOnSidebar,
      hasStockEnabled,
      canUseReporting,
      hasOrders,
      hasWebshopEnabled,
      canUseWebshop,
      canViewReservations,
      isBdk
    } = this.props.appStore.uiState;
    const { isPageLoading: reservationsLoading } = this.props.appStore.reserveNowStore;
    const reservationProps = this.getReservationProps();
    const sidebarClassNames = classnames('sidebar', this.props.isOpen ? 'sidebar--open' : 'sidebar--closed');
    const sidebarRounderClassNames = classnames(
      'sidebar__rounder',
      this.props.sidebarIsHovered && 'sidebar__rounder--open'
    );
    const sidebarInnerClassNames = classnames('sidebar__inner', this.props.sidebarIsHovered && 'sidebar__hover');
    return (
      <div
        ref={this.props.innerRef}
        onMouseEnter={this.props.handleSidebarHoverOpen}
        onMouseLeave={this.props.handleSidebarHoverClose}
        className={sidebarClassNames}
        data-th="Sidebar"
      >
        <button className="sidebar__button--open" onClick={this.handleClick}>
          <Icon name="validation-cross" />
        </button>
        <button className="sidebar__button--closed" onClick={this.handleClick}>
          <Icon name="burger" />
        </button>

        <div className={sidebarRounderClassNames}>
          <Rounder />
        </div>

        <div className={sidebarInnerClassNames}>
          <ul className="sidebar__buttonContainer">
            <LanguageSelectTrigger>
              {({ currentLanguage, handleModalOpen }) => (
                <SidebarItem
                  icon={<LanguageFlag imgSrc={currentLanguage.imageSrc} />}
                  sidebarIsHovered={this.props.sidebarIsHovered}
                  onClick={() => {
                    this.handleLanguageModalOpen();
                    handleModalOpen();
                  }}
                >
                  {currentLanguage.label}
                </SidebarItem>
              )}
            </LanguageSelectTrigger>
            {this.props.appStore.uiState.canViewDashboard && (
              <SidebarItem
                to={`/d/${this.props.params.dealershipId}`}
                onClick={this.props.handleStateChange}
                image="homeDashboard"
                dataThook="home"
                sidebarIsHovered={this.props.sidebarIsHovered}
              >
                {this.props.t('Sidebar.dashboard')}
              </SidebarItem>
            )}
            {canViewMetricsOnSidebar && (
              <SidebarItem
                to={`/d/${this.props.params.dealershipId}/stock/dealership-metrics/average-days-in-stock`}
                onClick={this.props.handleStateChange}
                image="homeDashboard"
                dataThook="home"
                sidebarIsHovered={this.props.sidebarIsHovered}
              >
                {this.props.t('Sidebar.metrics')}
              </SidebarItem>
            )}
            {hasStockEnabled && (
              <SidebarItem
                to={`/d/${this.props.params.dealershipId}/stock/list`}
                onClick={this.props.handleStateChange}
                image="stock"
                dataThook="stock"
                sidebarIsHovered={this.props.sidebarIsHovered}
              >
                {this.props.t('Sidebar.stock')}
              </SidebarItem>
            )}
            <SidebarItem
              to={`/d/${this.props.params.dealershipId}/customer/add`}
              onClick={this.props.handleStateChange}
              image="addCustomer"
              dataThook="add_customer"
              sidebarIsHovered={this.props.sidebarIsHovered}
            >
              {this.props.t('Sidebar.add_customer')}
            </SidebarItem>
            <SidebarItem
              to={{
                pathname: `/d/${this.props.params.dealershipId}/consumers`,
                query: defaultSearchParams
              }}
              onClick={this.props.handleStateChange}
              image="viewCustomers"
              dataThook="view_customers"
              sidebarIsHovered={this.props.sidebarIsHovered}
            >
              {this.props.t('Sidebar.view_customers')}
            </SidebarItem>

            {canViewReservations && (
              <TrackEvent
                featureName="sidebar"
                interactionName={reservationProps.interactionName}
                linkTo={`/d/${this.props.params.dealershipId}/${reservationProps.path}`}
              >
                <SidebarItem
                  to={`/d/${this.props.params.dealershipId}/${reservationProps.path}`}
                  image={'payments'}
                  sidebarIsHovered={this.props.sidebarIsHovered}
                  dataThook="reservations"
                >
                  {reservationsLoading ? <LoadingSpinner size="small" /> : reservationProps.text}
                </SidebarItem>
              </TrackEvent>
            )}

            {hasOrders && (
              <TrackEvent
                featureName="sidebar"
                interactionName="PushDealSidebarClick"
                linkTo={`/d/${this.props.params.dealershipId}/orders-and-deals`}
              >
                <SidebarItem
                  to={`/d/${this.props.params.dealershipId}/orders-and-deals`}
                  onClick={this.handlePushDealClick}
                  image={'digitalDeals'}
                  sidebarIsHovered={this.props.sidebarIsHovered}
                  dataThook="send_deal"
                >
                  {this.props.t('Sidebar.orders_deals')}
                </SidebarItem>
              </TrackEvent>
            )}
            {canUseReporting && (
              <SidebarItem
                to={`/d/${this.props.params.dealershipId}/reporting`}
                onClick={this.props.handleStateChange}
                image="reporting"
                dataThook="reporting"
                sidebarIsHovered={this.props.sidebarIsHovered}
              >
                {this.props.t('Sidebar.reporting')}
              </SidebarItem>
            )}
            {isBdk && (
              <SidebarItem
                to="https://ald-leasefinanz.de/ald/impressum"
                image="impressum"
                dataThook="impressum"
                sidebarIsHovered={this.props.sidebarIsHovered}
                newTab
              >
                Impressum
              </SidebarItem>
            )}
            {hasWebshopEnabled && canUseWebshop && canLoadWebshopModule() && (
              <SidebarItem
                to={`/d/${this.props.params.dealershipId}/webshop`}
                onClick={this.props.handleStateChange}
                icon={<WebshopIcon />}
                dataThook="webshop"
                sidebarIsHovered={this.props.sidebarIsHovered}
              >
                Webshop
              </SidebarItem>
            )}
            <SidebarItem
              to={`/d/${this.props.params.dealershipId}/settings`}
              onClick={this.props.handleStateChange}
              image="settings"
              dataThook="settings"
              sidebarIsHovered={this.props.sidebarIsHovered}
            >
              {this.props.t('Sidebar.settings')}
            </SidebarItem>
            <SidebarItem
              onClick={this.handleModalOpen}
              image="help"
              sidebarIsHovered={this.props.sidebarIsHovered}
              dataThook="help"
            >
              {this.props.t('Sidebar.help')}
            </SidebarItem>
            <SidebarItem
              onClick={this.signOut}
              image="signOut"
              sidebarIsHovered={this.props.sidebarIsHovered}
              dataThook="signOut"
            >
              {this.props.t('Sidebar.sign_out')}
            </SidebarItem>
          </ul>
          <WhatsNewSidebarLink
            link={`/d/${this.props.params.dealershipId}/platformupdates`}
            onClick={this.handleClick}
            hasPlatformUpdates={this.props.hasPlatformUpdates}
            sidebarIsHovered={this.props.sidebarIsHovered}
          />
        </div>
        <Modal isOpen={this.state.videoModalOpen} onClose={this.handleModalClose} title="Tutorial Videos">
          <HelpVideoModal currentPage={this.props.currentPage} currentQuery={this.props.currentQuery} />
        </Modal>
        <Modal
          isOpen={this.state.bdkHelpModalOpen}
          onClose={this.handleModalClose}
          title="Kontaktieren Sie das Support-Team"
        >
          <HelpNoVideoBDK currentPage={this.props.currentPage} currentQuery={this.props.currentQuery} />
        </Modal>
      </div>
    );
  }
}

Sidebar.propTypes = {
  innerRef: PropTypes.func,
  onSignOut: PropTypes.func,
  handleStateChange: PropTypes.func,
  isOpen: PropTypes.bool,
  currentPage: PropTypes.string,
  currentQuery: PropTypes.object,
  params: PropTypes.object,
  hasPlatformUpdates: PropTypes.bool
};
export default compose(withParams, withTranslation('Sidebar'), inject('appStore'), observer)(Sidebar);
