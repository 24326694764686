import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getCSFundersContent, getFunderContent, getFunderDetails } from '.';
import { useQuery } from '@tanstack/react-query';

export const SERVICE_PREFIX = 'content-service';

export const contentServiceQueries = createQueryKeys(SERVICE_PREFIX, {
  detail: (consumerDutyDetails: Parameters<typeof getFunderContent>['0'] | undefined) => {
    return {
      queryKey: [consumerDutyDetails ?? ''],
      queryFn: () => {
        if (consumerDutyDetails) {
          return getFunderContent(consumerDutyDetails);
        }
      }
    };
  },
  funderDetails: (funderCode: Parameters<typeof getFunderDetails>['0'] | undefined | null) => {
    return {
      queryKey: [funderCode ?? ''],
      queryFn: () => {
        if (funderCode) {
          return getFunderDetails(funderCode);
        }
      }
    };
  },
  fundersContent: (funderContentFilters: Parameters<typeof getCSFundersContent>['0'] | undefined | null) => {
    return {
      queryKey: [funderContentFilters || ''],
      queryFn: () => {
        if (funderContentFilters) {
          return getCSFundersContent(funderContentFilters);
        }
      }
    };
  }
});

export const useFunderConsumerDutyContent = (
  consumerDutyDetails: Parameters<typeof getFunderContent>['0'] | undefined
) => {
  return useQuery(contentServiceQueries.detail(consumerDutyDetails));
};

export const useFunderDetails = (funderCode: Parameters<typeof getFunderDetails>['0'] | undefined | null) => {
  return useQuery(contentServiceQueries.funderDetails(funderCode));
};

export const useFundersContent = (
  funderContentFilters: Parameters<typeof getCSFundersContent>['0'] | undefined | null
) => {
  return useQuery(contentServiceQueries.fundersContent(funderContentFilters));
};
