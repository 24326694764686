import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  delete draft.applicationStatuses!.NotProceeding;
  delete draft.applicationStatuses!.Other;
  delete draft.applicationStatuses!.ProblemPayout;
  delete draft.applicationStatuses!.Prospect;
  delete draft.applicationStatuses!.SentForPayout;
  delete draft.applicationStatuses!.Unknown;
  draft.applicationStatuses.WaitingForFurtherInformation!.isReproposable = true;
  draft.applicationStatuses.WaitingForFurtherInformation!.isComplete = true;
  draft.applicationStatuses.Error!.isEditable = false;
  draft.applicationStatuses.Error!.isReproposable = false;
  draft.applicationStatuses.Pending!.isEditable = false;
  draft.applicationStatuses.Pending!.isPending = true;
  draft.applicationStatuses.Referred!.isEditable = false;
  draft.applicationStatuses.Referred!.isReproposable = false;
  draft.applicationStatuses.Submitted!.isEditable = false;
  draft.applicationStatuses.Submitted!.isSubmitting = true;

  draft.applicationFields.AffordabilityDetails = {
    GrossAnnual: true
  };

  draft.validationRules.AffordabilityDetails = {
    Income: {
      GrossAnnual: 'currency'
    }
  };
});
