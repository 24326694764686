import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.PersonalDetails!.BornUK = true;
  draft.applicationFields.PersonalDetails!.BirthPlace = true;
  draft.validationRules.PersonalDetails!.CountryOfBirth!.BornUK = 'required';
  draft.validationRules.PersonalDetails!.CountryOfBirth!.BirthPlace = 'required:iffalse:BornUK';
  draft.validationRules.PersonalDetails!.Nationality = 'required';
});
