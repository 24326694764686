import React from 'react';
import PropTypes from 'prop-types';
import Icon from '~Common/Icon/Icon';

import classes from './imageCount.module.scss';

const ImageCount = ({ count }) => {
  if (!count || typeof count !== 'number') {
    return null;
  }

  return (
    <div className={classes.imageNumbers}>
      <Icon name="camera" />
      {count}
    </div>
  );
};

ImageCount.propTypes = {
  count: PropTypes.number
};

export default ImageCount;
