const sharedDefaultRules = {
  editSections: {
    vehicle: true,
    quote: true,
    employment: true,
    affordability: true,
    bankDetails: true
  },
  applicationStatuses: {
    Accepted: {
      isEditable: true,
      isEditableWhenFinalDecisionIsReceived: true,
      isReproposable: true,
      isComplete: true,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    ConditionalAccept: {
      isEditable: true,
      isEditableWhenFinalDecisionIsReceived: true,
      isReproposable: true,
      isComplete: true,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    Error: {
      isEditable: true,
      isEditableWhenFinalDecisionIsReceived: true, // overrides isEditable
      isReproposable: true,
      isComplete: false,
      isError: true,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    Intercepted: {
      isEditable: false,
      isEditableWhenFinalDecisionIsReceived: true,
      isReproposable: false,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false,
      isIntercepted: true
    },
    NoDecisionYet: {
      isEditable: true,
      isEditableWhenFinalDecisionIsReceived: true,
      isReproposable: true,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    NotProceeding: {
      isEditable: true,
      isEditableWhenFinalDecisionIsReceived: true,
      isReproposable: false,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    NotTakenUp: {
      isEditable: true,
      isEditableWhenFinalDecisionIsReceived: true,
      isReproposable: true,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    Other: {
      isEditable: true,
      isEditableWhenFinalDecisionIsReceived: true,
      isReproposable: true,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    PaidOut: {
      isEditable: false,
      isEditableWhenFinalDecisionIsReceived: true,
      isReproposable: false,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: true,
      isPending: false
    },
    Pending: {
      isEditable: true,
      isEditableWhenFinalDecisionIsReceived: true,
      isReproposable: false,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    ProblemPayout: {
      isEditable: true,
      isEditableWhenFinalDecisionIsReceived: true,
      isReproposable: false,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    Prospect: {
      isEditable: true,
      isEditableWhenFinalDecisionIsReceived: true,
      isReproposable: true,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    Referred: {
      isEditable: true,
      isEditableWhenFinalDecisionIsReceived: true,
      isReproposable: true,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    Rejected: {
      isEditable: true,
      isReproposable: true,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    Submitted: {
      isEditable: true,
      isReproposable: false,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    Submitting: {
      isEditable: false,
      isReproposable: false,
      isComplete: false,
      isError: false,
      isSubmitting: true,
      isPaidOut: false,
      isPending: false
    },
    SentForPayout: {
      isEditable: true,
      isReproposable: false,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    Unknown: {
      isEditable: true,
      isReproposable: true,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    },
    WaitingForFurtherInformation: {
      isEditable: true,
      isReproposable: false,
      isComplete: false,
      isError: false,
      isSubmitting: false,
      isPaidOut: false,
      isPending: false
    }
  }
};

export default sharedDefaultRules;
