import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as consumerActions from '../../../redux/consumer/consumerActions';
import ErrorApology from '../../Common/ErrorApology';
import * as componentActions from '../../../redux/component/componentActions';
import { denormaliseCustomer, currentVehicleTabIndex } from '../selectors/customerUiSelectors';
import * as modalActions from '../../../redux/modal/modalActions';
import { observer, inject } from 'mobx-react';
import { compose } from 'redux';
import { withParams, withQuery } from 'hocs/router';
import { Outlet } from 'react-router-dom';
import { push } from 'routerHistory';
class CustomerContainer extends React.Component {
  UNSAFE_componentWillMount() {
    this.props.load('CustomerContainer');

    if (this.props.appStore.uiState.hasOrders === true) {
      this.props.appStore.pushDealStore.getCustomerDeals(this.props.params.consumerId, this.props.params.dealershipId);
    }
  }

  componentWillUnmount() {
    this.props.unload('CustomerContainer');
  }

  render() {
    const { children, ...remainingProps } = this.props;

    if (this.props.components.CustomerContainer && this.props.components.CustomerContainer.errorMessage) {
      return <ErrorApology>{this.props.components.CustomerContainer.errorMessage}</ErrorApology>;
    }

    if (!(this.props.components.CustomerContainer && this.props.components.CustomerContainer.hasLoaded)) {
      return null;
    }

    return <Outlet context={remainingProps} />;
  }
}

CustomerContainer.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,
  hasError: PropTypes.bool,
  errorText: PropTypes.string,
  children: PropTypes.any,
  load: PropTypes.func,
  unload: PropTypes.func,
  components: PropTypes.object,
  appStore: PropTypes.object
};

function mapStateToProps(state, ownProps) {
  const customer = denormaliseCustomer(state);

  const canSelfServeQuotes =
    ownProps.appStore.uiState.canSelfServeQuotes &&
    !(
      process.env.REACT_APP_CAN_CORPORATE_SEND_DEAL !== 'true' &&
      customer.CustomerType &&
      customer.CustomerType.toLowerCase() === 'corporate'
    );

  return {
    session: state.session,
    consumer: customer,
    dealership: state.dealership || {},
    dealerships: state.session.Dealerships,
    enableSendDeal: canSelfServeQuotes,
    addNoteLoadingState: state.ui.customer.addCommentState,
    applicationLoadingState: state.ui.customer.createApplicantLoadingState,
    assignees: customer.assignedTo,
    components: state.components,
    selectedFinanceTab: state.ui.customer.selectedFinanceTab,
    currentVehicleTabIndex: currentVehicleTabIndex(state),
    selectedCfcCheckIndex: state.ui.customer.selectedCfcCheckIndex,
    selectedSentDealIndex: state.ui.customer.selectedSentDealIndex,
    submittingState: state.ui.customer.submittingState,
    submitError: state.ui.customer.submitError
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    applyForCfcCheck: (prospectId, quoteId, quote, dealershipId, consumerId) =>
      dispatch(consumerActions.createApplicantFromCfcProspect(prospectId, quoteId, quote, dealershipId, consumerId)),
    handleCfcCheckDetailsLink: (quote, prospectId) =>
      push(
        `/d/${ownProps.params.dealershipId}/consumers/${ownProps.params.consumerId}/cfcprospect/${prospectId}/quote/${quote.QuoteId}`
      ),

    load: (componentName) => dispatch(componentActions.componentLoad(componentName, ownProps.params, ownProps.query)),
    unload: (componentName) => dispatch(componentActions.componentUnload(componentName, ownProps.params)),
    handleFinanceApplicationsView: (quote, shouldPrefill) => {
      if (quote.LenderStatus === 'Not Submitted') {
        let prefill = shouldPrefill ? '?prefill=true' : '';

        push(
          `/d/${ownProps.params.dealershipId}/consumers/${ownProps.params.consumerId}/application/${quote.ApplicantId}/applicationsummary${prefill}`
        );
      } else {
        push(
          `/d/${ownProps.params.dealershipId}/consumers/${ownProps.params.consumerId}/application/${quote.ApplicantId}/status`
        );
      }
    },
    handleFinanceApplicationsEdit: (applicantId) =>
      push(
        `/d/${ownProps.params.dealershipId}/consumers/${ownProps.params.consumerId}/application/${applicantId}/quotepresubmission`
      ),
    onSortSavedQuotes: (vehicleId, field) => {
      dispatch(consumerActions.sortSection(vehicleId, 'SavedQuotes', field));
    },
    onSortFinanceApplications: (vehicleId, field) => {
      dispatch(consumerActions.sortSection(vehicleId, 'FinanceApplications', field));
    },
    onSortCfcProspects: (vehicleId, field) => {
      dispatch(consumerActions.sortSection(vehicleId, 'CfcProspects', field));
    },
    handleViewSavedQuote: (quote) => {
      push(`/d/${ownProps.params.dealershipId}/consumers/${ownProps.params.consumerId}/saved-quotes/${quote.QuoteId}`);
    },
    openCommentsModal: (customerId, vehicleId, notes) => {
      dispatch(modalActions.open('vehicleNotes', { customerId, vehicleId, notes }));
    },
    openEligibilityModal: (matchRate, decisionMessages) => {
      dispatch(modalActions.open('eligibilityModal', { matchRate, decisionMessages }));
    },
    openEligibilitySummaryModal: (personalScore, creditScore) => {
      dispatch(modalActions.open('eligibilitySummary', { personalScore, creditScore }));
    },
    openDealershipDistanceModal: (distance, dealerPostcode, customerPostcode) => {
      dispatch(
        modalActions.open('dealershipDistance', {
          distance,
          dealerPostcode,
          customerPostcode,
          customerId: ownProps.params.consumerId
        })
      );
    },
    selectFinanceTab: (name) => {
      dispatch(consumerActions.selectCustomerTab(name));
    },
    selectVehicle: (vehicle) => {
      dispatch(consumerActions.selectVehicle(vehicle));
    },
    selectCfcCheck: (selectedCfcCheckIndex) => {
      dispatch(consumerActions.selectCfcCheck(selectedCfcCheckIndex));
    },
    selectSentDeal: (selectedSentDealIndex) => {
      dispatch(consumerActions.selectSentDeal(selectedSentDealIndex));
    }
  };
}

export default compose(
  withParams,
  withQuery,
  inject(['appStore']),
  connect(mapStateToProps, mapDispatchToProps),
  observer
)(CustomerContainer);
