import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import Icon from '../Common/Icon/Icon';
import withFormState from './withFormState';
import './mobxFieldGroup.scss';
import MobxFormLabel from './MobxFormLabel';

@observer
@withFormState
class MobxFieldGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldShowError: false
    };
  }

  handleBlur = () => {
    this.setState({ shouldShowError: true });
  };

  triggerError = () => {
    this.setState({ shouldShowError: true });
  };

  render() {
    let { error, isInline, isInlineWide, errorMessage, size, className, information } = this.props;

    let showError =
      this.props.fieldShowsErrors && (this.props.isFormSubmitted || this.state.shouldShowError || this.props.showError);
    let isValid = showError && !error;
    let isInvalid = showError && !!error;
    let triggerError = this.triggerError;

    let classes = classNames(
      className,
      'mobxFieldGroup',
      size === 'small' && 'mobxFieldGroup--small',
      isInline && 'mobxFieldGroup__inline',
      isInlineWide && 'mobxFieldGroup__inlineWide',
      this.props.pushDealStyle && 'mobxFieldGroup--pushDeal',
      this.props.stockUploadStyles && 'mobxFieldGroup--stockUploadStyles'
    );

    let labelClasses = classNames(
      'mobxFieldGroup__label',
      isInline && 'mobxFieldGroup__label--inline',
      isInlineWide && 'mobxFieldGroup__label--inlineWide',
      size === 'small' && 'mobxFieldGroup__label--small',
      this.props.pushDealStyle && 'mobxFieldGroup--pushDeal__label'
    );

    let iconClasses = classNames(
      'mobxFieldGroup__icon',
      isValid && 'mobxFieldGroup__tick',
      isValid && this.props.pushDealStyle && 'mobxFieldGroup--pushDeal__tick',
      isInvalid && 'mobxFieldGroup__cross',
      isInvalid && this.props.pushDealStyle && 'mobxFieldGroup--pushDeal__cross',
      isInline && 'mobxFieldGroup__icon--inline',
      isInlineWide && 'mobxFieldGroup__icon--inlineWide',
      size === 'small' && 'mobxFieldGroup__icon--small',
      information && 'mobxFieldGroup__information'
    );

    let inputClasses = classNames(
      'mobxFieldGroup__input',
      size === 'small' && 'mobxFieldGroup__input--small',
      isInline && 'mobxFieldGroup__input--inline',
      isInlineWide && 'mobxFieldGroup__input--inlineWide',
      this.props.pushDealStyle && 'mobxFieldGroup--pushDeal__input'
    );

    let errorClasses = classNames(
      'mobxFieldGroup__errorMessage',
      isInline && 'mobxFieldGroup__errorMessage--inline',
      isInlineWide && 'mobxFieldGroup__errorMessage--inlineWide',
      size === 'small' && 'mobxFieldGroup__errorMessage--small',
      information && !isInvalid && 'mobxFieldGroup__information'
    );

    const children = React.Children.toArray(this.props.children);

    let labelComponents = [];
    let inputComponents = [];

    children.forEach((child) => {
      if (child.type === MobxFormLabel) {
        labelComponents.push(
          React.cloneElement(child, {
            size
          })
        );
      } else {
        inputComponents.push(
          React.cloneElement(child, {
            isValid,
            isInvalid,
            showError,
            triggerError,
            size,
            onBlur: this.handleBlur
          })
        );
      } // TODO: if not a Input which can read isValid/isInvalid/triggerError etc, injects junk into the DOM, which React throws away but does shout about in console.errors
    });

    return (
      <>
        {(this.props.pushDealStyle || this.props.stockUploadStyles) && (
          <div>
            <div className={classes}>
              <div className={labelClasses}>{labelComponents}</div>
              <div className={iconClasses}>
                {isValid && !information && <Icon name="validation-tick" />}
                {isInvalid && <Icon name="validation-cross" />}
                {!isInvalid && information && <Icon name="information" />}
              </div>
              <div className={inputClasses}>{inputComponents}</div>
            </div>
            <div className={errorClasses}>
              {showError && this.props.error && <span>{errorMessage || error.message}</span>}
              {information && !this.props.error && <span>{information}</span>}
            </div>
          </div>
        )}
        {/*
          isInlineWide is currently only used in the Find a vehicle page for quick quote
          We will be changing the layouts of forms going forward and this can become the permanent solutions
          or after upgrading Node and React we can replace it with a component library
         */}
        {isInlineWide && (
          <div className={classes}>
            <div className={labelClasses}>{labelComponents}</div>
            <div className={inputClasses}>
              <div className={inputClasses}>{inputComponents}</div>
              <div className={errorClasses}>
                {showError && this.props.error && <span>{errorMessage || error.message}</span>}
                {information && !this.props.error && <span>{information}</span>}
              </div>
            </div>
          </div>
        )}
        {!this.props.pushDealStyle && !this.props.stockUploadStyles && !isInlineWide && (
          <div className={classes}>
            <div className={labelClasses}>{labelComponents}</div>
            <div className={iconClasses}>
              {isValid && !information && <Icon name="validation-tick" />}
              {isInvalid && <Icon name="validation-cross" />}
              {!isInvalid && information && <Icon name="information" />}
            </div>
            <div className={inputClasses}>{inputComponents}</div>
            <div className={errorClasses}>
              {showError && this.props.error && <span>{errorMessage || error.message}</span>}
              {information && !this.props.error && <span>{information}</span>}
            </div>
          </div>
        )}
      </>
    );
  }
}

MobxFieldGroup.defaultProps = {
  isInline: false,
  isInlineWide: false,
  fieldShowsErrors: true
};

MobxFieldGroup.propTypes = {
  children: PropTypes.any,
  error: PropTypes.object,
  htmlFor: PropTypes.string,
  fieldShowsErrors: PropTypes.bool,
  isFormSubmitted: PropTypes.bool,
  size: PropTypes.string,
  showError: PropTypes.bool,
  isInline: PropTypes.bool,
  isInlineWide: PropTypes.bool,
  errorMessage: PropTypes.string,
  className: PropTypes.string,
  information: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default MobxFieldGroup;
