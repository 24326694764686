import produce from 'immer';
import defaultRules, { LenderConsumerConfig } from './default';

export default produce(defaultRules, (draft: LenderConsumerConfig) => {
  draft.applicationFields.AffordabilityDetails = {
    GrossAnnual: true
  };

  draft.validationRules.AffordabilityDetails = {
    Income: {
      GrossAnnual: 'required, currency'
    }
  };
});
