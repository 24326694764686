import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';
import './inputTwo.scss';
import CurrencySymbol from '../CurrencySymbol';

class Input2 extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    isValid: PropTypes.bool,
    isInvalid: PropTypes.bool,
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    suffix: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    valid: PropTypes.bool,
    invalid: PropTypes.bool,
    maxLength: PropTypes.number,
    prefix: PropTypes.string
  };

  UNSAFE_componentWillMount() {
    let value;

    if (this.props.type === 'currency') {
      value = parseFloat(this.props.value) ? parseFloat(this.props.value).toFixed(2) : this.props.value;
    } else {
      value = this.props.value;
    }

    this.setState({ value });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.type === 'currency' && document.activeElement !== this.refs.input) {
      const value = parseFloat(nextProps.value) ? parseFloat(nextProps.value).toFixed(2) : nextProps.value;
      this.setState({ value });
    } else {
      this.setState({ value: nextProps.value });
    }
  }

  handleBlur = () => {
    if (this.props.type === 'currency') {
      let newValue = isNaN(parseFloat(this.state.value)) ? this.state.value : parseFloat(this.state.value).toFixed(2);

      this.setState(
        {
          value: newValue
        },
        function() {
          //I'm sorry
          this.props.onChange({
            currentTarget: {
              value: newValue
            }
          });
        }
      );
    }

    this.props.onBlur && this.props.onBlur();
  };

  handleChange = (e) => {
    this.setState({
      value: e.currentTarget.value
    });

    this.props.onChange(e);
  };

  render() {
    const type = this.props.type;
    let inputStyles;

    inputStyles = classnames(
      this.props.suffix ? 'inputTwo__inputSuffix' : 'inputTwo__input',
      this.props.isInvalid && !this.props.disabled && 'inputTwo__invalid',
      this.props.isValid && !this.props.disabled && 'inputTwo__valid',
      this.props.disabled && 'inputTwo__disabled'
    );

    if (type === 'currency' || this.props.prefix) {
      inputStyles = classnames(
        'inputTwo__inputWithPrefix',
        this.props.isInvalid && !this.props.disabled && 'inputTwo__invalid',
        this.props.isValid && !this.props.disabled && 'inputTwo__valid',
        this.props.disabled && 'inputTwo__disabled'
      );
    }

    const suffixStyles = classnames(
      'inputTwo__suffix',
      this.props.suffix === '%' ? 'inputTwo__suffixFontSizeLarge' : 'inputTwo__suffixFontSizeSmall'
    );

    let prefixStyles = classnames('inputTwo__prefix', this.props.type !== 'currency' && 'inputTwo__textPrefix');

    const dataAttrs = {};

    if (this.props.isInvalid) {
      dataAttrs['data-is-invalid'] = true;
    }

    const { isInvalid, isValid, suffix, valid, invalid, ...remainingProps } = this.props;

    let prefix = this.props.type === 'currency' ? <CurrencySymbol /> : this.props.prefix;

    return (
      <div className="inputTwo">
        {prefix ? (
          <div className="inputTwo__prefixWrapper">
            <div className={prefixStyles}>{prefix}</div>
          </div>
        ) : (
          ''
        )}
        <input
          className={inputStyles}
          id={this.props.id}
          data-thook={`${this.props.id}Input`}
          placeholder={this.props.placeholder}
          {...remainingProps}
          value={this.state.value}
          type={this.props.type === 'currency' ? 'tel' : type}
          ref="input"
          autoComplete="off"
          {...dataAttrs}
          onBlur={this.handleBlur}
          onWheel={(e) => e.target.blur()}
          onChange={this.handleChange}
          maxLength={this.props.maxLength}
        />
        {this.props.suffix ? (
          <div className="inputTwo__suffixWrapper">
            <div className={suffixStyles}>{this.props.suffix}</div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

Input2.defaultProps = {
  type: 'text',
  valid: false,
  invalid: false,
  maxLength: 100
};

export default Input2;
