import React from 'react';
import { Route } from 'react-router-dom';
import StockContainer from '~SM/routes/stock';
import StockListContainer from '~SM/routes/stock/routes/list';
import StockFixWrapper from '~SM/routes/stock/routes/$vehicleId/routes/fix-issues';
import StockAddContainer from '~SM/routes/stock/routes/add';
import StockLookupContainer from '~SM/routes/stock/routes/add/routes/lookup';
import StockRefinementContainer from '~SM/routes/stock/routes/add/routes/refine';
import StockCreateWrapper from '~SM/routes/stock/routes/add/routes/create';
import StockEditWrapper from '~SM/routes/stock/routes/edit/routes/$vehicleId';
import StockEditVehicleDetails from '~SM/routes/stock/routes/edit/routes/$vehicleId/routes/details';
import StockMediaUploadContainer from '~SM/routes/stock/routes/edit/routes/$vehicleId/routes/media';
import StockEditRefinementContainer from '~SM/routes/stock/routes/edit/routes/$vehicleId/routes/refine';
import DealershipMetrics from './routes/stock/routes/dealership-metrics/DealershipMetrics';

import AverageDaysInStock from './routes/stock/routes/dealership-metrics/charts/AverageDaysInStock/AverageDaysInStock';
import SubmittedApplications from './routes/stock/routes/dealership-metrics/charts/SubmittedApplications/SubmittedApplications';
import EligibilityChecks from './routes/stock/routes/dealership-metrics/charts/EligibilityChecks/EligibilityChecks';
import OnlineQuotes from './routes/stock/routes/dealership-metrics/charts/OnlineQuotes/OnlineQuotes';
import SearchImpressions from './routes/stock/routes/dealership-metrics/charts/SearchImpressions/SearchImpressions';
import TrackedPage from '~/components/TrackedPage';
import ErrorBoundary from '~/components/Errors/ErrorBoundary';

const Stock = (
  <Route
    path="d/:dealershipId/stock"
    element={
      <ErrorBoundary>
        <StockContainer />
      </ErrorBoundary>
    }
  >
    {/* Metrics */}
    <Route
      path="dealership-metrics"
      element={
        <ErrorBoundary>
          <TrackedPage pageName="Stock - Vehicle Summary">
            <DealershipMetrics />
          </TrackedPage>
        </ErrorBoundary>
      }
    >
      <Route
        path="average-days-in-stock"
        element={
          <ErrorBoundary>
            <TrackedPage pageName="Average Vehicle Days in Stock">
              <AverageDaysInStock />
            </TrackedPage>
          </ErrorBoundary>
        }
      />
      <Route
        path="submitted-applications"
        element={
          <ErrorBoundary>
            <TrackedPage pageName="Submitted Applications">
              <SubmittedApplications />
            </TrackedPage>
          </ErrorBoundary>
        }
      />
      <Route
        path="eligibility-checks"
        element={
          <ErrorBoundary>
            <TrackedPage pageName="Eligibility Checks Completed">
              <EligibilityChecks />
            </TrackedPage>
          </ErrorBoundary>
        }
      />
      <Route
        path="online-quotes"
        element={
          <ErrorBoundary>
            <TrackedPage pageName="Online Quotes">
              <OnlineQuotes />
            </TrackedPage>
          </ErrorBoundary>
        }
      />
      <Route
        path="search-impressions"
        element={
          <ErrorBoundary>
            <TrackedPage pageName="Search Impressions">
              <SearchImpressions />
            </TrackedPage>
          </ErrorBoundary>
        }
      />
    </Route>

    <Route
      path="list"
      element={
        <TrackedPage pageName="Stock - List">
          <StockListContainer />
        </TrackedPage>
      }
    />

    <Route
      path=":vehicleId/fix-issues"
      element={
        <ErrorBoundary>
          <TrackedPage pageName="Stock - Vehicle Summary">
            <StockFixWrapper />
          </TrackedPage>
        </ErrorBoundary>
      }
    />

    {/* Stock Create */}
    <Route
      path="add"
      element={
        <ErrorBoundary>
          <StockAddContainer />
        </ErrorBoundary>
      }
    >
      <Route
        path="lookup"
        element={
          <ErrorBoundary>
            <TrackedPage pageName="Stock - Lookup">
              <StockLookupContainer />
            </TrackedPage>
          </ErrorBoundary>
        }
      />
      <Route
        path="refine"
        element={
          <ErrorBoundary>
            <TrackedPage pageName="Stock - Refine Vehicle Record">
              <StockRefinementContainer />
            </TrackedPage>
          </ErrorBoundary>
        }
      />
      <Route
        path="create"
        element={
          <ErrorBoundary>
            <TrackedPage pageName="Stock - Add Vehicle Record">
              <StockCreateWrapper />
            </TrackedPage>
          </ErrorBoundary>
        }
      />
    </Route>

    {/* Stock Edit */}
    <Route
      path="edit/:vehicleId"
      element={
        <ErrorBoundary>
          <StockEditWrapper />
        </ErrorBoundary>
      }
    >
      <Route
        path="details"
        element={
          <ErrorBoundary>
            <TrackedPage pageName="Stock - Edit Vehicle Record">
              <StockEditVehicleDetails />
            </TrackedPage>
          </ErrorBoundary>
        }
      />
      <Route
        path="media"
        element={
          <ErrorBoundary>
            <TrackedPage pageName="Stock - Edit Vehicle Media">
              <StockMediaUploadContainer />
            </TrackedPage>
          </ErrorBoundary>
        }
      />
    </Route>
    <Route
      path="edit/:vehicleId/refine"
      element={
        <ErrorBoundary>
          <TrackedPage pageName="Stock - Refine Vehicle Record">
            <StockEditRefinementContainer />
          </TrackedPage>
        </ErrorBoundary>
      }
    />
  </Route>
);

export default Stock;
