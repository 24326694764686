import * as actionTypes from '../actionTypes';
import _ from 'lodash';
import { makeMileOptions, makeMonthOptions, makeDayOptions } from './options.factory';
import i18n from '../../i18n';
import { formatOverrides } from './formatOverrides';
import appStore from '../../mobx-stores/appStore';
import { canSeeInterceptor } from '~/features';

const createState = () => ({
  Overrides: {},

  Dependants: [
    {
      key: '0',
      value: i18n.t('Options.none', {
        ns: 'Common'
      })
    },
    {
      key: '1',
      value: '1'
    },
    {
      key: '2',
      value: '2'
    },
    {
      key: '3',
      value: '3'
    },
    {
      key: '4',
      value: '4'
    },
    {
      key: '5',
      value: '5'
    },
    {
      key: '6',
      value: '6+'
    }
  ],
  YesOrNo: [
    {
      key: 'true',
      value: i18n.t('Options.yes', {
        ns: 'Common'
      })
    },
    {
      key: 'false',
      value: i18n.t('Options.no', {
        ns: 'Common'
      })
    }
  ],
  MileageOptions: makeMileOptions(3000, 45000),
  MotorBikeMileageOptions: makeMileOptions(3000, 45000, 2500),
  Terms: makeMonthOptions(12, 60),
  TermsExtended: makeMonthOptions(24, 120),
  CloseDealTerms: makeMonthOptions(12, 121),
  PreferredTerm: makeMonthOptions(12, 60, 6),
  PreferredTermExtended: makeMonthOptions(24, 120, 6),
  GermanTerms: makeMonthOptions(24, 84, 12),

  VatStatus: [
    {
      key: 'inc',
      value: i18n.t('Options.vat_inc', {
        ns: 'Common'
      })
    }
  ],
  VehicleType: [
    {
      key: 'used',
      value: i18n.t('Options.used', {
        ns: 'Common'
      })
    },
    {
      key: 'new',
      value: i18n.t('Options.new', {
        ns: 'Common'
      })
    }
  ],
  VehicleClass: [
    {
      key: 'car',
      value: i18n.t('Options.car', {
        ns: 'Common'
      })
    },
    {
      key: 'lcv',
      value: i18n.t('Options.lcv', {
        ns: 'Common'
      })
    },
    {
      key: 'bike',
      value: i18n.t('Options.bike', {
        ns: 'Common'
      })
    },
    ...(appStore.uiState.canUseMotorhomes
      ? [
          {
            key: 'motorhome',
            value: i18n.t('Options.motorhome', {
              ns: 'Common'
            })
          }
        ]
      : []),
    ...(appStore.uiState.canUseTouringCaravans
      ? [
          {
            key: 'touringcaravan',
            value: i18n.t('Options.touringcaravan', {
              ns: 'Common'
            })
          }
        ]
      : [])
  ],
  StockVehicleClass: [
    {
      key: 'car',
      value: i18n.t('Options.car', {
        ns: 'Common'
      })
    },
    {
      key: 'van',
      value: i18n.t('Options.van', {
        ns: 'Common'
      })
    },
    {
      key: 'motorbike',
      value: i18n.t('Options.motorbike', {
        ns: 'Common'
      })
    }
  ],
  StockVehicleClassIncUnknown: [
    {
      key: 'car',
      value: i18n.t('Options.car', {
        ns: 'Common'
      })
    },
    {
      key: 'van',
      value: i18n.t('Options.van', {
        ns: 'Common'
      })
    },
    {
      key: 'motorbike',
      value: i18n.t('Options.motorbike', {
        ns: 'Common'
      })
    },
    {
      key: 'unknown',
      value: i18n.t('Options.unknown', {
        ns: 'Common'
      })
    }
  ],
  Currency: [
    {
      key: 'GBP',
      value: i18n.t('Options.gbp', {
        ns: 'Common'
      })
    },
    {
      key: 'USD',
      value: i18n.t('Options.usd', {
        ns: 'Common'
      })
    },
    {
      key: 'EUR',
      value: i18n.t('Options.eur', {
        ns: 'Common'
      })
    }
  ],
  MileageUnits: [
    {
      key: 'MILE',
      value: i18n.t('Options.mile', {
        ns: 'Common'
      })
    },
    {
      key: 'KM',
      value: i18n.t('Options.km', {
        ns: 'Common'
      })
    }
  ],
  BuyerType: [
    {
      key: 'private',
      value: i18n.t('Options.private_individuals', {
        ns: 'Common'
      })
    },
    {
      key: 'business',
      value: i18n.t('Options.business', {
        ns: 'Common'
      })
    }
  ],
  ContactType: [
    {
      key: 'consumer',
      value: i18n.t('Options.private_individual', {
        ns: 'Common'
      })
    },
    {
      key: 'corporate',
      value: i18n.t('Options.business', {
        ns: 'Common'
      })
    }
  ],
  DepositType: [
    {
      key: 'fixed',
      value: i18n.t('Options.fixed', {
        ns: 'Common'
      })
    },
    {
      key: 'percent',
      value: i18n.t('Options.of_cash_price', {
        ns: 'Common'
      })
    }
  ],
  CommissionType: [
    {
      key: 'APR',
      value: i18n.t('Options.apr', {
        ns: 'Common'
      })
    },
    {
      key: 'FlatRate',
      value: i18n.t('Options.flat_rate', {
        ns: 'Common'
      })
    },
    {
      key: 'CommissionAdvance',
      value: i18n.t('Options.of_advance', {
        ns: 'Common'
      })
    },
    {
      key: 'CommissionFixed',
      value: i18n.t('Options.commission', {
        ns: 'Common'
      })
    }
  ],
  CompanyType: [
    {
      key: 'Association',
      value: i18n.t('Options.association', {
        ns: 'Common'
      })
    },
    {
      key: 'Corporate',
      value: i18n.t('Options.corporate', {
        ns: 'Common'
      })
    },
    {
      key: 'LimitedCompany',
      value: i18n.t('Options.limited_company', {
        ns: 'Common'
      })
    },
    {
      key: 'LimitedLiabilityPartnership',
      value: i18n.t('Options.limited_liability_partnership', {
        ns: 'Common'
      })
    },
    {
      key: 'LocalAuthority',
      value: i18n.t('Options.local_authority', {
        ns: 'Common'
      })
    },
    {
      key: 'NHSTrust',
      value: i18n.t('Options.nhs_trust', {
        ns: 'Common'
      })
    },
    {
      key: 'PublicLimitedCompany',
      value: i18n.t('Options.public_limited_company', {
        ns: 'Common'
      })
    },
    {
      key: 'Partnership',
      value: i18n.t('Options.partnership', {
        ns: 'Common'
      })
    },
    {
      key: 'RegisteredCharity',
      value: i18n.t('Options.registered_charity', {
        ns: 'Common'
      })
    },
    {
      key: 'School',
      value: i18n.t('Options.school', {
        ns: 'Common'
      })
    }
  ],
  PremisesType: [
    {
      key: 'Leased',
      value: i18n.t('Options.leased', {
        ns: 'Common'
      })
    },
    {
      key: 'Licensed',
      value: i18n.t('Options.licensed', {
        ns: 'Common'
      })
    },
    {
      key: 'OwnedMortgage',
      value: i18n.t('Options.owned_mortgage', {
        ns: 'Common'
      })
    },
    {
      key: 'OwnedOutright',
      value: i18n.t('Options.owned_outright', {
        ns: 'Common'
      })
    }
  ],
  LendersBDK: [
    {
      key: 'BDK',
      value: i18n.t('Options.bdk', {
        ns: 'Common'
      })
    }
  ],
  Lenders: [
    ...(canSeeInterceptor()
      ? [
          {
            key: 'ADM',
            value: i18n.t('Options.admiral_finance', { ns: 'Common' })
          }
        ]
      : []),
    {
      key: 'MOT',
      value: i18n.t('Options.moto_novo_finance', {
        ns: 'Common'
      })
    },
    {
      key: 'BLA',
      value: i18n.t('Options.black_horse_motor_finance', {
        ns: 'Common'
      })
    },
    {
      key: 'BAR',
      value: i18n.t('Options.barclays_partner_finance', {
        ns: 'Common'
      })
    },
    {
      key: 'MON',
      value: i18n.t('Options.moneyway', {
        ns: 'Common'
      })
    },
    {
      key: 'SAN',
      value: i18n.t('Options.santander_consumer_finance_uk_plc', {
        ns: 'Common'
      })
    },
    {
      key: 'MOE',
      value: i18n.t('Options.moneybarn', {
        ns: 'Common'
      })
    },
    {
      key: 'GMF',
      value: i18n.t('Options.gm_financial', {
        ns: 'Common'
      })
    },
    {
      key: 'ADV',
      value: i18n.t('Options.advantage_finance', {
        ns: 'Common'
      })
    },
    {
      key: 'ALD',
      value: i18n.t('Options.ald_automotive_limited', {
        ns: 'Common'
      })
    },
    {
      key: 'ALA',
      value: i18n.t('Options.alfa_romeo_financial_services', {
        ns: 'Common'
      })
    },
    {
      key: 'ALP',
      value: i18n.t('Options.alphera', {
        ns: 'Common'
      })
    },
    {
      key: 'ALL',
      value: i18n.t('Options.all_in_one_finance_ltd_uk_car_group_ltd', {
        ns: 'Common'
      })
    },
    {
      key: 'AML',
      value: i18n.t('Options.amigo_loans', {
        ns: 'Common'
      })
    },
    {
      key: 'AUF',
      value: i18n.t('Options.auto_union_finance', {
        ns: 'Common'
      })
    },
    {
      key: 'BLU',
      value: i18n.t('Options.blue_motor_finance_ltd', {
        ns: 'Common'
      })
    },
    {
      key: 'BWF',
      value: i18n.t('Options.borderway', {
        ns: 'Common'
      })
    },
    {
      key: 'BIL',
      value: i18n.t('Options.billing_finance_ltd', {
        ns: 'Common'
      })
    },
    {
      key: 'BMW',
      value: i18n.t('Options.bmw_financial_services_gb_limited', {
        ns: 'Common'
      })
    },
    {
      key: 'BNP',
      value: i18n.t('Options.bnp_paribas', {
        ns: 'Common'
      })
    },
    {
      key: 'CF7',
      value: i18n.t('Options.car_finance_247', {
        ns: 'Common'
      })
    },
    {
      key: 'CLU',
      value: i18n.t('Options.car_loans_4_u', {
        ns: 'Common'
      })
    },
    {
      key: 'CIT',
      value: i18n.t('Options.citroen_financial_services', {
        ns: 'Common'
      })
    },
    {
      key: 'CHR',
      value: i18n.t('Options.chrysler_financial_services', {
        ns: 'Common'
      })
    },
    {
      key: 'CLO',
      value: i18n.t('Options.close_brothers_motor_finance', {
        ns: 'Common'
      })
    },
    {
      key: 'DPF',
      value: i18n.t('Options.dpdg_finance_limited', {
        ns: 'Common'
      })
    },
    {
      key: 'DSG',
      value: i18n.t('Options.dsg_financial_services_limited', {
        ns: 'Common'
      })
    },
    {
      key: 'EVO',
      value: i18n.t('Options.evolution_funding', {
        ns: 'Common'
      })
    },
    {
      key: 'FCA',
      value: i18n.t('Options.fca_automotive_services_uk_ltd', {
        ns: 'Common'
      })
    },
    {
      key: 'FIA',
      value: i18n.t('Options.fiat_financial_services', {
        ns: 'Common'
      })
    },
    {
      key: 'FIR',
      value: i18n.t('Options.first_response_finance_limited', {
        ns: 'Common'
      })
    },
    {
      key: 'FOR',
      value: i18n.t('Options.fce_banks_plc_t_a_ford_credit_britain', {
        ns: 'Common'
      })
    },
    {
      key: 'GMA',
      value: i18n.t('Options.gmac_uk_plc', {
        ns: 'Common'
      })
    },
    {
      key: 'HDF',
      value: i18n.t('Options.harley_davidson_financial_services_europe_limited', {
        ns: 'Common'
      })
    },
    {
      key: 'HIT',
      value: i18n.t('Options.hitachi_capital_uk_plc', {
        ns: 'Common'
      })
    },
    {
      key: 'HON',
      value: i18n.t('Options.honda_finance_europe_plc', {
        ns: 'Common'
      })
    },
    {
      key: 'HSB',
      value: i18n.t('Options.hsbc_asset_finance_uk_ltd', {
        ns: 'Common'
      })
    },
    {
      key: 'HYU',
      value: i18n.t('Options.hyundai_capital_uk_limited', {
        ns: 'Common'
      })
    },
    {
      key: 'JAG',
      value: i18n.t('Options.jaguar_financial_services', {
        ns: 'Common'
      })
    },
    {
      key: 'LDR',
      value: i18n.t('Options.land_rover_financial_services', {
        ns: 'Common'
      })
    },
    {
      key: 'LAS',
      value: i18n.t('Options.la_ser_uk', {
        ns: 'Common'
      })
    },
    {
      key: 'LEA',
      value: i18n.t('Options.lease_plan_uk_limited', {
        ns: 'Common'
      })
    },
    {
      key: 'LOM',
      value: i18n.t('Options.lombard_north_central_plc', {
        ns: 'Common'
      })
    },
    {
      key: 'LSF',
      value: i18n.t('Options.london_and_surrey_motor_finance', {
        ns: 'Common'
      })
    },
    {
      key: 'MIF',
      value: i18n.t('Options.mann_island_finance', {
        ns: 'Common'
      })
    },
    {
      key: 'MAR',
      value: i18n.t('Options.marsh_finance_ltd', {
        ns: 'Common'
      })
    },
    {
      key: 'MAZ',
      value: i18n.t('Options.mazda_financial_services', {
        ns: 'Common'
      })
    },
    {
      key: 'MBF',
      value: i18n.t('Options.mercedes_benz_financial_services_uk_limited', {
        ns: 'Common'
      })
    },
    {
      key: 'NOR',
      value: i18n.t('Options.northridge_finance', {
        ns: 'Common'
      })
    },
    {
      key: 'PCF',
      value: i18n.t('Options.paccar_financial_plc', {
        ns: 'Common'
      })
    },
    {
      key: 'PAG',
      value: i18n.t('Options.paragon_car_finance', {
        ns: 'Common'
      })
    },
    {
      key: 'PEU',
      value: i18n.t('Options.peugeot_financial_services', {
        ns: 'Common'
      })
    },
    {
      key: 'PAC',
      value: i18n.t('Options.private_and_commercial', {
        ns: 'Common'
      })
    },
    {
      key: 'PSA',
      value: i18n.t('Options.psa_finance_uk_ltd', {
        ns: 'Common'
      })
    },
    {
      key: 'RCI',
      value: i18n.t('Options.rci_financial_services_limited', {
        ns: 'Common'
      })
    },
    {
      key: 'MIT',
      value: i18n.t('Options.shogun_finance_ltd_t_a_finance_mitsubishi', {
        ns: 'Common'
      })
    },
    {
      key: 'SOU',
      value: i18n.t('Options.southern_finance', {
        ns: 'Common'
      })
    },
    {
      key: 'SPO',
      value: i18n.t('Options.spot_finance_ltd', {
        ns: 'Common'
      })
    },
    {
      key: 'STL',
      value: i18n.t('Options.startline_motor_finance_ltd', {
        ns: 'Common'
      })
    },
    {
      key: 'SUZ',
      value: i18n.t('Options.suzuki_financial_services_limited', {
        ns: 'Common'
      })
    },
    {
      key: 'TCF',
      value: i18n.t('Options.the_car_finance_company', {
        ns: 'Common'
      })
    },
    {
      key: 'FUN',
      value: i18n.t('Options.the_funding_corporation_limited', {
        ns: 'Common'
      })
    },
    {
      key: 'TOY',
      value: i18n.t('Options.toyota_financial_services_uk_plc', {
        ns: 'Common'
      })
    },
    {
      key: 'VOK',
      value: i18n.t('Options.volkswagen_financial_services_uk_limited', {
        ns: 'Common'
      })
    },
    {
      key: 'VOL',
      value: i18n.t('Options.volvo_car_credit', {
        ns: 'Common'
      })
    },
    {
      key: 'ZUT',
      value: i18n.t('Options.zuto', {
        ns: 'Common'
      })
    },
    {
      key: '1ST',
      value: i18n.t('Options.1st_stop_car_finance', {
        ns: 'Common'
      })
    },
    {
      key: 'CRE',
      value: i18n.t('Options.creation_consumer_finance_limited', {
        ns: 'Common'
      })
    }
  ].concat([
    {
      key: 'Other',
      value: i18n.t('Options.other', {
        ns: 'Common'
      })
    }
  ]),
  ProductTypeBDK: [
    {
      key: 'Schlussraten',
      value: i18n.t('Options.balloon_payment', {
        ns: 'Common'
      })
    },
    {
      key: 'Klassische',
      value: i18n.t('Options.classic', {
        ns: 'Common'
      })
    }
  ],
  ProductType: [
    {
      key: 'HP',
      value: i18n.t('Options.hire_purchase', {
        ns: 'Common'
      })
    },
    {
      key: 'CS',
      value: i18n.t('Options.conditional_sale', {
        ns: 'Common'
      })
    },
    {
      key: 'BHP',
      value: i18n.t('Options.balloon_hire_purchase', {
        ns: 'Common'
      })
    },
    {
      key: 'LP',
      value: i18n.t('Options.loan', {
        ns: 'Common'
      })
    },
    {
      key: 'CH',
      value: i18n.t('Options.contract_hire', {
        ns: 'Common'
      })
    },
    {
      key: 'FL',
      value: i18n.t('Options.finance_lease', {
        ns: 'Common'
      })
    },
    {
      key: 'PCP',
      value: i18n.t('Options.personal_contract_purchase', {
        ns: 'Common'
      })
    }
  ],
  FinanceApplicationsSortOptions: [
    {
      key: 'Quote.ProductName',
      value: i18n.t('Options.product', {
        ns: 'Common'
      })
    },
    {
      key: 'Quote.CashPrice',
      value: i18n.t('Options.retail_price', {
        ns: 'Common'
      })
    },
    {
      key: 'Quote.RepresentativeApr',
      value: i18n.t('Options.apr', {
        ns: 'Common'
      })
    },
    {
      key: 'Quote.TotalAmountPayable',
      value: i18n.t('Options.total_amount_payable', {
        ns: 'Common'
      })
    },
    {
      key: 'Quote.FollowingPayments',
      value: i18n.t('Options.monthly_payments', {
        ns: 'Common'
      })
    },
    {
      key: 'LastModifiedTimestamp',
      value: i18n.t('Options.date', {
        ns: 'Common'
      })
    }
  ],
  SavedQuotesSortOptions: [
    {
      key: 'Quote.ProductName',
      value: i18n.t('Options.product', {
        ns: 'Common'
      })
    },
    {
      key: 'Quote.RepresentativeApr',
      value: i18n.t('Options.apr', {
        ns: 'Common'
      })
    },
    {
      key: 'Quote.TotalAmountPayable',
      value: i18n.t('Options.total_amount_payable', {
        ns: 'Common'
      })
    },
    {
      key: 'Quote.Term',
      value: i18n.t('Options.agreement_term', {
        ns: 'Common'
      })
    },
    {
      key: 'Quote.FollowingPayments',
      value: i18n.t('Options.monthly_payments', {
        ns: 'Common'
      })
    },
    {
      key: 'SavedTime',
      value: i18n.t('Options.date_saved', {
        ns: 'Common'
      })
    }
  ],
  CarFinanceChecksSortOptions: [
    {
      key: 'ProductName',
      value: i18n.t('Options.product', {
        ns: 'Common'
      })
    },
    {
      key: 'MatchRate',
      value: i18n.t('Options.eligibility_match', {
        ns: 'Common'
      })
    },
    {
      key: 'RepresentativeApr',
      value: i18n.t('Options.apr', {
        ns: 'Common'
      })
    },
    {
      key: 'TotalAmountPayable',
      value: i18n.t('Options.total_amount_payable', {
        ns: 'Common'
      })
    },
    {
      key: 'Term',
      value: i18n.t('Options.agreement_term', {
        ns: 'Common'
      })
    },
    {
      key: 'FollowingPayments',
      value: i18n.t('Options.monthly_payments', {
        ns: 'Common'
      })
    }
  ],
  QuotesListSortOptions: [
    {
      key: 'ProductName',
      value: i18n.t('Options.product', {
        ns: 'Common'
      })
    },
    {
      key: 'RepresentativeApr',
      value: i18n.t('Options.apr', {
        ns: 'Common'
      })
    },
    {
      key: 'TotalAmountPayable',
      value: i18n.t('Options.total_amount_payable', {
        ns: 'Common'
      })
    },
    {
      key: 'Term',
      value: i18n.t('Options.agreement_term', {
        ns: 'Common'
      })
    },
    {
      key: 'FollowingPayments',
      value: i18n.t('Options.monthly_payments', {
        ns: 'Common'
      })
    }
  ],
  SortDirectionOptions: [
    {
      key: 'asc',
      value: i18n.t('Options.ascending', {
        ns: 'Common'
      })
    },
    {
      key: 'desc',
      value: i18n.t('Options.descending', {
        ns: 'Common'
      })
    }
  ],
  QuickQuoteCustomerType: [
    {
      key: 'consumer',
      value: i18n.t('Options.private_customer', {
        ns: 'Common'
      })
    },
    {
      key: 'corporate',
      value: i18n.t('Options.business_customer', {
        ns: 'Common'
      })
    }
  ],
  ExportLeadType: [
    {
      key: 'closed-leads',
      value: i18n.t('Options.closed_deals', {
        ns: 'Common'
      })
    },
    {
      key: 'cfcleads',
      value: i18n.t('Options.car_finance_checks', {
        ns: 'Common'
      })
    },
    {
      key: 'applications',
      value: i18n.t('Options.finance_applications', {
        ns: 'Common'
      })
    }
  ],
  BnppLicenceType: [
    {
      key: 'FullUKLicence',
      value: i18n.t('Options.full_uk_licence', {
        ns: 'Common'
      })
    },
    {
      key: 'ProvisionalLicence',
      value: i18n.t('Options.provisional_licence', {
        ns: 'Common'
      })
    }
  ],
  ReservationPeriod: makeDayOptions(1, 30),
  RefundFilterOptions: [
    {
      key: 'all',
      value: i18n.t('Options.all', {
        ns: 'Common'
      })
    },
    {
      key: 'active',
      value: i18n.t('Options.active', {
        ns: 'Common'
      })
    },
    {
      key: 'refunded',
      value: i18n.t('Options.refunded', {
        ns: 'Common'
      })
    },
    {
      key: 'overdue',
      value: i18n.t('Options.expired', {
        ns: 'Common'
      })
    },
    {
      key: 'incomplete',
      value: i18n.t('Options.incomplete', {
        ns: 'Common'
      })
    },
    {
      key: 'paymentUsedAsDeposit',
      value: i18n.t('Options.payment_used_as_deposit', {
        ns: 'Common'
      })
    }
  ],
  LeadsFilterOptions: [
    {
      key: 'all',
      value: i18n.t('Options.all_leads', {
        ns: 'Common'
      })
    },
    {
      key: 'mine',
      value: i18n.t('Options.my_leads', {
        ns: 'Common'
      })
    },
    {
      key: 'unassigned',
      value: i18n.t('Options.unassigned_leads', {
        ns: 'Common'
      })
    }
  ],
  LenderStatusDev: [
    {
      key: 'Submitting',
      value: i18n.t('Options.submitting', {
        ns: 'Common'
      })
    },
    {
      key: 'Accepted',
      value: i18n.t('Options.accepted', {
        ns: 'Common'
      })
    },
    {
      key: 'ConditionalAccept',
      value: i18n.t('Options.conditional_accept', {
        ns: 'Common'
      })
    },
    {
      key: 'Rejected',
      value: i18n.t('Options.rejected', {
        ns: 'Common'
      })
    },
    {
      key: 'NoDecisionYet',
      value: i18n.t('Options.no_decision_yet', {
        ns: 'Common'
      })
    },
    {
      key: 'Referred',
      value: i18n.t('Options.referred', {
        ns: 'Common'
      })
    },
    {
      key: 'PaidOut',
      value: i18n.t('Options.paid_out', {
        ns: 'Common'
      })
    },
    {
      key: 'Pending',
      value: i18n.t('Options.pending', {
        ns: 'Common'
      })
    },
    {
      key: 'ProblemPayout',
      value: i18n.t('Options.problem_payout', {
        ns: 'Common'
      })
    },
    {
      key: 'Submitted',
      value: i18n.t('Options.submitted', {
        ns: 'Common'
      })
    },
    {
      key: 'NotTakenUp',
      value: i18n.t('Options.not_taken_up', {
        ns: 'Common'
      })
    },
    {
      key: 'WaitingForFurtherInformation',
      value: i18n.t('Options.waiting_for_further_information', {
        ns: 'Common'
      })
    },
    {
      key: 'SentForPayout',
      value: i18n.t('Options.sent_for_payout', {
        ns: 'Common'
      })
    },
    {
      key: 'NotProceeding',
      value: i18n.t('Options.not_proceeding', {
        ns: 'Common'
      })
    },
    {
      key: 'Other',
      value: i18n.t('Options.other', {
        ns: 'Common'
      })
    },
    {
      key: 'Error',
      value: i18n.t('Options.error', {
        ns: 'Common'
      })
    },
    {
      key: 'ProposalAdjustedAccepted',
      value: i18n.t('Options.proposal_adjusted_accepted', {
        ns: 'Common'
      })
    },
    {
      key: 'Intercepted',
      value: i18n.t('Options.intercepted', {
        ns: 'Common'
      })
    },
    {
      key: 'ContactLender',
      value: i18n.t('Options.contact_lender', {
        ns: 'Common'
      })
    },
    {
      key: 'RateAdjustedAccepted',
      value: i18n.t('Options.rate_adjusted_accepted', {
        ns: 'Common'
      })
    },
    {
      key: 'NoChange',
      value: i18n.t('Options.no_change', {
        ns: 'Common'
      })
    },
    {
      key: 'Timeout',
      value: i18n.t('Options.timeout', {
        ns: 'Common'
      })
    }
  ],
  CreditScoreOptions: [
    {
      key: 'NonTieredTier1',
      value: i18n.t('Options.excellent', {
        ns: 'Common'
      })
    },
    {
      key: 'NonTieredTier2',
      value: i18n.t('Options.very_good', {
        ns: 'Common'
      })
    },
    {
      key: 'NonTieredTier3',
      value: i18n.t('Options.good', {
        ns: 'Common'
      })
    },
    {
      key: 'NonTieredTier4',
      value: i18n.t('Options.fair', {
        ns: 'Common'
      })
    },
    {
      key: 'NonTieredTier5',
      value: i18n.t('Options.below_average', {
        ns: 'Common'
      })
    }
  ],
  SentDealsFilterOptions: [
    {
      key: 'all',
      value: i18n.t('Options.all', {
        ns: 'Common'
      })
    },
    {
      key: 'applicationSubmitted',
      value: i18n.t('Options.applied', {
        ns: 'Common'
      })
    },
    {
      key: 'viewed',
      value: i18n.t('Options.opened', {
        ns: 'Common'
      })
    },
    {
      key: 'sent',
      value: i18n.t('Options.sent', {
        ns: 'Common'
      })
    }
  ],
  SentDealsSoryByOptions: [
    {
      key: 'sentAt',
      value: i18n.t('Options.sent_date', {
        ns: 'Common'
      })
    },
    {
      key: 'updatedAt',
      value: i18n.t('Options.last_updated', {
        ns: 'Common'
      })
    }
  ],
  EnergyEfficiencyClass: [
    {
      key: 'A+++',
      value: 'A+++'
    },
    {
      key: 'A++',
      value: 'A++'
    },
    {
      key: 'A+',
      value: 'A+'
    },
    {
      key: 'A',
      value: 'A'
    },
    {
      key: 'B',
      value: 'B'
    },
    {
      key: 'C',
      value: 'C'
    },
    {
      key: 'D',
      value: 'D'
    },
    {
      key: 'E',
      value: 'E'
    },
    {
      key: 'F',
      value: 'F'
    },
    {
      key: 'G',
      value: 'G'
    }
  ]
});

function optionsReducer(state = {}, action) {
  switch (action.type) {
    case actionTypes.FETCH_OPTIONS_SUCCESS:
      let newOptions = formatOverrides(action.response.all);
      newOptions.Overrides = action.response.overrides;

      newOptions.LenderStatus2 = _.cloneDeep(newOptions.LenderStatus);
      _.find(newOptions.LenderStatus2, {
        key: 'ConditionalAccept'
      }).value = 'Conditional';
      _.find(newOptions.LenderStatus2, {
        key: 'NoDecisionYet'
      }).value = 'No Decision';
      _.find(newOptions.LenderStatus2, {
        key: 'WaitingForFurtherInformation'
      }).value = 'Waiting';
      newOptions.CompanySector = _.orderBy(newOptions.CompanySector, 'key');

      return Object.assign({}, state, newOptions);

    case actionTypes.RECREATE_OPTIONS:
      return Object.assign({}, state, createState());

    case actionTypes.RECREATE_CLAIM_BASED_OPTIONS:
      var options = createState();
      return Object.assign({}, state, { VehicleClass: options.VehicleClass });
    default:
      return state;
  }
}

export { createState };
export default optionsReducer;
