import platformApiUtils from '@ivendi/platform-api-utils';

export function setRepExample(dealershipId, quoteId, repType) {
  return platformApiUtils.post(`v1/rep-example/${dealershipId}`, {
    QuoteId: quoteId,
    RepresentativeExampleType: repType
  });
}

export function fetchExistingRepExample(dealershipId) {
  return platformApiUtils.get(`v1/rep-example/${dealershipId}`);
}
