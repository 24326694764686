import { connect } from 'react-redux';
import PlatformUpdates from '../PlatformUpdates';
import * as platformUpdatesActions from '../../../redux/platformUpdates/platformUpdatesActions';

function mapStateToProps(state) {
  return {
    session: state.session,
    dealership: state.dealership,
    platformUpdates: state.platformUpdates,
    pageSize: state.platformUpdates.PageSize,
    currentPage: state.platformUpdates.Page,
    totalUpdates: state.platformUpdates.Total,
    isLoading: state.platformUpdates.isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPlatformUpdates: () => {
      dispatch(platformUpdatesActions.fetchPlatformUpdates());
    },
    changePage: (newPage) => {
      dispatch(platformUpdatesActions.changePage(newPage));
    },
    resetOptions: () => {
      dispatch(platformUpdatesActions.resetOptions());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PlatformUpdates);
