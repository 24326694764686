import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FunderLogo from '../../Common/FunderLogo';
import QuoteSmallPrint from '../../Quoting/components/QuoteSmallPrint';
import QuotesListCardCheckbox from './QuotesListCardCheckbox';
import './cardOuter.scss';
import classnames from 'classnames';
import CombinedQuoteMessage from '../../Common/CombinedQuoteMessage';
import MediaQuery from 'react-responsive';
import FieldToggle from '../../Common/Form/FieldToggle';
import Modal from '../../Common/Modal/Modal';
import Icon from '../../Common/Icon/Icon';
import { fundersToShowPublicWarning } from '../../../core/fundersPublicWarning';
import _ from 'lodash';
import { addPoundSymbolToString } from '../../../core/helpers';

class QuoteCardOuter extends Component {
  state = {
    modalIsOpen: false
  };

  handleModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };

  render() {
    const {
      children,
      funder,
      quote,
      onQuoteSelect,
      vehicleClass,
      showFullQuote,
      showCombinedQuoteClick,
      showFunderLogoDesktop,
      toggleItemFullQuote,
      trackingPage,
      isPersonalQuote,
      isRbp,
      showCombinedDealDisclaimer,
      newCarOffer
    } = this.props;
    let isCombinedQuote = quote.PersonalLoan || quote.NegativeEquityLoan;
    const showFunderWarning = (funder) => {
      return funder === quote.FunderCode;
    };
    const publicWarnings = quote && quote.ConsumerWarnings && quote.ConsumerWarnings.PublicWarnings;
    const hasWarningTwoHundred = _.some(publicWarnings, { Number: 200 });

    const publicWarningsCreationAdvanceErrorMessageNumber = 506;
    const advanceWarningPoundSignPosition = 32;
    const creationAdvancePublicWarningMessage = _.find(publicWarnings, {
      Number: publicWarningsCreationAdvanceErrorMessageNumber
    });
    const creationMessageWithPoundSymbol = addPoundSymbolToString(
      creationAdvancePublicWarningMessage && creationAdvancePublicWarningMessage.Message,
      advanceWarningPoundSignPosition
    );

    return (
      <div data-thook={trackingPage} className="cardOuter">
        <div className={classnames('cardOuter__inner', quote.isChecked && 'cardOuter__inner--checked')}>
          {onQuoteSelect && <QuotesListCardCheckbox quote={quote} onQuoteSelect={onQuoteSelect} />}
          <div>
            {showFunderLogoDesktop ? (
              <div className="cardOuter__funderLogo">
                <FunderLogo funder={funder} />
              </div>
            ) : (
              <MediaQuery maxWidth="767px">
                <div className="cardOuter__funderLogo">
                  <FunderLogo funder={funder} />
                </div>
              </MediaQuery>
            )}
          </div>
          {children}
        </div>
        {!quote.Errors && (
          <div className={classnames('cardOuter__smallPrint', quote.isChecked && 'cardOuter__smallPrint--selected')}>
            <QuoteSmallPrint
              quote={quote}
              vehicleClass={vehicleClass}
              showFullQuote={showFullQuote}
              isPersonalQuote={isPersonalQuote}
              newCarOfferText={newCarOffer && newCarOffer.OfferText}
            />

            <div className="cardOuter__smallPrintButtons">
              <div className="cardOuter__combinedQuoteMessage">
                {showCombinedDealDisclaimer && (
                  <div className="cardOuter__warningOuter" onClick={this.handleModal}>
                    <div className="cardOuter__warningIcon">
                      <Icon name="alert" />
                    </div>
                    <Modal isOpen={this.state.modalIsOpen} onClose={this.handleModal}>
                      <div className="cardOuter__modalOuter">
                        <div className="cardOuter__modalTop">
                          This deal may require restructuring before proposal...
                        </div>
                        <div className="cardOuter__modalBottom">
                          The customer can complete their finance application via their account, but deals which include
                          the funding of value added products or negative equity may need to be restructured afterwards
                          into separate quotes and applications before submission to Creation Consumer Finance Limited
                        </div>
                      </div>
                    </Modal>
                    <div className="cardOuter__warning">This deal may require restructuring before proposal...</div>
                  </div>
                )}
                {fundersToShowPublicWarning.findIndex(showFunderWarning) !== -1 && hasWarningTwoHundred && (
                  <div className="cardOuter__warningOuter" onClick={this.handleModal}>
                    <div className="cardOuter__warningIcon">
                      <Icon name="alert" />
                    </div>
                    <Modal isOpen={this.state.modalIsOpen} onClose={this.handleModal}>
                      <div className="cardOuter__modalOuter">
                        <div className="cardOuter__modalTop">This Lender does not finance insurance products.</div>
                        <div className="cardOuter__modalBottom">
                          If insurance has been included in your finance example, this may need to be recalculated
                          before submission.
                        </div>
                      </div>
                    </Modal>
                    <div className="cardOuter__warning">
                      This Lender does not finance insurance products; if insurance has been included in your finance
                      example, this may need to be recalculated before submission.
                    </div>
                  </div>
                )}
                {isCombinedQuote && (
                  <CombinedQuoteMessage
                    numberOfIcons={quote.PersonalLoan && quote.NegativeEquityLoan ? 3 : 2}
                    onClick={() => showCombinedQuoteClick(quote)}
                    colour="grey"
                  />
                )}
                {creationAdvancePublicWarningMessage && (
                  <div className="cardOuter__warningOuter">
                    <div className="cardOuter__warningIcon">
                      <Icon name="alert" />
                    </div>
                    <div className="cardOuter__creationText">{creationMessageWithPoundSymbol}</div>
                  </div>
                )}

                <div>
                  {isRbp && (
                    <div className="cardOuter__rbpLabel">This APR is based on the customer’s credit rating</div>
                  )}
                </div>
              </div>
              {toggleItemFullQuote && (
                <div className="cardOuter__smallPrintButtonWrap">
                  <FieldToggle
                    showHide={showFullQuote}
                    text={'Full Quote'}
                    onClick={toggleItemFullQuote}
                    trackingPage={trackingPage}
                    trackingItem={'IndividualQuote'}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

QuoteCardOuter.propTypes = {
  children: PropTypes.node,
  funder: PropTypes.string,
  quote: PropTypes.object,
  onQuoteSelect: PropTypes.func,
  vehicleClass: PropTypes.string,
  showFullQuote: PropTypes.bool,
  showCombinedQuoteClick: PropTypes.func,
  toggleItemFullQuote: PropTypes.func,
  showFunderLogoDesktop: PropTypes.bool,
  isRbp: PropTypes.bool,
  newCarOffer: PropTypes.object
};
QuoteCardOuter.defaultProps = {
  showFunderLogoDesktop: true,
  showFullQuote: true,
  isPersonalQuote: true
};

export default QuoteCardOuter;
