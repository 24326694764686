import { useTranslation } from 'react-i18next';
import { QuoteCardVariantType, QuoteCardVariantTypeEnum } from '~/components/QuoteCard/types';
import Button from '~/components/Common/Button/Button';
import DisabledButtonWithMessage from '~/components/Common/DisabledButtonWithMessage';
import { calculateCurrentApplicationNumber } from '~/core/helpers';
import { hasExtendedTermLength } from '~/utils/quoteUtils';

const QuoteButton = ({
  variant,
  canViewConsumerApplications,
  isCombinedQuote,
  isQuickQuote,
  vehicleClass,
  financeCheck,
  isCustomerQuote,
  canCreateApplications,
  chosenQuoteId,
  dealershipId,
  consumerId,
  vehicleId,
  quoteId,
  proceedingState,
  multiQuoteRefId,
  financeType,
  financeApplications,
  canGenerateQuotes,
  lenderStatus,
  supportsPreApproval,
  handleProceed,
  handleSave,
  handleView,
  handleEdit
}: QuoteButtonProps) => {
  const { t } = useTranslation('QuoteCard');
  const loadingState = chosenQuoteId === quoteId ? proceedingState : '';

  if (variant === QuoteCardVariantTypeEnum.FinanceQuote || variant === QuoteCardVariantTypeEnum.Requote) {
    const isQuickQuoteAndExtendedTermVehicle = isQuickQuote && hasExtendedTermLength(vehicleClass);
    if (((!isQuickQuote || isQuickQuoteAndExtendedTermVehicle) && !isCustomerQuote) || isCombinedQuote) {
      return (
        <>
          {canCreateApplications && (
            <Button
              isLoading={loadingState === 'loading'}
              hasError={loadingState === 'error'}
              onClick={handleProceed}
              buttonStyle="primary"
              dataTestId="quote-card-button-proceed"
            >
              {supportsPreApproval && variant === QuoteCardVariantTypeEnum.FinanceQuote
                ? t('QuoteCardButtons.get_pre_approval')
                : t('QuoteCardButtons.proceed_to_application')}
            </Button>
          )}
        </>
      );
    } else if (canCreateApplications) {
      return (
        <>
          <Button
            isLoading={loadingState === 'loading'}
            hasError={loadingState === 'error'}
            onClick={handleProceed}
            buttonStyle="primary"
            dataTestId="quote-card-button-proceed"
          >
            {supportsPreApproval
              ? t('QuoteCardButtons.get_pre_approval')
              : t('QuoteCardButtons.proceed_to_application')}
          </Button>

          <Button
            isLoading={loadingState === 'loading'}
            hasError={loadingState === 'error'}
            onClick={handleSave}
            buttonStyle="secondary"
            dataTestId="quote-card-button-save"
          >
            {t('QuoteCardButtons.save_quote')}
          </Button>
        </>
      );
    } else {
      return (
        <Button
          isLoading={loadingState === 'loading'}
          hasError={loadingState === 'error'}
          onClick={handleSave}
          buttonStyle="primary"
        >
          {t('QuoteCardButtons.save')}
        </Button>
      );
    }
  } else if (variant === 'FinanceApplication' && canViewConsumerApplications) {
    if (multiQuoteRefId) {
      if (financeType === 'FS' && calculateCurrentApplicationNumber(multiQuoteRefId, financeApplications) === 1) {
        return (
          <DisabledButtonWithMessage
            message={t('QuoteCardButtons.you_must_submit_the_relevant_vehicle_loan_before_any_additional_loans')}
            dataTestId="quote-card-button-view"
          >
            {t('QuoteCardButtons.view_application')}
          </DisabledButtonWithMessage>
        );
      } else {
        return (
          <Button
            onClick={() => {
              handleView && handleView(true);
            }}
            buttonStyle="primary"
            dataTestId="quote-card-button-view"
          >
            {t('QuoteCardButtons.view_application')}
          </Button>
        );
      }
    } else {
      return canGenerateQuotes && canCreateApplications ? (
        lenderStatus === 'Not Submitted' ? (
          <>
            <Button onClick={handleView} buttonStyle="primary" dataTestId="quote-card-button-view">
              {t('QuoteCardButtons.view_application')}
            </Button>

            <Button onClick={handleEdit} buttonStyle="secondary" dataTestId="quote-card-button-edit">
              {t('QuoteCardButtons.edit_quote')}
            </Button>
          </>
        ) : (
          <>
            <Button onClick={handleView} buttonStyle="primary" dataTestId="quote-card-button-view">
              {t('QuoteCardButtons.view_application')}
            </Button>

            <Button buttonStyle="secondary" dataTestId="quote-card-button-edit" disabled>
              {t('QuoteCardButtons.edit_quote')}
            </Button>
          </>
        )
      ) : (
        <>
          <Button onClick={handleView} buttonStyle="primary" dataTestId="quote-card-button-view">
            {t('QuoteCardButtons.view_application')}
          </Button>

          <Button buttonStyle="secondary" dataTestId="quote-card-button-edit" disabled>
            {t('QuoteCardButtons.edit_quote')}
          </Button>
        </>
      );
    }
  } else if (variant === 'CarFinanceCheck' && canViewConsumerApplications) {
    return financeCheck?.Viewable ? (
      <Button onClick={handleView} dataTestId="quote-card-button-view">
        {t('QuoteCardButtons.view')}
      </Button>
    ) : (
      <DisabledButtonWithMessage
        message={t(
          'QuoteCardButtons.to_comply_with_i_vendis_data_retention_policy_under_gdpr_old_application_data_and_eligibility_check_detail_is_only_available_for_paid_out_applications'
        )}
        dataTestId="quote-card-button-view"
      >
        {t('QuoteCardButtons.view')}
      </DisabledButtonWithMessage>
    );
  } else if (variant === 'SavedQuote') {
    return <Button onClick={handleView}>{t('QuoteCardButtons.view_quote')}</Button>;
  } else if (variant === 'SelfServiceDeals' && dealershipId && consumerId && vehicleId && quoteId) {
    return (
      <Button
        to={`/d/${dealershipId}/consumers/${consumerId}/vehicle/${vehicleId}/selfservicequote/${quoteId}`}
        type="button"
      >
        {t('QuoteCardButtons.view')}
      </Button>
    );
  } else {
    return null;
  }
};

interface QuoteButtonProps {
  variant: QuoteCardVariantType;
  canViewConsumerApplications: boolean;
  financeCheck?: any;
  isCombinedQuote: boolean;
  isQuickQuote: boolean;
  vehicleClass: string;
  isCustomerQuote: boolean;
  canCreateApplications: boolean;
  chosenQuoteId: string;
  dealershipId?: string;
  consumerId?: string;
  vehicleId?: string;
  quoteId: string;
  proceedingState: any;
  multiQuoteRefId?: string;
  financeType?: string;
  financeApplications?: any;
  canGenerateQuotes?: boolean;
  lenderStatus?: string;
  supportsPreApproval?: boolean;
  handleProceed?: () => void;
  handleSave?: () => void;
  handleView?: (...args: any) => void;
  handleEdit?: () => void;
  handleEligibilityDetails?: () => void;
}

export default QuoteButton;
